import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import advanced from "dayjs/plugin/advancedFormat"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(advanced)

/**
 * Returns the user's timezone from session storage or default to user's current timezone
 */
export const zone = (): string => sessionStorage.getItem("session.zone") || dayjs.tz.guess()

/**
 * Formats a date using Day.js
 * @param _value The date to format
 * @param _format The desired format string
 */
export const format = (_value: string | Date | number, _format: string): string => {
  const date = dayjs(_value)
  return date.isValid() ? date.format(_format) : ""
}

/**
 * Converts a date to UTC and optionally formats it
 * @param _value The date to convert
 * @param _format Optional format string
 */
export const toUtc = (_value: string | Date | number, _format: string = ""): string => {
  const date = format(_value, "YYYY-MM-DD[T]HH:mm:ss")
  return dayjs.tz(date, zone()).utc().format(_format)
}

/**
 * Converts a date to a Day.js UTC object
 * @param _value Optional date to convert, defaults to current date
 */
export const toUtcDate = (_value?: string | Date | number) => dayjs(_value || new Date()).utc()

/**
 * Returns the current UTC date, optionally including time
 * @param _time Whether to include time or set to start of day
 */
export const utcDate = (_time = true) => {
  const date = dayjs().utc()
  return _time ? date.toDate() : date.startOf("day").toDate()
}

/**
 * Gets the UTC offset for a given date in the user's timezone
 * @param _value The date to get the offset for
 */
export const utcOffset = (_value: string) => dayjs.tz(format(_value, "YYYY-MM-DD[T]HH:mm:ss[Z]"), zone()).utcOffset()

/**
 * Converts a date to the user's timezone and formats it
 * @param _value The date to convert
 * @param _format The desired format string
 */
export const toZone = (_value: string | Date | number, _format: string = "MM/DD/YYYY") => {
  const date = format(_value, "YYYY-MM-DD[T]HH:mm:ss[Z]")
  return dayjs(date).tz("America/Chicago").format(_format)
}

/**
 * Converts a date to a Day.js object in the user's timezone
 * @param _value The date to convert
 */
export const toZoneValue = (_value: string | Date | number) => {
  const date = format(_value, "YYYY-MM-DD[T]HH:mm:ss[Z]")
  return dayjs.tz(date, zone())
}

/**
 * Creates a Date object in the user's timezone, optionally including time
 * @param _value The date to convert
 * @param _time Whether to include time or set to start of day
 */
export const zoneDate = (_value?: string | Date | number, _time = false) => {
  const zone = zoneToObject(toUtcDate(_value).toDate())
  return _time ? new Date(zone.years, zone.months, zone.date) : new Date(zone.years, zone.months, zone.date, 0, 0, 0, 0)
}

/**
 * Calculates the difference between a date and now
 * @param _value The date to compare
 * @param _unit The unit of time to return the difference in
 */
export const diffFromNow = (_value: string | Date | number, _unit: dayjs.OpUnitType = "second") => {
  return dayjs(_value).diff(dayjs(), _unit)
}

/**
 * Calculates the difference between a date and the start of its day
 * @param _value The date to compare
 * @param _format The format of the input date string
 * @param _unit The unit of time to return the difference in
 */
export const diffFromDay = (_value: string | Date | number, _format: string, _unit: dayjs.OpUnitType = "second") => {
  return dayjs(_value, _format).diff(dayjs(_value, _format).startOf("day"), _unit)
}

/**
 * Formats a duration in seconds
 * @param _value The duration in seconds
 * @param _format The desired format string
 */
export const toDuration = (_value: number, _format: string = "D[d] H[h] m[m]") => {
  return dayjs.duration(_value, "second").format(_format)
}

/**
 * Converts a duration to seconds
 * @param value The duration to convert
 */
export const timeToSeconds = (value: number) => {
  return dayjs.duration(value).asSeconds()
}

export const durationToString = (start: string, end: string) => {
  if (!start && !end) return
  const startDate = dayjs(start, "YYYY/MM/DD HH:mm")
  const endDate = dayjs(end, "YYYY/MM/DD HH:mm")
  const totalSeconds = endDate.diff(startDate, "seconds")

  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return `${minutes}:${seconds}`
}
/**
 * Formats a date as a time string
 * @param _value The date to format
 * @param _format The desired time format string
 */
export const toTime = (_value: string | Date | number, _format: string = "hh:mm:ss A") =>
  dayjs.utc(_value).format(_format)

/**
 * Resets the time component of a date to 00:00:00
 * @param _value The date to reset
 */
export const resetHours = (_value: Date | string) => {
  return dayjs(_value).startOf("day").toDate()
}

/**
 * Checks if a date is today
 * @param _value The date to check
 */
export const isToday = (_value: Date | string): boolean => {
  const currentDate = utcDate(false)
  const comparisonDate = toUtcDate(_value).startOf("day").toDate()
  return dayjs(currentDate).isSame(comparisonDate, "day")
}

/**
 * Generates an array of formatted dates between two dates
 * @param start The start date
 * @param end The end date
 * @param format The desired date format for the output
 * @param diffType The unit of time to use for generating the range
 */
export const generateDateRange = (
  start: string | Date | number,
  end: string | Date | number,
  format: string = "YYYY-MM-DD",
  diffType: dayjs.QUnitType | dayjs.OpUnitType = "day"
) => {
  const startDate = dayjs(start)
  const endDate = dayjs(end)
  const totalDiff = endDate.diff(startDate, diffType)
  const dateRange = []

  for (let i = 0; i <= totalDiff; i++) dateRange.push(startDate.add(i, diffType as dayjs.ManipulateType).format(format))

  return dateRange
}

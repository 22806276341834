<script lang="ts" setup>
const activeTab = ref<string>("Top 10(Route)")
const tabs = ref<any>(["Top 10(Route)", "Top 10(Segment)", "All"])
const emit = defineEmits(["activeTab"])

const handleTab = (tab: string) => {
  emit("activeTab", tab === "Top 10(Route)" ? "route" : tab === "Top 10(Segment)" ? "segment" : "all")
  activeTab.value = tab
}
</script>

<template>
  <div class="absolute right-1 top-1 z-20 dark:bg-gray-800">
    <div class="tab-container-data dark:bg-gray-800">
      <div v-for="tab in tabs" :key="tab" :class="['tab-data', { active: activeTab === tab }]" @click="handleTab(tab)">
        {{ tab }}
      </div>
    </div>
  </div>
</template>

<style>
.tab-container-data {
  display: flex;
  border-radius: 25px;
  border: 2px solid #ccc;
  background: white;
  padding: 6px;
}

.tab-data {
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.active {
  background-color: #17a2b8;
  color: white;
}
</style>

export const useManagementStore = defineStore("management", () => {
  const listLoading = ref<boolean>(false)
  const truckList = ref<any>([])
  const driverList = ref<any>([])
  const cardList = ref<any>([])
  const storeList = ref<any>([])
  const companiesList = ref<any>([])
  const companyList = ref<any>([])

  return { companyList, truckList, cardList, storeList, driverList, companiesList, listLoading }
})

<script setup lang="ts">
import { DatePicker } from "$/ui"
import DashboardPriceMapContent from "./DashboardPriceMapContent.vue"
import { useDashboardMap } from "~/management/dashboard"

const { mapPrices, mapLoading, mapDates, fetchFuelPrices } = useDashboardMap()
const modal = useModal()

const isModalOpen = ref<boolean>(false)

const onDateChange = () => {
  if (!mapDates.value[1]) mapDates.value[1] = mapDates.value[0]
  fetchFuelPrices()
}

onMounted(() => fetchFuelPrices())
</script>

<template>
  <div class="relative border border-gray-200 py-2 px-4 bg-white dark:bg-gray-900 dark:border-gray-700">
    <Spinner v-if="mapLoading" />
    <div class="w-full h-full flex flex-col text-xs">
      <div class="flex items-center justify-between flex-nowrap border-b border-gray-200 dark:border-gray-700 pb-1">
        <h6>
          {{ $t("labels.price_map") }}
        </h6>

        <div class="flex items-center gap-4 flex-nowrap">
          <DatePicker
            v-model="mapDates"
            :dark="isDark"
            range
            class="min-w-36"
            :placeholder="$t('placeholders.period')"
            :enable-time-picker="false"
            :clearable="false"
            :max-date="new Date()"
            @update:model-value="onDateChange"
          />

          <ButtonIcon variant="ghost" color="success" icon="lucide:refresh-ccw" width="20" @click="fetchFuelPrices" />
          <ButtonIcon
            variant="ghost"
            color="secondary"
            icon="lucide:maximize-2"
            width="20"
            @click="() => modal.show('gas-prices')"
          />
        </div>
      </div>

      <DashboardPriceMapContent :map-prices="mapPrices" />

      <Modal id="gas-prices" v-model="isModalOpen" :loading="false" :label="$t('labels.price_map')" size="xl">
        <div class="p-2.5">
          <DashboardPriceMapContent :map-prices="mapPrices" />
        </div>
      </Modal>
    </div>
  </div>
</template>

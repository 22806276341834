import type { AxiosInstance } from "axios"

import { COMPANY, DRIVER, TRUCK } from "~/management/management.model"
import { FUEL_STORE } from "~/map/stations/stations.model"
import { FuelCompaniesList } from "~/management/fuel"

export const useManagementApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1"
  const baseCard: Readonly<string> = "/api/v1/cards/company"
  const baseStores: Readonly<string> = "/api/v1/fuel/stores"
  const baseCompany: Readonly<string> = "/api/v1/company"

  return {
    GET_CARDS(params: PaginationParams): ResponseContainer<DRIVER[]> {
      return axios.$get(`${baseCard}`, { params })
    },
    GET_COMPANIES_SELECT_LIST(params: any): ResponseContainer<FuelCompaniesList[]> {
      return axios.$get(`${baseCompany}/assigned_company`, { params })
    },
    GET_STORES(params: PaginationParams, states?: string[]): Promise<IResponse<FUEL_STORE[]>> {
      const stateParams = states && states.length ? states.map((state) => `states=${state}`).join("&") : ""
      return axios.$get<IResponse<FUEL_STORE[]>>(`${baseStores}?${stateParams}`, { params })
    },
    GET_DRIVERS(params: PaginationParams): ResponseContainer<DRIVER[]> {
      return axios.$get(`${base}/driver`, { params })
    },
    GET_TRUCKS(params: PaginationParams): ResponseContainer<TRUCK[]> {
      return axios.$get(`${base}/truck`, { params })
    },
    GET_COMPANIES(params: PaginationParams): ResponseContainer<COMPANY[]> {
      return axios.$get(`${base}/company`, { params })
    },
    CREATE_STORE_LOGO(station_ids: number[], sending_type: string, formData: FormData) {
      const stationIdsString = station_ids.join(",")
      return axios.$post(`${base}/fuel/station/set-logo`, formData, {
        params: {
          station_ids: stationIdsString,
          sending_type: sending_type
        }
      })
    },
    UPLOAD_LOGO(formData: FormData) {
      return axios.$post(`${base}/fuel/station/upload-logo`, formData)
    }
  }
}

<script setup lang="ts">
import { useFuelService } from "~/management/fuel"
import { useManagementStore, useManagementService } from "~/management"

const { assignCardToTruck } = useFuelService()
const { fetchTruckList, fetchCardList } = useManagementService()

const management = useManagementStore()
const { required } = useRule()
const isLoading = ref<boolean>(false)
const form = ref<{ truck_number: any; card: Nullable<number> }>({ truck_number: null, card: null })
const { t } = useI18n()
const { cardList, truckList } = storeToRefs(management)

const rules = computed(() => ({
  truck_number: { required },
  card: { required }
}))

const { vuelidate, hasError } = useValidate(form, rules)

const onSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (isValid) assignCardToTruck(form.value.truck_number.id!, form.value.card!, isLoading)
}

const onHide = () => {
  form.value = { truck_number: null, card: null }
  vuelidate.value?.$reset()
  isLoading.value = false
}
</script>

<template>
  <Sidebar id="assign-fc" :label="$t('actions.assign_fc')" @hide="onHide">
    <form class="space-y-2.5" @submit.prevent="onSubmit">
      <div class="box">
        <FormGroup v-slot="{ id }" v-bind="hasError('truck_number')" required :label="$t('labels.truck')">
          <LazySelect
            :id="id"
            v-model="form.truck_number"
            value-prop="id"
            object
            content-refreshable
            :options="truckList"
            :placeholder="t('placeholders.select_truck')"
            :fetch="fetchTruckList"
            :get-option-label="(item: any) => item.truck_id || ''"
          />
        </FormGroup>
        <div v-if="form.truck_number" class="flex flex-nowrap items-center justify-start gap-2 text-xs">
          <p class="text-black dark:text-gray-300">{{ $t("labels.driver") }}:</p>
          <span class="capitalize text-primary-700">{{ form.truck_number?.driver_dto?.name || "N/A" }}</span>
        </div>
      </div>

      <FormGroup v-bind="hasError('card')" required>
        <LazySelect
          v-model="form.card"
          value-prop="id"
          content-refreshable
          :options="cardList"
          :placeholder="t('placeholders.select_card')"
          :fetch="fetchCardList"
          :get-option-label="(item: any) => item.number || ''"
        />
      </FormGroup>

      <div class="flex justify-end">
        <Button
          type="submit"
          :label="$t('actions.submit')"
          :loading="isLoading"
          :disabled="vuelidate.$error || isLoading"
        />
      </div>
    </form>
  </Sidebar>
</template>

<style scoped>
.box {
  @apply mt-2 flex flex-col gap-2 rounded-sm border border-gray-200 p-2 dark:border-gray-800;
}
</style>

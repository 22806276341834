<script setup lang="ts">
import { useFuelService, useFuelStore, TankDetail } from "~/management/fuel"

const { patchTankSize, getFuelSuggestions } = useFuelService()
const { required } = useRule()
const { t } = useI18n()
const rules = ref({
  tank: { required }
})

const storeTank = useFuelStore()

const modal = useModal()
const formLoading = ref<boolean>(false)
const truck = ref<string>("")
const driver = ref<string>("")
const { formTank } = storeToRefs(storeTank)
const { vuelidate, hasError } = useValidate(formTank, rules)

const onSave = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  try {
    formLoading.value = true
    await patchTankSize(formTank.value.truck_id!, formTank.value.tank)
    await getFuelSuggestions()
  } finally {
    formLoading.value = false
    modal.hide("tank-details")
    onHide()
  }
}

const onHide = () => {
  formTank.value = new TankDetail()
  formLoading.value = false
  vuelidate.value?.$reset()
}

const onBeforeShow = async ({
  id,
  tank,
  truck_id,
  name
}: {
  id: number | null
  tank: number | null
  truck_id: string
  name: string
}) => {
  if (!id) return
  await nextTick(() => {
    driver.value = name
    truck.value = truck_id
    formTank.value.truck_id = id
    formTank.value.tank = tank
  })
}
</script>

<template>
  <Modal
    id="tank-details"
    :label="$t('actions.tank_size_set')"
    :loading="formLoading"
    :close-on-backdrop="false"
    @hide="onHide"
    @before-show="onBeforeShow"
  >
    <form class="grid gap-4 p-4">
      <FormGroup :label="$t('labels.truck')">
        <span>{{ truck || "" }}</span>
      </FormGroup>

      <FormGroup :label="$t('labels.driver')">
        <span>{{ driver || "" }}</span>
      </FormGroup>

      <FormGroup v-slot="{ id }" v-bind="hasError('tank')" required :label="$t('labels.tank_size')">
        <Input :id v-model="formTank.tank" type="number" :placeholder="$t('placeholders.select_tank')" />
      </FormGroup>
    </form>

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button color="danger" :label="$t('actions.cancel')" @click="modal.hide('tank-details')" />
        <Button :label="$t('actions.submit')" @click="onSave" />
      </div>
    </template>
  </Modal>
</template>

import type { AxiosInstance } from "axios"

import {
  BookPayload,
  FuelSuggestion,
  FuelSuggestionDetail,
  SuggestionStopPayload,
  TruckCurrentLocation,
  TruckBookInfo
} from "~/management/fuel/fuel.model"

export const useFuelApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1/suggestion"
  const baseTransaction: Readonly<string> = "/api/v1/transactions"
  const baseTruck: Readonly<string> = "/api/v1/truck"

  return {
    GET_TODAYS_SUGGESTION(): ResponseContainer<number> {
      return axios.$get(`${base}/today/count`)
    },
    GET_SUGGESTION_DETAILS(id: number): ResponseContainer<FuelSuggestionDetail> {
      return axios.$get(`${base}/${id}`)
    },

    GET_TRUCK_BOOK_INFO(id: number): ResponseContainer<TruckBookInfo> {
      return axios.$get(`${baseTruck}/book/info/${id}`)
    },

    GET_SUGGESTION_DETAILS_VIN(vin: string): ResponseContainer<any> {
      return axios.$get(`${baseTruck}/info/${vin}`)
    },

    GET_COUNT_SUGGESTIONS(): ResponseContainer<number> {
      return axios.$get(`${baseTruck}/suggestion-count`)
    },

    POST_TRANSACTION_PENDING(params: { stop_id: number }): ResponseContainer {
      return axios.$post(`${baseTransaction}/pending`, null, { params })
    },

    POST_SUGGESTION(payload: BookPayload): ResponseContainer {
      return axios.$post(base, payload)
    },

    PATCH_TANK_SIZE(id: number | null, tank: number | null): ResponseContainer {
      return axios.$patch(`${baseTruck}/set-tank/${id}`, null, { params: { tank } })
    },
    EDIT_STOP(payload: SuggestionStopPayload): ResponseContainer {
      return axios.$put(`${base}/stop`, payload)
    },
    DELETE_STOP(stopId: number, reason: string, note: string): ResponseContainer {
      return axios.$delete(`${base}/stop/${stopId}`, { params: { status: reason, note } })
    },
    GET_SUGGESTIONS(
      params: Record<string, any>,
      bookerIds?: number[],
      companyIds?: number[],
      sortMiles?: any
    ): ResponseContainer<FuelSuggestion[]> {
      return axios.$get(`${baseTruck}/suggestion`, {
        params: {
          ...params,
          bookerIds: bookerIds?.join(", "),
          companyIds: companyIds?.join(", "),
          sort_miles: sortMiles
        }
      })
    },
    GET_CURRENT_LOCATION(vinNumber: string): ResponseContainer<TruckCurrentLocation> {
      return axios.$get(`/api/v1/samsara/gps/current/location`, { params: { vin_number: vinNumber } })
    },
    ASSIGN_CARD(truckId: number, cardId: number): ResponseContainer {
      return axios.$put(`${baseTruck}/assign/card/${truckId}`, null, { params: { cardId } })
    },
    ASSIGN_BOOKER(truckId: number[], bookerId: number): ResponseContainer {
      return axios.$put(`${baseTruck}/assign/booker`, null, { params: { bookerId, truck_ids: truckId.join(", ") } })
    }
  }
}

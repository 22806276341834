import { DriverLocation, Waypoint } from "~/map/stations/stations.model"

export const mockWaypoints: Waypoint[] = [
  {
    type: "pickup",
    latitude: 40.7128, // New York
    longitude: -74.006,
    sequence: 1,
    address: "350 5th Ave",
    city: "New York",
    state: "NY"
  },
  {
    type: "delivery",
    latitude: 39.9526, // Philadelphia
    longitude: -75.1652,
    sequence: 2,
    address: "1400 John F Kennedy Blvd",
    city: "Philadelphia",
    state: "PA"
  },
  {
    type: "pickup",
    latitude: 41.8781, // Chicago
    longitude: -87.6298,
    sequence: 3,
    address: "233 S Wacker Dr",
    city: "Chicago",
    state: "IL"
  },
  {
    type: "delivery",
    latitude: 34.0522, // Los Angeles
    longitude: -118.2437,
    sequence: 4,
    address: "200 N Spring St",
    city: "Los Angeles",
    state: "CA"
  }
]

export const mockDriverLocation: DriverLocation = {
  coordinates: {
    latitude: 40.7128,
    longitude: -74.006
  },
  distance: 8.4,
  state: "CT",
  location: "Connecticut",
  time: "2024-10-22T14:30:00",
  direction: "sw",
  status: "en_route"
}

export const mapRouteData = {
  driverLocation: mockDriverLocation,
  waypoints: mockWaypoints
}

<script setup lang="ts">
import { useUsersService, useUsersStore } from "~/management/users"

const modal = useModal()
const usersStore = useUsersStore()
const { getUsersDetails } = useUsersService()

const { formLoading, userCompanyList } = storeToRefs(usersStore)

const onBeforeShow = async (id: number) => {
  if (id) {
    formLoading.value = true
    await getUsersDetails(id)
    formLoading.value = false
  }
}

const onHide = () => {
  userCompanyList.value = []
  formLoading.value = false
  modal.hide("usersCompany")
}
</script>

<template>
  <Modal
    id="usersCompany"
    label="Company Details"
    :loading="formLoading"
    :close-on-backdrop="false"
    size="xs"
    @before-show="onBeforeShow"
    @hide="onHide"
  >
    <div v-if="userCompanyList && userCompanyList.length">
      <table class="min-w-full table-auto border-collapse">
        <thead>
          <tr>
            <th class="px-4 py-2 border-b text-base">Company Name</th>
            <th class="px-4 py-2 border-b text-base">Driver Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in userCompanyList" :key="index">
            <td class="px-4 py-2 border-b">{{ item.company_name }}</td>
            <td class="px-4 py-2 border-b">{{ item.driver_count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="text-center p-2">No Data</div>

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button color="secondary" :label="$t('actions.close')" @click="hide" />
      </div>
    </template>
  </Modal>
</template>

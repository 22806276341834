<script setup lang="ts">
import { computed } from "vue"

export interface IProps {
  tag?: string
  vertical?: boolean
}

const props = withDefaults(defineProps<IProps>(), { tag: "div" })

const groupClass = computed(() => (props.vertical ? "button-group-vertical" : "button-group"))
</script>

<template>
  <component v-bind="$attrs" :is="tag" :class="groupClass">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { useRouteProtection } from "$/composables/useRouteProtection"

import PriceStoreList from "~/management/priceStores/components/PriceStore.vue"
import StoreModalCreate from "~/management/priceStores/components/Stores/StoresModal.vue"
import CompanyLogoModal from "~/management/priceStores/components/Stores/CompanyLogoModal.vue"
import { RolePermissionsModel } from "~/management/roles"

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_PRICES__STORES_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <PriceStoreList />
    <CompanyLogoModal />
    <StoreModalCreate />
  </div>
</template>

<script setup lang="ts">
</script>

<template>
  <div class="app-loading">
    <h4 class="w-40 h-40 animate-pulse animation-duration-3">
      GL FUELBOARD
    </h4>
  </div>
</template>

<style lang="css">
.app-loading {
  @apply fixed top-0 left-0 w-full min-h-dvh z-[9999];
  @apply grid place-items-center overflow-hidden;
  @apply bg-gray-100 dark:bg-gray-950;
}
</style>

<template>
  <div class="w-full p-8">
    <div
      class="mb-1 flex items-center justify-between text-[13px] font-semibold uppercase tracking-wide text-gray-900 dark:text-gray-300"
    >
      {{ $t("labels.stop", { order: bookIndex ? bookIndex + 1 : 1 }) }}
      <template v-if="bookStop?.store?.store_number"></template>
      <ButtonCopy :disabled="!bookStop.amount" :text-to-copy="copyContent" @click="copyContentDetail" />
    </div>
    <div class="grid gap-1 text-[11px]">
      <div class="line">
        <p class="text-green-700">{{ $t("labels.store_name") }}:</p>
        <p class="text-[#17191C] dark:text-gray-300">
          {{ bookStop.store?.name || "" }}
          {{ bookStop.store?.store_number ? ": #" + bookStop.store?.store_number : "" }}
        </p>
      </div>
      <div class="line">
        <p class="text-green-700">{{ $t("labels.amount") }}:</p>
        <p v-if="bookStop.is_full || bookStop.amount" class="text-[#17191C] dark:text-gray-300">
          {{ bookStop.is_full ? "For a Full Tank" : "For a " + bookStop.amount || 0 }} gallons
        </p>
      </div>
      <!--      <div class="line">-->
      <!--        <p class="text-green-700">{{ $t("labels.price") }}:</p>-->
      <!--        <p class="text-[#17191C] dark:text-gray-300">${{ bookStop?.store?.actual_price?.toFixed(2) || 0 }}</p>-->
      <!--      </div>-->
      <div class="line">
        <p class="text-green-700">{{ $t("labels.address") }}:</p>
        <p class="text-[#17191C] dark:text-gray-300">
          {{
            `${bookStop?.store?.address || ""} ${bookStop?.store?.city || ""} ${bookStop?.store?.state ? ", " + bookStop?.store?.state || "" : ""} ${bookStop?.store?.zip_code || ""}`
          }}
        </p>
      </div>
      <div class="line">
        <p class="font-bold text-green-700">{{ $t("labels.distance") }}:</p>
        <p class="font-bold text-[#17191C] dark:text-gray-300">{{ distance || 0 }} miles</p>
      </div>
      <div class="line">
        <p class="text-green-700">{{ $t("labels.interstate") }}:</p>
        <p class="text-[#17191C] dark:text-gray-300">{{ bookStop?.store?.interstate || "" }}</p>
      </div>
      <div class="line">
        <p class="text-green-700">{{ $t("labels.location") }}:</p>
        <a
          v-if="bookStop?.store?.latitude && bookStop?.store?.longitude"
          class="cursor-ne-resize break-all text-[#17191C] hover:underline dark:text-gray-300"
          :href="getGoogleMapsUrl({ latitude: bookStop.store.latitude, longitude: bookStop.store.longitude })"
          target="_blank"
        >
          {{ getGoogleMapsUrl({ latitude: bookStop.store.latitude, longitude: bookStop.store.longitude }) }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BookStopModel } from "~/management/fuel"
import { getGoogleMapsUrl } from "$/utils/location"
import { ICoordinate } from "$/models"
import { useStationsService } from "~/map/stations/stations.service"

interface Props {
  bookStop: BookStopModel
  bookIndex: number
  currentLocation: ICoordinate | null
  form: any
}

const { currentLocation, bookStop, form, bookIndex } = defineProps<Props>()
const copyContent = ref<string>("")
const distance = ref<number | null>()

const { calculateRouteDistance } = useStationsService()

const copyContentDetail = () => {
  copyContent.value = `Fuel stop #${bookIndex + 1}

${bookStop.store?.name || ""} #${bookStop.store?.store_number || ""}

Amount: ${bookStop.is_full ? "For a Full Tank" : bookStop.amount ? "For a " + " " + bookStop.amount + " gallons" : 0}

Address:  ${bookStop.store?.address || ""} ${bookStop.store.city || ""} ${bookStop.store.state ? ", " + bookStop.store.state || "" : ""} ${bookStop.store.zip_code || ""}

Interstate: ${bookStop.store?.interstate || ""}

Distance: ${distance.value || 0} miles

Map link: ${
    bookStop.store.latitude && bookStop.store.longitude
      ? getGoogleMapsUrl({
          latitude: bookStop.store.latitude,
          longitude: bookStop.store.longitude
        })
      : ""
  }`
}

const lat = computed(() => bookStop?.store?.latitude)
const lng = computed(() => bookStop?.store?.longitude)

watchEffect(() => {
  if (
    currentLocation?.latitude == null ||
    currentLocation?.longitude == null ||
    lat.value == null ||
    lng.value == null
  ) {
    distance.value = 0
    return
  }

  const origin = {
    lat: currentLocation.latitude,
    lng: currentLocation.longitude
  }

  const destination = {
    lat: lat.value,
    lng: lng.value
  }

  calculateRouteDistance(origin, destination).then((res) => {
    distance.value = res
    form[bookIndex].distance = res
  })
})
</script>

<style scoped>
.line {
  @apply grid grid-cols-2;
}
</style>

export interface IBooker {
  id: number
  amount: number
  is_full: boolean
  is_matched: boolean
  status: Nullable<string>
  note: Nullable<string>
  delete_status: string
  created_at: string
  suggestion_dto: {
    id: number
    note: Nullable<string>
  }
  truck_dto: {
    truck_id: string
    booker_id: Nullable<string>
    booker: Nullable<string>
  }
  driver_dto: {
    name: Nullable<string>
  }
  fuel_station_dto: {
    store_number: string
    city: string
    state: string
    address: string
    country: string
    longitude: number
    latitude: number
    state_avg: number
  }
  fuel_price_dto: {
    actual_price: number
    effective_date: string
  }
  booker_dto: {
    name: string
  }
}

export interface IBookerStats {
  booker_id: number
  matched: number
  stop_id: number
  unmatched: number
  canceled: number
  book_avg_price: number
  state_avg_price: number
  total_stops_count: number
  booker: string
}

export class BookersModel {
  id!: number
  amount: number = 0
  isFull: boolean = false
  isMatched: boolean = false
  status: string | null = null
  notes: string | null = null
  deleteStatus: string | null = null
  truckNumber: string = ""
  driver: string = ""
  dateTime: string = ""
  fuelStop: string = ""
  location: string = ""
  price: number = 0
  stateAvgPrice: number = 0
  booker: string = ""
  suggestion: {
    id: number
    note: Nullable<string>
  } | null = null

  constructor(data?: IBooker) {
    if (data) {
      this.id = data.id
      this.amount = data.amount || 0
      this.isFull = data.is_full || false
      this.isMatched = data.is_matched || false
      this.status = data.status ?? ""
      this.notes = data.note ?? ""
      this.deleteStatus = data.delete_status
      this.dateTime = data.created_at || ""
      this.truckNumber = data.truck_dto?.truck_id || ""
      this.driver = data.driver_dto?.name || ""
      this.price = data.fuel_price_dto.actual_price || 0
      this.booker = data.booker_dto?.name || ""
      this.suggestion = data.suggestion_dto

      if (data.fuel_station_dto) {
        this.fuelStop = data.fuel_station_dto.store_number || ""
        this.location = `${data.fuel_station_dto.city}, ${data.fuel_station_dto.state}`
        this.stateAvgPrice = data.fuel_station_dto.state_avg || 0
      }
    }
  }
}

export class BookersStatsModel {
  id!: number
  booker: string = ""
  nickname: string = ""
  books: number = 0
  matched: number = 0
  unmatched: number = 0
  canceled: number = 0
  bookAvgPrice: number = 0
  stateAvgPrice: number = 0

  constructor(data?: IBookerStats) {
    if (data) {
      this.id = data.booker_id!
      this.booker = data.booker || ""
      this.unmatched = data.unmatched || 0
      this.nickname = data.nickname || ""
      this.books = data.total_stops_count || 0
      this.matched = data.matched || 0
      this.canceled = data.canceled || 0
      this.bookAvgPrice = data.book_avg_price || 0
      this.stateAvgPrice = data.state_avg_price || 0
    }
  }
}

export interface BookingParams extends PaginationParams {
  inputDate: Nullable<string>
  bookerId: Nullable<number>
  truckId?: Nullable<number>
  driverId?: Nullable<number>
}

export interface BookingTotalsStats {
  unmatched: number
  canceled: number
  total: number
  total_book_price: number
  total_state_price: number
}

export class BookingTotals {
  total_stops: number = 0
  avg_actual_price: number = 0

  constructor(data?: BookingTotals) {
    if (data) {
      this.total_stops = data.total_stops || 0
      this.avg_actual_price = data?.avg_actual_price || 0
    }
  }
}

<script setup lang="ts">
import { CardAssignModel, useCardService, useCardStore, useCardApi } from "~/management/card"
import { useManagementStore, useManagementService } from "~/management"
import { vMaska } from "maska/vue"

const { GET_ONE_CARD_NAME } = useCardApi()
const { fetchTruckList } = useManagementService()
const { assignCard, fetchLazyCard } = useCardService()
const cardStore = useCardStore()
const management = useManagementStore()

const { truckList } = storeToRefs(management)
const { required } = useRule()
const { form, formLoading, selectList } = storeToRefs(cardStore)
const { t } = useI18n()
const rules = ref({
  number: { number: { required } },
  unit_number: { truck_id: { required } },
  passcode: { required }
})
const modal = useModal()
const cardHolderName = ref<string>("")

const { vuelidate, hasError } = useValidate(form, rules)

const onSave = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  try {
    formLoading.value = true
    if (form.value.unit_number?.id) {
      form.value.truck_id = form.value.unit_number.id
    }
    await assignCard(form.value.number.id!, form.value)
    modal.hide("card-assign")
    onHide()
  } finally {
    formLoading.value = false
  }
}

const getDriverName = async (val: any) => {
  if (val && !val.id) return
  try {
    const res = await GET_ONE_CARD_NAME(val.id)
    cardHolderName.value = res.content || ""
  } catch (error) {
    console.log(error)
    cardHolderName.value = ""
  }
}

const onHide = () => {
  form.value = new CardAssignModel()
  formLoading.value = false
  vuelidate.value?.$reset()
}
</script>

<template>
  <Modal
    id="card-assign"
    :label="$t('actions.assign_card')"
    :loading="formLoading"
    :close-on-backdrop="false"
    @hide="onHide"
  >
    <form class="grid gap-4 p-4">
      <FormGroup v-bind="hasError('number.number')" required :label="t('labels.card_number')">
        <LazySelect
          v-model="form.number"
          value-prop="id"
          append-to-body
          object
          content-refreshable
          :options="selectList"
          :placeholder="t('placeholders.select_card')"
          :fetch="fetchLazyCard"
          :get-option-label="(item: any) => item.number || ''"
          autocomplete="new-password"
          @update:model-value="getDriverName"
        />
      </FormGroup>
      <div class="flex flex-nowrap items-center justify-end gap-2 text-sm">
        <p class="font-bold text-black dark:text-gray-300">{{ t("labels.driver") }}:</p>
        <span v-if="cardHolderName && form.number" class="font-bold capitalize text-primary-700">{{
          cardHolderName
        }}</span>
        <span v-else class="font-bold capitalize text-primary-700">N/A</span>
      </div>

      <FormGroup v-bind="hasError('unit_number.truck_id')" required :label="t('labels.truck')">
        <LazySelect
          v-model="form.unit_number"
          append-to-body
          object
          value-prop="id"
          content-refreshable
          :options="truckList"
          :placeholder="t('placeholders.select_truck')"
          :fetch="fetchTruckList"
          :get-option-label="(item: any) => item.truck_id || ''"
          autocomplete="new-password"
        />
      </FormGroup>

      <div class="flex flex-nowrap items-center justify-end gap-2 text-sm">
        <p class="font-bold text-black dark:text-gray-300">{{ t("labels.driver") }}:</p>
        <span v-if="form.unit_number?.driver_dto?.name" class="font-bold capitalize text-primary-700">{{
          form.unit_number?.driver_dto?.name
        }}</span>
        <span v-else class="font-bold capitalize text-primary-700">N/A</span>
      </div>

      <FormGroup v-slot="{ id }" v-bind="hasError('passcode')" required :label="$t('labels.password_birthday')">
        <InputPassword
          :id
          v-model="form.passcode"
          v-maska="'########'"
          :placeholder="$t('MMDDYYYY')"
          autocomplete="new-password"
        />
      </FormGroup>
    </form>

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button color="danger" :label="$t('actions.cancel')" @click="hide" />
        <!-- TODO: enable button on production -->
        <Button :label="$t('actions.submit')" :disabled="vuelidate.$error" @click="onSave" />
      </div>
    </template>
  </Modal>
</template>

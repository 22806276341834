import {
  createI18nMessage,
  email,
  helpers,
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
  requiredIf,
  sameAs
} from "@vuelidate/validators"

export const useRule = () => {
  const i18n = useI18n()
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })

  const usernameValidator = (value: string) => {
    if (!value) return true

    if (value.startsWith("@")) return false

    const urlPattern = /(https?:\/\/)|(www\.)|(\.[a-z]{2,})/i
    return !urlPattern.test(value)
  }

  return {
    email: withI18nMessage(email),
    required: withI18nMessage(required),
    sameAs: withI18nMessage(sameAs, { withArguments: true }),
    minLength: withI18nMessage(minLength, { withArguments: true }),
    maxLength: withI18nMessage(maxLength, { withArguments: true }),
    requiredIf: withI18nMessage(requiredIf, { withArguments: true }),
    username: withI18nMessage(usernameValidator),
    maxValue,
    helpers,
    minValue
  }
}

<script setup lang="ts">
import { computed } from "vue"
import Select from "@vueform/multiselect"

interface IProps {
  total: number
  range?: number
  modelValue?: number
  allLabel?: string
}

const props = withDefaults(defineProps<IProps>(), { range: 10, allLabel: "All" })

const model = defineModel<any>()

const options = computed(() => {
  const options = []

  for (let index = props.range; index < (props.total > 100 ? 101 : props.total); index += props.range) {
    options.push({ label: index, value: index })
  }

  if (props.total <= 1000) {
    options.push({ label: props.allLabel, value: props.total })
  }

  return options
})
</script>

<template>
  <Select v-model="model" class="sm:!w-28" append-to-body :options="options" :can-clear="false" :can-deselect="false" />
</template>

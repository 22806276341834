export type CardStatus = "ACTIVE" | "INACTIVE" | "FRAUD"

export class CardModel {
  id!: number
  number: string = ""
  unit_number: string = ""
  driver_name: string = ""
  status: CardStatus = "INACTIVE"
  dsl_limit: number = 0
  ulsd_limit: number = 0
  def_limit: number = 0
  assigned_date: string = ""
  last_updated: string = ""
  is_used: boolean = false
  limits: any = null

  constructor(data?: Partial<CardModel>) {
    if (data) {
      this.id = data.id!
      this.number = data.number || ""
      this.unit_number = data.unit_number || ""
      this.driver_name = data.driver_name || ""
      this.status = data.status || "INACTIVE"
      this.dsl_limit = data.dsl_limit || 0
      this.def_limit = data.def_limit || 0
      this.ulsd_limit = data.ulsd_limit || 0
      this.assigned_date = data.assigned_date || ""
      this.last_updated = data.last_updated || ""
      this.is_used = data.is_used || false
      this.limits = data.limits || null
    }
  }
}

export class CardAssignModel {
  number: { id: Nullable<number>; number: any } = { id: null, number: "" }
  unit_number: { truck_id: any; driver_dto: { name: string } } = { truck_id: null, driver_dto: { name: "" } }
  passcode: Nullable<string> = null
  driver_name?: Nullable<string> = null
  truck_id?: Nullable<number> = null

  constructor(data?: CardAssignModel) {
    if (data) {
      this.number = data.number
      this.unit_number = data.unit_number
      this.driver_name = data.driver_name
      this.passcode = data.passcode
      this.truck_id = data.truck_id
    }
  }
}

export class CardEditModel {
  number: string = ""
  limits: any

  constructor(data?: CardEditModel) {
    if (data) {
      this.number = data.number
      this.limits = data?.limits || null
    }
  }
}

export class CardStatisticsModel {
  active: number = 0
  total: number = 0
  inactive: number = 0

  constructor(data?: CardStatisticsModel) {
    if (data) {
      this.active = data.active
      this.total = data.total
      this.inactive = data.inactive
    }
  }
}

export interface HistoryEntry {
  id: number
  load_id: number
  column_name: string
  old_value: string
  new_value: string
  edited_by: string
  time: string
}

export interface HistoryPayload {
  column_name: string
  old_value: string
  new_value: string
}

export interface EditCardUsedPayload {
  is_used: boolean | string
}

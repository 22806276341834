export interface SystemSettings {
  created_at: string
  result: number
  fuel_percent: number
  speed: number
  update_ms: number
  active: boolean
  id: number
  minutes: number
  correction_mpg: number
}

export interface SystemSettingsPreview {
  radius: number
  minutes: number
  fuel_percent: number
  speed: number
  update_ms: number
  correction_mpg: number
}

export interface EfsSystemSettingsPreview {
  account_name: string
  username: string
  password: string
}

export interface SystemActive {
  active: string | boolean
}

export class EfsAssignModel {
  id?: Nullable<number> = null
  account_name: string = ""
  username: string = ""
  password: string = ""

  constructor(data?: EfsAssignModel) {
    if (data) {
      this.id = data.id || null
      this.account_name = data.account_name || ""
      this.username = data.username || ""
      this.password = data.password || ""
    }
  }
}

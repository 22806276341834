<script lang="ts" setup>
import { onMounted, ref, watch } from "vue"
import { storeToRefs } from "pinia"

import { useStationsService } from "~/map/stations/stations.service"
import { useStationsStore } from "~/map/stations/stations.store"
import { useFuelStore } from "~/management/fuel"
import { ICoordinate } from "$/models"
import RoutePlanner from "~/map/stations/components/RoutePlanner.vue"

const { currentLocation, directionAngle } = defineProps<{
  currentLocation: ICoordinate | null
  directionAngle: number
}>()

const { initMapv2, showDriverLocation } = useStationsService()
const { errorToast } = useAlert()

const isLoadingMap = ref<boolean>(false)
const fuelStore = useFuelStore()
const sidebar = useSidebar()
const isMapReady = ref(false)
const loadUpPoints = reactive({
  originPos: null,
  destinationPos: null,
  intermediate: []
})

const { bookForm, isVisibleMap } = storeToRefs(fuelStore)

const mapStore = useStationsStore()
const { mapContainer, globalMap } = storeToRefs(mapStore)

const initializeMap = async () => {
  isLoadingMap.value = true
  if (!sidebar.isOpen("book")) {
    isVisibleMap.value = false
    return
  }

  initMapv2()
  await updateMap()
  isMapReady.value = true

  showDriverLocation(currentLocation, directionAngle)

  isLoadingMap.value = false
}

const updateMap = async () => {
  globalMap.value.removeObjects(globalMap.value.getObjects())

  if (bookForm.value?.truck?.driver_dto?.sections_address_dto_list) {
    const addresses = bookForm.value.truck.driver_dto.sections_address_dto_list
    if (!addresses.length) {
      isVisibleMap.value = false
      await errorToast("No addresses found")
      return
    }

    loadUpPoints.originPos = { lat: addresses[0].latitude, lng: addresses[0].longitude }
    loadUpPoints.destinationPos = {
      lat: addresses[addresses.length - 1].latitude,
      lng: addresses[addresses.length - 1].longitude
    }

    loadUpPoints.intermediate = addresses.slice(1, -1).map((addr) => ({
      lat: addr.latitude,
      lng: addr.longitude
    }))
  }
}

watch(
  () => currentLocation,
  async (newLocation) => {
    if (isMapReady.value && newLocation) {
      isLoadingMap.value = true
      await updateMap()
      showDriverLocation(newLocation, directionAngle)
      isLoadingMap.value = false
    }
  }
)

onMounted(initializeMap)

// re-initialize map on dark mode switch

// watch(
//   () => currentLocation,
//   () => {
//     initializeMap()
//   }
// )

// watch([() => isDark.value, () => bookForm.value?.truck?.vin_number], () => {
//   updateMapTheme(isDark.value)
// })
</script>

<template>
  <div class="relative">
    <div v-if="isLoadingMap" class="absolute inset-0 z-10 flex items-center justify-center bg-white/75">
      <div class="h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
    </div>

    <div ref="mapContainer" class="h-[750px] min-w-full 2xl:h-[90vh]">
      <RoutePlanner
        :current-location="currentLocation"
        :direction-angle="directionAngle"
        :load-up-points="loadUpPoints"
        :is-map-ready="isMapReady"
      />
    </div>
  </div>
</template>

import { BookPayload, FuelModel, SuggestionStopPayload, useFuelApi, useFuelStore } from "~/management/fuel"
import { Ref } from "vue"
import { push } from "notivue"

export const useFuelService = () => {
  const fuelApi = useFuelApi()
  const sidebar = useSidebar()
  const fuelStore = useFuelStore()
  const modal = useModal()
  const { successToast } = useAlert()
  const {
    sortMiles,
    companyIds,
    list,
    listLoading,
    query,
    pagination,
    bookForm,
    isLoading,
    bookerIds,
    suggestion_stops,
    truck_data
  } = storeToRefs(fuelStore)

  const getTruckBookInfo = async (id: number) => {
    try {
      await fuelApi.GET_TRUCK_BOOK_INFO(id).then(({ content }) => {
        if (content?.message) {
          push.success(content.message)
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getFuelSuggestions = () => {
    listLoading.value = true
    fuelApi
      .GET_SUGGESTIONS(fuelStore.transformQuery(), bookerIds.value, companyIds.value, sortMiles.value)
      .then(({ content, totalElements }) => {
        list.value = content.map((suggestItem) => new FuelModel(suggestItem))
        pagination.value.total = totalElements ?? 0
      })
      .finally(() => (listLoading.value = false))
  }

  const getTodaySuggestionCount = (count: Ref<number>) => {
    fuelApi.GET_TODAYS_SUGGESTION().then(({ content }) => {
      count.value = content
    })
  }

  const requestPendingTransaction = async (id: number, onSuccess?: () => void) => {
    try {
      isLoading.value = true
      const res = await fuelApi.POST_TRANSACTION_PENDING({ stop_id: id })
      if (res?.content !== "ok") return
      if (suggestion_stops.value.length === 1) {
        modal.hide("book_details")
        getFuelSuggestions()
      } else {
        onSuccess?.()
      }
    } catch (err) {
      console.log(err, "err")
    } finally {
      isLoading.value = false
    }
  }

  const getFuelSuggestionDetails = async (id: number, isLoading: Ref<boolean>) => {
    isLoading.value = true
    await fuelApi
      .GET_SUGGESTION_DETAILS(id)
      .then(({ content }) => {
        const { suggestion_stops: stops, ...restData } = content
        suggestion_stops.value = stops
        truck_data.value = restData
      })
      .finally(() => (isLoading.value = false))
  }
  const getFuelSuggestionDetailsVinNumber = async (vin: string, isLoading: Ref<boolean>) => {
    isLoading.value = true
    await fuelApi
      .GET_SUGGESTION_DETAILS_VIN(vin)
      .then(({ content }) => {
        if (content.unit_number) {
          const { ...restData } = content
          truck_data.value = restData
        }
      })
      .finally(() => (isLoading.value = false))
  }

  const addFuelSuggestion = (loading: Ref<boolean>) => {
    loading.value = true
    fuelApi
      .POST_SUGGESTION(new BookPayload(bookForm.value))
      .then(() => {
        getFuelSuggestions()
        successToast("Successfully added fuel suggestion")
      })
      .finally(() => {
        loading.value = false
      })
  }

  const patchTankSize = async (id: number | null, tank: number | null) => {
    return await fuelApi.PATCH_TANK_SIZE(id, tank)
  }

  const assignCardToTruck = (truckId: number, cardId: number, loading: Ref<boolean>) => {
    loading.value = true
    fuelApi
      .ASSIGN_CARD(truckId, cardId)
      .then(() => {
        getFuelSuggestions()
        sidebar.hide("assign-fc")
      })
      .finally(() => {
        loading.value = false
      })
  }

  const assignBookerToTruck = async (truckId: any, bookerId: number, loading: Ref<boolean>) => {
    loading.value = true
    await fuelApi
      .ASSIGN_BOOKER(truckId, bookerId)
      .then(() => {
        getFuelSuggestions()
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateSuggestionStop = (payload: SuggestionStopPayload, loading: Ref<boolean>, onSuccess?: () => void) => {
    loading.value = true
    fuelApi
      .EDIT_STOP(payload)
      .then(() => {
        onSuccess?.()
        sidebar.hide("book-edit")
      })
      .finally(() => {
        loading.value = false
      })
  }

  const removeSuggestionStop = (
    { stopId, reason, note }: { stopId: number; reason: string; note: string },
    loading: Ref<boolean>,
    onSuccess?: () => void
  ) => {
    loading.value = true
    fuelApi
      .DELETE_STOP(stopId, reason, note)
      .then(() => {
        if (suggestion_stops.value.some((item) => item.supplier_code) && suggestion_stops.value.length === 2) {
          modal.hide("book_details")
          getFuelSuggestions()
        } else if (suggestion_stops.value.length === 1) {
          modal.hide("book_details")
          getFuelSuggestions()
        } else {
          onSuccess?.()
          sidebar.hide("book-delete")
        }
      })
      .finally(() => {
        loading.value = false
      })
  }

  const debouncedSearch = useDebounceFn(() => {
    query.value.page = 0
    getFuelSuggestions()
  }, 600)

  return {
    getFuelSuggestionDetailsVinNumber,
    patchTankSize,
    getTruckBookInfo,
    getFuelSuggestions,
    debouncedSearch,
    addFuelSuggestion,
    getFuelSuggestionDetails,
    assignCardToTruck,
    requestPendingTransaction,
    assignBookerToTruck,
    updateSuggestionStop,
    removeSuggestionStop,
    getTodaySuggestionCount
  }
}

<template>
  <div class="w-full max-w-4xl mx-auto">
    <table class="w-full text-xs border-collapse">
      <thead>
        <tr class="text-green-700 font-semibold text-center">
          <th class="uppercase bg-transparent text-black text-left">{{ $t("thead.truck") }}</th>
          <th class="bg-transparent text-left">{{ $t("thead.location") }}</th>
          <th class="bg-transparent">{{ $t("thead.date_time") }}</th>
          <th class="bg-transparent">{{ $t("thead.fuel") }}</th>
          <th class="bg-transparent">{{ $t("thead.miles") }}</th>
        </tr>
      </thead>
      <tbody class="border border-gray-200 bg-[#30B0C7] text-white text-center">
        <tr>
          <td class="py-1">
            <div class="grid grid-cols-2 gap-6 p-1 w-24">
              <span class="font-semibold">#{{ truck.truck_id || "" }}</span>
              <div class="relative">
                <Icon icon="lucide:truck" width="20" />
                <FuelTruckStopDirection
                  v-if="index !== 0"
                  :distance="distanceBetweenStop"
                  :duration="formatDuration(route?.duration || 0)"
                  class-name="absolute bottom-8 -right-12"
                />
              </div>
            </div>
          </td>
          <td class="py-1">
            <div class="flex items-center">
              <span v-if="truck?.location"> {{ truck.location }} |</span>
              <ButtonIcon
                v-if="truck?.latitude && truck?.longitude"
                icon="lucide:external-link"
                :href="getGoogleMapsUrl({ latitude: truck.latitude, longitude: truck.longitude })"
                target="_blank"
                width="14"
                style="color: white"
                variant="ghost"
                size="xs"
              />
              <span v-else>-</span>
            </div>
          </td>
          <td class="py-1">{{ truck.time ? toZone(truck.time, "MM/DD HH:mm CT") : "" }}</td>
          <td class="py-1">
            <div class="flex items-center gap-2 flex-nowrap w-full">
              <span class="text-xs font-semibold whitespace-nowrap">{{ truck.fuel_level || "" }}%</span>
              <div class="relative flex-grow h-5 bg-gray-200 rounded-sm overflow-hidden w-[50px]">
                <div
                  class="absolute inset-y-0 left-0 bg-black transition-all duration-300 ease-in-out"
                  :style="{ width: `${Math.min(truck.fuel_level, 100)}%` }"
                />
              </div>
            </div>
          </td>
          <td class="py-1">{{ truck.miles || "" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"

import FuelTruckStopDirection from "~/management/fuel/components/details/FuelTruckStopDirection.vue"

import { getGoogleMapsUrl } from "$/utils/location"
import { formatDuration } from "$/utils/string"

import { CALCULATED_ROUTE, FUEL_TRUCK } from "~/management/fuel"

const props = defineProps<{
  truck: FUEL_TRUCK
  index: number
  route?: CALCULATED_ROUTE
}>()

const distanceBetweenStop = computed(() => {
  const meters = props.route?.distance || 0
  const miles = meters * 0.000621371
  return Math.round(miles * 10) / 10
})
</script>

import type { AxiosInstance } from "axios"

export const useAxios = (): AxiosInstance => {
  const instance = inject<AxiosInstance>("$axios")!

  if (!instance) {
    console.error("Instance of axios not found")
    console.warn(
      "To use the useAxios, you need to create an instance of axios using the createAxios method in the main.ts file"
    )
  }

  return instance
}

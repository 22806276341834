<script setup lang="ts">
import dayjs from "dayjs"

import { ButtonIcon, DatePicker, Icon } from "$/ui"

import { PricesHistoryModelTable, usePriceStoresService, usePriceStoresStore } from "~/management/priceStores"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"

const { t } = useI18n()
const { getPriceHistoryListTable, getStoreStatistics, getSelectPricesHistory } = usePriceStoresService()
const store = usePriceStoresStore()
const { listPriceHistory, listLoading, storeStatistics, queryPriceHistory, selectPriceHistory, todayAveragePrice } =
  storeToRefs(store)
const date = ref<any>(new Date())
const mouseDownColIndex = ref<any>(0)
const hoveredRowIndex = ref<any>(0)
const hoveredColIndex = ref<any>(0)
const isToday = ref<boolean>(false)

const getFilteredPriceHistoryListTable = (): void => {
  queryPriceHistory.value.page = 0
  getPriceHistoryListTable()
}

const handleDateChange = (selectedDate: { month: number; year: number } | null) => {
  if (!selectedDate) return

  const selected = dayjs(new Date(selectedDate.year, selectedDate.month))

  queryPriceHistory.value.fromDate = selected.startOf("month").format("YYYY-MM-DD")
  queryPriceHistory.value.toDate =
    selected.date() === 1 ? selected.endOf("month").format("YYYY-MM-DD") : selected.format("YYYY-MM-DD")
  isSimilarDate(selectedDate)
  getFilteredPriceHistoryListTable()
}

const columnConfig = computed<ITableCol<PricesHistoryModelTable>[]>(() => {
  const baseCols: ITableCol<PricesHistoryModelTable>[] = [
    {
      name: "idx",
      label: t("thead.no")
    },
    {
      name: "store_number",
      label: t("thead.store")
    },
    {
      name: "city",
      label: t("thead.city")
    },
    {
      name: "state",
      label: t("thead.st")
    }
  ]

  if (isToday.value) {
    baseCols.push({
      name: "today",
      label: t("thead.today")
    })
  }

  return baseCols
})

const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate()
}

const daysInMonth = computed(() => {
  const [year, month] = queryPriceHistory.value.fromDate.split("-").map(Number)
  return getDaysInMonth(year, month - 1)
})

const cols = computed<ITableCol<PricesHistoryModelTable>[]>(() => [
  ...columnConfig.value.map((col: any, index: number) => ({
    name: col.name,
    label: col.label,
    dataClass: mouseDownColIndex.value == index && hoveredColIndex.value >= index ? "select-text" : ""
  })),
  ...Array.from({ length: daysInMonth.value }, (_, i) => {
    const trueIndex = i + columnConfig.value.length
    const day = daysInMonth.value - i
    return {
      name: `actual_price_${day}`,
      label: `${day}`,
      dataClass: mouseDownColIndex.value == trueIndex && hoveredColIndex.value >= trueIndex ? "select-text" : ""
    }
  })
])

const refresh = () => {
  getPriceHistoryListTable()
  getStoreStatistics()
}

onMounted(() => {
  handleDateChange({ month: date.value.getUTCMonth(), year: date.value.getUTCFullYear() })
})

const mouseDown = (event: any) => {
  const cell = event.target.closest("td, th")
  if (!cell) return
  const row = cell.parentNode

  mouseDownColIndex.value = [...row.children].indexOf(cell)
}

const mouseMove = (event: any) => {
  const cell = event.target.closest("td, th")
  if (!cell) return
  const row = cell.parentNode
  const table = row.parentNode
  const colIndex = [...row.children].indexOf(cell)
  const rowIndex = [...table.children].indexOf(row)
  hoveredColIndex.value = colIndex
  hoveredRowIndex.value = rowIndex
}

const isSimilarDate = (selectedDate: any) => {
  let currentDate = new Date()
  isToday.value = currentDate.getMonth() === selectedDate.month && currentDate.getFullYear() === selectedDate.year
}
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="w-1/8 flex items-center gap-3">
        <DatePicker
          v-model="date"
          :dark="isDark"
          class="min-w-36"
          year-picker
          month-picker
          :clearable="false"
          :max-date="new Date()"
          @update:model-value="handleDateChange"
        />
        <LazySelect
          v-model="queryPriceHistory.fuel_station_id"
          value-prop="id"
          content-refreshable
          :options="selectPriceHistory"
          :placeholder="t('placeholders.store')"
          :fetch="getSelectPricesHistory"
          :get-option-label="(item: any) => item.store_number || ''"
          @update:model-value="getFilteredPriceHistoryListTable"
        />
      </div>
    </template>
    <div class="flex items-stretch justify-end gap-5">
      <HeaderAmountItem :amount="todayAveragePrice" :description="$t('labels.today_avg')" />
      <HeaderAmountItem :amount="storeStatistics.totalStores" :description="$t('labels.total_stores')" />
      <ButtonIcon variant="ghost" color="success" :loading="listLoading" @click="refresh">
        <Icon icon="lucide:refresh-ccw" :width="20" />
      </ButtonIcon>
    </div>
  </TableHeader>

  <Table
    no-wrap
    :skeleton="listLoading"
    :loading="listLoading"
    :cols="cols"
    :rows="listPriceHistory"
    class="price-table mt-4 select-none"
    head-class="bg-slate-200 dark:bg-gray-800"
    @mousemove="mouseMove"
    @mousedown="mouseDown"
  >
    <template #idx="{ idx }">
      {{ idx ? idx : "" }}
    </template>

    <template #today="{ row }">
      <span v-if="!!row.fuel_prices?.length" class="text-info-500">
        {{ (row.fuel_prices[row.fuel_prices.length - 1].actual_price || 0).toFixed(2) }}
      </span>
    </template>
  </Table>

  <TableFooter
    v-model:page="queryPriceHistory.page"
    v-model:per-page="queryPriceHistory.size"
    :total="queryPriceHistory.total"
    class="select-none"
    @change="getPriceHistoryListTable"
  />
</template>

<style>
.price-table tbody tr:first-of-type {
  position: sticky;
  top: 1.74rem;
  background: rgb(226 232 240);
  font-weight: bold;
}

.dark .price-table tbody tr:first-of-type {
  position: sticky;
  top: 1.74rem;
  color: white;
  background: rgb(31 41 55);
  font-weight: bold;
}
</style>

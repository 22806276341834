<script setup lang="ts">
import { ButtonIcon, Icon } from "$/ui"
import {
  PricesModelTable,
  usePriceStoresService,
  usePriceStoresStore,
  PriceStoresStatistics
} from "~/management/priceStores"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"

const { t } = useI18n()
const { getPriceListTable, getStoreStatistics, getSelectPrices } = usePriceStoresService()
const store = usePriceStoresStore()
const { listPriceTable, listLoading, storeStatistics, queryPrice, queryPriceHistory, queryStores, selectPrices } =
  storeToRefs(store)
const date = ref<any>(new Date())

onMounted(() => {
  queryPrice.value.fromDate = `${date.value.getFullYear()}-${String(date.value.getMonth() + 1).padStart(2, "0")}-${date.value.getDate()}`
  queryPrice.value.toDate = `${date.value.getFullYear()}-${String(date.value.getMonth() + 1).padStart(2, "0")}-${date.value.getDate() + 1}`
  getPriceListTable()
})

const cols = computed<ITableCol<PricesModelTable>[]>(() => [
  {
    name: "idx",
    label: t("thead.no")
  },
  {
    name: "store_number",
    label: t("thead.store")
  },
  {
    name: "adress",
    label: t("thead.address")
  },
  {
    name: "retail_price",
    label: t("thead.retail_price")
  },

  {
    name: "discount_retail",
    label: t("thead.discount_retail")
  },
  {
    name: "savings",
    label: t("thead.savings")
  },
  {
    name: "today",
    label: t("thead.today")
  },
  {
    name: "tomorrow",
    label: t("thead.tomorrow")
  }
])

const getFilteredPriceListTable = (): void => {
  queryPrice.value.page = 0
  getPriceListTable()
}

const refresh = () => {
  getPriceListTable()
  getStoreStatistics()
}

onUnmounted(() => {
  queryPrice.value = new PriceStoresStatistics()
  queryPriceHistory.value = new PriceStoresStatistics()
  queryStores.value = new PriceStoresStatistics()
})
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="w-1/8">
        <LazySelect
          v-model="queryPrice.fuel_station_id"
          value-prop="id"
          content-refreshable
          :options="selectPrices"
          :placeholder="t('placeholders.store')"
          :fetch="getSelectPrices"
          :get-option-label="(item: any) => item.store_number || ''"
          @update:model-value="getFilteredPriceListTable"
          @clear="getFilteredPriceListTable"
        />
      </div>
    </template>
    <div class="flex items-stretch justify-end gap-5">
      <HeaderAmountItem :amount="storeStatistics.totalStores" :description="$t('labels.total_stores')" />
      <ButtonIcon variant="ghost" color="success" :loading="listLoading" @click="refresh">
        <Icon icon="lucide:refresh-ccw" :width="20" />
      </ButtonIcon>
    </div>
  </TableHeader>
  <Table
    no-wrap
    :skeleton="listLoading"
    :loading="listLoading"
    :cols="cols"
    :rows="listPriceTable"
    class="mt-4"
    head-class="bg-slate-200 dark:bg-gray-800"
  >
    <template #idx="{ idx, sequence }">
      {{ sequence(idx, queryPrice.page || 0, queryPrice.size || 10) }}
    </template>

    <template #retail_price="{ row }">
      {{ row.fuel_prices && row.fuel_prices[0].retail_price }}
    </template>

    <template #discount_retail="{ row }">
      {{ row.fuel_prices && row.fuel_prices[0].disc_retail }}
    </template>
    <template #savings="{ row }">
      {{ row.fuel_prices && row.fuel_prices[0].savings_total }}
    </template>
    <template #today="{ row }">
      {{ row.fuel_prices && row.fuel_prices[0].actual_price }}
    </template>
    <template #tomorrow="{ row }">
      {{ row.fuel_prices && row.fuel_prices.length === 2 ? row.fuel_prices[1].actual_price : "" }}
    </template>
  </Table>

  <TableFooter
    v-model:page="queryPrice.page"
    v-model:per-page="queryPrice.size"
    :total="queryPrice.total"
    @change="getPriceListTable"
  />
</template>

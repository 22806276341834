import "floating-vue/dist/style.css"
import "@vuepic/vue-datepicker/dist/main.css"
import "$/assets/css/index.css"

import { createApp } from "vue"
import { VTooltip } from "floating-vue"

import { http, i18n, notivue, router, store } from "./plugins"
import { App } from "./components"

export const app = createApp(App)

app.use(i18n)
app.use(http)
app.use(notivue)
app.use(router)
app.use(store)

app.directive("tooltip", VTooltip)

app.mount("#app")

<script setup lang="ts">
import { provide, ref, useSlots } from "vue"

interface Props {
  title?: string
  vertical?: boolean
  unique?: string
  pill?: boolean
  modelValue?: string
}

const props = defineProps<Props>()
const emit = defineEmits(["update:modelValue"])

const slots: any = useSlots()
const tabTitles = ref(
  slots
    .default()
    .map((tab: any) => tab.props?.title)
    .filter(Boolean)
)
const selectedTitle = ref(props.modelValue || tabTitles.value[0])
provide("selectedTitle", selectedTitle)

// Watch for external changes to modelValue
watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      selectedTitle.value = newValue
    }
  }
)

function onTitleSelected(title: any) {
  selectedTitle.value = title
  emit("update:modelValue", title)
}
</script>

<template>
  <div :class="vertical ? 'flex gap-5' : ''" class="tabs__main">
    <ul class="font-base mb-5 border-0 text-sm text-gray-500" :class="vertical ? 'w-1/6' : 'flex flex-wrap'">
      <li
        v-for="item in tabTitles"
        :key="item"
        class="mr-2 cursor-pointer px-5 py-2"
        :class="{
          'border-b-[2px] border-slate-800 font-medium text-slate-950	': item === selectedTitle && !pill,
          'bg-primary active shadow shadow-primary text-white rounded': item === selectedTitle && pill
        }"
        @click="onTitleSelected(item)"
      >
        <span class="dark:text-gray-400">{{ item }}</span>
      </li>
    </ul>
    <div :class="vertical ? 'w-5/6' : ''" class="tabs__main__body">
      <slot />
    </div>
  </div>
</template>

import { StoresAssignModel, usePriceStoresApi, usePriceStoresStore } from "~/management/priceStores"
// import { UsersAssignModel } from "~/management/users"

export const usePriceStoresService = () => {
  const priceStoresStore = usePriceStoresStore()
  const priceStoresApi = usePriceStoresApi()

  const {
    queryStores,
    queryPrice,
    queryPriceHistory,
    selectPriceHistory,
    selectPrices,
    listPriceTable,
    listPriceHistory,
    form,
    list,
    listLoading,
    storeStatistics,
    todayAveragePrice
  } = storeToRefs(priceStoresStore)

  const getPriceListTable = async () => {
    try {
      listLoading.value = true
      const { content, page, totalElements }: any = await priceStoresApi.GET_PRICE_TABLE({
        page: queryPrice.value.page,
        size: queryPrice.value.size,
        keyword: queryPrice.value.keyword,
        fromDate: queryPrice.value.fromDate,
        toDate: queryPrice.value.toDate,
        fuel_station_id: queryPrice.value.fuel_station_id
      })
      listPriceTable.value = content
      queryPrice.value.page = page
      queryPrice.value.total = totalElements
      listLoading.value = false
    } catch (err) {
      listLoading.value = false
    }
  }

  const getPriceHistoryListTable = async () => {
    try {
      listLoading.value = true
      const res: any = await priceStoresApi.GET_PRICE_HISTORY_AVG_PRICE({
        from_date: queryPriceHistory.value.fromDate,
        to_date: queryPriceHistory.value.toDate
      })
      const { content, page, totalElements }: any = await priceStoresApi.GET_PRICE_HISTORY_LIST_TABLE({
        page: queryPriceHistory.value.page,
        size: queryPriceHistory.value.size,
        keyword: queryPriceHistory.value.keyword,
        fromDate: queryPriceHistory.value.fromDate,
        toDate: queryPriceHistory.value.toDate,
        fuel_station_id: queryPriceHistory.value.fuel_station_id
      })
      if (res.content && content) {
        todayAveragePrice.value = res.content[res.content.length - 1].avg_actual_price
        res.content.forEach((item: any) => {
          item.actual_price = item.avg_actual_price
        })
        const result = res.content.reduce((acc: Record<string, number>, item: any) => {
          const date = new Date(item.effective_date).getUTCDate()
          acc[`actual_price_${date}`] = item.avg_actual_price // Assign dynamically
          return acc
        }, {})

        content.forEach((item: any) => {
          item.fuel_prices &&
            item.fuel_prices.forEach((i: any) => {
              const date = new Date(i.effective_date).getUTCDate()
              item[`actual_price_${date}`] = i.actual_price // Dynamic property assignment
            })
        })
        listPriceHistory.value = [{ ...result, fuel_prices: [...res.content] }, ...content]
        queryPriceHistory.value.page = page
        queryPriceHistory.value.total = totalElements
      }
      listLoading.value = false
    } catch (err) {
      listLoading.value = false
    }
  }

  const setActivateStore = async (id: number) => {
    return await priceStoresApi.PATCH_ACTIVE_STORE(id)
  }

  const setDeactivateStore = (id: number) => {
    return priceStoresApi.PATCH_DEACTIVATE_STORE(id)
  }
  const getSelectPricesHistory = async (val: { page?: number; size?: number; keyword?: string }) => {
    await priceStoresApi
      .GET_PRICE_HISTORY_LIST_TABLE({
        page: val.page,
        size: val.size,
        keyword: val.keyword,
        fromDate: queryPriceHistory.value.fromDate,
        toDate: queryPriceHistory.value.toDate
      })
      .then((res: any) => {
        selectPriceHistory.value = res
      })
  }

  const getSelectPrices = async (val: { page?: number; size?: number; keyword?: string }) => {
    await priceStoresApi
      .GET_PRICE_TABLE({
        page: val.page,
        size: val.size,
        keyword: val.keyword,
        fromDate: queryPrice.value.fromDate,
        toDate: queryPrice.value.toDate
      })
      .then((res: any) => {
        selectPrices.value = res
      })
  }
  const getOneStoreList = async (id: number) => {
    const { content }: any = await priceStoresApi.GET_ONE_STORE(id)
    if (content) form.value = new StoresAssignModel(content)
  }

  const getStoresListTable = async () => {
    try {
      listLoading.value = true
      const { content, page, totalElements }: any = await priceStoresApi.GET_STORE_LIST_TABLE({
        page: queryStores.value.page,
        size: queryStores.value.size,
        keyword: queryStores.value.keyword,
        fuel_station_id: queryStores.value.fuel_station_id,
        state: queryStores.value.state
      })
      list.value = content
      queryStores.value.page = page
      queryStores.value.total = totalElements
      listLoading.value = false
    } catch (err) {
      listLoading.value = false
    }
  }

  const setStore = async (upload_id: number) => {
    return await priceStoresApi.POST_NEW_STORE({ ...form.value, file_id: upload_id })
  }
  const updateStore = async (value: any) => {
    return await priceStoresApi.PUT_NEW_STORE(value)
  }

  const getStoreStatistics = async () => {
    try {
      const res = await priceStoresApi.GET_STORE_STATISTICS()
      storeStatistics.value = res.content
    } catch (error) {
      console.error(error, "error store statistics")
    }
  }

  return {
    updateStore,
    getOneStoreList,
    getSelectPricesHistory,
    getSelectPrices,
    setDeactivateStore,
    setActivateStore,
    getStoreStatistics,
    setStore,
    getPriceHistoryListTable,
    getPriceListTable,
    getStoresListTable
  }
}

<script setup lang="ts">
import Tabs from "~/management/components/TabsContent.vue"
import Tab from "~/management/components/TabContent.vue"
import PricesList from "~/management/priceStores/components/Prices/PricesList.vue"
import PriceHistoryList from "~/management/priceStores/components/PriceHistory/PricesHistoryList.vue"
import StoresList from "~/management/priceStores/components/Stores/StoresList.vue"

import { usePriceStoresService } from "~/management/priceStores"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const shouldBeShown = computed(() => profile.value.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_PAGE_GET))

const { getStoreStatistics } = usePriceStoresService()
onMounted(() => {
  getStoreStatistics()
})
</script>
<template>
  <Tabs>
    <Tab v-if="shouldBeShown" title="PRICES">
      <PricesList />
    </Tab>
    <Tab v-if="shouldBeShown" title="PRICES HISTORY">
      <PriceHistoryList />
    </Tab>
    <Tab v-if="profile.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_STORES_PAGE_GET)" title="STORES">
      <StoresList />
    </Tab>
  </Tabs>
</template>

<script setup lang="ts">
import {
  BookStopModel,
  SuggestionStop,
  SuggestionStopPayload,
  useFuelApi,
  useFuelService,
  useFuelStore
} from "~/management/fuel"
import FuelBookStop from "~/management/fuel/components/FuelBookStop.vue"
import { useManagementService, useManagementStore } from "~/management"
import { ICoordinate } from "$/models"

const { GET_CURRENT_LOCATION } = useFuelApi()
const { fetchStoresList } = useManagementService()
const { updateSuggestionStop } = useFuelService()
const fuelStore = useFuelStore()
const managementStore = useManagementStore()

const { storeList } = storeToRefs(managementStore)
const { truck_data, isOpenEditSidebar } = storeToRefs(fuelStore)
const { required } = useRule()

const emit = defineEmits(["reload"])

const rules = computed(() => ({
  store: { required },
  amount: { required }
}))
const { t } = useI18n()
const form = ref<BookStopModel>(new BookStopModel())
const loading = ref<boolean>(false)
const currentLocation = ref<ICoordinate | null>(null)

const { vuelidate, hasError } = useValidate(form, rules)

const onSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()

  if (isValid) updateSuggestionStop(new SuggestionStopPayload(form.value), loading, () => emit("reload"))
}

const onBeforeShow = (args: SuggestionStop) => {
  form.value = new BookStopModel(args)
  if (!truck_data.value?.vin_number) return
  GET_CURRENT_LOCATION(truck_data.value.vin_number)
    .then((res: any) => {
      currentLocation.value = {
        latitude: res.content.latitude || null,
        longitude: res.content.longitude || null
      }
    })
    .catch(() => (currentLocation.value = null))
}

const onHide = () => {
  form.value = new BookStopModel()
  vuelidate.value?.$reset()
  isOpenEditSidebar.value.clear()
}
</script>

<template>
  <Sidebar
    id="book-edit"
    is-absolute-position
    :label="$t('actions.edit_book')"
    @before-show="onBeforeShow"
    @hide="onHide"
  >
    <form class="space-y-2.5" @submit.prevent="onSubmit">
      <div class="max-h-[250px] overflow-y-auto">
        <div class="box relative">
          <FormGroup v-slot="{ id }" v-bind="hasError('store')" required :label="$t('labels.stop', { order: 1 })">
            <LazySelect
              :id
              v-model="form.store"
              object
              append-to-body
              value-prop="store_number"
              content-refreshable
              :options="storeList"
              :placeholder="t('placeholders.store')"
              :fetch="() => fetchStoresList({ size: 1000 })"
              :fetch-on-open="false"
              :get-option-label="(item: any) => item.store_number || ''"
            />
          </FormGroup>

          <div class="grid w-full grid-cols-12">
            <InputCheckbox v-model="form.is_full" :label="$t('labels.full')" class="col-span-4 self-center" />

            <FormGroup v-slot="{ id }" v-bind="hasError('amount')" required class="col-span-8">
              <Input
                :id="id"
                v-model="form.amount"
                :disabled="form.is_full"
                type="number"
                :min="form.is_full ? '' : 1"
                size="xs"
                :placeholder="$t('placeholders.enter_amount')"
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <Textarea v-model="form.note" :placeholder="$t('placeholders.notes')" />

      <div class="flex justify-end pb-2.5">
        <Button
          type="submit"
          :label="$t('actions.submit')"
          :loading="loading"
          :disabled="vuelidate.$error || loading"
        />
      </div>
    </form>
    <FuelBookStop :current-location="currentLocation" :book-stop="form" class="box" />
  </Sidebar>
</template>

<style scoped>
.box {
  @apply mt-2 flex flex-col gap-2 rounded-sm border border-gray-200 p-2 dark:border-gray-800;
}
</style>

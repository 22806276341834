<script setup lang="ts">
import { Icon } from "@iconify/vue"

import { ButtonIcon } from "$/ui"

import { IdlingDetail, IdlingModel, useIdlingService, useIdlingStore } from "~/management/idling"
import { toDuration } from "$/utils/date"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const { t } = useI18n()
const modal = useModal()
const idlingStore = useIdlingStore()
const sessionStore = useSessionStore()

const { idlingDetails, queryDetails, detailsLoading } = storeToRefs(idlingStore)
const { profile } = storeToRefs(sessionStore)

const idLingList = ref<IdlingModel>(new IdlingModel())

const { fetchIdlingDetail } = useIdlingService()

const cols = computed<ITableCol<IdlingDetail>[]>(() => {
  const baseCols: ITableCol<IdlingDetail>[] = [
    {
      name: "idx",
      label: t("thead.no")
    },
    {
      name: "name",
      label: t("thead.name")
    },
    {
      name: "start_time",
      label: t("thead.start"),
      formatter: (value: string) => (value ? toZone(value, "MM/DD HH:mm CT") : "")
    },
    {
      name: "end_time",
      label: t("thead.end"),
      formatter: (value: string) => (value ? toZone(value, "MM/DD HH:mm CT") : "")
    },
    {
      name: "duration",
      label: t("thead.duration")
    },
    {
      name: "gallons",
      label: t("thead.gallons")
    },
    {
      name: "location",
      label: t("thead.address")
    },
    {
      name: "reason",
      label: t("thead.reason")
    },
    {
      name: "updated_by_name",
      label: t("thead.booker")
    }
  ]

  if (profile.value.authorities.has(RolePermissionsModel.CAN_IDLING_SET_REASON)) {
    baseCols.push({
      name: "actions",
      label: t("thead.actions"),
      headClass: "flex items-center justify-center",
      dataClass: "flex items-center justify-center"
    })
  }

  return baseCols
})

const onBeforeShow = (data: IdlingModel) => {
  idLingList.value = data
  queryDetails.value.truck_id = data.id
  fetchIdlingDetail(queryDetails.value)
}

const hide = () => {
  queryDetails.value.page = 0
}
</script>

<template>
  <Modal
    id="idling-details"
    size="xl"
    :label="$t('labels.idling_details', { extraInfo: `#${idLingList.truck_id} ${idLingList.driver_name || ''}` })"
    @before-show="onBeforeShow"
    @hide="hide"
  >
    <div class="w-full h-full flex flex-col text-xs">
      <div class="flex items-center gap-2 p-4 font-semibold">
        <p>
          {{ $t("labels.total_duration") }}:
          <span class="font-normal">{{
            idLingList.total_duration_sec ? toDuration(idLingList.total_duration_sec) : ""
          }}</span>
        </p>
        <p>
          {{ $t("labels.total_gallons") }}: <span class="font-normal">{{ idLingList.total_gallons }}</span>
        </p>
      </div>
      <Table no-wrap :loading="detailsLoading" :cols="cols" :rows="idlingDetails">
        <template #idx="{ idx, sequence }">
          {{ sequence(idx, queryDetails.page, queryDetails.size) }}
        </template>

        <template #duration="{ row }">
          {{ row && row.duration_sec ? toDuration(row.duration_sec) : "" }}
        </template>

        <template #location="{ row }: any">
          <div class="flex items-center gap-2">
            <span v-if="row && row.location">{{ row.location }}</span>

            <ButtonCopy
              v-if="row && row.latitude && row.longitude"
              :text-to-copy="Object.values({ latitude: row.latitude, longitude: row.longitude }).join(', ')"
            />
            <ButtonIcon
              v-if="row && row.latitude && row.longitude"
              :href="getGoogleMapsUrl({ latitude: row.latitude, longitude: row.longitude })"
              target="_blank"
              icon="lucide:external-link"
              color="info"
              variant="ghost"
              width="18"
              size="xs"
            />
          </div>
        </template>

        <template #actions="{ row }">
          <ButtonIcon
            v-if="row && row.id"
            pilled
            size="sm"
            variant="ghost"
            color="secondary"
            :label="$t('actions.edit')"
            @click="() => modal.show('idling-reason', { id: row.id, idlingIdData: idLingList.id, reason: row.reason })"
          >
            <Icon icon="lucide:pencil" :width="18" />
          </ButtonIcon>
        </template>
      </Table>
    </div>

    <TableFooter
      v-model:page="queryDetails.page"
      v-model:per-page="queryDetails.size"
      :total="queryDetails.total"
      @change="fetchIdlingDetail(queryDetails)"
    />
  </Modal>
</template>

import { ref } from "vue"
import { defineStore } from "pinia"

import { ModalId, ModalValue } from "$/ui"

export const useModalStore = defineStore("modal", () => {
  const instance = ref<Map<ModalId, ModalValue>>(new Map())

  const setModal = (id: ModalId, value: ModalValue) => {
    if (!instance.value.has(id)) instance.value.set(id, value)
    else console.warn(`Modal with id "${id}" duplicated!`)
  }

  const getModal = (id: ModalId): ModalValue | undefined => {
    return instance.value.get(id)
  }

  const removeModal = (id: ModalId) => {
    if (instance.value.has(id)) instance.value.delete(id)
  }

  return { instance, setModal, getModal, removeModal }
})

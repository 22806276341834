<script setup lang="ts">
import { useLayoutStore } from "~/layout"
import { ButtonIcon, Icon } from "$/ui"

const store = useLayoutStore()
const toggle = () => (store.sidebarIsShown = !store.sidebarIsShown)
</script>

<template>
  <ButtonIcon class="lg:hidden" variant="flat" color="secondary" @click="toggle">
    <Icon icon="lucide:menu" :width="20" />
  </ButtonIcon>
</template>

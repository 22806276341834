<script setup lang="ts">
import { computed } from "vue"

export interface IProps {
  variant?: "solid" | "flat" | "white"
  color?: "primary" | "secondary" | "success" | "warning" | "danger" | "info"
  label?: string
  status?: string
  rounded?: boolean
  squared?: boolean
}

const props = withDefaults(defineProps<IProps>(), { variant: "solid", color: "primary" })


const status = computed<Record<string, string>>(() => (props.status ?? ""))

const badgeClass = computed(() => ({
  "badge": true,
  "badge-rounded": props.rounded,
  "badge-squared": props.squared,
  ["badge-" + (status.value?.color ?? props.color)]: !!props.color,
  ["badge-" + (status.value?.variant ?? props.variant)]: true
}))
</script>

<template>
  <div v-bind="$attrs" :class="badgeClass">
    <slot>
      {{ status }}
    </slot>
  </div>
</template>

<script setup lang="ts">
import { useSessionStore } from "~/session"
import PrimaryHeader from "./PrimaryHeader.vue"

const sessionStore = useSessionStore()
</script>

<template>
  <PrimaryHeader v-if="sessionStore.isAdmin" class="shrink-0" />
  <slot />
</template>

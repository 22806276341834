import { useModalStore } from "../stores"

export const useModal = () => {
  const store = useModalStore()

  const show = <T>(id: string | number, args?: T) => {
    const modal = store.getModal(id)
    if (modal && modal?.show) modal.show(args)
  }

  const hide = (id: string | number) => {
    const modal = store.getModal(id)
    if (modal && modal?.hide) modal.hide()
  }

  return { show, hide }
}

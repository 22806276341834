import type { AxiosInstance } from "axios"
import { PatchProfile, PasswordResetResponse, PasswordChangeRequest, Profile, SignIn, SignInResponse } from "~/session"

export const useSessionApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1"
  const usersBase: Readonly<string> = "/api/v1/users"

  return {
    SIGN_IN(payload: SignIn): Promise<SignInResponse> {
      return axios.$post(`${base}/authenticate`, payload)
    },
    POST_RESET_PASSWORD_INIT(params: any) {
      return axios.$post(`${base}/reset-password/init`, null, { params })
    },
    POST_RESET_PASSWORD_FINISH(payload: PasswordResetResponse) {
      return axios.$post(`${base}/reset-password/finish`, payload)
    },
    CHANGE_USER_PASSWORD(user_id: number, payload: PasswordChangeRequest): Promise<any> {
      return axios.$post(`${base}/reset-password/${user_id}`, payload)
    },
    PATCH_PROFILE(payload: PatchProfile): Promise<any> {
      return axios.$put(`${base}/profile`, payload)
    },
    FETCH_ONE_USER(user_id: number): ResponseContainer<Profile> {
      return axios.$get(`${usersBase}/${user_id}`)
    }
  }
}

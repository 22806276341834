import type { AxiosInstance } from "axios"
import {
  EfsSystemSettingsPreview,
  SystemActive,
  SystemSettings,
  SystemSettingsPreview
} from "~/management/systemSettings/systemSettings.model"

export const useSystemSettingsApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1/settings"

  return {
    GET_SYSTEM_SETTINGS(type: string): ResponseContainer<SystemSettings> {
      return axios.$get(`${base}/info`, { params: { type } })
    },

    GET_EFS_SETTINGS(params: any): ResponseContainer<SystemSettings> {
      return axios.$get(`/api/v1/efs-accounts`, { params })
    },

    CREATE_SYSTEM_SETTING(payload: SystemSettingsPreview): ResponseContainer<SystemSettings[]> {
      return axios.$post(base, payload)
    },

    CREATE_EFS_SETTING(payload: EfsSystemSettingsPreview): ResponseContainer<EfsSystemSettingsPreview> {
      return axios.$post("/api/v1/efs-accounts/save", payload)
    },

    ACTIVATE_SETTING(id: number, params: SystemActive) {
      return axios.$patch(`${base}/status/${id}`, null, { params })
    },

    EDIT_SYSTEM_SETTING(payload: SystemSettingsPreview, type: string) {
      return axios.$put(base, payload, { params: { type } })
    },

    EDIT_EFS_SETTING(payload: EfsSystemSettingsPreview) {
      return axios.$post("/api/v1/efs-accounts/save", payload)
    },

    GET_SINGLE_SYSTEM_SETTING(id: number, type: string): ResponseContainer<SystemSettings> {
      return axios.$get(`${base}/${id}`, { params: { type } })
    },

    GET_EFS_SINGLE_SYSTEM_SETTING(id: number): ResponseContainer<EfsSystemSettingsPreview> {
      return axios.$get(`/api/v1/efs-accounts/get-cards/${id}`)
    },

    DELETE_EFS_ACCOUNT(id: number): Promise<ResponseType> {
      return axios.$delete(`/api/v1/efs-accounts/${id}`)
    }
  }
}

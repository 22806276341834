<script setup lang="ts">
import { breakpointsTailwind } from "@vueuse/core"

interface IProps {
  label?: string
  labelFor?: string
  hint?: any
  required?: boolean
  invalid?: boolean
  wrap?: boolean
}

defineProps<IProps>()
const uid = crypto.randomUUID()
const breakpoints = useBreakpoints(breakpointsTailwind)

const isDesktop = breakpoints.greaterOrEqual("lg")
</script>

<template>
  <div class="form-group" :class="{ invalid, '!grid grid-cols-12': !wrap && isDesktop }">
    <div v-if="label" class="flex items-center justify-between col-span-4">
      <label class="form-label" :for="labelFor || uid">
        {{ label }}
        <span v-if="required" class="text-danger-600">*</span>
      </label>
      <slot :id="uid" name="corner" />
    </div>
    <div
      :class="[
        'col-span-8 self-center w-full',
        {
          '!col-span-12': !label
        }
      ]"
    >
      <slot :id="uid" />
    </div>
    <small v-if="hint" class="form-hint" :class="{ 'col-span-12 text-right': !wrap }">{{ hint }}</small>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { Icon } from "@iconify/vue"

export interface IProps {
  total: number
  perPage?: number
  eachSide?: number
  firstPage?: number
}

const props = withDefaults(defineProps<IProps>(), { modelValue: 0, total: 0, perPage: 10, eachSide: 2, firstPage: 0 })
const model = defineModel<number>({ default: 0 })

const pageCount = computed(() => Math.ceil(props.total / props.perPage))
const lastPage = computed(() => pageCount.value - 1)
const size = computed(() => props.eachSide * 2 + 1)
const notEnoughPages = computed(() => pageCount.value < size.value)

const start = computed(() => {
  if (model.value < props.eachSide) return 0
  if (model.value >= pageCount.value - props.eachSide) return pageCount.value - size.value
  return model.value - props.eachSide
})

const isCurrentPage = (page: number) => model.value === (notEnoughPages.value ? page : page + start.value)

const loadPage = (page: number) => {
  if (page >= props.firstPage && page <= lastPage.value) model.value = page
}
</script>

<template>
  <div v-if="lastPage > firstPage" class="pagination">
    <button
      class="pagination-button pagination-link"
      :disabled="model === firstPage"
      @click="loadPage(firstPage)"
    >
      <Icon icon="lucide:chevrons-left" :width="24" />
    </button>

    <button class="grid pagination-button" :disabled="model === firstPage" @click="loadPage(model - 1)">
      <Icon icon="lucide:chevron-left" :width="22" />
    </button>

    <template v-if="notEnoughPages">
      <template v-for="(n, i) in pageCount" :key="n + i">
        <button
          class="pagination-button pagination-page"
          :class="{ active: isCurrentPage(i) }"
          @click="loadPage(i)"
        >
          {{ n }}
        </button>
      </template>
    </template>

    <template v-else>
      <template v-for="(n, i) in size" :key="n + i">
        <button class="pagination-button" :class="{ active: isCurrentPage(i) }" @click="loadPage(start + i)">
          {{ start + n }}
        </button>
      </template>
    </template>

    <button class="grid pagination-button" :disabled="model === lastPage" @click="loadPage(model + 1)">
      <Icon icon="lucide:chevron-right" :width="22" />
    </button>

    <button class="pagination-button pagination-link" :disabled="model === lastPage" @click="loadPage(lastPage)">
      <Icon icon="lucide:chevrons-right" :width="24" />
    </button>
  </div>
</template>

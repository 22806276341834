<script setup lang="ts">
import { ButtonIcon, DatePicker, Icon } from "$/ui"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"

import { useManagementService, useManagementStore } from "~/management"
import { BookersStatsModel, BookingTotals, useBookersService, useBookersStore } from "~/management/bookers"
import { useUsersService, useUsersStore } from "~/management/users"

const { getCompanyLazySelect } = useManagementService()
const { getUsersLazySelect } = useUsersService()
const { fetchBookingsTotal, fetchBookingStatistics } = useBookersService()

const emit = defineEmits(["switch-tab"])

const { t } = useI18n()
const companyStore = useManagementStore()
const usersStore = useUsersStore()
const { companyList } = storeToRefs(companyStore)
const { selectUsers } = storeToRefs(usersStore)
const bookerStore = useBookersStore()
const { query: storeQuery, companyStatsIds, filterStats } = storeToRefs(bookerStore)

const isLoading = ref<boolean>(false)
const total = ref<BookingTotals>(new BookingTotals())
const query = ref({
  page: 0,
  size: 20,
  bookerId: null,
  sort_dir: "",
  sort_name: "",
  inputDate: ""
})
const paginationTotal = ref<number>(0)
const list = ref<BookersStatsModel[]>([])

const cols = computed<ITableCol<BookersStatsModel>[]>(() => [
  {
    name: "idx",
    label: t("thead.no")
  },

  {
    name: "nickname",
    label: t("thead.booker")
  },
  {
    name: "books",
    label: t("thead.books"),
    sortField: "books",
    headClass: "w-[100px] pr-[120px]",
    dataClass: "!pl-[20px]"
  },
  {
    name: "matched",
    label: t("thead.matched"),
    sortField: "matched",
    headClass: "w-[100px] pr-[120px]",
    dataClass: "!pl-[30px]"
  },
  {
    name: "unmatched",
    sortField: "unmatched",
    label: t("thead.unmatched"),
    headClass: "w-[100px] pr-[120px]",
    dataClass: "!pl-[30px]"
  },
  {
    name: "canceled",
    sortField: "canceled",
    label: t("thead.canceled"),
    headClass: "w-[100px] pr-[120px]",
    dataClass: "!pl-[30px]"
  },
  {
    name: "bookAvgPrice",
    label: t("thead.books_avg_price"),
    formatter: (value: number) => `${value ? "$" + value.toFixed(2) : ""}`
  },
  {
    name: "stateAvgPrice",
    label: t("thead.state_avg_price"),
    formatter: (value: number) => `${value ? "$" + value.toFixed(2) : ""}`
  }
])

const loadBookerStatistics = () => {
  fetchBookingsTotal(total, query.value)
  fetchBookingStatistics(list, paginationTotal, query.value, isLoading)
}

const handleBookerClick = (val: any) => {
  if (!val.id) return
  storeQuery.value.bookerId = val.id
  filterStats.value = { id: val.id, name: val.nickname }
  emit("switch-tab", "BOOKINGS")
}

onBeforeMount(() => {
  loadBookerStatistics()
})

const filteredCompany = () => {
  nextTick(() => {
    loadBookerStatistics()
  })
}

const refresh = () => {
  query.value.page = 0
  loadBookerStatistics()
}

const sortingBooking = (val: any, order: string) => {
  if (order === "null") {
    query.value.sort_name = ""
    query.value.sort_dir = ""
    loadBookerStatistics()
    return
  }

  if (val === "books") {
    query.value.sort_dir = order
    query.value.sort_name = val
    query.value.page = 0
    loadBookerStatistics()
  } else if (val === "matched") {
    query.value.sort_dir = order
    query.value.sort_name = val
    query.value.page = 0
    loadBookerStatistics()
  } else if (val === "unmatched") {
    query.value.sort_dir = order
    query.value.sort_name = val
    query.value.page = 0
    loadBookerStatistics()
  } else if (val === "canceled") {
    query.value.sort_dir = order
    query.value.sort_name = val
    query.value.page = 0
    loadBookerStatistics()
  }
}

watch(
  () => companyStatsIds.value,
  () => {
    if (companyStatsIds.value.length > 1) {
      let index = companyStatsIds.value.indexOf(999)
      if (index !== -1) {
        companyStatsIds.value.splice(index, 1)
      }
    }
  },
  { deep: true }
)
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="flex w-full flex-wrap items-center gap-4">
        <div class="w-1/6">
          <LazySelect
            v-model="query.bookerId"
            value-prop="id"
            content-refreshable
            :options="selectUsers"
            :placeholder="t('placeholders.select_booker')"
            :fetch="getUsersLazySelect"
            :get-option-label="
              (item: any) =>
                item?.nickname + ' ' + fullName(item?.firstname, item?.lastname) + ' ' + `(${item?.truck_count})`
            "
            @update:model-value="refresh"
          >
            <template #option="{ option }">
              <span class="w-4/5">
                {{ option?.nickname + " " + fullName(option?.firstname, option?.lastname) }}
              </span>
              <span class="absolute right-2"> ({{ option?.truck_count }}) </span>
            </template>
          </LazySelect>
        </div>
        <div>
          <LazySelect
            v-model="companyStatsIds"
            mode="multiple"
            class="tags-select min-w-[200px]"
            value-prop="id"
            tooltip
            content-refreshable
            :hide-selected="false"
            :close-on-select="false"
            :options="companyList"
            :placeholder="t('placeholders.all_companies')"
            :fetch="getCompanyLazySelect"
            :get-option-label="(item: any) => item.name || ''"
            @update:model-value="filteredCompany"
          />
        </div>
        <div>
          <DatePicker
            v-model="query.inputDate"
            model-type="format"
            auto-apply
            teleport="body"
            content-refreshable
            :enable-time-picker="false"
            :dark="isDark"
            :placeholder="$t('placeholders.date')"
            :max-date="new Date()"
            class="w-1/6 min-w-36"
            @update:model-value="refresh"
          />
        </div>
      </div>
    </template>

    <div class="flex items-center justify-end gap-5">
      <HeaderAmountItem :amount="total.total_stops" :description="$t('labels.total_books')" />
      <HeaderAmountItem :amount="total.avg_actual_price" :description="$t('labels.book_avg_price')" />

      <ButtonIcon variant="ghost" color="success" :loading="isLoading" @click="refresh">
        <Icon icon="lucide:refresh-ccw" :width="20" />
      </ButtonIcon>
    </div>
  </TableHeader>

  <Table
    class="booker-stats mt-4"
    no-wrap
    :skeleton="isLoading"
    :loading="isLoading"
    :cols="cols"
    :rows="list"
    head-class="bg-slate-200 dark:bg-gray-800"
    @sorting="sortingBooking"
  >
    <template #idx="{ idx }">
      {{ idx !== list.length - 1 ? idx + 1 : "" }}
    </template>

    <template #nickname="{ row }">
      <Link :label="row.nickname" color="info" class="capitalize" @click="handleBookerClick(row)" />
    </template>
  </Table>

  <TableFooter
    v-model:page="query.page"
    v-model:per-page="query.size"
    :total="paginationTotal"
    @change="loadBookerStatistics"
  />
</template>

<style>
.booker-stats tbody tr:last-child {
  position: sticky;
  bottom: 0;
  background: rgb(226 232 240);
  font-weight: bold;
  pointer-events: none;

  a {
    color: black;
    font-weight: bold;
  }
}

.dark .booker-stats tbody tr:last-child {
  position: sticky;
  bottom: 0;
  color: white;
  background: rgb(31 41 55);
  font-weight: bold;
  pointer-events: none;
  a {
    color: white;
    font-weight: bold;
  }
}
</style>

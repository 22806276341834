<script setup lang="ts">
import { ButtonIcon, Icon } from "$/ui"
import { UsersModel, useUsersService, useUsersStore } from "~/management/users"
import UsersMenu from "~/management/users/components/UsersMenu.vue"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"
import { useSessionStore } from "~/session"
import { RolePermissionsModel } from "~/management/roles"

const modal = useModal()
const { t } = useI18n()
const { getUsersListTable, getUsersLazySelect, getUsersCount } = useUsersService()

const usersStore = useUsersStore()
const sessionStore = useSessionStore()

const { profile } = storeToRefs(sessionStore)
const { list, listLoading, query, usersCount, selectUsers } = storeToRefs(usersStore)

const cols = computed<ITableCol<UsersModel>[]>(() => {
  const baseCols: ITableCol<UsersModel>[] = [
    {
      name: "idx",
      label: t("thead.no")
    },
    {
      name: "nickname",
      label: t("thead.nick_name")
    },
    {
      name: "firstname",
      label: t("thead.name"),
      formatter: (firstname: string, row: UsersModel) => getFullName(firstname, row.lastname)
    },
    {
      name: "company_count",
      label: t("thead.company")
    },

    {
      name: "created_at",
      label: t("thead.date_created"),
      formatter: (value: string) => (value ? toZone(value, "MM/DD/YYYY HH:mm CT") : "")
    },
    {
      name: "email",
      label: t("thead.email")
    },
    {
      name: "phone",
      label: t("thead.phone")
    },
    {
      name: "telegram",
      label: t("thead.telegram")
    },
    {
      name: "role_name",
      label: t("thead.role")
    },
    {
      name: "active",
      label: t("thead.status")
    }
  ]

  if (
    profile.value.authorities.has(RolePermissionsModel.CAN_USER_EDIT) ||
    profile.value.authorities.has(RolePermissionsModel.CAN_USER_DEACTIVATE) ||
    profile.value.authorities.has(RolePermissionsModel.CAN_USER_ACTIVATE)
  ) {
    baseCols.push({
      name: "actions",
      label: t("thead.actions"),
      labelClass: "justify-center"
    })
  }

  return baseCols
})

const refresh = () => {
  query.value.page = 0
  getUsersListTable()
  getUsersCount()
}

onUnmounted(() => {
  query.value = {}
})
onMounted(() => {
  getUsersListTable()
  getUsersCount()
})
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="mt-1 !w-1/6">
        <LazySelect
          v-model="query.user_id"
          value-prop="id"
          content-refreshable
          :options="selectUsers"
          :placeholder="t('placeholders.users')"
          :fetch="getUsersLazySelect"
          :get-option-label="
            (item: any) =>
              item?.nickname + ' ' + fullName(item?.firstname, item?.lastname) + ' ' + `(${item?.truck_count})`
          "
          @update:model-value="refresh"
        >
          <template #option="{ option }">
            <span class="w-4/5">
              {{ option?.nickname + " " + fullName(option?.firstname, option?.lastname) }}
            </span>
            <span class="absolute right-2"> ({{ option?.truck_count }}) </span>
          </template>
        </LazySelect>
      </div>
    </template>
    <div class="flex items-center justify-end gap-5">
      <HeaderAmountItem :amount="usersCount" :description="$t('labels.total_users')" />
      <Button v-if="profile.authorities.has(RolePermissionsModel.CAN_USER_CREATE)" @click="modal.show('users')">
        {{ $t("actions.new_user") }}
      </Button>
      <ButtonIcon variant="ghost" color="success" :loading="false" @click="refresh">
        <Icon icon="lucide:refresh-ccw" :width="20" />
      </ButtonIcon>
    </div>
  </TableHeader>

  <Table
    no-wrap
    :skeleton="listLoading"
    :loading="listLoading"
    :cols="cols"
    :rows="list"
    class="mt-4"
    head-class="bg-slate-200 dark:bg-gray-800"
  >
    <template #idx="{ idx, sequence }">
      {{ sequence(idx, query.page || 0, query.size || 10) }}
    </template>

    <template #active="{ row }">
      <span :class="row.active ? 'text-green-700' : 'text-red-500'">
        {{ row.active ? $t("labels.active") : $t("labels.inactive") }}
      </span>
    </template>
    <template #telegram="{ row }">
      <Link :href="`https://t.me/${row.telegram}`" color="info" target="_blank" :label="row.telegram" />
    </template>

    <template #company_count="{ row }">
      <badge v-if="row.company_count" class="cursor-pointer" @click="modal.show('usersCompany', row.id)">
        {{ row.company_count }}
      </badge>
    </template>

    <template #actions="{ row }">
      <UsersMenu :id="row.id" :is-active="row.active" />
    </template>
  </Table>

  <TableFooter
    v-model:page="query.page"
    v-model:per-page="query.size"
    :total="query.total"
    @change="getUsersListTable"
  />
</template>

<script lang="ts" setup>
import { Icon } from "@iconify/vue"

import { ButtonIcon } from "$/ui"

import { useCardService } from "~/management/card"
import { useSessionStore } from "~/session"
import { RolePermissionsModel } from "~/management/roles"

interface IProps {
  id: number
  number: string
  isActive: boolean
}

const { id, number } = defineProps<IProps>()

const { show } = useModal()
const { activateCard, deactivateCard } = useCardService()

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const showEditModal = () => show("card-edit", { id, number })
const showHistoryModal = () => show("card-history", { id, number })
</script>

<template>
  <div class="flex items-center justify-center gap-3 -my-2" @click.stop>
    <Button
      v-if="isActive && profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_DEACTIVATE)"
      color="info"
      variant="ghost"
      @click="deactivateCard(id, number)"
    >
      <span>
        {{ $t("actions.deactivate") }}
      </span>
    </Button>
    <Button
      v-else-if="profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_ACTIVATE)"
      color="info"
      variant="ghost"
      @click="activateCard(id, number)"
    >
      <span> {{ $t("actions.activate") }} </span>
    </Button>

    <ButtonIcon
      v-if="profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_SET_LIMIT)"
      :label="$t('actions.edit')"
      color="secondary"
      pilled
      size="sm"
      variant="ghost"
      @click="showEditModal"
    >
      <Icon :width="18" icon="lucide:pencil" />
    </ButtonIcon>

    <ButtonIcon
      v-if="profile.authorities.has(RolePermissionsModel.CAN_CARD_HISTORY_PAGE_GET)"
      :label="$t('labels.card_history')"
      color="secondary"
      pilled
      size="sm"
      variant="ghost"
      @click="showHistoryModal"
    >
      <Icon :width="18" icon="lucide:file-clock" />
    </ButtonIcon>
  </div>
</template>

import type { NavigationGuard } from "vue-router"

export const useSessionMiddleware: NavigationGuard = (to, from, next) => {
  const hasAppToken = !!localStorage.getItem("app_token")

  const isPublic = to.meta?.public || false

  const unauthorized = !isPublic && !hasAppToken

  if (unauthorized) next({ name: "sign-in" })
  else if (to.name === "sign-in" && hasAppToken) next({ name: from.name })
  else {
    next()
  }
}

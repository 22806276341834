import { RolePermissionsModel } from "~/management/roles"

export class SignIn {
  email: string = ""
  password: string = ""
}

export interface SignInResponse {
  result: any
  content: {
    token: string
    fcmToken: string | null
    permissions: string[]
    id: number
    role: string
    firstname: string
    lastname: string
  }
}

export class PatchProfile {
  firstname: string = ""
  lastname: string = ""
  phone: string = ""
  nickname: string = ""
}

export class ForgotPassword {
  email: string = ""
  hash: string = ""
}

export class PasswordChanging {
  email: string = ""
  newPassword: string = ""
  confirmPassword: string = ""
  token: string = ""
}

export interface PasswordResetResponse {
  email: string
  newPassword: string
  confirmPassword: string
  token: string
}

export class PasswordChangeRequest {
  oldPassword: string = ""
  newPassword: string = ""
  confirmPassword: string = ""
}

export enum Role {
  USER = "USER",
  SUPER_ADMIN = "SUPER_ADMIN",
  OWNER = "OWNER"
}

export class Profile {
  id!: number
  nickname: string = ""
  firstname: string = ""
  lastname: string = ""
  role_name: string = ""
  email: string = ""
  phone: string = ""
  password: string | null = null
  active: boolean = true
  authorities: Set<RolePermissionsModel> = new Set()

  constructor(data?: Profile) {
    if (data) {
      this.id = data.id
      this.nickname = data.nickname
      this.phone = data.phone
      this.email = data.email
      this.firstname = data.firstname
      this.lastname = data.lastname
      this.role_name = data.role_name
      this.password = data.password
      this.active = data.active
      this.authorities = new Set(data.authorities)
    }
  }
}

export class UsersModel {
  id!: number
  firstname: string = ""
  lastname: string = ""
  company_count: string = ""
  drivers: Nullable<number> = null
  created: string = ""
  email: string = ""
  phone: string = ""
  telegram: string = ""
  role: string = ""
  status: string = ""
  active: boolean = false

  constructor(data?: Partial<UsersModel>) {
    if (data) {
      this.id = data.id!
      this.firstname = data.firstname || ""
      this.lastname = data.lastname || ""
      this.company_count = data.company_count || ""
      this.drivers = data.drivers || null
      this.created = data.created || ""
      this.email = data.email || ""
      this.phone = data.phone || ""
      this.telegram = data.telegram || ""
      this.role = data.role || ""
      this.status = data.status || ""
      this.active = data.active || false
    }
  }
}

export class UsersCompanyModel {
  public id: number
  public name: string

  constructor(data: Partial<{ company_id: number; company_name: string }> = {}) {
    this.id = data.company_id ?? 0
    this.name = data.company_name ?? ""
  }
}

export interface UsersCompanyDetails {
  company_id: number
  company_name: string
  driver_count: number
}

export class UsersAssignModel {
  id?: Nullable<number> = null
  nickname: string = ""
  firstname: string = ""
  lastname: string = ""
  email: string = ""
  phone: string = ""
  telegram: string = ""
  password: string = ""
  role_id: Nullable<number> = null
  role_name: string = ""
  company_name: string = ""
  company_ids: Nullable<number> = null
  companies: UsersCompanyModel[] = []

  constructor(data?: UsersAssignModel) {
    if (data) {
      this.id = data.id || null
      this.role_name = data.role_name || ""
      this.company_name = data.company_name || ""
      this.telegram = data.telegram || ""
      this.nickname = data.nickname || ""
      this.firstname = data.firstname || ""
      this.lastname = data.lastname || ""
      this.email = data.email || ""
      this.phone = data.phone || ""
      this.password = data.password || ""
      this.role_id = data.role_id || null
      this.company_ids = data.company_ids || null
      this.companies = data.companies ? data.companies.map((item: any) => new UsersCompanyModel(item)) : []
    }
  }
}

<script setup lang="ts">
import { Icon } from "@iconify/vue"

import { useSidebarStore } from "$/ui/stores/sidebar.store"
import { useLayoutStore } from "~/layout"

interface Props {
  id: string | number
  label: string
  width?: string
  color?: "primary" | "warning" | "danger" | string
  isAbsolutePosition?: boolean
}

interface Emits {
  (e: "before-show", args?: any): void
  (e: "show", args?: any): void
  (e: "before-hide"): void
  (e: "hide"): void
}

const props = withDefaults(defineProps<Props>(), {
  width: "300px",
  color: "primary"
})

const emits = defineEmits<Emits>()

const store = useSidebarStore()
const layoutStore = useLayoutStore()
const { openSidebars } = storeToRefs(layoutStore)

const visible = ref(false)
const args = ref<any>()

const headerColor = computed(() => {
  if (props.color === "warning") return "bg-orange-500"
  if (props.color === "danger") return "bg-red-500"

  return "bg-[#64937C]"
})
const zIndex = computed(() => {
  const index = openSidebars.value.findIndex((id) => id === props.id)
  return index !== -1 ? 1000 + index : 1000
})

const show = (showArgs?: unknown) => {
  args.value = showArgs
  emits("before-show", args.value)
  visible.value = true

  if (!openSidebars.value.includes(props.id)) openSidebars.value.push(props.id)
}

const hide = () => {
  emits("before-hide")
  visible.value = false
  const index = openSidebars.value.findIndex((id) => id === props.id)
  if (index !== -1) {
    openSidebars.value.splice(index, 1)
  }
}

const onAfterEnter = () => {
  emits("show", args.value)
}

const onAfterLeave = () => {
  emits("hide")
  args.value = undefined
}

onMounted(() => {
  if (props.id) store.setSidebar(props.id, { show, hide })
})

onBeforeUnmount(() => {
  if (props.id) store.removeSidebar(props.id)
  hide()
})
</script>

<template>
  <Transition
    name="sidebar"
    enter-active-class="transition-transform duration-300 ease-in-out"
    leave-active-class="transition-transform duration-300 ease-in-out"
    enter-from-class="translate-x-full"
    leave-to-class="translate-x-full"
    @after-enter="onAfterEnter"
    @after-leave="onAfterLeave"
  >
    <div
      v-if="visible"
      class="fixed top-20 right-0 h-[calc(100%-80px)] bg-white dark:bg-gray-900 overflow-y-auto shadow-2xl px-1.5 py-2.5 border border-[#64937C] scrollbar-none"
      :class="{ '!absolute !top-0 !h-full': isAbsolutePosition }"
      :style="{ width, zIndex }"
    >
      <div class="flex items-center justify-between text-white py-1 px-2 -mx-1.5 -mt-2.5" :class="headerColor">
        <div class="flex items-center gap-2 cursor-pointer">
          <Icon icon="lucide:x" @click="hide" />
          <slot name="header-icon" />
        </div>

        <h2 class="text-base font-bold capitalize">
          {{ label }}
        </h2>
      </div>

      <slot :hide="hide" :args="args"></slot>
    </div>
  </Transition>
</template>

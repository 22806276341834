import { UsersAssignModel, useUsersApi, useUsersStore } from "~/management/users"

export const useUsersService = () => {
  const usersApi = useUsersApi()
  const usersStore = useUsersStore()
  const { confirmActivateDeactivate } = useAlert()
  const { roleDetails, userCompanyList, list, usersCount, form, selectUsers, listLoading, query } =
    storeToRefs(usersStore)

  const activateUser = async (id: number) => {
    confirmActivateDeactivate(false).then(() => {
      usersApi.USER_ACTIVATE(id).then(() => getUsersListTable())
    })
  }
  const getUsersCount = async () => {
    await usersApi.GET_USERS_COUNT().then(({ content }) => {
      usersCount.value = content
    })
  }
  const deactivateUser = async (id: number) => {
    await confirmActivateDeactivate(true).then(() => {
      usersApi.USER_DEACTIVATE(id).then(() => getUsersListTable())
    })
  }

  const getUsers = async (id: number) => {
    const { content }: any = await usersApi.GET_USER_ID(id)
    if (content) form.value = new UsersAssignModel(content)
    if (content.role_id && content.role_name) {
      roleDetails.value = { id: content.role_id, name: content.role_name }
    }
    if (content.companies?.length) {
      form.value.company_ids = content.companies.map((item: any) => item.company_id)
    }
  }

  const getUsersDetails = async (id: number) => {
    const { content }: any = await usersApi.GET_USER_DETAILS_ID(id)
    if (content) userCompanyList.value = content
  }

  const getUsersListTable = async () => {
    listLoading.value = true
    usersApi
      .GET_USERS({
        page: query.value.page,
        keyword: query.value.keyword,
        size: query.value.size,
        user_id: query.value.user_id
      })
      .then(({ content, page, totalElements }: any) => {
        list.value = content
        query.value.page = page
        query.value.total = totalElements
      })
      .finally(() => (listLoading.value = false))
  }

  const getUsersLazySelect = async (val: { page?: number; size?: number; keyword?: string }) => {
    await usersApi
      .GET_USERS({
        page: val.page,
        size: val.size,
        keyword: val.keyword
      })
      .then((res: any) => {
        selectUsers.value = res
      })
  }
  const setUsers = async (value: UsersAssignModel) => {
    delete value.id
    return await usersApi.POST_USER(value)
  }
  const setUsersEdit = async (value: UsersAssignModel) => {
    return await usersApi.PATCH_USER_ID(value)
  }

  return {
    getUsersDetails,
    getUsersCount,
    getUsersLazySelect,
    getUsersListTable,
    activateUser,
    deactivateUser,
    getUsers,
    setUsers,
    setUsersEdit
  }
}

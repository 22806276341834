<template>
  <div class="mt-4 grid grid-cols-1 p-2.5 lg:grid-cols-11">
    <div class="order-3 col-span-5 flex flex-col items-start justify-start lg:order-2 lg:items-end">
      <h6
        v-if="!stop.is_insert_point && stop?.supplier_code"
        class="text-xs font-semibold uppercase"
        :class="suggestedTitleColor"
      >
        {{ $t("labels.suggested") }}
      </h6>
      <div v-else-if="!stop.is_insert_point">&nbsp;</div>

      <template v-if="!!stop.store_number">
        <FuelDetailsSuggestedStop :stop :index @reload="emit('reload')" />
      </template>
    </div>

    <template v-if="stop?.supplier_code">
      <div class="order-2 flex flex-row items-center gap-10 py-6 lg:order-2 lg:flex-col lg:py-0">
        <h6 class="text-xs font-semibold uppercase">{{ $t("labels.matched") }}</h6>
        <Icon v-if="matched" icon="bi-check-circle-fill" :width="20" color="#00ff1e" />
        <Icon v-else icon="bi-x-circle-fill" :width="20" color="#FF3B30" />
      </div>

      <div class="order-1 col-span-5 flex flex-col-reverse items-start justify-start lg:order-3 lg:flex-col">
        <h6 class="text-xs font-semibold uppercase" :class="actualTitleColor">
          {{ $t("labels.actual") }}
        </h6>

        <FuelDetailsActualStop :stop :index />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"

import FuelDetailsSuggestedStop from "~/management/fuel/components/details/FuelDetailsSuggestedStop.vue"
import FuelDetailsActualStop from "~/management/fuel/components/details/FuelDetailsActualStop.vue"

import { SuggestionStop } from "~/management/fuel"
import { useMatchColors } from "~/management/fuel/fuel.hooks"

const emit = defineEmits(["reload"])
const props = defineProps<{
  stop: SuggestionStop
  index: number
}>()

const { matched, failedSide } = useMatchColors(props.stop)

const actualTitleColor = computed(() => {
  if (!props.stop?.supplier_code) return "text-gray-500"
  return failedSide === "green" ? "text-green-500" : "text-red-500"
})

const suggestedTitleColor = computed(() => {
  if (!props.stop?.supplier_code) return "text-gray-500"
  return failedSide === "green" ? "text-green-500" : "text-red-500"
})
</script>

import { RolePermissionsModel } from "~/management/roles/roles.model"

interface Permission {
  label: string
  roles?: string[]
  value: RolePermissionsModel
}

interface PermissionCategory {
  title: string
  permissions: Permission[]
}

type PermissionsRecord = Record<string, PermissionCategory>

export const PERMISSIONS: PermissionsRecord = {
  PAGES: {
    title: "Pages",
    permissions: [
      { label: "Access the truck page", value: RolePermissionsModel.CAN_ACCESS_TRUCK_PAGE },
      { label: "Access the settings page", value: RolePermissionsModel.CAN_ACCESS_SETTINGS_PAGE },
      { label: "Access the fuel book page", value: RolePermissionsModel.CAN_ACCESS_FUEL_BOOK_PAGE },
      { label: "Access the cards management page", value: RolePermissionsModel.CAN_ACCESS_CARDS_PAGE },
      { label: "Access booker page", value: RolePermissionsModel.CAN_ACCESS_BOOKERS_PAGE },
      { label: "View the transactions page", value: RolePermissionsModel.CAN_ACCESS_TRANSACTIONS_PAGE },
      { label: "Access price and store details", value: RolePermissionsModel.CAN_ACCESS_PRICES__STORES_PAGE },
      { label: "View idling information for trucks", value: RolePermissionsModel.CAN_ACCESS_IDLING_PAGE },
      { label: "Manage users", value: RolePermissionsModel.CAN_ACCESS_USERS_PAGE },
      { label: "Manage roles (view, create, edit)", value: RolePermissionsModel.CAN_ACCESS_ROLES_PAGE }
    ]
  },
  TRUCK: {
    title: "Truck",
    permissions: [
      { label: "Assign a booker to a truck", value: RolePermissionsModel.CAN_TRUCK_ASSIGN_BOOKER },
      { label: "Assign a fuel card to a truck", value: RolePermissionsModel.CAN_TRUCK_ASSIGN_CARD },
      { label: "Access truck information", value: RolePermissionsModel.CAN_TRUCK_PAGE_GET },
      { label: "Can set truck Samsara vin", value: RolePermissionsModel.CAN_TRUCK_SET_SAMSARA_VIN },
      { label: "Can set truck tank", value: RolePermissionsModel.CAN_TRUCK_SET_TANK }
    ]
  },
  FUEL_STATION: {
    title: "Fuel Station",
    permissions: [
      { label: "Access the fuel stations page", value: RolePermissionsModel.CAN_FUEL_STATION_PAGE_GET },
      { label: "Access store details at fuel stations", value: RolePermissionsModel.CAN_FUEL_STATION_STORES_PAGE_GET },
      { label: "Access to create station/store", value: RolePermissionsModel.CAN_FUEL_STATION_CREATE },
      { label: "Access to deactivate fuel station", value: RolePermissionsModel.CAN_FUEL_STATION_DEACTIVATE },
      { label: "Access to activate fuel station", value: RolePermissionsModel.CAN_FUEL_STATION_ACTIVATE },
      { label: "Can Fuel station edit", value: RolePermissionsModel.CAN_FUEL_STATION_EDIT }
    ]
  },
  IDLING: {
    title: "Idling",
    permissions: [
      { label: "Access idling details for trucks", value: RolePermissionsModel.CAN_IDLING_PAGE_GET },
      { label: "Access idling details for trucks", value: RolePermissionsModel.CAN_IDLING_WITH_TRUCKS_PAGE_GET },
      { label: "Add reasons for idling", value: RolePermissionsModel.CAN_IDLING_SET_REASON }
    ]
  },
  ROLE: {
    title: "Role",
    permissions: [
      { label: "Access the roles page", value: RolePermissionsModel.CAN_ROLE_PAGE_GET },
      { label: "Create roles", value: RolePermissionsModel.CAN_ROLE_CREATE },
      { label: "Edit roles", value: RolePermissionsModel.CAN_ROLE_EDIT },
      { label: "Activate roles", value: RolePermissionsModel.CAN_ROLE_ACTIVATE },
      { label: "Deactivate roles", value: RolePermissionsModel.CAN_ROLE_DEACTIVATE }
    ]
  },
  SUGGESTION: {
    title: "Suggestion",
    permissions: [
      { label: "View suggestions (view stop)", value: RolePermissionsModel.CAN_SUGGESTION_GET_ONE },
      { label: "Add new suggestions", value: RolePermissionsModel.CAN_SUGGESTION_CREATE }
    ]
  },
  SUGGESTION_STOP: {
    title: "Suggestion Stop",
    permissions: [
      { label: "Edit stops suggestions", value: RolePermissionsModel.CAN_SUGGESTION_STOP_EDIT },
      { label: "Delete stops suggestions", value: RolePermissionsModel.CAN_SUGGESTION_STOP_DELETE },
      {
        label: "Access to suggestion page",
        value: RolePermissionsModel.CAN_SUGGESTION_STOP_BOOKINGS_PAGE_GET
      },
      {
        label: "View booking stats or booking-related suggestions",
        value: RolePermissionsModel.CAN_SUGGESTION_STOP_BOOKINGS_STATS_PAGE_GET
      }
    ]
  },
  USER: {
    title: "User",
    permissions: [
      { label: "Access the users page", value: RolePermissionsModel.CAN_USER_PAGE_GET },
      { label: "Create users", value: RolePermissionsModel.CAN_USER_CREATE },
      { label: "Edit users", value: RolePermissionsModel.CAN_USER_EDIT },
      { label: "Deactivate users", value: RolePermissionsModel.CAN_USER_DEACTIVATE },
      { label: "Activate users", value: RolePermissionsModel.CAN_USER_ACTIVATE }
    ]
  },
  CARD: {
    title: "Card",
    permissions: [
      { label: "Access the cards page", value: RolePermissionsModel.CAN_CARD_PAGE_GET },
      { label: "Set limits access", value: RolePermissionsModel.CAN_EFS_CARD_SET_LIMIT },
      { label: "Can efs card assign", value: RolePermissionsModel.CAN_EFS_CARD_ASSIGN },
      { label: "Deactivate cards", value: RolePermissionsModel.CAN_EFS_CARD_DEACTIVATE },
      { label: "Activate cards", value: RolePermissionsModel.CAN_EFS_CARD_ACTIVATE },
      { label: "Card history access", value: RolePermissionsModel.CAN_CARD_HISTORY_PAGE_GET },
      { label: "Can set card available limit", value: RolePermissionsModel.CAN_EFS_CARD_SET_AVAILABLE_LIMIT }
    ]
  },
  TRANSACTION: {
    title: "Transaction",
    permissions: [{ label: "Access transaction", value: RolePermissionsModel.CAN_TRANSACTION_PAGE_GET }]
  },

  SETTINGS: {
    title: "Settings",
    permissions: [
      {
        label: "Can status change",
        value: RolePermissionsModel.CAN_SETTINGS_CHANGE_STATUS
      },
      { label: "Edit settings", value: RolePermissionsModel.CAN_SETTINGS_EDIT }
    ]
  }
}

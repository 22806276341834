<script setup lang="ts">
import { useRouteProtection } from "$/composables/useRouteProtection"
import { IdlingDetailsModal, IdlingList, IdlingReasonModal } from "~/management/idling"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_IDLING_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <IdlingList v-if="profile.authorities.has(RolePermissionsModel.CAN_IDLING_WITH_TRUCKS_PAGE_GET)" />
    <IdlingDetailsModal />
    <IdlingReasonModal />
  </div>
</template>

import { ref } from "vue"
import { defineStore } from "pinia"

type SidebarId = string | number
type SidebarValue = { show: (args?: any) => void; hide: () => void }

export const useSidebarStore = defineStore("sidebar", () => {
  const instance = ref<Map<SidebarId, SidebarValue>>(new Map())

  const setSidebar = (id: SidebarId, value: SidebarValue) => {
    if (!instance.value.has(id)) instance.value.set(id, value)
    else console.warn(`Sidebar with id "${id}" already exists!`)
  }

  const getSidebar = (id: SidebarId): SidebarValue | undefined => {
    return instance.value.get(id)
  }

  const removeSidebar = (id: SidebarId) => {
    if (instance.value.has(id)) instance.value.delete(id)
  }

  return { instance, setSidebar, getSidebar, removeSidebar }
})

<script setup lang="ts">
import type { InputHTMLAttributes, LabelHTMLAttributes } from "vue"

interface IProps {
  label?: string
  labelProps?: LabelHTMLAttributes
  inputProps?: InputHTMLAttributes
  labelClass?: string
  inputClass?: string
  value?: any
}

defineProps<IProps>()
const model = defineModel<any>()
const uid = crypto.randomUUID()
</script>

<template>
  <div class="flex items-center gap-2 select-none">
    <label
      v-bind="labelProps"
      class="w-full text-xs leading-none font-medium cursor-pointer max-w-4"
      :for="uid"
      :class="labelClass"
    >
      {{ label }}
    </label>

    <input
      v-bind="inputProps"
      :id="uid"
      v-model="model"
      class="form-checkbox checkbox shrink-0"
      type="checkbox"
      :value="value"
      :class="inputClass"
    />
  </div>
</template>

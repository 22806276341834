<script setup lang="ts">
import { DatePicker } from "$/ui"
import { useTransactionsApi, ParamsReport } from "~/management/transaction"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

/* API CALLS */
const { GENERATE_REPORT } = useTransactionsApi()

/* Vue Dependencies */
const modal = useModal()

/* Reactivity */
const isLoading = ref<boolean>(false)
const params = ref(new ParamsReport())

const transformQuery = () => {
  const { dateRange, ...rest } = params.value
  const [start_date, end_date] = dateRange
    ? dateRange.map((date: any) => (date ? format(date, "YYYY-MM-DD[T]00:00") : undefined))
    : []

  return pickBy(
    {
      ...rest,
      start_date,
      end_date
    },
    identity
  )
}
/* Validation */
const { required } = useRule()
const rules = computed(() => ({
  dateRange: { required }
}))
const { vuelidate, hasError } = useValidate(params, rules)

/*Functions*/
const onSave = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  try {
    isLoading.value = true

    await GENERATE_REPORT(transformQuery()).then((res) => {
      const url = window.URL.createObjectURL(res)
      const link = document.createElement("a")

      link.href = url
      link.setAttribute("download", "Report.xlsx")
      document.body.appendChild(link)
      link.click()
    })

    onHide()
  } finally {
    isLoading.value = false
  }
}

/* Lifecycle */
const onBeforeShow = () => {
}
const onHide = () => {
  params.value = new ParamsReport()
  modal.hide("report")
  vuelidate.value?.$reset()
}


</script>

<template>
  <Modal
    id="report"
    :label="$t('actions.generate_report')"
    :loading="isLoading"
    :close-on-backdrop="false"
    size="xs"
    @before-show="onBeforeShow"
    @hide="onHide"
  >
    <form class="grid gap-4 p-4">
      <FormGroup v-slot="{ id }" v-bind="hasError('dateRange')" required>
        <DatePicker
          :id
          v-model="params.dateRange"
          teleport="body"
          :range="{autoRange:7}"
          hide-input-icon
          :dark="isDark"
          :enable-time-picker="false"
          :placeholder="$t('placeholders.period')"
          :max-date="new Date()"
          class="min-w-44"
        />
      </FormGroup>
      <Input v-model="params.card_number" :placeholder="$t('placeholders.enter_card_number')" />
      <div class="flex gap-4">
        <label class="flex items-center text-[14px]">
          {{ $t("labels.retail_price_daily") + ":" }}
          <input v-model="params.retail_price_only" type="checkbox" class="ml-2" />
        </label>
        <label class="flex items-center text-[14px]">
          {{ $t("labels.show_entire") + ":" }}
          <input v-model="params.show_full_card" type="checkbox" class="ml-2" />
        </label>
      </div>
      <label class="flex items-center text-[14px]">
        {{ $t("labels.show_time") + ":" }}
        <input v-model="params.show_transaction_time" type="checkbox" class="ml-2" />
      </label>
    </form>

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button color="danger" :label="$t('actions.cancel')" @click="modal.hide('report')" />
        <Button :label="$t('actions.generate_report')" @click="onSave" />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useDark } from "@vueuse/core"
import { darkTheme, lightTheme, Notifications, Notivue, NotivueSwipe } from "notivue"

const isDark = useDark({ storageKey: "theme" })
</script>

<template>
  <Notivue v-slot="item">
    <NotivueSwipe :item="item">
      <Notifications :item="item" :theme="isDark ? darkTheme : lightTheme" />
    </NotivueSwipe>
  </Notivue>
</template>

<style lang="css">
:root {
  --nv-z: 9999;
}
</style>

import { useSidebarStore } from "$/ui/stores/sidebar.store"

export const useSidebar = () => {
  const store = useSidebarStore()

  const show = <T>(id: string | number, args?: T) => {
    const sidebar = store.getSidebar(id)
    if (sidebar && sidebar?.show) sidebar.show(args)
  }

  const hide = (id: string | number) => {
    const sidebar = store.getSidebar(id)
    if (sidebar && sidebar?.hide) sidebar.hide()
  }

  const isOpen = (id: string | number): boolean => {
    const sidebar = store.getSidebar(id)
    return !!sidebar
  }

  return { show, hide, isOpen }
}

export const DELETING_REASONS = {
  route_change: "ROUTE CHANGE",
  load_cancelled: "LOAD CANCELLED",
  fuel_over: "FUEL OVER",
  got_further: "GOT FURTHER",
  missed: "MISSED",
  problem_with_efs: "PROBLEM WITH EFS",
  miscommunication: "MISCOMMUNICATION",
  long_queue: "LONG QUEUE",
  not_working: "NOT WORKING"
}

import { DashboardBooker, DashboardMPG } from "~/management/dashboard/dashboard.model"

export const useDashboardStore = defineStore("dashboard", () => {
  const mpgStats = ref<DashboardMPG[]>([])
  const mpgLoading = ref<boolean>(false)
  const mpgDate = ref<any>(new Date())

  const bookerStats = ref<DashboardBooker[]>([])
  const bookerLoading = ref<boolean>(false)
  const bookerDate = ref<string>(format(new Date(), "MM/DD/YYYY"))
  const bookerId = ref<number | null>(null)
  const query = ref<any>({
    page: 0,
    size: 20,
    total: 0
  })

  const queryMpg = ref<any>({
    page: 0,
    size: 20,
    total: 0,
    direction: "DESC"
  })

  return {
    mpgStats,
    query,
    queryMpg,
    mpgLoading,
    mpgDate,
    bookerStats,
    bookerLoading,
    bookerDate,
    bookerId
  }
})

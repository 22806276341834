<script lang="ts" setup>
import { XIcon } from "lucide-vue-next"

const props = defineProps<{
  loading?: boolean
  suggestions: any[]
  placeholder?: string
  id?: number
  removable?: boolean
}>()

const emits = defineEmits(["input", "select", "remove", "blur"])
const model = defineModel<null | string>()

const isFocused = ref(false)

function handleInput(q: string) {
  if (!model.value) return
  if (model.value.trim() === "") return

  emits("input", q, props.id)
}

</script>


<template>
  <div class="relative">
    <div class="flex items-center">
      <div
        v-if="isFocused"
        class="fixed inset-0 bg-black/30 transition-opacity duration-200 z-10"
      />

      <input
        v-model="model"
        :disabled="loading"
        :placeholder="placeholder"
        class="w-[400px] p-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-lg focus:border-transparent z-20
                 dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:shadow-gray-900"
        type="text"
        @blur="$emit('blur')"
        @input="handleInput(model!)"
      >
      <button
        v-if="removable"
        class="absolute top-1 left-[370px] z-20 bg-transparent p-2 "
        @click="$emit('remove', id)"
      >
        <XIcon class="w-4 h-4 text-gray-600 dark:text-gray-300" />
      </button>

      <Transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <ul
          v-if="model && (suggestions.length > 0)"
          class="absolute top-5 left-1 z-30 w-[400px] py-2 mt-6  bg-white rounded-2xl shadow-lg max-h-60 overflow-auto origin-top
                   dark:bg-gray-800 dark:shadow-gray-900/50"
        >
          <TransitionGroup
            enter-active-class="transition-all duration-200 ease-out"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition-all duration-200 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <li
              v-for="(suggestion, index) in suggestions"
              :key="index"
              :class="[
                'px-4 py-2 text-sm text-gray-700 cursor-pointer',
                'dark:text-gray-300',

              ]"
              @click="$emit('select', suggestion, id)"
            >
              {{ suggestion.title }}
            </li>
          </TransitionGroup>
        </ul>
      </Transition>
    </div>
  </div>
</template>



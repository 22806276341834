import type { AxiosInstance } from "axios"
import { TrucksModel } from "~/management/trucks/trucks.model"

export const useTrucksApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1/truck"

  return {
    GET_TRUCKS(params: PaginationParams): ResponseContainer<TrucksModel[]> {
      return axios.$get(`${base}/get-all`, { params })
    },
    EDIT_TRUCK(id: number, params: any): Promise<ResponseType> {
      return axios.$patch(`${base}/set-samsara-vin/${id}`, null, { params })
    }
  }
}

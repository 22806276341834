<script setup lang="ts">
import { Profile, useSessionService, useSessionStore } from "~/session"

const modal = useModal()
const { required } = useRule()

const sessionStore = useSessionStore()
const { setProfile, getProfile } = useSessionService()

const { profile } = storeToRefs(sessionStore)
const loading = ref(false)
const editing = ref(false)

const form = ref(new Profile())
const rules = ref({ firstname: { required }, nickname: { required } })

const { vuelidate, hasError } = useValidate(form, rules)

const toggleEditing = () => (editing.value = !editing.value)

const toggleModal = () => {
  modal.hide("profile")
  modal.show("profile-password")
}

const onShow = () => (form.value = new Profile(profile.value))

const onCancel = () => {
  editing.value = false
  form.value = new Profile(profile.value)
  vuelidate.value?.$reset()
}

const onSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  let id: number = Number(localStorage.getItem("user_id"))
  loading.value = true
  await setProfile({
    firstname: form.value.firstname,
    lastname: form.value.lastname,
    phone: form.value.phone,
    nickname: form.value.nickname
  })
  await getProfile(id)
  modal.hide("profile")
  loading.value = false
  editing.value = false
}
</script>

<template>
  <Modal id="profile" :label="$t('labels.profile')" size="xs" :closable="!editing" @show="onShow">
    <div class="grid gap-4 p-4">
      <FormGroup v-slot="{ id }" v-bind="hasError('nickname')" required :label="$t('labels.nickname')">
        <Input :id="id" v-model="form.nickname" :disabled="!editing" />
      </FormGroup>

      <FormGroup v-slot="{ id }" v-bind="hasError('firstname')" required :label="$t('labels.first_name')">
        <Input :id="id" v-model="form.firstname" :disabled="!editing" />
      </FormGroup>

      <FormGroup v-slot="{ id }" :label="$t('labels.lastname')">
        <Input :id="id" v-model="form.lastname" :disabled="!editing" />
      </FormGroup>

      <FormGroup v-slot="{ id }" :label="$t('labels.phone')">
        <InputMask :id="id" v-model="form.phone" mask="+# ### ### ####" :disabled="!editing" />
      </FormGroup>
    </div>

    <template #footer>
      <div class="modal-footer">
        <template v-if="editing">
          <Button color="danger" :label="$t('actions.cancel')" @click="onCancel" />
          <Button :label="$t('actions.save')" :loading="loading" @click="onSubmit" />
        </template>

        <template v-else>
          <Button color="info" :label="$t('actions.change_password')" @click="toggleModal" />
          <Button :label="$t('actions.edit')" @click="toggleEditing" />
        </template>
      </div>
    </template>
  </Modal>
</template>

import { useSystemSettingsStore } from "~/management/systemSettings/systemSettings.store"
import { useSystemSettingsApi } from "~/management/systemSettings/systemSettings.api"
import {
  EfsSystemSettingsPreview,
  SystemActive,
  SystemSettingsPreview
} from "~/management/systemSettings/systemSettings.model"

export const useSystemSettingsService = () => {
  const systemSettingsApi = useSystemSettingsApi()
  const { confirmActivateDeactivate } = useAlert()
  const { formEfs, listLoading, list, pagination, settingTypeComputed, form } = storeToRefs(useSystemSettingsStore())

  const getSystemSettingsList = async () => {
    listLoading.value = true
    if (settingTypeComputed.value !== "EFS") {
      systemSettingsApi
        .GET_SYSTEM_SETTINGS(settingTypeComputed.value)
        .then(({ content, totalPages, totalElements }) => {
          list.value = [content]
          pagination.value.totalPages = totalPages
          pagination.value.totalElements = totalElements
        })
        .catch(() => {})
        .finally(() => (listLoading.value = false))
    } else {
      systemSettingsApi
        .GET_EFS_SETTINGS({
          size: 20
        })
        .then(({ content, totalPages, totalElements }) => {
          list.value = content
          pagination.value.totalPages = totalPages
          pagination.value.totalElements = totalElements
        })
        .catch(() => {})
        .finally(() => (listLoading.value = false))
    }
  }

  const createSystemSetting = async (payload: SystemSettingsPreview) => {
    return systemSettingsApi.CREATE_SYSTEM_SETTING(payload)
  }

  const createEfsSystemSetting = async (payload: EfsSystemSettingsPreview) => {
    return systemSettingsApi.CREATE_EFS_SETTING(payload)
  }
  const editEfsSystemSetting = async (payload: EfsSystemSettingsPreview) => {
    return systemSettingsApi.EDIT_EFS_SETTING(payload)
  }

  const activateSystemSetting = async (id: number, params: SystemActive) => {
    confirmActivateDeactivate(true).then(() => {
      systemSettingsApi
        .ACTIVATE_SETTING(id, params)
        .then(() => getSystemSettingsList())
        .catch(() => {})
    })
  }

  const deactivateSystemSetting = async (id: number, params: SystemActive) => {
    confirmActivateDeactivate(false).then(() => {
      systemSettingsApi
        .ACTIVATE_SETTING(id, params)
        .then(() => getSystemSettingsList())
        .catch(() => {})
    })
  }

  const editSystemSetting = async (payload: SystemSettingsPreview) => {
    return systemSettingsApi.EDIT_SYSTEM_SETTING(payload, settingTypeComputed.value)
  }

  const getSingleSystemSetting = async (id: number) => {
    const { content } = await systemSettingsApi.GET_SINGLE_SYSTEM_SETTING(id, settingTypeComputed.value).catch(() => {})
    Object.assign(form.value, content)
  }

  const getEfsSingleSystemSetting = async (id: number) => {
    const { content } = await systemSettingsApi.GET_EFS_SINGLE_SYSTEM_SETTING(id)
    Object.assign(formEfs.value, content)
  }

  return {
    getEfsSingleSystemSetting,
    editEfsSystemSetting,
    createEfsSystemSetting,
    getSystemSettingsList,
    createSystemSetting,
    activateSystemSetting,
    deactivateSystemSetting,
    editSystemSetting,
    getSingleSystemSetting
  }
}

import type { AxiosInstance } from "axios"
import { FuelTransaction } from "~/management/transaction/transaction.model"

export const useTransactionsApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1/transactions"

  return {
    GET_TRANSACTIONS(params: PaginationParams, companyIds: number[]): ResponseContainer<FuelTransaction[]> {
      return axios.$get(base, {
        params: {
          ...params,
          company_ids: companyIds?.join(", ")
        }
      })
    },
    GET_TRANSACTIONS_COUNT() {
      return axios.$get(`${base}/count`)
    },
    GENERATE_REPORT(params: any): ResponseContainer<any> {
      return axios.$get(`${base}/generate-report`, { params, responseType: "blob" })
    }
  }
}

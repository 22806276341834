<script lang="ts" setup>
import { Icon } from "@iconify/vue"

import MainSidebarSwitcher from "./MainSidebarSwitcher.vue"
import MainThemeSwitcher from "./MainThemeSwitcher.vue"
import { useSessionStore } from "~/session"

const sessionStore = useSessionStore()
const { profile, username } = storeToRefs(sessionStore)

const route = useRoute()
const { t } = useI18n()

const titles: Record<string, string> = {
  "/management/dashboard": t("menu.dashboard"),
  "/management/fuel-book": t("menu.fuel_book"),
  "/management/cards": t("menu.cards"),
  "/management/price-stores": t("menu.price_stores"),
  "/management/idling": t("menu.idling"),
  "/management/users": t("menu.users"),
  "/management/bookers": t("menu.bookers"),
  "/management/report": t("menu.report"),
  "/management/transactions": t("menu.transactions"),
  "/management/roles": t("menu.roles"),
  "/management/system-settings": t("menu.system_settings"),
  "/management/trucks": t("menu.trucks")
}

const mainTitle = computed(() => titles[route.path] ?? "")
</script>

<template>
  <header class="app-header">
    <div class="app-header-container">
      <div class="app-header-left">
        <MainSidebarSwitcher />
      </div>

      <slot />

      <div class="flex items-center justify-between w-full">
        <span class="font-semibold capitalize text-primary-700">{{ mainTitle }}</span>

        <div class="app-header-right">
          <MainThemeSwitcher />
          <Icon :width="20" icon="lucide:user" />
          <span class="text-xs text-right font-semibold">{{ username }}</span>
          <span class="w-px h-10 bg-gray-200 dark:bg-gray-800" />
          <span class="text-xs text-right text-gray-400">{{ profile.role_name }}</span>
        </div>
      </div>
    </div>
  </header>
</template>

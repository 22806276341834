import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"
import { router } from "@/app/plugins"

export const useRouteProtection = (permission: RolePermissionsModel) => {
  const isChecking = ref(true)

  if (!permission) {
    isChecking.value = false
    return { isChecking }
  }

  const sessionStore = useSessionStore()
  const { profile } = storeToRefs(sessionStore)

  onBeforeMount(() => {
    if (profile.value.authorities.has(permission)) return (isChecking.value = false)
    router.push({ path: "/management/dashboard" })
  })

  return { isChecking }
}

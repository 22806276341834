<script setup lang="ts">
import { RolePermissionsModel, useRolesStore } from "~/management/roles"
import { PERMISSIONS } from "~/management/roles/roles.constants"

const { hasError } = defineProps<{ hasError: any }>()

const { t } = useI18n()

const rolesStore = useRolesStore()
const { form } = storeToRefs(rolesStore)

const toggleCategoryPermissions = (categoryKey: string, isChecked: boolean): void => {
  const categoryPermissions = PERMISSIONS[categoryKey].permissions.map((permission) => permission.value)
  if (isChecked) {
    form.value.authorities = [...new Set([...form.value.authorities, ...categoryPermissions])]
  } else {
    form.value.authorities = form.value.authorities.filter(
      (permission) => !categoryPermissions.includes(permission as RolePermissionsModel)
    ) as RolePermissionsModel[]
  }
}

const isCategoryChecked = (categoryKey: string): boolean => {
  const categoryPermissions = PERMISSIONS[categoryKey].permissions.map((permission) => permission.value)
  return categoryPermissions.every((permission) => form.value.authorities.includes(permission as RolePermissionsModel))
}
</script>

<template>
  <form class="space-y-4 p-4">
    <FormGroup v-slot="{ id }" :label="$t('labels.name')" v-bind="hasError('name')">
      <Input :id v-model="form.name" required :placeholder="t('placeholders.enter_role_name')" />
    </FormGroup>
    <FormGroup :label="$t('labels.description')">
      <Input v-model="form.description" :placeholder="t('placeholders.enter_description')" />
    </FormGroup>
    <hr class="h-px border-0 bg-gray-200 dark:bg-gray-700" />
    <div>
      <h2 class="text-lg font-semibold">Permissions</h2>
      <div v-for="(category, key) in PERMISSIONS" :key="key" class="mt-4">
        <label class="flex items-center font-medium">
          {{ category.title }}
          <input
            type="checkbox"
            class="ml-2"
            :checked="isCategoryChecked(key)"
            @change="toggleCategoryPermissions(key, ($event.target as HTMLInputElement).checked)"
          />
        </label>
        <div class="mt-2 grid grid-cols-2 gap-x-2">
          <div v-for="permission in category.permissions" :key="permission.value">
            <label>
              <input v-model="form.authorities" type="checkbox" :value="permission.value" />
              {{ permission.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
// import { usePriceStoresService, usePriceStoresStore } from "~/management/priceStores"
import Select from "@vueform/multiselect"
import { useManagementService, useManagementStore } from "~/management"

const modal = useModal()
// const { setStore, getStoresListTable, getOneStoreList, updateStore } = usePriceStoresService()
// const store = usePriceStoresStore()
const { successToast } = useAlert()
const managementStore = useManagementStore()

const { storeList } = storeToRefs(managementStore)
const { fetchStoresList, createStoreLogo } = useManagementService()

const { t } = useI18n()
const loading = ref(false)
const imageUrl = ref<null | string>("")
const { required } = useRule()
const form = reactive({
  file: "",
  station_ids: [],
  sending_type: ""
})

const selectTypes = ref<any>([
  {
    title: "All",
    value: "ALL"
  },
  {
    title: "Custom",
    value: "CUSTOM"
  }
])

const rules = computed(() => ({
  file: { required },
  station_ids: form.sending_type === "CUSTOM" ? { required } : {},
  sending_type: { required }
}))

const { vuelidate, hasError } = useValidate(form, rules)
const store_edit = ref<number | null>(null)
const label = computed<string>(() => (store_edit.value ? "Edit store logo" : "Add store logo"))

const onSave = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return

  const formData = new FormData()
  formData.append("file", form.file)

  loading.value = true

  await createStoreLogo(form.station_ids, form.sending_type, formData).finally(() => {
    loading.value = false
  })
  modal.hide("company_logo")
  successToast("Store logo successfully added")
}

const onHide = () => {
  // form.value = new StoresAssignModel()

  // store.formLoading = false
  // store_edit.value = null

  form.file = ""
  form.station_ids = []
  form.sending_type = ""
  imageUrl.value = null
  modal.hide("company_logo")
  vuelidate.value?.$reset()
}
const clearType = () => {
  form.sending_type = ""
}

function handleFile(e: Event) {
  const file = e.target.files[0]
  if (file && file.type.startsWith("image/")) {
    form.file = file
    const reader = new FileReader()
    reader.onload = (e) => {
      imageUrl.value = e.target.result
    }
    reader.readAsDataURL(file)
  } else {
    imageUrl.value = null
  }
}
</script>

<template>
  <Modal id="company_logo" :close-on-backdrop="false" :label="label" :loading="loading" @hide="onHide">
    <form class="grid gap-4 p-4">
      <FormGroup v-slot="{ id }" label="Logo" required v-bind="hasError('file')">
        <div class="flex items-center gap-2">
          <img v-if="imageUrl" :src="imageUrl" alt="Selected Image" class="h-12 w-10 rounded" />
          <input
            :id
            type="file"
            :placeholder="t('placeholders.enter_store')"
            accept="image/png, image/gif, image/jpeg"
            class="w-full cursor-pointer rounded border bg-white text-sm font-semibold text-gray-400 file:mr-4 file:cursor-pointer file:border-0 file:bg-gray-100 file:px-4 file:py-3 file:text-gray-500 file:hover:bg-gray-200"
            @change="handleFile"
          />
        </div>
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.type')" v-bind="hasError('sending_type')" required>
        <Select
          :id
          v-model="form.sending_type"
          searchable
          label="title"
          :can-deselect="false"
          :options="selectTypes"
          append-to-body
          :placeholder="t('placeholders.select_type')"
          class="!rounded-none"
          @clear="clearType"
        />
      </FormGroup>
      <FormGroup
        v-slot="{ id }"
        :label="form.sending_type === 'ALL' ? $t('labels.except_stores') : $t('stores')"
        :required="form.sending_type !== 'ALL'"
        v-bind="hasError('station_ids')"
      >
        <LazySelect
          :id="id"
          v-model="form.station_ids"
          :close-on-select="false"
          :fetch="() => fetchStoresList({ size: 1000 })"
          :get-option-label="(item: any) => item.store_number || ''"
          :hide-selected="false"
          :options="storeList"
          :placeholder="form.sending_type === 'ALL' ? $t('labels.except_stores') : $t('stores')"
          append-to-body
          class="tags-select min-h-[80px]"
          content-refreshable
          mode="tags"
          multiple-content
          value-prop="id"
        />
      </FormGroup>
    </form>
    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button :label="$t('actions.cancel')" color="danger" @click="hide" />
        <Button :disabled="vuelidate.$error" :label="$t('actions.submit')" :loading="loading" @click="onSave" />
      </div>
    </template>
  </Modal>
</template>

import type { AxiosInstance } from "axios"
import { IdlingModel, IdlingParams, IdlingPayload } from "~/management/idling/idling.model"

export const useIdlingApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1/idling"

  return {
    GET_IDLING(params: IdlingParams, companyIds: number[]): ResponseContainer<IdlingModel[]> {
      return axios.$get(`${base}/trucks`, {
        params: {
          ...params,
          company_ids: companyIds?.join(", ")
        }
      })
    },
    GET_IDLING_DETAIL(params: any): ResponseContainer<IdlingModel[]> {
      return axios.$get(`${base}`, { params })
    },
    POST_IDLING(payload: IdlingPayload): ResponseContainer<IdlingModel> {
      return axios.$put(base, payload)
    }
  }
}

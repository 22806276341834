<script setup lang="ts">
defineProps<{ title: string }>()
const selectedTitle = inject("selectedTitle")
</script>

<template>
  <div v-if="title === selectedTitle">
    <slot />
  </div>
</template>

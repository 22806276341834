<template>
  <ButtonIcon
    v-if="isCopied"
    v-tooltip="'Copied!'"
    icon="lucide:copy-check"
    color="info"
    variant="ghost"
    width="16"
    size="xs"
    @click="copyText"
  />
  <ButtonIcon v-else icon="lucide:copy" color="info" variant="ghost" width="16" size="xs" @click="copyText" />
</template>

<script setup lang="ts">
import { ButtonIcon } from "$/ui"

interface Props {
  textToCopy: string
}

const props = defineProps<Props>()

const isCopied = ref<boolean>(false)

const copyText = async () => {
  if (typeof navigator !== "undefined" && navigator.clipboard) {
    try {
      await nextTick()
      setTimeout(() => {
        navigator.clipboard.writeText(props.textToCopy)
        isCopied.value = true
      }, 100)
      setTimeout(() => {
        isCopied.value = false
      }, 2000)
    } catch (error) {
      console.error("Failed to copy text: ", error)
    }
  } else {
    alert("Clipboard API is not supported in this browser.")
  }
}
</script>

import { ref } from "vue"
import { defineStore } from "pinia"
import { useTitle } from "@vueuse/core"

export const useMainStore = defineStore("main", () => {
  const loading = ref(false)
  const title = useTitle("", { titleTemplate: "%s GL FUELBOARD" })

  return { loading, title }
})

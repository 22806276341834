<script setup lang="ts">
import { RolesAssignModel, useRolesService, useRolesStore } from "~/management/roles"
import RolesForm from "~/management/roles/components/RolesForm.vue"

const modal = useModal()
const { addRole, editRole, getSingleRole, getRolesListTable } = useRolesService()

const { t } = useI18n()
const { required } = useRule()

const rolesStore = useRolesStore()

const { form, formLoading } = storeToRefs(rolesStore)

const rules = ref({
  name: { required }
})
const rolesEdit = ref<number | null>(null)

const { vuelidate, hasError } = useValidate(form, rules)

const label = computed<string>(() => (rolesEdit.value ? t("actions.edit_roles") : t("actions.add_roles")))

const onSave = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return

  try {
    rolesStore.formLoading = true

    if (rolesEdit.value) {
      await editRole({ id: rolesEdit.value, ...form.value })
    } else {
      await addRole(form.value)
    }

    await getRolesListTable()
    onHide()
  } catch (error) {
    rolesStore.formLoading = false
  }
}

const onBeforeShow = async (id: number) => {
  if (!id) return
  formLoading.value = true
  rolesEdit.value = id
  await getSingleRole(id)
  formLoading.value = false
}

const onHide = () => {
  rolesEdit.value = null
  form.value = new RolesAssignModel()
  formLoading.value = false
  modal.hide("roles")
  vuelidate.value?.$reset()
}
</script>

<template>
  <Modal id="roles" :label :loading="formLoading" :close-on-backdrop="false" @before-show="onBeforeShow" @hide="onHide">
    <RolesForm :has-error="hasError" />

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button color="danger" :label="$t('actions.cancel')" @click="hide" />
        <Button
          :label="$t('actions.submit')"
          :disabled="vuelidate.$error || formLoading"
          :loading="formLoading"
          @click="onSave"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts" generic="T">
import { computed, onBeforeMount, ref, shallowRef, useSlots, watch } from "vue"
import isEqual from "lodash/isEqual"
import get from "lodash/get"
import { Icon } from "@iconify/vue"

import { RouteLocationRaw } from "vue-router"
import { ITableCol } from "$/ui"

interface ITableProps<T> {
  cols: ITableCol<T>[]
  rows: T[]
  rowLink?: (row: T, idx: number) => string | RouteLocationRaw
  rowClass?: (row: T, idx: number) => string
  minRows?: number
  emptyText?: string
  headClass?: string
  bodyClass?: string
  maxHeight?: string
  minHeight?: string
  selectMode?: "single" | "multiple"
  selected?: T[] | T | T[keyof T][]
  selectBy?: keyof T
  hover?: boolean
  noWrap?: boolean
  rounded?: boolean
  striped?: boolean
  loading?: boolean
  gridline?: boolean
  skeleton?: boolean
  clickable?: boolean
  groupDrivers?: string[]
  collapsible?: boolean
  inifiniteScroll?: boolean
  expandRow?: boolean
  groupBy?: (row: T) => string
  groupOrder?: string[]
  defaultOpenGroups?: boolean
  store?: boolean
  tableRow?: number
}

interface ITableEmits<T> {
  (e: "update:selected", value: T[] | T): void

  (e: "row-mounted", row: T, el: HTMLElement): void

  (e: "sorting", field: string, order: "asc" | "desc" | "null"): void

  (e: "select", value: T | T[keyof T], row: T): void

  (e: "deselect", value: T | T[keyof T], row: T): void

  (e: "row-clicked", row: T, idx: number, event: MouseEvent): void

  (e: "row-mouseover", row: T, idx: number, event: MouseEvent): void

  (e: "row-mouseleave", row: T, idx: number, event: MouseEvent): void

  (e: "row-mouseout", row: T, idx: number, event: MouseEvent): void

  (e: "header-clicked", col: ITableCol<T>, idx: number, event: MouseEvent): void

  (e: "cell-clicked", col: ITableCol<T>, row: T, idx: number, event: MouseEvent): void

  (e: "table-scroll", col: ITableCol<T>, event: MouseEvent): void

  (e: "group-toggled", group: string): void

  (e: "intersection-target", value: Element | null): void
}

const props = withDefaults(defineProps<ITableProps<T>>(), {
  minRows: 0,
  hover: true,
  defaultOpenGroups: true
})
const emits = defineEmits<ITableEmits<T>>()

const slots = useSlots()

// Refs
const tableRowStyle = ref<any>()
const uid = ref("")
const sortField = ref("")
const selectedRecords = ref<any>()
const lastSelectedIdx = ref<number>()
const sortOrder = ref<"asc" | "desc" | "null">("asc")
const tableCols = ref<ITableCol<T>[]>([])
const tableRows = shallowRef<T[]>([])
const expandedGroups = ref(new Set<string>())

// Computed
const displayEmptyRow = computed(() => !tableRows.value?.length)
const countVisibleCols = computed(() => tableCols.value?.filter((col) => col?.visible).length)
const isMultipleSelect = computed(() => props.selectMode === "multiple")
const intersectionTarget = ref<HTMLDivElement>()
const tableClass = computed(() => ({
  table: true,
  "table-hover": props.hover,
  "table-nowrap": props.noWrap,
  "table-linked": props.rowLink,
  "table-striped": props.striped,
  "table-gridline": props.gridline,
  "table-clickable": props.clickable
}))

const selectedModel = computed({
  get() {
    if (props.selected) return props.selected
    return selectedRecords.value
  },
  set(value) {
    emits("update:selected", value)
    selectedRecords.value = value
  }
})

const isAllSelected = computed(() => {
  if (isMultipleSelect.value) {
    if (tableRows.value?.length === selectedModel.value?.length) {
      if (props.selectBy) return true
      return tableRows.value?.every((row, idx) => isEqual(row, selectedModel.value?.at(idx)))
    }
  }
  return false
})

const lessThanMinRows = computed(() => {
  if (!tableRows.value.length || tableRows.value?.length === 0) return true
  return tableRows.value.length < props.minRows
})

const blankRows = computed(() => {
  if (!tableRows.value.length || tableRows.value?.length === 0) return props.minRows
  if (tableRows.value.length >= props.minRows) return 0
  return props.minRows - tableRows.value?.length
})

const groupedRows = computed(() => {
  if (!props.collapsible || !props.groupBy) return { default: props.rows }

  const groups: Record<string, T[]> = {}

  props.rows.forEach((row) => {
    // @ts-expect-error prop.groupBy is already checked above
    const groupKey = props.groupBy(row)
    if (!groups[groupKey]) groups[groupKey] = []
    groups[groupKey].push(row)
  })

  return groups
})

const effectiveGroupOrder = computed(() => {
  if (props.groupOrder) {
    //Incoming group orders will show after all other items , but with order at the end of list
    const allGroups = new Set([...props.groupDrivers, ...Object.keys(groupedRows.value), ...props.groupOrder])
    return Array.from(allGroups).sort((a, b) => {
      const indexA = props.groupOrder?.indexOf(a) ?? 0
      const indexB = props.groupOrder?.indexOf(b) ?? 0

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB
      }

      if (indexA === -1 && indexB !== -1) return -1
      if (indexA !== -1 && indexB === -1) return 1

      return 0
    })
  }
  return Object.keys(groupedRows.value)
})

// Methods
const sequence = (idx: number, page: number, size: number) => idx + 1 + page * size
const hasFormatter = <T,>(col: ITableCol<T>) => typeof col?.formatter === "function"

const callFormatter = <T,>(col: ITableCol<T>, row: T) => {
  if (!hasFormatter(col)) return
  if (typeof col.formatter === "function") return col.formatter(get(row, col.name), row)
}

const selectAll = (event: Event) => {
  const value = props.selectBy ? tableRows.value?.map((row) => row[props.selectBy!]) : tableRows.value
  if ((<HTMLInputElement>event.target).checked) selectedModel.value = value
  else selectedModel.value = []
}

const normalizeCols = () => {
  if (!props.cols?.length) return
  tableCols.value = []
  props.cols.forEach((col, idx) => tableCols.value.push(newCol(col, idx)))
}

const newCol = (col: ITableCol<T>, idx: number) => {
  const defaultValue = {
    name: "",
    headClass: "",
    dataClass: "",
    sortField: null,
    formatter: null,
    withoutLink: false,
    visible: true,
    width: null,
    index: idx
  }

  return Object.assign({}, defaultValue, col)
}

const setRows = (rows: T[]) => {
  if (Array.isArray(rows)) tableRows.value = rows
}

const getRowClass = (row: T, idx: number) => {
  if (typeof props.rowClass === "function") return props.rowClass(row, idx)
  return props.rowClass
}

const setBgClass = (row: any) => {
  if (props.inifiniteScroll && row?.suggestion_status === "NEW") {
    if (!row?.is_pending) {
      return "bg-green-200 dark:bg-green-400 "
    } else if (row?.is_pending) {
      return "bg-yellow-200 dark:bg-yellow-400"
    }
    if (props.store && !row.active) {
      return "bg-gray-50 opacity-50 dark:bg-gray-600 "
    }
  }
}

const setBgStyle = (row: any) => {
  if (props.tableRow && row?.truck?.id && row?.suggestion_status !== "NEW") {
    return row.truck.id === tableRowStyle.value ? "bg-gray-200  dark:bg-gray-600 " : ""
  }
  if (props.tableRow && row?.truck?.id && row?.suggestion_status == "NEW") {
    return row.truck.id === tableRowStyle.value ? "bg-gray-200 opacity-70  dark:bg-gray-600" : ""
  }
}

watchEffect(() => {
  if (props.tableRow) {
    tableRowStyle.value = props.tableRow
  }
})

const tableHeight = computed(() => {
  if (props.maxHeight) {
    return props.maxHeight
  } else if (props.inifiniteScroll) {
    return "80vh"
  } else {
    if (window.innerWidth >= 2100) {
      return "80vh"
    } else if (window.innerWidth >= 1780) {
      return "75vh"
    } else if (window.innerWidth >= 1536) {
      return "70vh"
    } else {
      return "65vh"
    }
  }
})

const getColLabel = (col: ITableCol<T>) => (typeof col.label === "undefined" ? col.name?.replace(".", " ") : col.label)

const getValue = (row: T) => (props?.selectBy ? row[props.selectBy] : row)

const isColSlot = (name: string) => typeof slots[name] !== "undefined"

const renderNormalCol = (col: ITableCol<T>, row: T) => {
  return hasFormatter(col) ? callFormatter(col, row) : get(row, col.name, "")
}

const sortBy = (col: ITableCol<T>) => {
  if (col.sortField?.length) {
    if (sortField.value === col.sortField) {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : sortOrder.value === "desc" ? "null" : "asc"
    } else {
      sortField.value = col.sortField
      sortOrder.value = "asc"
    }

    emits("sorting", sortField.value, sortOrder.value)
  }
}

const rangeSelect = (_row: T, idx: number) => {
  if (lastSelectedIdx.value !== -1) {
    const start = Math.min(lastSelectedIdx.value!, idx) + 1
    const end = Math.max(lastSelectedIdx.value!, idx)

    for (let i = start; i <= end; i++) {
      const value = getValue(tableRows.value.at(i)!)
      selectedModel.value?.push(value)
    }
  }
}

const isGroupExpanded = (groupKey: string) => expandedGroups.value.has(groupKey)

const initializeExpandedGroups = () => {
  if (intersectionTarget.value) {
    emits("intersection-target", intersectionTarget.value)
  } else {
    emits("intersection-target", null)
  }

  if (props.defaultOpenGroups) {
    expandedGroups.value = new Set(effectiveGroupOrder.value)
  } else {
    expandedGroups.value.clear()
  }
}

// Events
const toggleGroup = (groupKey: string) => {
  if (isGroupExpanded(groupKey)) expandedGroups.value.delete(groupKey)
  else expandedGroups.value.add(groupKey)

  emits("group-toggled", groupKey)
}

const onHeaderClicked = (col: ITableCol<T>, idx: number, event: MouseEvent) => {
  sortBy(col)
  emits("header-clicked", col, idx, event)
}

const onCellClicked = (col: ITableCol<T>, row: T, idx: number, event: MouseEvent) => {
  emits("cell-clicked", col, row, idx, event)
}

const scrollTable = (event: MouseEvent) => {
  emits("table-scroll", event)
}

const onRowMounted = (row: T, el: any) => {
  emits("row-mounted", row, el)
}

const onRowClicked = (row: T, idx: number, event: MouseEvent) => {
  emits("row-clicked", row, idx, event)
}

const onRowMouseover = (row: T, idx: number, event: MouseEvent) => {
  emits("row-mouseover", row, idx, event)
}

const onRowMouseout = (row: T, idx: number, event: MouseEvent) => {
  emits("row-mouseout", row, idx, event)
}

const onRowMouseleave = (row: T, idx: number, event: MouseEvent) => {
  emits("row-mouseleave", row, idx, event)
}

// eslint-disable-next-line @typescript-eslint/ban-types
const onNavigate = (event: MouseEvent, navigate: Function) => {
  if (props.clickable) event.preventDefault()
  else navigate(event)
}

const onChange = (row: T, rowIdx: number, event: Event) => {
  const checked = (<HTMLInputElement>event.target).checked

  if (checked) {
    lastSelectedIdx.value = rowIdx
    emits("select", getValue(row), row)
  } else emits("deselect", getValue(row), row)
}

// Watches
watch(
  () => props.cols,
  () => normalizeCols(),
  { immediate: true, deep: true }
)

watch(
  () => props.rows,
  (value) => setRows(value),
  { immediate: true }
)

onMounted(initializeExpandedGroups)

watch(() => [props.rows, props.groupBy], initializeExpandedGroups)

onBeforeMount(() => {
  uid.value = crypto.randomUUID()

  if (isMultipleSelect.value) selectedRecords.value = []
  else selectedRecords.value = null
})
</script>

<template>
  <div class="relative overflow-hidden dark:border-gray-800" :class="{ border: gridline, 'rounded-xl': rounded }">
    <div class="table-container" :style="{ maxHeight: tableHeight }" @scroll="scrollTable">
      <table :class="tableClass">
        <thead class="table-head" :class="[headClass, { 'sticky top-[0.01rem] z-20': tableHeight }]">
          <tr>
            <template v-if="selectMode">
              <th v-if="tableRows?.length" class="table-select-col">
                <input
                  v-if="isMultipleSelect"
                  class="checkbox form-checkbox"
                  type="checkbox"
                  :name="uid"
                  :checked="isAllSelected"
                  @click.stop
                  @change="selectAll"
                />
              </th>
            </template>

            <template v-for="(col, idx) in tableCols">
              <template v-if="col.visible">
                <th
                  :key="idx"
                  :class="[col.headClass, { sortable: col.sortField, sticky: col?.fixed }]"
                  @click="onHeaderClicked(col, idx, $event)"
                >
                  <div class="table-head-col" :class="[col.labelClass, { 'justify-between': col.sortField }]">
                    {{ getColLabel(col) }}
                    <!-- Sort icons -->
                    <Icon v-if="col.sortField && sortField !== col.sortField" icon="lucide:arrow-up-down" :width="14" />
                    <template v-if="sortField === col.sortField">
                      <Icon v-if="sortOrder === 'desc'" icon="lucide:arrow-down-wide-narrow" :width="14" />
                      <Icon v-if="sortOrder === 'asc'" icon="lucide:arrow-up-narrow-wide" :width="14" />
                      <Icon v-if="sortOrder === 'null'" icon="lucide:arrow-up-down" :width="14" />
                    </template>
                  </div>
                </th>
              </template>
            </template>
          </tr>
        </thead>

        <tbody v-cloak class="table-body" :class="bodyClass">
          <template v-for="groupKey in effectiveGroupOrder" :key="groupKey">
            <tr
              v-if="collapsible && groupedRows[groupKey]?.length"
              class="group-header cursor-pointer"
              @click="toggleGroup(groupKey)"
            >
              <td :colspan="countVisibleCols + (selectMode ? 1 : 0) + 1" class="relative !p-0">
                <Icon
                  :icon="isGroupExpanded(groupKey) ? 'lucide:chevron-down' : 'lucide:chevron-right'"
                  :width="18"
                  class="absolute left-0 top-1/2 -translate-y-1/2 text-white"
                />
                <slot name="group-header" :group-key="groupKey" :count="groupedRows[groupKey]?.length || 0">
                  <div class="flex items-center">
                    <span class="ml-2 font-bold">{{ groupKey }} ({{ groupedRows[groupKey]?.length || 0 }})</span>
                  </div>
                </slot>
              </td>
            </tr>

            <template v-if="!collapsible || isGroupExpanded(groupKey)">
              <template v-for="(row, rowIdx) in groupedRows[groupKey]" :key="rowIdx">
                <tr
                  :ref="(el) => onRowMounted(row, el)"
                  class="group table-row"
                  :class="[getRowClass(row, rowIdx), { 'group-row': collapsible }, setBgClass(row), setBgStyle(row)]"
                  @click="onRowClicked(row, rowIdx, $event)"
                  @mouseover="onRowMouseover(row, rowIdx, $event)"
                  @mouseout="onRowMouseout(row, rowIdx, $event)"
                  @mouseleave="onRowMouseleave(row, rowIdx, $event)"
                >
                  <template v-if="selectMode">
                    <td class="table-select-col">
                      <input
                        v-if="isMultipleSelect"
                        v-model="selectedModel"
                        class="checkbox form-checkbox"
                        type="checkbox"
                        :name="uid"
                        :value="getValue(row)"
                        @click.exact.stop
                        @click.shift="rangeSelect(row, rowIdx)"
                        @change="onChange(row, rowIdx, $event)"
                      />
                      <input
                        v-else
                        v-model="selectedModel"
                        class="radio form-radio"
                        type="radio"
                        :name="uid"
                        :value="getValue(row)"
                        @click.stop
                        @change="onChange(row, rowIdx, $event)"
                      />
                    </td>
                  </template>

                  <template v-for="(col, idx) in tableCols" :key="idx">
                    <template v-if="col?.visible">
                      <!-- Slot cols -->
                      <template v-if="isColSlot(col.name)">
                        <td
                          :class="[
                            col.dataClass,
                            { 'table-without-link': col?.withoutLink, 'table-fixed-col': col?.fixed }
                          ]"
                          :style="{ width: col.width }"
                        >
                          <RouterLink
                            v-if="rowLink && !col?.withoutLink"
                            v-slot="{ href, navigate }"
                            custom
                            :to="rowLink(row, rowIdx)"
                          >
                            <a :href="href" @click="onNavigate($event, navigate)">
                              <slot :name="col.name" :col="col" :row="row" :idx="rowIdx" :sequence="sequence" />
                            </a>
                          </RouterLink>
                          <slot v-else :name="col.name" :col="col" :row="row" :idx="rowIdx" :sequence="sequence" />
                        </td>
                      </template>
                      <!-- Default cols -->
                      <template v-else>
                        <td
                          v-if="rowLink && !col?.withoutLink"
                          :class="[col.dataClass, { 'table-fixed-col': col?.fixed }]"
                          :style="{ width: col.width }"
                          @click="onCellClicked(col, row, rowIdx, $event)"
                        >
                          <RouterLink v-slot="{ href, navigate }" custom :to="rowLink(row, rowIdx)">
                            <a :href="href" @click="onNavigate($event, navigate)">{{ renderNormalCol(col, row) }}</a>
                          </RouterLink>
                        </td>
                        <td
                          v-else
                          :class="[
                            col.dataClass,
                            { 'table-without-link': col?.withoutLink, 'table-fixed-col': col?.fixed }
                          ]"
                          :style="{ width: col.width }"
                          @click="onCellClicked(col, row, rowIdx, $event)"
                        >
                          {{ renderNormalCol(col, row) }}
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>
                <slot v-if="expandRow" name="expandRow" :row="row"></slot>
              </template>
            </template>
          </template>

          <!-- Empty row -->
          <template v-if="displayEmptyRow && !loading">
            <tr>
              <td :colspan="countVisibleCols + (selectMode ? 1 : 0) + (collapsible ? 1 : 0)" class="table-empty-row">
                <Icon class="mx-auto mb-2" icon="solar:inbox-line-line-duotone" width="40" />
                No Data
              </td>
            </tr>
          </template>

          <!-- Blank rows -->
          <template v-if="lessThanMinRows">
            <tr v-for="i in blankRows" :key="i" :class="{ 'animate-pulse': skeleton }">
              <td v-if="collapsible"></td>
              <td v-if="tableRows?.length && selectMode" class="table-select-col">
                <div :class="{ 'table-skeleton': skeleton }">&nbsp;</div>
              </td>
              <template v-for="(col, idx) in tableCols">
                <td
                  v-if="col?.visible"
                  :key="idx"
                  class="table-blank-row"
                  :class="col.dataClass"
                  :style="{ width: col.width }"
                >
                  <div :class="{ 'table-skeleton': skeleton }">&nbsp;</div>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
      <div ref="intersectionTarget" class="h-px"></div>
    </div>

    <transition name="fade" mode="out-in" class="!z-20">
      <Spinner v-if="loading" />
    </transition>
  </div>
</template>

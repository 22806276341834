<script lang="ts" setup>
// @ts-expect-error: TypeScript does not recognize this module
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js"
import { useStationsStore } from "~/map/stations/stations.store"
import RoutePlannerAutocomplete from "~/map/stations/components/RoutePlannerAutocomplete.vue"
import { Circle, Flag, MapPin, XIcon } from "lucide-vue-next"
import { debounce } from "lodash"
import { storeToRefs } from "pinia"
import { CalcRouteQueryParams, RouterRoute } from "~/map/stations/stations.model"
import * as turf from "@turf/turf"
import { useManagementService, useManagementStore } from "~/management"
import { BookStopModel, useFuelStore } from "~/management/fuel"
import { StoresListTable } from "~/management/priceStores"
import { formatLocation } from "$/utils/location"
import TopTenStations from "~/map/stations/components/TopTenStations.vue"
import { useStationsService } from "../stations.service"

const props = defineProps<{
  currentLocation?: Record<string, unknown>
  loadUpPoints: Record<string, unknown>
  isMapReady?: boolean
}>()

const managementStore = useManagementStore()
const { errorToast } = useAlert()
const { globalPlatform, globalMap } = storeToRefs(useStationsStore())
const { geocodeAddress } = useStationsService()
const topTenOverall = ref([])
const { fetchStoresList } = useManagementService()
const topTenPerSection = ref([])
const allStations = ref([])

const { storeList } = storeToRefs(managementStore)
const loadingFuel = ref(false)

const currentTabId = ref("route")
const globalSearched = ref<boolean>(false)
const waypoints = ref([
  {
    id: generateWaypointId(),
    query: "",
    suggestions: [],
    placeholder: "Set starting point",
    position: { lat: 0, lng: 0 }
  },
  {
    id: generateWaypointId(),
    query: "",
    suggestions: [],
    placeholder: "Destination",
    position: { lat: 0, lng: 0 }
  }
])

watch(
  () => props.isMapReady,
  async (val) => {
    if (val) {
      assignDriverLocation()
    }
  }
)

async function assignDriverLocation() {
  const title = await geocodeAddress({
    lat: props?.currentLocation?.latitude || 0,
    lng: props?.currentLocation?.longitude || 0
  })

  await new Promise((resolve) => {
    setTimeout(resolve, 1000)
  })
  waypoints.value = [
    {
      id: generateWaypointId(),
      query: title || "",
      suggestions: [],
      placeholder: "Set starting point",
      position: { lat: props?.currentLocation?.latitude || 0, lng: props?.currentLocation?.longitude || 0 }
    },
    {
      id: generateWaypointId(),
      query: "",
      suggestions: [],
      placeholder: "Destination",
      position: { lat: 0, lng: 0 }
    }
  ]
}

watch(
  () => props.currentLocation,
  async (val) => {
    if (!val) return
    await assignDriverLocation()
  }
)

watch(
  () => props?.loadUpPoints,
  (val) => {
    if (!val) return
    if (props?.loadUpPoints?.originPos && props?.loadUpPoints?.destinationPos) {
      waypoints.value[0].position = props.loadUpPoints.originPos
      waypoints.value[1].position = props.loadUpPoints.destinationPos
      handleGo()
      return
    }
  },
  { immediate: true, deep: true }
)

const loading = ref(false)
const destination = computed(() => waypoints.value[waypoints.value.length - 1])
const origin = computed(() => waypoints.value[0])

const selectedRouteIndex = ref(0)
const routePolylines = ref([])
let routes = []

function getIntermediate() {
  if (waypoints.value.length <= 2) return []
  return waypoints.value.slice(1, -1)
}

async function calculateRoute(queryParams: CalcRouteQueryParams): Promise<RouterRoute[] | undefined> {
  const router = globalPlatform.value.getRoutingService(null, 8)
  const {
    routingMode = "fast",
    origin,
    destination,
    returnInfo = "polyline",
    transportMode = "truck",
    lang = "en-US",
    intermediate
  } = queryParams

  const routingParameters = {
    transportMode,
    origin,
    destination,
    routingMode,
    return: returnInfo,
    alternatives: 2,
    lang
  }

  if (intermediate && intermediate.length > 0) {
    routingParameters.via = new H.service.Url.MultiValueQueryParameter(
      intermediate.map((wp) => `${wp.position.lat},${wp.position.lng}`)
    )
  }

  try {
    loading.value = true
    const route = await router.calculateRoute(routingParameters)
    return route.routes
  } catch (error) {
    console.log(error, "ERROR CALC ROUTE")
  } finally {
    loading.value = false
  }
}

async function handleGo() {
  if (!origin.value.position.lng || !origin.value.position.lat) {
    errorToast("Origin address not provided")
    return
  }

  if (!destination.value.position.lng || !destination.value.position.lat) {
    errorToast("Destination not provided")
    return
  }

  const originPos = origin.value.position
  const destinationPos = destination.value.position
  const intermediate = getIntermediate()

  routes = await calculateRoute({
    origin: `${originPos.lat},${originPos.lng}`,
    destination: `${destinationPos.lat},${destinationPos.lng}`,
    intermediate
  })

  removeMapObjectsExceptTruckMarker()
  routePolylines.value = [] // Clear previous polylines
  await drawRoutes(routes)

  // Show fuel stations for the default selected route (index 0)
  await showFuelStationsForRoute(selectedRouteIndex.value)
}

function fuelStationMarkers() {
  if (!globalMap.value) return
  const objects = globalMap.value.getObjects()
  return objects.filter((obj) => obj instanceof H.map.DomMarker && obj.getData()?.store_number)
}

function removeMapObjectsExceptTruckMarker() {
  const objects = globalMap.value.getObjects()
  const objectsToRemove = objects.filter((obj) => obj.getData() !== "truck")
  globalMap.value.removeObjects(objectsToRemove)
}

function removeFuelStationMarkers() {
  const markers = fuelStationMarkers()
  globalMap.value.removeObjects(markers)
}

// **New: Function to show fuel stations for a specific route**
async function showFuelStationsForRoute(routeIndex) {
  // Clear existing markers
  removeFuelStationMarkers()

  const selectedRoute = routes[routeIndex]
  const routeLineStrings = selectedRoute.sections.map((section) =>
    H.geo.LineString.fromFlexiblePolyline(section.polyline)
  )
  const multiLineString = new H.geo.MultiLineString(routeLineStrings)
  const multiLineGeoJSON = multiLineString.toGeoJSON()

  // Get all fuel stations along the route
  const stationsPerSection = await getFuelStationsWithinRoute(multiLineGeoJSON)

  // Compute top 10 cheapest stations per section (existing logic)
  const computedTopTenPerSection = stationsPerSection.map((stations) =>
    stations
      .filter((station) => station.active && station.actual_price)
      .sort((a, b) => a.actual_price - b.actual_price)
      .slice(0, 10)
  )
  topTenPerSection.value = computedTopTenPerSection

  // Flatten all stations for the "All" tab
  allStations.value = stationsPerSection.flat()
  // Get unique stations
  const uniqueStations = [
    ...new Map(
      allStations.value
        .filter((s) => s.active) // Only keep active stations
        .map((s) => [s.id, s]) // Create key-value pairs for the Map
    ).values()
  ]

  // Compute the overall top 10 cheapest stations
  topTenOverall.value = uniqueStations
    .filter((station) => station.actual_price)
    .sort((a, b) => a.actual_price - b.actual_price)
    .slice(0, 10)

  // Create a rank lookup map for the top 10 stations
  const topTenRanks = new Map(topTenOverall.value.map((station, index) => [station.id, index + 1]))
  const topTenIds = new Map(topTenPerSection.value.flat().map((station, index) => [station.id, (index % 10) + 1]))
  // Create markers for all unique stations, passing the rank map
  const fuelMarkers = await getFuelStationMarkers(uniqueStations, topTenRanks, topTenIds)
  globalMap.value.addObjects(fuelMarkers)

  // Apply the current tab filter
  handleActiveTab(currentTabId.value)
}

// **Modified: Calculate fuel stations for a single route**
async function getFuelStationsWithinRoute(multiLineGeoJSON: any) {
  const turfLineStrings: any = []
  multiLineGeoJSON.coordinates.forEach((coordinate) => {
    turfLineStrings.push(turf.lineString(coordinate))
  })

  loadingFuel.value = true
  await fetchStoresList({ page: 0, size: 1000, active: true }).finally(() => (loadingFuel.value = false))
  const fuelStations = storeList.value?.content ?? []

  // Array to hold stations per section
  const stationsPerSection: any[] = []

  turfLineStrings.forEach((lineString) => {
    const stationsOnRoute = fuelStations
      .map((station) => {
        const stationPoint = turf.point([station.longitude || 0, station.latitude || 0])
        const distance = turf.pointToLineDistance(stationPoint, lineString, { units: "miles" })
        const roundedDistance = parseFloat(distance.toFixed(2))
        return { ...station, distanceToRoute: roundedDistance }
      })
      .filter((station) => station.distanceToRoute < 1)
    stationsPerSection.push(stationsOnRoute)
  })

  return stationsPerSection
}

// **Modified: Simplified marker creation for a single route**
async function getFuelStationMarkers(
  fuelStations: any[],
  topTenRanks: Map<string, number>,
  topTenIds: Map<string, number>
) {
  const markers = []

  fuelStations.forEach((station) => {
    const actualPrice = station.actual_price ?? 0
    const htmlString = `
      <div class="relative">
        ${
          currentTabId.value === "segment" && topTenIds.has(station.id)
            ? `<div class="absolute top-[-10px] left-[-10px] bg-blue-400 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">${topTenIds.get(station.id)}</div>`
            : topTenRanks.get(station.id)
              ? `<div class="absolute top-[-10px] left-[-10px] bg-blue-400 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">${topTenRanks.get(station.id)}</div>`
              : ""
        }
        <!-- Main pin body -->
        <div class="rounded-t-sm shadow-md overflow-hidden flex">
          <!-- Station header -->
          <div class="bg-[#38761d] px-1 py-0.5 text-white text-center text-[11px] truncate">
            <div class="flex items-center justify-between">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide h-3 w-3 lucide-dollar-sign-icon lucide-dollar-sign"><line x1="12" x2="12" y1="2" y2="22"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
              <h4>${actualPrice}</h4>
            </div>
            <div>
             <p class="text-[9px] text-gray-700">
              ${station.logo_url ? `<img src="${station.logo_url}" alt="logo" class="w-[50px] h-[30px]" />` : ""}
            </p>
            </div>
            <div class="flex items-center justify-between">
              <svg class="w-3 h-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 20V7C4 5.89543 4.89543 5 6 5H13C14.1046 5 15 5.89543 15 7V20M4 20H15M4 20H3M15 20H16M14 9H19.4C19.7314 9 20 9.26863 20 9.6V16.4C20 16.7314 19.7314 17 17.5 17H17.5M7 9H12M8 12H11" stroke="white" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <p>${station.store_number}</p>
            </div>
          </div>
        </div>
      </div>
    `

    const domIcon = new H.map.DomIcon(htmlString, {
      onAttach: function (clonedElement, domIcon, domMarker) {
        clonedElement.style.transform = "translate(-50%, -100%)"
        clonedElement.addEventListener("click", (e) => {
          handleFuelStationCardClick({ target: domMarker })
        })
      },
      onDetach: function (clonedElement, domIcon, domMarker) {
        clonedElement.removeEventListener("click", (e) => {
          handleFuelStationCardClick({ target: domMarker })
        })
      }
    })

    const marker = new H.map.DomMarker({ lat: station.latitude, lng: station.longitude }, { data: station })
    marker.setIcon(domIcon)
    markers.push(marker)
  })

  return markers
}

function handleFuelStationCardClick(e: Event) {
  const marker = e.target
  const station = marker.getData()

  const { handleAddStopToSidebar, bookForm } = useFuelStore()
  handleAddStopToSidebar(
    new BookStopModel({
      id: station.id,
      stop_order: bookForm.stops.length,
      store: new StoresListTable({
        id: station.id,
        name: station.name,
        store_number: station.store_number,
        address: station.address,
        city: station.city ?? "",
        state: station.state ?? "",
        zip_code: station.zip_code ?? "",
        active: station.active ?? false,
        latitude: station.latitude,
        longitude: station.longitude,
        interstate: station.interstate ?? "",
        actual_price: station.actual_price
      }),
      distance: station.distanceToDriver,
      address: formatLocation({ address: station.address ?? "", city: station.city ?? "" }),
      price: station.actual_price ?? 0,
      amount: 0,
      is_full: false
    })
  )
}

function createDomIcon(labelIndex: number, type: "origin" | "waypoint" | "destination"): H.map.DomIcon {
  const color = type === "origin" ? "#469946" : type === "destination" ? "#FF4646" : "#FFC107"

  const label = String.fromCharCode(65 + labelIndex) // 65 is ASCII for 'A'

  return new H.map.DomIcon(
    `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 384 512" style="margin-left: -15px; margin-top: -40px">
      <path fill="${color}"
            d="M192 0C86.4 0 0 86.4 0 192c0 76.8 25.6 99.2 172.8 310.4a24 24 0 0 0 38.4 0C358.4 291.2 384 268.8 384 192 384 86.4 297.6 0 192 0zm84.85 242.91a16 16 0 1 1-16-16 16 16 0 0 1 16 16z"/>
      <text x="192" y="280" font-family="Arial" font-size="250" text-anchor="middle" fill="#FFF">${label}</text>
    </svg>`
  )
}

async function drawRoutes(routes?: RouterRoute[]) {
  if (!routes?.length) return
  const waypoints = getIntermediate()
  const numWaypoints = waypoints.length

  const domMarker = new H.map.DomMarker(origin.value.position, {
    icon: createDomIcon(0, "origin")
  })

  const waypointMarkers: H.map.DomMarker[] = waypoints.map((wp, index) => {
    return new H.map.DomMarker(
      {
        lat: wp.position.lat,
        lng: wp.position.lng
      },
      { icon: createDomIcon(index + 1, "waypoint") }
    )
  })

  const endMarker = new H.map.DomMarker(destination.value.position, {
    icon: createDomIcon(numWaypoints + 1, "destination")
  })

  const group = new H.map.Group()

  let boundingBox: H.geo.Rect | null = null

  routes.forEach((route, index) => {
    const routeLineStrings: H.geo.LineString[] = []
    route.sections.forEach((section) => {
      const lineString = H.geo.LineString.fromFlexiblePolyline(section.polyline)
      routeLineStrings.push(lineString)
      boundingBox = boundingBox ? boundingBox.mergeRect(lineString.getBoundingBox()) : lineString.getBoundingBox()
    })

    const routeMultiLineString = new H.geo.MultiLineString(routeLineStrings)

    const routeLine = new H.map.Polyline(routeMultiLineString, {
      style: {
        strokeColor: index === selectedRouteIndex.value ? "#4285F4" : "#808080", // Blue for selected, orange for alternatives
        lineWidth: index === selectedRouteIndex.value ? 6 : 5, // Thicker for selected
        lineTailCap: "round",
        lineHeadCap: "round",
        lineDash: [] // Solid lines for all routes
      }
    })

    // Set z-index to ensure the selected route is on top
    routeLine.setZIndex(index === selectedRouteIndex.value ? 10 : 0)
    routePolylines.value.push(routeLine)

    routeLine.addEventListener(
      "tap",
      () => {
        selectedRouteIndex.value = index
        showFuelStationsForRoute(index)

        routePolylines.value.forEach((polyline, idx) => {
          polyline.setStyle({
            strokeColor: idx === index ? "#4285F4" : "#808080",
            lineWidth: idx === index ? 6 : 5,
            lineDash: [] // Solid lines for all
          })
          polyline.setZIndex(idx === index ? 10 : 0) // Update z-index on tap
        })
      },
      false
    )

    group.addObjects([routeLine, domMarker, endMarker, ...waypointMarkers])
    globalMap.value.addObject(group)
  })

  if (boundingBox) {
    globalMap.value.getViewModel().setLookAtData({ bounds: boundingBox }, true)
  }
}

function generateWaypointId() {
  return Math.random()
}

function selectSuggestion(item: any, id: number) {
  const waypoint = waypoints.value.find((wp) => wp.id === id)
  if (waypoint) {
    waypoint.query = item.title
    waypoint.suggestions = []
    if (item.position) waypoint.position = item.position
  }
}

function removeWaypoint(id: number) {
  waypoints.value = waypoints.value.filter((item: any) => item.id !== id)
}

const debouncedAutosuggestion = debounce((q: string, id: number) => {
  globalPlatform.value
    .getSearchService()
    .autosuggest({ at: "40.7128,74.0060", in: "countryCode:USA", limit: 5, q }, (res) => {
      waypoints.value.forEach((item: any) => {
        if (item.id === id) item.suggestions = res.items
      })
    })
}, 300)

function handleInput(q: string, id: number) {
  debouncedAutosuggestion(q, id)
}

function addWaypoint() {
  waypoints.value.push({
    id: generateWaypointId(),
    query: "",
    suggestions: [],
    placeholder: "Destination",
    position: { lat: 0, lng: 0 }
  })
}

function handleBlur() {
  waypoints.value.forEach((item: any) => (item.suggestions = []))
}

function handleClear() {
  waypoints.value = [
    {
      id: generateWaypointId(),
      query: "",
      suggestions: [],
      placeholder: "Set starting point",
      position: { lat: 0, lng: 0 }
    },
    { id: generateWaypointId(), query: "", suggestions: [], placeholder: "Destination", position: { lat: 0, lng: 0 } }
  ]
  globalSearched.value = true
  removeMapObjectsExceptTruckMarker()
}

watch(
  () => currentTabId.value,
  () => {
    loadingFuel.value = true
    if (props?.loadUpPoints?.originPos && props?.loadUpPoints?.destinationPos && !globalSearched.value) {
      waypoints.value[0].position = props.loadUpPoints.originPos
      waypoints.value[1].position = props.loadUpPoints.destinationPos
      handleGo()
      return
    } else {
      handleGo()
    }
    nextTick(() => {
      loadingFuel.value = false
    })
  }
)

const findLocation = () => {
  globalSearched.value = true
  handleGo()
}

function handleActiveTab(id) {
  currentTabId.value = id

  const markers = fuelStationMarkers()
  if (!markers) return

  if (id === "route") {
    // "Top 10" tab
    // Show only markers in the top 10 of any section
    const topTenIds = new Set(topTenOverall.value.flat().map((station) => station.id))
    markers.forEach((marker) => {
      const station = marker.getData()
      marker.setVisibility(topTenIds.has(station.id))
    })
  } else if (id === "segment") {
    // "Top 10" tab
    // Show only markers in the top 10 of any section
    const topTenIds = new Set(topTenPerSection.value.flat().map((station) => station.id))
    markers.forEach((marker) => {
      const station = marker.getData()
      marker.setVisibility(topTenIds.has(station.id))
    })
  } else if (id === "all") {
    // "All" tab
    // Show all markers
    markers.forEach((marker) => {
      const station = marker.getData()
      marker.setVisibility(station.actual_price)
    })
  }
}

const canAddWaypoint = computed(() => waypoints.value.length < 4)
const removable = computed(() => waypoints.value.length > 2)
const clearable = computed(() => waypoints.value.some((item: any) => item.query.length > 0))
</script>

<template>
  <div>
    <div
      v-if="loadingFuel"
      class="selection-none absolute top-0 z-50 flex h-screen w-full items-center justify-center bg-[rgba(0,0,0,0.6)] text-center text-2xl text-white"
    >
      Loading...
    </div>
    <TopTenStations @active-tab="handleActiveTab" />
    <div class="absolute left-1 top-1 z-20 flex flex-col gap-2 rounded-md bg-white p-4 shadow-lg dark:bg-gray-800">
      <div
        v-if="clearable"
        class="absolute -right-8 top-0 cursor-pointer bg-white p-1 dark:bg-gray-800"
        @click="handleClear"
      >
        <XIcon class="h-5 w-5 text-gray-600 dark:text-gray-300" />
      </div>
      <TransitionGroup
        enter-active-class="transition-all duration-200 ease-out"
        enter-from-class="opacity-0 -translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition-all duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-for="(point, i) in waypoints" :key="point.id" class="flex items-center gap-1">
          <Flag v-if="destination.id === point.id" class="h-4 w-4 text-red-500" />
          <Circle v-else-if="i === 0" class="h-4 w-4 text-success-500" />
          <MapPin v-else class="h-4 w-4" />
          <RoutePlannerAutocomplete
            :id="point.id"
            v-model="point.query"
            :loading="loading"
            :placeholder="point.placeholder"
            :removable="removable"
            :suggestions="point.suggestions"
            @blur="handleBlur"
            @input="handleInput"
            @remove="removeWaypoint"
            @select="selectSuggestion"
          />
        </div>
      </TransitionGroup>
      <div class="flex gap-2">
        <Button
          v-if="canAddWaypoint"
          :loading="loading"
          class="inset-0 w-full bg-[#38761d] p-1 text-white transition active:scale-90"
          rounded
          size="sm"
          @click="addWaypoint"
        >
          + Add destination
        </Button>
        <Button
          :loading="loading"
          class="inset-0 w-full bg-[#38761d] text-white transition active:scale-90"
          rounded
          size="sm"
          @click="findLocation"
        >
          Go
        </Button>
      </div>
    </div>
  </div>
</template>

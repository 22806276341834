<script lang="ts" setup>
import { useSystemSettingsService, useSystemSettingsStore } from "~/management/systemSettings"
import SystemSettingsForm from "~/management/systemSettings/components/SystemSettingsForm.vue"
import { settingType } from "~/management/systemSettings/systemSettings.constrants"

const modal = useModal()
const { getSystemSettingsList, createSystemSetting, getSingleSystemSetting, editSystemSetting } =
  useSystemSettingsService()

const { t } = useI18n()
const { required, maxValue, minValue, requiredIf } = useRule()

const systemSettingsStore = useSystemSettingsStore()

const { form, formLoading, settingTypeComputed } = storeToRefs(systemSettingsStore)

const rules = ref({
  radius: { required, minValue: minValue(0.1), maxValue: maxValue(100) },
  minutes: { required, minValue: minValue(20), maxValue: maxValue(1000) },
  fuel_percent: { required, minValue: minValue(90), maxValue: maxValue(100) },
  speed: { required, minValue: minValue(0), maxValue: maxValue(1000) },
  update_ms: { required, minValue: minValue(0) },
  correction_mpg: { requiredMpg: requiredIf(settingTypeComputed.value === settingType.MPG), minValue: minValue(0) }
})
const settingsEdit = ref<number | null>(null)

const { vuelidate, hasError } = useValidate(form, rules)

const label = computed<string>(() =>
  settingsEdit.value ? t("actions.edit_system_settings") : t("actions.add_system_settings")
)

const handleSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  formLoading.value = true
  try {
    if (settingsEdit.value) {
      const res = await editSystemSetting(form.value, settingTypeComputed.value)
      if (res?.result?.code === "ok") {
        await getSystemSettingsList()
      }
    } else {
      const res = await createSystemSetting(form.value)
      if (res?.result?.code === "ok") {
        await getSystemSettingsList()
      }
    }
    await nextTick(() => {
      onHide()
    })
  } finally {
    formLoading.value = false
  }
}

const onBeforeShow = async (id: number) => {
  if (!id) return
  formLoading.value = true
  settingsEdit.value = id
  getSingleSystemSetting(id).finally(() => {
    formLoading.value = false
  })
}

const onHide = () => {
  settingsEdit.value = null
  Object.assign(form.value, systemSettingsStore.initialForm)
  formLoading.value = false
  modal.hide("system-settings")
  vuelidate.value?.$reset()
}
</script>

<template>
  <Modal
    id="system-settings"
    :close-on-backdrop="false"
    :label
    :loading="formLoading"
    @hide="onHide"
    @before-show="onBeforeShow"
  >
    <SystemSettingsForm :has-error="hasError" @submit="handleSubmit" />

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button :label="$t('actions.cancel')" color="danger" @click="hide" />
        <Button
          :disabled="vuelidate.$error || formLoading"
          :label="$t('actions.submit')"
          :loading="formLoading"
          @click="handleSubmit"
        />
      </div>
    </template>
  </Modal>
</template>

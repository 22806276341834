interface SuggestionDTO {
  id: number | null
  note: string | null
}

interface SuggestionStopDTO {
  id: number | null
  amount: number | null
  is_full: boolean | null
  created_at: string | null
  note: string | null
}

interface TruckDTO {
  id: number | null
  tank: number | null
  fuel_level: number | null
}

interface DriverDTO {
  id: number | null
  phone: string | null
  lastname: string | null
  firstname: string | null
}

interface BookerDTO {
  id: number | null
  lastname: string | null
  firstname: string | null
}

interface CardDTO {
  id: number | null
  card_number: string | null
  status: string | null
  unit_number: string | null
}

interface FuelStationDTO {
  id: number | null
  store_number: string | null
  city: string | null
  state: string | null
  address: string | null
  country: string | null
  longitude: number | null
  latitude: number | null
  interstate: string | null
}

interface FuelPriceDTO {
  id: number | null
  actual_price: number | null
  effective_date: string | null
}

export interface FuelTransaction {
  id: number
  address1: string
  address2: string
  card_number: string
  city: string
  country: string
  driver_name: string
  invoice: string
  latitude: number
  longitude: number
  location_name: string
  state: string
  supplier_code: string
  transaction_date: string
  unit_number: string
  zip: string
  amount: number
  disc_amount: number
  ppu: number
  quantity: number
  is_matched: boolean
  created_at: string
  suggestion_dto: SuggestionDTO
  suggestion_stop_dto: SuggestionStopDTO
  truck_dto: TruckDTO
  driver_dto: DriverDTO
  booker_dto: BookerDTO
  card_dto: CardDTO
  fuel_station_dto: FuelStationDTO
  fuel_price_dto: FuelPriceDTO
}

export interface TransactionLimit {
  disc_amount: number
  limit_id: string
  ppu: number
  quantity: number
  total_amount: number
}

export class TransactionModel {
  id!: number
  is_likely_fraud: boolean = false
  transaction_date: string = ""
  unit_number: string = ""
  driver_name: string = ""
  card_number: string = ""
  quantity: number = 0
  total_amount: number = 0
  disc_amount: number = 0
  state: string = ""
  city: string = ""
  location_name: string = ""
  address: string = ""
  supplier_loc_code: string = ""
  ppu: number = 0
  actual_price: number = 0
  store_number: string = ""
  amount: number = 0
  booker: string = ""
  created: string = ""
  note: string = ""
  line_items: TransactionLimit[] = []

  constructor(data?: TransactionModel | any) {
    if (data) {
      this.id = data.id!
      this.is_likely_fraud = data.is_likely_fraud || false
      this.transaction_date = data.transaction_date || ""
      this.unit_number = data.unit_number || ""
      this.driver_name = data.driver_name || ""
      this.card_number = data.card_number ? formatCardNumber(data.card_number) : ""
      this.quantity = data.quantity || 0
      this.total_amount = data.total_amount || 0
      this.disc_amount = data.disc_amount || 0
      this.location_name = data.location_name || ""
      this.address = data.state && data.city ? `${data.city || ""}, ${data.state}` : ""
      this.supplier_loc_code = data.supplier_loc_code || ""
      this.ppu = data.ppu || 0
      this.actual_price = data.suggestion.actual_price || 0
      this.store_number = data.suggestion.store_number || ""
      this.amount = data.suggestion.is_full ? "Full" : data.suggestion.amount || 0
      this.booker = data.suggestion.booker || ""
      this.created = data.suggestion.created || ""
      this.note = data.suggestion.note || ""
      this.line_items = data.line_items
    }
  }
}

export class ParamsReport {
  start_date: string
  end_date: string
  card_number: string
  retail_price_only: boolean
  show_full_card: boolean
  show_transaction_time: boolean
  dateRange: any

  constructor(data: Partial<ParamsReport> = {}) {
    this.start_date = data.start_date ?? ""
    this.end_date = data.end_date ?? ""
    this.card_number = data.card_number ?? ""
    this.retail_price_only = data.retail_price_only ?? false
    this.show_full_card = data.show_full_card ?? false
    this.show_transaction_time = data.show_transaction_time ?? false
    this.dateRange = data.dateRange ?? []
  }
}

import { ref } from "vue"
import { defineStore } from "pinia"
import { breakpointsTailwind, useSessionStorage } from "@vueuse/core"

export const useLayoutStore = defineStore("layout", () => {
  const sidebarIsShown = ref<boolean>(false)

  const openSidebars = ref<(string | number)[]>([])
  const breakpoints = useBreakpoints(breakpointsTailwind)

  const isDesktop = breakpoints.greater("md")
  const isHover = ref<boolean>(false)
  const isRightSidebarShown = computed(() => openSidebars.value.length > 0)
  const rightSidebarWidth = computed(() => (openSidebars.value.length > 0 && isDesktop ? "300px" : "0"))

  const sidebarIsCollapsed = useSessionStorage("isLeftSidebarCollapsed", false)

  return {
    isHover,
    sidebarIsShown,
    sidebarIsCollapsed,
    rightSidebarWidth,
    openSidebars,
    isRightSidebarShown
  }
})

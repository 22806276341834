<script setup lang="ts">
import { UsersAssignModel, useUsersService, useUsersStore } from "~/management/users"
import { useManagementService, useManagementStore } from "~/management"
import { useRolesService, useRolesStore } from "~/management/roles"
import { COMPANY } from "~/management/management.model"

const modal = useModal()

const { getRolesLazySelect } = useRolesService()
const { setUsers, setUsersEdit, getUsers } = useUsersService()
const usersStore = useUsersStore()
const roleStore = useRolesStore()
const managementStore = useManagementStore()
const { getUsersListTable } = useUsersService()
const { fetchCompaniesList } = useManagementService()

const { t } = useI18n()
const { required, email, username } = useRule()
const { selectList } = storeToRefs(roleStore)
const { companiesList } = storeToRefs(managementStore)
const { form, formLoading, roleDetails } = storeToRefs(usersStore)
const rules = ref({
  nickname: { required },
  role_id: { required },
  email: {
    required,
    email
  },
  company_ids: { required },
  telegram: { username }
})
const { vuelidate, hasError } = useValidate(form, rules)
const users_edit = ref<number | null>(null)

const label = computed<string>(() => (users_edit.value ? t("actions.edit_users") : t("actions.add_users")))

const onSave = async () => {
  const isValid = await vuelidate.value?.$validate()

  if (!isValid) return
  try {
    usersStore.formLoading = true
    if (users_edit.value) {
      await setUsersEdit({
        id: users_edit.value,
        ...form.value
      })
    } else {
      await setUsers(form.value)
    }

    await getUsersListTable()
    onHide()
  } catch (error) {
    usersStore.formLoading = false
  }
}
const onBeforeShow = async (id: number) => {
  if (id) {
    formLoading.value = true
    users_edit.value = id
    await getUsers(id)
    formLoading.value = false
  }
}

const onHide = () => {
  users_edit.value = null
  form.value = new UsersAssignModel()
  formLoading.value = false
  modal.hide("users")
  vuelidate.value?.$reset()
}
</script>

<template>
  <Modal id="users" :label :loading="formLoading" :close-on-backdrop="false" @before-show="onBeforeShow" @hide="onHide">
    <form class="grid gap-4 p-4">
      <FormGroup v-slot="{ id }" v-bind="hasError('nickname')" required :label="$t('labels.nick_name')">
        <Input :id v-model="form.nickname" :placeholder="t('placeholders.enter_nick_name')" />
      </FormGroup>
      <FormGroup :label="$t('labels.first_name')">
        <Input v-model="form.firstname" :placeholder="t('placeholders.enter_first_name')" />
      </FormGroup>
      <FormGroup :label="$t('labels.last_name')">
        <Input v-model="form.lastname" :placeholder="t('placeholders.enter_last_name')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" v-bind="hasError('email')" required :label="$t('labels.email')">
        <Input :id v-model="form.email" type="email" :placeholder="t('placeholders.enter_email')" />
      </FormGroup>
      <FormGroup :label="$t('labels.phone')">
        <Input v-model="form.phone" :placeholder="t('placeholders.enter_phone')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" v-bind="hasError('telegram')" :label="$t('labels.telegram')">
        <Input :id v-model="form.telegram" :placeholder="t('placeholders.enter_username')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" v-bind="hasError('role_id')" required :label="$t('labels.role')">
        <LazySelect
          v-if="form"
          :id
          v-model="form.role_id"
          :content="{ id: roleDetails?.id, name: roleDetails?.name }"
          :contenteditable="!!users_edit"
          :options="selectList"
          content-refreshable
          value-prop="id"
          :placeholder="t('placeholders.enter_role')"
          :fetch="getRolesLazySelect"
          :get-option-label="(item: any) => item.name || ''"
        />
      </FormGroup>

      <FormGroup v-slot="{ id }" v-bind="hasError('company_ids')" required :label="$t('labels.company')">
        <LazySelect
          :id
          v-model="form.company_ids"
          mode="tags"
          content-refreshable
          :hide-selected="false"
          :close-on-select="false"
          value-prop="id"
          :multiple="form.companies"
          multiple-content
          autocomplete="true"
          :options="companiesList"
          :placeholder="t('placeholders.enter_company')"
          :fetch="fetchCompaniesList"
          :get-option-label="(item: COMPANY) => item.name"
        >
        </LazySelect>
      </FormGroup>
    </form>

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button color="danger" :label="$t('actions.cancel')" @click="hide" />
        <Button
          :label="$t('actions.submit')"
          :disabled="vuelidate.$error || formLoading"
          :loading="formLoading"
          @click="onSave"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ButtonHTMLAttributes, computed } from "vue"
import { Icon } from "@iconify/vue"

export interface IProps extends /* @vue-ignore */ ButtonHTMLAttributes {
  variant?: "solid" | "ghost" | "flat" | "white"
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  color?: "primary" | "secondary" | "success" | "warning" | "danger" | "info"
  rounded?: boolean
  squared?: boolean
  loading?: boolean
  disabled?: boolean
  label?: string
  href?: string
  icon?: string
  width?: string
  height?: string
  to?: string | Record<string, any>
}

const props = withDefaults(defineProps<IProps>(), {
  size: "md",
  color: "primary",
  variant: "solid",
  width: "24",
  squared: true
})

const buttonClass = computed(() => ({
  "icon-button": true,
  "button-rounded": props.rounded,
  "button-squared": props.squared,
  "button-disabled": props.disabled || props.loading,
  ["icon-button-" + props.size]: true,
  ["button-" + props.color]: true,
  ["button-" + props.variant]: true
}))

const buttonAttrs = computed(() => {
  if (props.to) return { is: "router-link", to: props.to }
  else if (props.href) return { is: "a", href: props.href }
  else return { is: "button" }
})
</script>

<template>
  <component
    v-bind="{ ...$attrs, ...buttonAttrs }"
    :is="buttonAttrs.is"
    v-tooltip.bottom="label"
    :class="buttonClass"
    :disabled="disabled || loading"
  >
    <slot v-if="loading" name="loading">
      <Icon icon="lucide:loader" class="w-4 animate-spin" />
    </slot>

    <slot v-else>
      <Icon v-if="icon" :icon="icon" :width="width" :height="height" />
    </slot>
  </component>
</template>

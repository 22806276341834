<script setup lang="ts">
import { useRouteProtection } from "$/composables/useRouteProtection"
import RolesList from "~/management/roles/components/RolesList.vue"
import RolesModal from "~/management/roles/components/RolesModal.vue"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_ROLES_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <RolesList v-if="profile.authorities.has(RolePermissionsModel.CAN_ROLE_PAGE_GET)" />
    <RolesModal />
  </div>
</template>

<script setup lang="ts">
import { computed, InputHTMLAttributes, InputTypeHTMLAttribute } from "vue"

interface IProps extends /* @vue-ignore */ InputHTMLAttributes {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl"
  color?: "primary" | "secondary" | "success" | "warning" | "danger"
  pilled?: boolean
  squared?: boolean
  disabled?: boolean
  modelValue?: string | number
  type?: InputTypeHTMLAttribute
}

const props = withDefaults(defineProps<IProps>(), {
  size: "md",
  type: "text",
  color: "primary",
  squared: true
})

const model = defineModel<any>()

const inputClass = computed(() => ({
  input: true,
  "input-pilled": props.pilled,
  "input-squared": props.squared,
  "input-disabled": props.disabled,
  ["input-" + props.size]: true,
  ["input-" + props.color]: true
}))
</script>

<template>
  <input v-bind="$attrs" v-model="model" :class="inputClass" :type="type" />
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"
import { useSessionService } from "~/session"
import { Icon, Button, ButtonGroup } from "$/ui"

const route = useRoute()
const { t } = useI18n()

const { confirmSignOut } = useSessionService()

const navs = computed(() => [
  {
    label: t("labels.companies"),
    to: { name: "companies" },
    icon: "lucide:building-2"
  },
  {
    label: t("labels.users"),
    to: { name: "users" },
    icon: "lucide:users"
  }
])

const activeClass = (to: Record<string, string>) => (route.name === to.name ? "primary" : "secondary")
</script>

<template>
  <div class="primary-nav">
    <div class="max-w-screen-2xl mx-auto">
      <div class="flex flex-col gap-4 p-4 sm:items-center sm:flex-row">
        <ButtonGroup>
          <Button
            v-for="nav in navs"
            :key="nav.label"
            variant="white"
            class="w-full sm:w-auto"
            :color="activeClass(nav.to)"
            :to="nav.to"
          >
            <Icon :icon="nav.icon" :width="20" />
            {{ nav.label }}
          </Button>
        </ButtonGroup>

        <Button class="sm:ml-auto" variant="white" color="danger" @click="confirmSignOut">
          <Icon icon="lucide:log-out" :width="20" />
          {{ $t("actions.logout") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="css">
.primary-nav {
  @apply border-b bg-gray-50 dark:bg-gray-900 dark:border-gray-800;
}
</style>

<script setup lang="ts">
import { Modal } from "$/ui"

import { capitalizeFirstLetter } from "$/utils/string"
import { HistoryEntry, useCardApi } from "~/management/card"

const cardApi = useCardApi()

const isLoading = ref<boolean>(false)
const card_number = ref<string>("")
const card_id = ref<number | null>(null)

const historyEntries = ref<HistoryEntry[]>([])
const query = ref<PaginationParams>({
  keyword: "",
  page: 0,
  size: 20
})
const pagination = ref<any>({
  totalPages: 0,
  totalElements: 0
})

const formatColumnName = (columnName: string): string => {
  const mappings: Record<string, string> = {
    defLimit: "DEFD Limit",
    dslLimit: "DSL Limit from",
    ulsdLimit: "ULSD Limit",
    number: "Card number"
  }

  return mappings[columnName] || columnName.replace(/_/g, " ")
}

const formattedEntries = computed(() => {
  return historyEntries.value.map((entry) => ({
    editedBy: entry.edited_by,
    changes: sanitizeHtml(
      `${capitalizeFirstLetter(formatColumnName(entry.column_name))} changed from <strong>${entry?.old_value || ""}</strong> to <strong>${entry?.new_value || ""}</strong>`
    ),
    date: entry.time ? toZone(entry.time, "MM/DD/YYYY CT") : "",
    time: entry.time ? toZone(entry.time, "HH:mm CT") : ""
  }))
})

const fetchCardHistory = (id: number) => {
  isLoading.value = true
  cardApi
    .GET_CARD_HISTORY(id, query.value)
    .then(({ content, totalElements }) => {
      historyEntries.value = content
      pagination.value.totalElements = totalElements ?? 0
    })
    .finally(() => (isLoading.value = false))
}

const onBeforeShow = ({ id, number }: { id: number; number: string }) => {
  card_number.value = number
  card_id.value = id
  fetchCardHistory(id)
}

const onBeforeHide = () => {
  historyEntries.value = []
  isLoading.value = false
  card_number.value = ""
  card_id.value = null
  query.value.page = 0
}

// NOTE: check if TableFooter works for this history modal. If not, uncomment these lines instead
// const changePage = (newPage: number) => {
//   query.value.page = newPage
// }
//
// watch(
//   () => query.value.page,
//   () => fetchCardHistory(card_id.value!)
// )
//
// const pageRange = computed(() => {
//   const range = []
//   const start = Math.max(0, query.value.page! - 2)
//   const end = Math.min(pagination.value.totalPages - 1, query.value.page! + 2)
//   for (let i = start; i <= end; i++) {
//     range.push(i)
//   }
//   return range
// })
//
// const displayPageNumber = (pageIndex: number) => pageIndex + 1
</script>

<template>
  <Modal
    id="card-history"
    :label="`${$t('labels.card_history')}: ${card_number}`"
    :loading="isLoading"
    size="xl"
    @before-show="onBeforeShow"
    @before-hide="onBeforeHide"
  >
    <table v-if="!!historyEntries.length" class="w-full text-xs">
      <thead>
        <tr>
          <th class="py-2 text-left">{{ $t("thead.no") }}</th>
          <th class="py-2 text-left">{{ $t("thead.edited_by") }}</th>
          <th class="py-2 text-left">{{ $t("thead.changes") }}</th>
          <th class="py-2 text-left">{{ $t("thead.date") }}</th>
          <th class="py-2 text-left">{{ $t("thead.time") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in formattedEntries" :key="index">
          <td class="p-2">
            {{ index + 1 }}
          </td>
          <td class="p-2">
            {{ entry.editedBy }}
          </td>
          <td class="p-2" v-html="entry.changes" />
          <td class="p-2">
            {{ entry.date }}
          </td>
          <td class="p-2">
            {{ entry.time }}
          </td>
        </tr>
      </tbody>
    </table>

    <p v-else class="p-4 text-center text-lg text-gray-500">{{ $t("common.no_data") }}</p>

    <TableFooter
      v-model:page="query.page!"
      v-model:per-page="query.size!"
      :total="pagination.totalElements"
      @change="() => fetchCardHistory(card_id!)"
    />

    <!-- NOTE: check if TableFooter works for this history modal. If not, uncomment these lines instead   -->
    <!--    <div v-if="pagination.totalPages > 1" class="pagination mt-4 flex justify-center items-center space-x-2">-->
    <!--      <button-->
    <!--        :disabled="query.page === 0"-->
    <!--        class="px-3 py-1 border rounded"-->
    <!--        :class="{ 'opacity-50 cursor-not-allowed': query.page === 0 }"-->
    <!--        @click="changePage(query.page! - 1)"-->
    <!--      >-->
    <!--        Previous-->
    <!--      </button>-->
    <!--      <button-->
    <!--        v-for="page in pageRange"-->
    <!--        :key="page"-->
    <!--        class="px-3 py-1 border rounded"-->
    <!--        :class="{ 'bg-blue-500 text-white': page === query.page }"-->
    <!--        @click="changePage(page)"-->
    <!--      >-->
    <!--        {{ displayPageNumber(page) }}-->
    <!--      </button>-->
    <!--      <button-->
    <!--        :disabled="query.page === pagination.totalPages - 1"-->
    <!--        class="px-3 py-1 border rounded"-->
    <!--        :class="{ 'opacity-50 cursor-not-allowed': query.page === pagination.totalPages - 1 }"-->
    <!--        @click="changePage(query.page! + 1)"-->
    <!--      >-->
    <!--        Next-->
    <!--      </button>-->
    <!--    </div>-->
  </Modal>
</template>

<style scoped>
table {
  border-collapse: collapse;
}

th {
  border-bottom: 1px solid #e2e8f0;
}
</style>

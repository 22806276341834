<script setup lang="ts">
import Select from "@vueform/multiselect"
import { useLayoutStore } from "~/layout"

interface Props {
  fetch: (params: any) => Promise<void>
  options: IResponse<any>
  getOptionLabel?: (option: any) => void
  getTooltipName?: (option: any) => void
  fetchOnOpen?: boolean
  content?: any
  contenteditable?: boolean
  contentRefreshable?: boolean
  tooltip?: boolean
  multiple?: any
  multipleContent?: boolean
  characters?: string
  valueId?: any
}

const props = withDefaults(defineProps<Props>(), {
  getOptionLabel: () => {},
  getTooltipName: () => {},
  fetchOnOpen: true,
  contenteditable: false,
  contentRefreshable: false,
  multipleContent: false,
  tooltip: false
})

const store = useLayoutStore()

const loading = ref<boolean>(false)
const newValue = ref<any>()
const oldValue = ref<number | null>(null)
const localeOptions = ref<any>([])
const newArray = ref<any>([])
const keyword = ref<Nullable<string>>(null)
const el = ref<HTMLElement | null>(null)
const nextPage = ref(0)
const size = ref(20)
const selected = ref<boolean>(false)
const selectedItems = ref<any>([])
const clearValue = ref<boolean>(false)
const contentEditable = ref<boolean>(false)
const multiselectRef = ref(null)

const selectedItem = (val: string) => {
  contentEditable.value = false
  selected.value = !!val
}

const onSearch = useDebounceFn(async (val) => {
  keyword.value = val.trim()
  nextPage.value = 0
  await props.fetch({ size: size.value, page: nextPage.value, keyword: keyword.value })
  await observe()
  localeOptions.value = props.options.content
  loading.value = false
}, 600)

const searchItem = (val: any) => {
  if (val) {
    selected.value = false
  }
  if (selected.value && loading.value) return
  loading.value = true
  onSearch(val)
}

const getInfiniteOptions = async () => {
  if (loading.value) return
  if (nextPage.value === props.options.totalPages || nextPage.value > props.options.totalPages) return disconnect()
  loading.value = true
  await props.fetch({ size: size.value, page: nextPage.value, keyword: keyword.value, ...props.valueId })
  await observe()
  const data = props.options.content || []
  localeOptions.value.push(...data)
  nextPage.value++
  loading.value = false
}

watchEffect(() => {
  contentEditable.value = props.contenteditable
})

watch(
  () => props.content,
  () => {
    if (oldValue.value !== props.content.id) {
      newValue.value = props.content
    }
  },
  { deep: true }
)

watchEffect(() => {
  loading.value = true
  newArray.value = localeOptions.value.map((item: any) => ({
    ...item,
    tooltip: props.getTooltipName(item),
    label: props.getOptionLabel(item)
  }))

  if (props.contentRefreshable) {
    loading.value = false
  }

  if (props.contenteditable && !clearValue.value) {
    oldValue.value = props.content.id
    newArray.value.unshift({ label: props.content?.name || "", id: props.content?.id || null })
    loading.value = false
  } else if (newValue.value && newValue.value.id) {
    newArray.value.unshift({ label: newValue.value?.name || "", id: newValue.value?.id || null })
  }
  if (props.multipleContent && props.multiple?.length && !clearValue.value) {
    let transformedData = props.multiple.map((item: any) => ({
      label: item.name,
      id: item.id
    }))
    newArray.value.unshift(...transformedData)
  }
  newArray.value = Array.from(new Map(newArray.value.map((item: any) => [item.id, item])).values())

  nextTick(() => {
    loading.value = false
  })
})

const { observe, disconnect } = useInfinite(el, getInfiniteOptions)
const lengthValues = (val: any) => {
  selectedItems.value = val
  return val.length
}

const clearData = () => {
  clearValue.value = true
  keyword.value = ""
  if (props.tooltip) {
    selectedItems.value = []
  }
}

onMounted(() => {
  loading.value = false
  getInfiniteOptions()
})

watch(
  () => store.isHover,
  () => {
    if (multiselectRef.value) {
      multiselectRef.value?.blur()
    }
  }
)
</script>

<template>
  <Select
    v-bind="$attrs"
    ref="multiselectRef"
    v-tooltip.left-start="selectedItems.map((item: any) => item.tooltip || item.label).join('\n')"
    :options="newArray"
    :loading="loading"
    :class="[multipleContent ? 'custom-multiselect min-h-[70px]' : '']"
    class="min-w-36 !rounded-none"
    searchable
    value-prop="id"
    autocomplete="true"
    append-to-body
    @input="selectedItem"
    @clear="clearData"
    @search-change="searchItem"
  >
    <template #afterlist>
      <span ref="el" class="pt-1" />
    </template>
    <template #option="{ option }">
      <slot name="option" :option="option" />
    </template>
    <template v-if="props.tooltip" #multiplelabel="{ values }: any">
      <div class="multiselect-multiple-label">
        {{ lengthValues(values) }} {{ props.characters || "characters" }} selected
      </div>
    </template>
  </Select>
</template>

<style>
.custom-multiselect .multiselect-wrapper {
  overflow: scroll;
}

.custom-multiselect .multiselect-tag-wrapper {
  max-width: 300px;
  white-space: break-spaces;
  word-break: break-word;
}

.custom-multiselect .multiselect-tags {
  height: 30px;
}
</style>

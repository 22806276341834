import type { AxiosInstance } from "axios"
import { UsersModel, UsersAssignModel, UsersCompanyDetails } from "~/management/users/users.model"

export const useUsersApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1"

  return {
    GET_USERS_COUNT() {
      return axios.$get(`${base}/users/count`)
    },
    GET_USER_ID(id: number): ResponseContainer<UsersAssignModel[]> {
      return axios.$get(`${base}/users/${id}`)
    },
    GET_USER_DETAILS_ID(id: number): ResponseContainer<UsersCompanyDetails[]> {
      return axios.$get(`${base}/users/details/${id}`)
    },
    PATCH_USER_ID(params: any): Promise<ResponseType> {
      return axios.$put(`${base}/users`, params)
    },
    GET_USERS(params: Record<string, any>): ResponseContainer<UsersModel[]> {
      return axios.$get(`${base}/users`, { params })
    },
    POST_USER(users: any): Promise<ResponseType> {
      return axios.$post(`${base}/users`, users)
    },
    USER_ACTIVATE(id: number): Promise<ResponseType> {
      return axios.$patch(`${base}/users/activate/${id}`)
    },
    USER_DEACTIVATE(id: number): Promise<ResponseType> {
      return axios.$patch(`${base}/users/deactivate/${id}`)
    }
  }
}

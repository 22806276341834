<script setup lang="ts">
import { useLayoutStore } from "~/layout"

const layoutStore = useLayoutStore()

const leftMargin = computed(() => (layoutStore.sidebarIsCollapsed ? "lg:ml-[72px]" : "lg:ml-[280px]"))

const mainContentStyle = computed(() => ({
  paddingRight: layoutStore.rightSidebarWidth,
  transition: "padding-right 0.3s ease-in-out"
}))
</script>

<template>
  <div class="ease-in-out duration-300" :class="leftMargin">
    <div class="app-content">
      <slot name="header" />

      <main :style="mainContentStyle">
        <slot />
      </main>

      <slot name="footer" />
    </div>
  </div>
</template>

import {
  CardAssignModel,
  CardEditModel,
  CardStatus,
  EditCardUsedPayload,
  HistoryPayload,
  useCardApi,
  useCardStore
} from "~/management/card"

export const useCardService = () => {
  const {
    GET_CARDS,
    GET_CARD,
    GET_CARD_STATISTICS,
    CARD_ACTIVATE,
    CARD_DEACTIVATE,
    ASSIGN_CARD,
    EDIT_ASSIGN_CARD,
    POST_CARD_HISTORY,
    GET_CARD_RELOAD,
    EDIT_CARD_USED
  } = useCardApi()
  const { confirmActivateDeactivate, confirmInUsedNotInUsed } = useAlert()

  const cardStore = useCardStore()
  const { formLoading, selectList, companyIds, list, cardStatistics, listLoading, query, formEdit } =
    storeToRefs(cardStore)

  const fetchCard = (id: number) => {
    formLoading.value = true
    GET_CARD(id)
      .then(({ content }) => {
        formEdit.value = new CardEditModel(content)
        if (content?.limits?.length) {
          formEdit.value.limits = content.limits
        }
      })
      .finally(() => (formLoading.value = false))
  }
  const fetchCardStatistics = () => {
    GET_CARD_STATISTICS().then(({ content }) => {
      cardStatistics.value = content
    })
  }

  const fetchCardsList = () => {
    listLoading.value = true
    GET_CARDS(cardStore.transformQuery(), <CardStatus>query.value.status, companyIds.value)
      .then(({ content, totalPages, totalElements }) => {
        query.value.total = totalPages as number
        if (list.value && list.value.length && totalElements > 100 && query.value.page !== 0) {
          list.value = [...list.value, ...content]
          return
        } else {
          list.value = [...content]
        }
      })
      .finally(() => (listLoading.value = false))
  }
  const fetchLazyCard = async (params: PaginationParams) => {
    await GET_CARDS({
      page: params.page,
      size: params.size,
      keyword: params.keyword
    }).then((res: any) => {
      selectList.value = res
    })
  }
  const debouncedSearch = useDebounceFn(() => {
    query.value.page = 0
    query.value.keyword = query.value.keyword.trim()
    fetchCardsList()
  }, 600)

  const activateCard = async (id: number, number: string) => {
    await confirmActivateDeactivate(true).then(async () => {
      try {
        listLoading.value = true
        await CARD_ACTIVATE(id, number)
        fetchCardsList()
      } finally {
        listLoading.value = false
      }
    })
  }
  const deactivateCard = async (id: number, number: string) => {
    await confirmActivateDeactivate(false).then(async () => {
      try {
        listLoading.value = true
        await CARD_DEACTIVATE(id, number)
        fetchCardsList()
      } finally {
        listLoading.value = false
      }
    })
  }

  const switchToInUse = async (id: number) => {
    await confirmInUsedNotInUsed(false).then(() => {
      listLoading.value = true
      editCardUsed(id, { is_used: false })
        .then(() => fetchCardsList())
        .finally(() => (listLoading.value = false))
    })
  }

  const switchToNotInUse = async (id: number) => {
    await confirmInUsedNotInUsed(true).then(() => {
      listLoading.value = true
      editCardUsed(id, { is_used: true })
        .then(() => fetchCardsList())
        .finally(() => (listLoading.value = false))
    })
  }

  const assignCard = async (id: number, payload: CardAssignModel) => {
    await ASSIGN_CARD(id, {
      ...payload,
      number: payload.number.number,
      driver_name: payload.unit_number.driver_dto.name,
      unit_number: payload.unit_number.truck_id
    }).then(() => fetchCardsList())
  }

  const editAssignCard = async (id: number, payload: CardEditModel) => {
    await EDIT_ASSIGN_CARD(id, payload).then(() => fetchCardsList())
  }

  const addCardHistory = async (id: number, payload: HistoryPayload[]) => {
    await POST_CARD_HISTORY(id, payload)
  }

  const fetchCardReload = async (id: number) => {
    listLoading.value = true
    const { content } = await GET_CARD_RELOAD(id).finally(() => (listLoading.value = false))

    formEdit.value = new CardEditModel(content)
    if (content?.limits?.length) {
      formEdit.value.limits = content.limits.map((item: any) => ({
        ...item, // Preserve other properties
        value: item.dailyMax // Assign the value
      }))
    }
  }

  const editCardUsed = async (id: number, payload: EditCardUsedPayload) => {
    listLoading.value = true
    await EDIT_CARD_USED(id, payload).finally(() => (listLoading.value = false))
  }

  return {
    fetchLazyCard,
    fetchCardStatistics,
    fetchCardsList,
    fetchCard,
    debouncedSearch,
    activateCard,
    deactivateCard,
    assignCard,
    editAssignCard,
    addCardHistory,
    fetchCardReload,
    switchToInUse,
    switchToNotInUse,
    editCardUsed
  }
}

import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { BookersModel, BookingParams } from "~/management/bookers"

export const useBookersStore = defineStore("bookers", () => {
  const list = ref<BookersModel[]>([])
  const listLoading = ref<boolean>(false)
  const bookingsCount = ref<any>({
    content: 0
  })
  const companyIds = ref<number[]>([])
  const companyStatsIds = ref<number[]>([])
  const filterStats = ref<{ id: number; name: string }>()
  const query = ref<BookingParams>({
    page: 0,
    size: 20,
    driverId: null,
    truckId: null,
    bookerId: null,
    inputDate: ""
  })
  const paginationTotal = ref<number>(0)

  const transformQuery = () => {
    return pickBy<unknown>(query.value, identity)
  }

  return {
    filterStats,
    companyStatsIds,
    companyIds,
    bookingsCount,
    list,
    listLoading,
    query,
    paginationTotal,
    transformQuery
  }
})

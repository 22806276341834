<script lang="ts" setup>
import Select from "@vueform/multiselect"

import { ButtonIcon, Icon } from "$/ui"
import { getGoogleMapsUrl } from "$/utils/location"
import { StoresListTable, usePriceStoresService, usePriceStoresStore } from "~/management/priceStores"
import { useManagementService, useManagementStore } from "~/management"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const { t } = useI18n()
const modal = useModal()

const { fetchStoresList } = useManagementService()
const { getStoresListTable, getStoreStatistics, setActivateStore, setDeactivateStore } = usePriceStoresService()
const store = usePriceStoresStore()
const managementStore = useManagementStore()
const sessionStore = useSessionStore()

const { profile } = storeToRefs(sessionStore)
const { storeList } = storeToRefs(managementStore)
const { list, listLoading, queryStores, storeStatistics } = storeToRefs(store)

const hideRow = async (id: number) => {
  await setDeactivateStore(id)
  await getStoresListTable()
}
const showRow = async (id: number) => {
  await setActivateStore(id)
  await getStoresListTable()
}

const getFilteredStoresListTable = async () => {
  queryStores.value.page = 0
  await getStoresListTable()
}

const cols = computed<ITableCol<StoresListTable>[]>(() => {
  const baseCols = [
    {
      name: "idx",
      label: t("thead.no"),
      width: "40px",
      labelClass: "ml-2",
      dataClass: "text-center"
    },
    {
      name: "store_number",
      width: "100px",
      label: t("thead.store")
    },
    {
      name: "name",
      label: t("thead.name")
    },
    {
      name: "country",
      label: t("thead.country")
    },
    {
      name: "latitude",
      label: t("thead.city")
    },
    {
      name: "state",
      label: t("thead.state")
    },

    {
      name: "address",
      label: t("thead.address")
    },
    {
      name: "interstate",
      label: t("thead.interstate")
    },
    {
      name: "state",
      label: t("thead.st")
    }
  ]
  if (
    profile.value.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_ACTIVATE) ||
    profile.value.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_DEACTIVATE)
  ) {
    baseCols.push({
      name: "actions",
      label: t("thead.actions")
    })
  }
  return baseCols
})

const refresh = () => {
  getStoresListTable()
  getStoreStatistics()
}
onMounted(() => {
  getStoresListTable()
})
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="w-1/8 flex items-center gap-3">
        <LazySelect
          v-model="queryStores.fuel_station_id"
          :fetch="fetchStoresList"
          :get-option-label="(item: any) => item.store_number || ''"
          :options="storeList"
          :placeholder="t('placeholders.store')"
          content-refreshable
          value-prop="id"
          @update:model-value="getFilteredStoresListTable"
        />
        <Select
          v-model="queryStores.state"
          :options="states"
          :placeholder="$t('placeholders.state')"
          class="min-w-36 !rounded-none"
          content-refreshable
          label="label"
          searchable
          value-prop="abbreviation"
          @update:model-value="getFilteredStoresListTable"
        />
      </div>
    </template>
    <div class="flex items-center justify-end gap-5">
      <HeaderAmountItem :amount="storeStatistics.totalStores" :description="$t('labels.total_stores')" />
      <HeaderAmountItem :amount="storeStatistics.activeStores" :description="$t('labels.active_stores')" />
      <HeaderAmountItem :amount="storeStatistics.inactiveStores" :description="$t('labels.inactive_stores')" />
      <Button
        v-if="profile.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_CREATE)"
        @click="modal.show('stores')"
      >
        {{ $t("actions.new_store") }}
      </Button>
      <Button
        v-if="profile.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_CREATE)"
        @click="modal.show('company_logo')"
      >
        Set Store Logo
      </Button>
      <ButtonIcon :loading="listLoading" color="success" variant="ghost" @click="refresh">
        <Icon :width="20" icon="lucide:refresh-ccw" />
      </ButtonIcon>
    </div>
  </TableHeader>
  <Table
    :cols="cols"
    :hover="false"
    :loading="listLoading"
    :rows="list"
    :skeleton="listLoading"
    class="mt-4"
    head-class="bg-slate-200 dark:bg-gray-800"
    no-wrap
    store
  >
    <template #idx="{ idx, sequence }">
      {{ sequence(idx, queryStores.page || 0, queryStores.size || 10) }}
    </template>

    <template #latitude="{ row }">
      <div class="flex items-center gap-1">
        <p class="w-[100px] !whitespace-pre !text-wrap">
          {{ row.city }}
        </p>
        <ButtonCopy
          v-if="row.latitude && row.longitude"
          :disabled="!row.active"
          :text-to-copy="Object.values({ latitude: row.latitude, longitude: row.longitude }).join(', ')"
        />
        <ButtonIcon
          v-if="row.latitude && row.longitude"
          :disabled="!row.active"
          :href="getGoogleMapsUrl({ latitude: row.latitude, longitude: row.longitude })"
          color="info"
          icon="lucide:external-link"
          size="xs"
          target="_blank"
          variant="ghost"
          width="18"
        />
      </div>
    </template>
    <template #actions="{ row }">
      <div class="flex items-center gap-1">
        <ButtonIcon
          v-if="row.active && profile.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_ACTIVATE)"
          class="!z-10"
          color="danger"
          icon="lucide:eye-off"
          size="xs"
          variant="ghost"
          width="20"
          @click="hideRow(row.id)"
        />
        <ButtonIcon
          v-else-if="profile.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_DEACTIVATE)"
          color="success"
          icon="lucide:eye"
          size="xs"
          variant="ghost"
          width="20"
          @click="showRow(row.id)"
        />
        <ButtonIcon
          v-if="profile.authorities.has(RolePermissionsModel.CAN_FUEL_STATION_EDIT)"
          :disabled="!row.active"
          :label="$t('actions.edit')"
          color="secondary"
          pilled
          size="sm"
          variant="ghost"
          @click="modal.show('stores', row.id)"
        >
          <Icon :width="18" icon="lucide:pencil" />
        </ButtonIcon>
      </div>
    </template>
  </Table>

  <TableFooter
    v-model:page="queryStores.page"
    v-model:per-page="queryStores.size"
    :total="queryStores.total"
    @change="getStoresListTable"
  />
</template>

<script setup lang="ts">
import Select from "@vueform/multiselect"

import { useFuelStore } from "~/management/fuel"
import { useCardService } from "~/management/card"
import { useManagementStore, useManagementService } from "~/management"

const { deactivateCard, activateCard } = useCardService()
const { fetchCardList } = useManagementService()
const storeFuel = useFuelStore()
const managementStore = useManagementStore()

const { oldVal, card_actions } = storeToRefs(storeFuel)
const { cardList } = storeToRefs(managementStore)
const { required } = useRule()
const sidebar = useSidebar()

const { t } = useI18n()
const isLoading = ref<boolean>(false)
const form = ref<{ action: string; card: { id: Nullable<number>; number: string } | null }>({
  action: "deactivate",
  card: null
})
const rules = computed(() => ({
  action: { required },
  card: { required }
}))

const { vuelidate, hasError } = useValidate(form, rules)

const onSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (isValid) {
    isLoading.value = true
    try {
      if (form.value.action === "deactivate") {
        await deactivateCard(form.value.card!.id!, form.value.card!.number)
      } else await activateCard(form.value.card!.id!, form.value.card!.number)
    } finally {
      isLoading.value = false
    }

    sidebar.hide("deactivation")
  }
}

const onHide = () => {
  form.value = { action: "deactivate", card: null }
  vuelidate.value?.$reset()
  isLoading.value = false
  oldVal.value = ""
}
</script>

<template>
  <Sidebar id="deactivation" :label="$t('actions.deactivate')" color="warning" @hide="onHide">
    <form class="space-y-2.5 py-2.5" @submit.prevent="onSubmit">
      <FormGroup v-slot="{ id }" v-bind="hasError('card')" required>
        <LazySelect
          :id="id"
          v-model="form.card"
          object
          content-refreshable
          value-prop="id"
          :options="cardList"
          :placeholder="t('placeholders.select_card')"
          :fetch="fetchCardList"
          :get-option-label="(item: any) => item.number || ''"
        />
      </FormGroup>
      <div v-if="form.card && form.card.status" class="flex flex-nowrap items-center justify-start gap-2 text-xs">
        <p class="text-black dark:text-gray-300">{{ $t("Status card") }}:</p>
        <span class="capitalize text-primary-700">{{ form.card.status === "INACTIVE" ? "Inactive" : "Active" }}</span>
      </div>

      <div class="box">
        <FormGroup v-slot="{ id }" v-bind="hasError('action')" required :label="$t('labels.action')">
          <Select
            :id
            v-model="form.action"
            :options="card_actions"
            class="!rounded-none"
            :placeholder="$t('placeholders.select_action')"
          />
        </FormGroup>
      </div>

      <div class="flex justify-end">
        <Button
          type="submit"
          :loading="isLoading"
          :label="$t('actions.submit')"
          :disabled="vuelidate.$error || isLoading"
        />
      </div>
    </form>
  </Sidebar>
</template>

<style scoped>
.box {
  @apply mt-2 flex flex-col gap-2 rounded-sm border border-gray-200 p-2 dark:border-gray-800;
}
</style>

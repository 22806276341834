<script setup lang="ts">
import { BookersPage } from "~/management/bookers"
import { RolePermissionsModel } from "~/management/roles"
import { useRouteProtection } from "$/composables/useRouteProtection"

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_BOOKERS_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <BookersPage />
  </div>
</template>

import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { SystemSettingsPreview, EfsAssignModel } from "~/management/systemSettings/systemSettings.model"
import { settingType } from "~/management/systemSettings/systemSettings.constrants"

export const useSystemSettingsStore = defineStore("system-settings", () => {
  const list = ref<any>([])
  const route = useRoute()
  const listLoading = ref(false)
  const formLoading = ref(false)
  const initialForm = {
    radius: 0.1,
    minutes: 20,
    fuel_percent: 90,
    speed: 0,
    update_ms: 0,
    correction_mpg: 0
  }

  const settingTypeComputed = computed(() =>
    route.name === "mobile-settings"
      ? settingType.MOBILE
      : route.name === "mpg-settings"
        ? settingType.MPG
        : settingType.EFS
  )
  const form = reactive<SystemSettingsPreview>({ ...initialForm })
  const formEfs = ref<EfsAssignModel>(new EfsAssignModel())
  const query = ref<Omit<Query, "keyword">>({
    page: 0,
    size: 10
  })

  const pagination = ref<any>({
    totalPages: 0,
    totalElements: 0
  })

  const transformQuery = () => {
    return pickBy<unknown>(query.value, identity)
  }

  return {
    list,
    formEfs,
    listLoading,
    query,
    pagination,
    form,
    formLoading,
    transformQuery,
    initialForm,
    settingTypeComputed
  }
})

export const states = [
  { label: "Alabama", id: "alabama", abbreviation: "AL" },
  { label: "Alaska", id: "alaska", abbreviation: "AK" },
  { label: "Arizona", id: "arizona", abbreviation: "AZ" },
  { label: "Arkansas", id: "arkansas", abbreviation: "AR" },
  { label: "California", id: "california", abbreviation: "CA" },
  { label: "Colorado", id: "colorado", abbreviation: "CO" },
  { label: "Connecticut", id: "connecticut", abbreviation: "CT" },
  { label: "Delaware", id: "delaware", abbreviation: "DE" },
  { label: "Florida", id: "florida", abbreviation: "FL" },
  { label: "Georgia", id: "georgia", abbreviation: "GA" },
  { label: "Hawaii", id: "hawaii", abbreviation: "HI" },
  { label: "Idaho", id: "idaho", abbreviation: "ID" },
  { label: "Illinois", id: "illinois", abbreviation: "IL" },
  { label: "Indiana", id: "indiana", abbreviation: "IN" },
  { label: "Iowa", id: "iowa", abbreviation: "IA" },
  { label: "Kansas", id: "kansas", abbreviation: "KS" },
  { label: "Kentucky", id: "kentucky", abbreviation: "KY" },
  { label: "Louisiana", id: "louisiana", abbreviation: "LA" },
  { label: "Maine", id: "maine", abbreviation: "ME" },
  { label: "Maryland", id: "maryland", abbreviation: "MD" },
  { label: "Massachusetts", id: "massachusetts", abbreviation: "MA" },
  { label: "Michigan", id: "michigan", abbreviation: "MI" },
  { label: "Minnesota", id: "minnesota", abbreviation: "MN" },
  { label: "Mississippi", id: "mississippi", abbreviation: "MS" },
  { label: "Missouri", id: "missouri", abbreviation: "MO" },
  { label: "Montana", id: "montana", abbreviation: "MT" },
  { label: "Nebraska", id: "nebraska", abbreviation: "NE" },
  { label: "Nevada", id: "nevada", abbreviation: "NV" },
  { label: "New Hampshire", id: "new_hampshire", abbreviation: "NH" },
  { label: "New Jersey", id: "new_jersey", abbreviation: "NJ" },
  { label: "New Mexico", id: "new_mexico", abbreviation: "NM" },
  { label: "New York", id: "new_york", abbreviation: "NY" },
  { label: "North Carolina", id: "north_carolina", abbreviation: "NC" },
  { label: "North Dakota", id: "north_dakota", abbreviation: "ND" },
  { label: "Ohio", id: "ohio", abbreviation: "OH" },
  { label: "Oklahoma", id: "oklahoma", abbreviation: "OK" },
  { label: "Oregon", id: "oregon", abbreviation: "OR" },
  { label: "Pennsylvania", id: "pennsylvania", abbreviation: "PA" },
  { label: "Rhode Island", id: "rhode_island", abbreviation: "RI" },
  { label: "South Carolina", id: "south_carolina", abbreviation: "SC" },
  { label: "South Dakota", id: "south_dakota", abbreviation: "SD" },
  { label: "Tennessee", id: "tennessee", abbreviation: "TN" },
  { label: "Texas", id: "texas", abbreviation: "TX" },
  { label: "Utah", id: "utah", abbreviation: "UT" },
  { label: "Vermont", id: "vermont", abbreviation: "VT" },
  { label: "Virginia", id: "virginia", abbreviation: "VA" },
  { label: "Washington", id: "washington", abbreviation: "WA" },
  { label: "West Virginia", id: "west_virginia", abbreviation: "WV" },
  { label: "Wisconsin", id: "wisconsin", abbreviation: "WI" },
  { label: "Wyoming", id: "wyoming", abbreviation: "WY" }
]
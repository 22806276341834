import DOMPurify from "dompurify"
import { DateTime } from "luxon"

import { ILocation, Time } from "$/models"

export const capitalize = (str: string): string => (str ? str.charAt(0).toUpperCase() + str.slice(1) : "")
export const truncate = (str: string, max: number) => (str.length > max ? str.substring(0, max - 1).concat("...") : str)
export const padZero = (value: number) => (value < 10 ? `0${value}` : value)

export const splitAndTrim = (value: string, separator: string) => {
  if (!value) return []

  return value
    ?.trim()
    ?.split(separator)
    ?.map((value) => value?.trim())
}

export const fullName = (n: string, sn: string, separator = " ") => {
  const name: string = capitalize(n?.trim())
  const surname: string = capitalize(sn?.trim())

  return `${name}${separator}${surname}`.trim()
}
export const formatNumber = (num: number) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatTime = (time: Time, seconds = true) => {
  const { hh = 0, mm = 0, ss = 0 } = time || {}
  const formattedTime = [padZero(hh), padZero(mm)]
  if (seconds) formattedTime.push(padZero(ss))
  return formattedTime.join(":")
}

export const timeToString = (time: Time, seconds: boolean = true) => {
  if (time) {
    const _hours = time?.hh < 10 && time?.hh > -10 ? `0${Math.abs(time.hh)}` : Math.abs(time.hh)
    const _minutes = time?.mm < 10 && time?.mm > -10 ? `0${Math.abs(time.mm)}` : Math.abs(time.mm)
    const _seconds = time?.ss < 10 && time?.ss > -10 ? `0${Math.abs(time.ss)}` : Math.abs(time.ss)

    const isNegative = time?.hh < 0 || (time?.hh === 0 && time?.mm < 0) || (time?.mm === 0 && time?.ss < 0)

    if (seconds) return time ? `${isNegative ? "-" : ""}${_hours}:${_minutes}:${_seconds}` : ""
    return time ? `${isNegative ? "-" : ""}${_hours}${_minutes}` : ""
  }

  return seconds ? "00:00:00" : "00:00"
}

export const locationToString = (location: Partial<ILocation>) => {
  return location?.location
    ? `${location?.distance || 0} mi ${location?.direction?.toUpperCase() || ""} of ${location?.location || ""}, ${location?.state || ""}`
    : ""
}

export const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  if (hours > 0) {
    return `${hours}h ${minutes}min`
  }
  return `${minutes}min`
}

export const formatCardNumber = (cardNumber: string) => {
  return cardNumber ? "****" + cardNumber.slice(-5) : ""
}

export const timeAgo = (dateString: string): string => {
  const date = DateTime.fromISO(dateString, { zone: "utc" })
  const utcTime = DateTime.utc()

  const diffInSecs = Math.floor(utcTime.diff(date, "seconds").seconds)

  if (diffInSecs < 60) return "just now"

  if (diffInSecs < 3600) {
    const mins = Math.floor(diffInSecs / 60)
    return `${mins} ${mins === 1 ? "min" : "mins"} ago`
  }

  if (diffInSecs < 86400) {
    const hours = Math.floor(diffInSecs / 3600)
    const remainingMinutes = Math.floor((diffInSecs % 3600) / 60)

    if (remainingMinutes > 0) {
      return `${hours} ${hours === 1 ? "hour" : "hours"} and ${remainingMinutes} ${remainingMinutes === 1 ? "min" : "mins"} ago`
    }

    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`
  }

  const days = Math.floor(diffInSecs / 86400)
  const remainingSecsAfterDays = diffInSecs % 86400
  const hours = Math.floor(remainingSecsAfterDays / 3600)
  const remainingMinutes = Math.floor((remainingSecsAfterDays % 3600) / 60)

  let result = `${days} ${days === 1 ? "day" : "days"}`

  if (hours > 0) {
    result += ` ${hours} ${hours === 1 ? "hour" : "hours"}`
  }

  if (remainingMinutes > 0) {
    result += ` ${remainingMinutes} ${remainingMinutes === 1 ? "min" : "mins"}`
  }

  result += " ago"

  return result
}

export const getFullName = (firstName: string, lastName: string) => `${firstName} ${lastName}`

export const capitalizeFirstLetter = (str: string, isRestLowerCase: boolean = false): string => {
  return str.toLowerCase().replace(/\b\w+/g, function (word) {
    return word.charAt(0).toUpperCase() + (isRestLowerCase ? word.slice(1).toLowerCase() : word.slice(1))
  })
}

export const sanitizeHtml = (html: string): string => {
  return DOMPurify.sanitize(html)
}

<script lang="ts" setup>
import { Icon } from "@iconify/vue"

import { ButtonIcon } from "$/ui"
import { RolePermissionsModel } from "~/management/roles"

import { useSessionStore } from "~/session"
import { useSystemSettingsApi, useSystemSettingsService } from "~/management/systemSettings"

const sessionStorage = useSessionStore()
const { confirmDelete } = useAlert()
const { profile } = storeToRefs(sessionStorage)
const isLoading = ref<boolean>(false)

interface IProps {
  id: number
  isActive: boolean
  settingTypeComputed: string
}

const { id, settingTypeComputed } = defineProps<IProps>()
const { show } = useModal()
const { activateSystemSetting, deactivateSystemSetting, getSystemSettingsList } = useSystemSettingsService()
const { DELETE_EFS_ACCOUNT } = useSystemSettingsApi()
const showModal = () => {
  if (settingTypeComputed !== "EFS") {
    show("system-settings", id)
  } else {
    show("efs-settings-form", id)
  }
}

const deleteEfsAccount = () => {
  confirmDelete().then((res) => {
    isLoading.value = true
    if (res) {
      try {
        DELETE_EFS_ACCOUNT(id).then(() => {
          getSystemSettingsList()
        })
      } finally {
        isLoading.value = false
      }
    }
  })
}
</script>

<template>
  <div class="-my-2 flex items-center justify-center gap-3" @click.stop>
    <div
      v-if="profile.authorities.has(RolePermissionsModel.CAN_SETTINGS_CHANGE_STATUS) && settingTypeComputed !== 'EFS'"
    >
      <Button v-if="isActive" color="info" variant="ghost" @click="deactivateSystemSetting(id, { active: false })">
        <span> {{ $t("actions.deactivate") }} </span>
      </Button>
      <Button v-else color="info" variant="ghost" @click="activateSystemSetting(id, { active: true })">
        <span> {{ $t("actions.activate") }} </span>
      </Button>
    </div>

    <ButtonIcon
      v-if="profile.authorities.has(RolePermissionsModel.CAN_SETTINGS_EDIT) && settingTypeComputed !== 'EFS'"
      :label="$t('actions.edit')"
      color="secondary"
      pilled
      size="sm"
      variant="ghost"
      @click="showModal"
    >
      <Icon :width="18" icon="lucide:pencil" />
    </ButtonIcon>

    <ButtonIcon
      v-if="settingTypeComputed === 'EFS'"
      icon="lucide:trash"
      :label="$t('actions.delete')"
      :loading="isLoading"
      color="danger"
      width="18"
      size="xs"
      variant="ghost"
      @click="deleteEfsAccount"
    />
  </div>
</template>

<script setup lang="ts">
import MainHeader from "./MainHeader.vue"
import MainSidebar from "./MainSidebar.vue"
import MainContent from "./MainContent.vue"

import { SessionProfileModal, SessionProfilePasswordModal, useSessionService, useSessionStore } from "~/session"

const isLoading = ref(true)
const { getProfile } = useSessionService()
const sessionStore = useSessionStore()
const { profile, userId } = storeToRefs(sessionStore)

onMounted(async () => {
  if (!profile.value.id && userId.value) await getProfile(profile.value.id || Number(userId.value))
  isLoading.value = false
})
</script>

<template>
  <template v-if="isLoading">
    <div class="flex items-center justify-center h-screen">
      <Spinner />
    </div>
  </template>
  <template v-else>
    <MainSidebar />

    <MainContent>
      <template #header>
        <MainHeader />
      </template>

      <slot />
    </MainContent>

    <SessionProfileModal />
    <SessionProfilePasswordModal />
  </template>
</template>

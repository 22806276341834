import { createI18n } from "vue-i18n"
import en from "@/app/locales/en.json"

export const i18n = createI18n({
  locale: localStorage.getItem("locale") || "en",
  fallbackLocale: "en",
  globalInjection: true,
  legacy: false,
  messages: { en }
})

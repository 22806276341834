<script lang="ts" setup>
import { StoresAssignModel, usePriceStoresService, usePriceStoresStore } from "~/management/priceStores"
import Select from "@vueform/multiselect"
import { useManagementService } from "~/management"

const imageUrl = ref<string | null>("")
const uploadId = ref()
const modal = useModal()
const { setStore, getStoresListTable, getOneStoreList, updateStore } = usePriceStoresService()
const { uploadStationLogo } = useManagementService()
const store = usePriceStoresStore()
const { t } = useI18n()
const { required } = useRule()
const { form, formLoading } = storeToRefs(store)

const { successToast } = useAlert()
const rules = ref({
  store: { required },
  state: { required },
  name: { required },
  city: { required },
  interstate: { required },
  latitude: { required },
  longitude: { required },
  address: { required },
  zip_code: { required }
})
const { vuelidate, hasError } = useValidate(form, rules)
const store_edit = ref<number | null>(null)
const label = computed<string>(() => (store_edit.value ? t("actions.edit_store") : t("actions.add_store")))

const onSave = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return

  try {
    store.formLoading = true
    if (store_edit.value) {
      await updateStore({
        store_number: form.value.store,
        ...form.value,
        file_id: uploadId.value
      })
      await successToast("Successfully updated the store.")
    } else {
      await setStore()
      await successToast("Successfully created the store.")
      await setStore(uploadId.value)
    }
    await getStoresListTable()
    onHide()
  } catch {
    store.formLoading = false
  }
}

const onHide = () => {
  form.value = new StoresAssignModel()
  store.formLoading = false
  store_edit.value = null
  modal.hide("stores")
  imageUrl.value = null
  uploadId.value = undefined
  vuelidate.value?.$reset()
}
const onBeforeShow = async (id: number) => {
  if (id) {
    formLoading.value = true
    form.value.id = id
    store_edit.value = id
    await getOneStoreList(id)
    formLoading.value = false
  }
}

async function handleFile(e: Event) {
  const file = e.target.files[0]
  if (file && file.type.startsWith("image/")) {
    const formData = new FormData()
    formData.append("file", file)
    formLoading.value = true
    const res = await uploadStationLogo(formData)
    formLoading.value = false
    uploadId.value = res.content
    const reader = new FileReader()
    reader.onload = (e) => {
      imageUrl.value = e.target.result
    }
    reader.readAsDataURL(file)
  } else {
    imageUrl.value = null
  }
}
</script>

<template>
  <Modal
    id="stores"
    :close-on-backdrop="false"
    :label="label"
    :loading="formLoading"
    @hide="onHide"
    @before-show="onBeforeShow"
  >
    <form class="grid gap-4 p-4">
      <FormGroup v-slot="{ id }" :label="$t('labels.store')" required v-bind="hasError('store')">
        <Input :id v-model="form.store" :placeholder="t('placeholders.enter_store')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.store_name')" required v-bind="hasError('name')">
        <Input :id v-model="form.name" :placeholder="t('placeholders.enter_store_name')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.country')">
        <Input :id v-model="form.country" :placeholder="t('placeholders.enter_country')" />
      </FormGroup>
      <FormGroup :label="$t('labels.state')" required v-bind="hasError('state')">
        <Select
          v-model="form.state"
          :options="states"
          :placeholder="t('placeholders.enter_state')"
          append-to-body
          class="select-user !rounded-none"
          label="label"
          searchable
          value-prop="abbreviation"
        />
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.city')" required v-bind="hasError('city')">
        <Input :id v-model="form.city" :placeholder="t('placeholders.enter_city')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.address')" required v-bind="hasError('address')">
        <Input :id v-model="form.address" :placeholder="t('placeholders.enter_address')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.zip_code')" required v-bind="hasError('zip_code')">
        <Input :id v-model="form.zip_code" :placeholder="t('placeholders.enter_zip_code')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.interstate')" required v-bind="hasError('interstate')">
        <Input :id v-model="form.interstate" :placeholder="t('placeholders.enter_interstate')" />
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.latitude')" required v-bind="hasError('latitude')">
        <Input :id v-model="form.latitude" :placeholder="t('placeholders.enter_latitude')" type="number" />
      </FormGroup>
      <FormGroup v-slot="{ id }" :label="$t('labels.longitude')" required v-bind="hasError('longitude')">
        <Input :id v-model="form.longitude" :placeholder="t('placeholders.enter_longitude')" type="number" />
      </FormGroup>
      <FormGroup v-slot="{ id }" label="Logo">
        <div class="flex items-center gap-2">
          <img v-if="form.logo_url && !imageUrl && store_edit" :src="form.logo_url" alt="" class="h-12 w-10 rounded" />
          <img v-if="imageUrl" :src="imageUrl" alt="Selected Image" class="h-12 w-10 rounded" />
          <input
            :id
            type="file"
            :placeholder="t('placeholders.enter_store')"
            accept="image/png, image/gif, image/jpeg"
            class="w-full cursor-pointer rounded border bg-white text-sm font-semibold text-gray-400 file:mr-4 file:cursor-pointer file:border-0 file:bg-gray-100 file:px-4 file:py-3 file:text-gray-500 file:hover:bg-gray-200"
            @change="handleFile"
          />
        </div>
      </FormGroup>
    </form>
    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button :label="$t('actions.cancel')" color="danger" @click="hide" />
        <Button :disabled="vuelidate.$error" :label="$t('actions.submit')" @click="onSave" />
      </div>
    </template>
  </Modal>
</template>

import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { BookForm, FUEL_TRUCK, TankDetail, FuelModel, SuggestionStop } from "~/management/fuel/fuel.model"

export const useFuelStore = defineStore("fuel", () => {
  const list = ref<FuelModel[]>([])
  const isLoading = ref<boolean>(false)
  const listLoading = ref<boolean>(false)
  const isVisibleMap = ref<boolean>(false)
  const truck_data = ref<Nullable<FUEL_TRUCK>>(null)
  const suggestion_stops = ref<SuggestionStop[]>([])
  const isOpenDeleteSidebar = ref<Map<number, boolean>>(new Map())
  const isOpenEditSidebar = ref<Map<number, boolean>>(new Map())
  const oldVal = ref<string>("")
  const form = ref<FuelModel>(new FuelModel())
  const formTank = ref<TankDetail>(new TankDetail())
  const formLoading = ref<boolean>(false)
  const bookForm = ref<BookForm>(new BookForm())
  const isStop = ref<boolean>(false)
  const card_actions = ref<any>([
    {
      value: "deactivate",
      label: "Deactivate"
    },
    {
      value: "activate",
      label: "Activate"
    }
  ])

  const bookerIds = ref<number[]>([])
  const companyIds = ref<number[]>([])
  const sortMiles = ref<boolean | null>(null)
  const query = ref<any>({
    truckId: null,
    driverId: null,
    companyIds: null,
    cardId: "",
    page: 0,
    size: 20,
    status: null
  })

  const pagination = ref({
    total: 0
  })

  const transformQuery = () => {
    return pickBy<unknown>(query.value, identity)
  }

  const handleAddStopToSidebar = (gas_station: any) => {
    if (!isStop.value) {
      if (bookForm.value && bookForm.value.stops.length > 1) return
      if (gas_station?.id && bookForm.value.stops.some((stop: any) => stop?.id === gas_station.id)) return
      const emptyObject = bookForm.value.stops.find((item) => !item.store?.store_number)
      if (emptyObject && !emptyObject.store?.store_number) {
        Object.assign(emptyObject, gas_station)
      } else {
        bookForm.value.stops.push(gas_station)
      }
      if (bookForm.value.stops.length > 0 && emptyObject && !emptyObject.store?.store_number) {
        bookForm.value.stops.push(gas_station)
      }
    }
  }

  return {
    isLoading,
    oldVal,
    sortMiles,
    companyIds,
    formTank,
    list,
    listLoading,
    query,
    pagination,
    bookerIds,
    form,
    isStop,
    formLoading,
    bookForm,
    isOpenDeleteSidebar,
    isOpenEditSidebar,
    isVisibleMap,
    truck_data,
    card_actions,
    suggestion_stops,
    transformQuery,
    handleAddStopToSidebar
  }
})

<script setup lang="ts">
import { computed } from "vue"

import { TablePagination, TablePerPage } from "$/ui"

interface IProps {
  total: number
  page: number
  perPage: number
  range?: number
}

interface IEmits {
  (e: "update:page", value: number): void

  (e: "update:perPage", value: number): void

  (e: "change"): void
}

const props = withDefaults(defineProps<IProps>(), { perPage: 10 })
const emits = defineEmits<IEmits>()

const pageModel = computed({
  get() {
    return props.page
  },
  set(value: number) {
    emits("update:page", value)
  }
})

const perPageModel = computed({
  get() {
    return props.perPage
  },
  set(value: number) {
    emits("update:page", 0)
    emits("update:perPage", value)
  }
})
</script>

<template>
  <div class="table-footer">
    <TablePagination
      v-model="pageModel"
      :total="total"
      :per-page="perPageModel"
      @update:model-value="emits('change')"
    />
    <div v-if="total >= perPage" class="table-footer-right">
      <TablePerPage
        v-model="perPageModel"
        :total="total"
        :range="range"
        :all-label="$t('labels.all')"
        @update:model-value="emits('change')"
      />
    </div>
  </div>
</template>

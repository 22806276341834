import { useTrucksApi, useTrucksStore } from "~/management/trucks"

export const useTrucksService = () => {
  const { GET_TRUCKS, EDIT_TRUCK } = useTrucksApi()

  const truckManagement = useTrucksStore()
  const { selectList, list, listLoading, query } = storeToRefs(truckManagement)

  const fetchTrucksList = () => {
    listLoading.value = true
    GET_TRUCKS(truckManagement.transformQuery())
      .then(({ content, totalElements }) => {
        list.value = content
        query.value.total = totalElements ?? 0
      })
      .finally(() => (listLoading.value = false))
  }
  const fetchLazyTrucks = async (params: PaginationParams) => {
    await GET_TRUCKS({
      page: params.page,
      size: params.size,
      keyword: params.keyword
    }).then((res: any) => {
      selectList.value = res
    })
  }

  const debouncedSearch = useDebounceFn(() => {
    query.value.page = 0
    query.value.keyword = query.value.keyword.trim()
    fetchTrucksList()
  }, 600)

  const editTruck = async (id: number, params: any) => {
    return EDIT_TRUCK(id, params)
  }

  return {
    debouncedSearch,
    fetchTrucksList,
    fetchLazyTrucks,
    editTruck
  }
}

import { Icon } from "@iconify/vue"
import { RolePermissionsModel } from "~/management/roles"
import { SidebarComponentItem, SidebarHeaderItem, SidebarItem } from "vue-sidebar-menu"

interface SidebarItemWithKey extends SidebarItem {
  key?: RolePermissionsModel
}

interface SidebarComponentItemWithKey extends SidebarComponentItem {
  key?: RolePermissionsModel
}

interface SidebarHeaderItemWithKey extends SidebarHeaderItem {
  key?: RolePermissionsModel
}

type Menu = Array<Partial<SidebarItemWithKey> | SidebarComponentItemWithKey | SidebarHeaderItemWithKey>

export const useSidebarMenus = (): Menu => {
  const { t } = useI18n()

  return [
    {
      header: t("menu.fuel_management")
    },
    {
      title: t("menu.dashboard"),
      href: { name: "dashboard" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:layout-dashboard", width: 18 }
      }
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_FUEL_BOOK_PAGE,
      title: t("menu.fuel_book"),
      href: { name: "fuel-book" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:fuel", width: 18 }
      }
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_CARDS_PAGE,
      title: t("menu.cards"),
      href: { name: "cards" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:credit-card", width: 18 }
      }
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_BOOKERS_PAGE,
      title: t("menu.bookers"),
      href: { name: "bookers" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:user-pen", width: 18 }
      }
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_TRANSACTIONS_PAGE,
      title: t("menu.transactions"),
      href: { name: "transactions" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:arrow-right-left", width: 18 }
      }
    },
    // {
    //   title: t("menu.report"),
    //   href: { name: "report" },
    //   icon: {
    //     element: Icon,
    //     attributes: { icon: "lucide:proportions", width: 18 }
    //   }
    // },
    {
      key: RolePermissionsModel.CAN_ACCESS_PRICES__STORES_PAGE,
      title: t("menu.price_stores"),
      href: { name: "price-stores" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:badge-dollar-sign", width: 18 }
      }
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_IDLING_PAGE,
      title: t("menu.idling"),
      href: { name: "idling" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:badge-info", width: 18 }
      }
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_TRUCK_PAGE,
      title: t("menu.trucks"),
      href: { name: "trucks" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:truck", width: 18 }
      }
    },
    {
      header: t("menu.settings")
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_USERS_PAGE,
      title: t("menu.profile"),
      direction: "profile",
      icon: {
        element: Icon,
        attributes: { icon: "lucide:user-round-cog", width: 18 }
      }
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_USERS_PAGE,
      title: t("menu.users"),
      href: { name: "users" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:users", width: 18 }
      }
    },

    {
      key: RolePermissionsModel.CAN_ACCESS_ROLES_PAGE,
      title: t("menu.roles"),
      href: { name: "roles" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:shield-check", width: 18 }
      }
    },
    {
      key: RolePermissionsModel.CAN_ACCESS_SETTINGS_PAGE,
      title: t("menu.system_settings"),
      href: { name: "system-settings" },
      icon: {
        element: Icon,
        attributes: { icon: "lucide:settings", width: 18 }
      }
    }
  ]
}

import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { UsersAssignModel, UsersModel, UsersCompanyDetails } from "~/management/users"

export const useUsersStore = defineStore("users", () => {
  const list = ref<UsersModel[]>([])
  const userCompanyList = ref<UsersCompanyDetails[]>([])
  const selectUsers = ref<any>([])
  const listLoading = ref<boolean>(false)
  const usersCount = ref<number>(0)
  const form = ref<UsersAssignModel>(new UsersAssignModel())
  const roleDetails = ref<{ id: number; name: string }>()
  const formLoading = ref<boolean>(false)

  const query = ref<any>({
    page: 0,
    size: 20,
    total: 0,
    totalPages: 0,
    role: null,
    keyword: null,
    user_id: null
  })

  const transformQuery = () => {
    return pickBy<unknown>(query.value, identity)
  }

  return {
    roleDetails,
    userCompanyList,
    list,
    usersCount,
    listLoading,
    query,
    form,
    selectUsers,
    formLoading,
    transformQuery
  }
})

<script setup lang="ts">
import { ButtonIcon, DatePicker, Icon } from "$/ui"
import { DashboardMPG, useDashboardBookerStats, useDashboardStore } from "~/management/dashboard"

const { t } = useI18n()

const store = useDashboardStore()
const { fetchMpgList } = useDashboardBookerStats()
const intersectionTarget = ref<HTMLDivElement>()

const { mpgLoading, mpgDate, mpgStats, queryMpg } = storeToRefs(store)

const gettingTarget = (val: any) => {
  intersectionTarget.value = val
}

const cols = computed<ITableCol<DashboardMPG>[]>(() => [
  {
    name: "idx",
    label: t("thead.no")
  },
  {
    name: "truckId",
    label: t("thead.truck")
  },
  {
    name: "driverName",
    label: t("thead.driver"),
    dataClass: "w-[40px] whitespace-break-spaces"
  },
  {
    name: "week_1",
    label: "W1",
    labelClass: "flex justify-center"
  },
  {
    name: "week_2",
    label: "W2",
    labelClass: "flex justify-center"
  },
  {
    name: "week_3",
    label: "W3",
    labelClass: "flex justify-center"
  },
  {
    name: "week_4",
    label: "W4",
    labelClass: "flex justify-center"
  }
])

const refreshMpg = () => {
  queryMpg.value.page = 0
  mpgStats.value = []
  fetchMpgList()
}
const sorting = (val: string) => {
  queryMpg.value.page = 0
  mpgStats.value = []
  if (val === "DESC") {
    queryMpg.value.direction = "DESC"
    fetchMpgList()
  } else {
    queryMpg.value.direction = "ASC"
    fetchMpgList()
  }
  resume()
}

const { pause, resume } = useIntersectionObserver(intersectionTarget, async ([entry]) => {
  if (entry.isIntersecting) {
    if (!mpgStats.value.length || queryMpg.value.total - 1 === queryMpg.value.page) {
      pause()
      return
    }
    queryMpg.value.page = queryMpg.value.page + 1
    await fetchMpgList()
  }
})

const checkingDirection = (val: any, week: number) => {
  let currentCount = val.weeklyDataList[week].averageMpg
  let oldCount = val.weeklyDataList[week - 1].averageMpg

  return currentCount >= oldCount
}

watch(mpgLoading, (newLoading) => {
  if (newLoading) return pause()
  resume()
})

onMounted(() => refreshMpg())
onUnmounted(() => {
  mpgDate.value = new Date()
})
</script>

<template>
  <div class="border border-gray-200 bg-white px-4 py-2 dark:border-gray-700 dark:bg-gray-900">
    <div class="flex w-full flex-col text-xs">
      <div class="flex flex-nowrap items-center justify-between border-b border-gray-200 pb-1 dark:border-gray-700">
        <h6>
          {{ $t("labels.mpg_stats") }}
        </h6>

        <div class="flex flex-nowrap items-center gap-4">
          <ButtonIcon
            v-if="queryMpg.direction === 'DESC'"
            variant="white"
            color="secondary"
            icon="lucide:arrow-down-up"
            width="20"
            @click="sorting('ASC')"
          />
          <ButtonIcon
            v-else
            variant="white"
            color="secondary"
            icon="lucide:arrow-up-down"
            width="20"
            @click="sorting('DESC')"
          />
          <DatePicker
            v-model="mpgDate"
            :dark="isDark"
            class="min-w-36"
            year-picker
            month-picker
            :clearable="false"
            :max-date="new Date()"
            @update:model-value="refreshMpg"
          />
          <ButtonIcon variant="ghost" color="success" :loading="mpgLoading" @click="refreshMpg">
            <Icon icon="lucide:refresh-ccw" :width="20" />
          </ButtonIcon>
        </div>
      </div>
      <Table
        no-wrap
        :loading="mpgLoading"
        :cols="cols"
        :rows="mpgStats"
        head-class="bg-slate-200 dark:bg-gray-800"
        max-height="400px"
        @intersection-target="gettingTarget"
      >
        <template #idx="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #week_1="{ row }">
          <div v-if="row && row.weeklyDataList.length && row.weeklyDataList[1].averageMpg" class="flex items-center">
            <Icon v-if="checkingDirection(row, 1)" icon="lucide:move-up" :width="20" class="text-green-500" />
            <Icon v-else icon="lucide:move-down" :width="20" class="text-red-500" />

            <span v-for="(item, index) in row.weeklyDataList" :key="index">
              {{ item.week === "1" ? item.averageMpg : "" }}
            </span>
          </div>
        </template>
        <template #week_2="{ row }">
          <div v-if="row && row.weeklyDataList.length && row.weeklyDataList[2].averageMpg" class="flex items-center">
            <Icon v-if="checkingDirection(row, 2)" icon="lucide:move-up" :width="20" class="text-green-500" />
            <Icon v-else icon="lucide:move-down" :width="20" class="text-red-500" />

            <span v-for="(item, index) in row.weeklyDataList" :key="index">
              {{ item.week === "2" ? item.averageMpg : "" }}
            </span>
          </div>
        </template>
        <template #week_3="{ row }">
          <div v-if="row && row.weeklyDataList.length && row.weeklyDataList[3].averageMpg" class="flex items-center">
            <Icon v-if="checkingDirection(row, 3)" icon="lucide:move-up" :width="20" class="text-green-500" />
            <Icon v-else icon="lucide:move-down" :width="20" class="text-red-500" />
            <span v-for="(item, index) in row.weeklyDataList" :key="index">
              {{ item.week === "3" ? item.averageMpg : "" }}
            </span>
          </div>
        </template>
        <template #week_4="{ row }">
          <div v-if="row && row.weeklyDataList.length && row.weeklyDataList[4].averageMpg" class="flex items-center">
            <Icon v-if="checkingDirection(row, 4)" icon="lucide:move-up" :width="20" class="text-green-500" />
            <Icon v-else icon="lucide:move-down" :width="20" class="text-red-500" />
            <span v-for="(item, index) in row.weeklyDataList" :key="index">
              {{ item.week === "4" ? item.averageMpg : "" }}
            </span>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

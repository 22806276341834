<script setup lang="ts">
import { useRouteProtection } from "$/composables/useRouteProtection"
import { TransactionsList } from "~/management/transaction"

import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"
import TransactionReport from "~/management/transaction/modals/TransactionReport.vue"

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_TRANSACTIONS_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <TransactionsList v-if="profile.authorities.has(RolePermissionsModel.CAN_TRANSACTION_PAGE_GET)" />
    <TransactionReport />
  </div>
</template>

<script setup lang="ts">
import Tabs from "~/management/components/TabsContent.vue"
import Tab from "~/management/components/TabContent.vue"
import Bookings from "./Bookings/BookingsList.vue"
import BookerStats from "./BookerStats/BookerStats.vue"
import { useSessionStore } from "~/session"
import { RolePermissionsModel } from "~/management/roles"

const activeTab = ref("BOOKINGS")

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

onMounted(() => {
  if (
    !profile.value.authorities.has(RolePermissionsModel.CAN_SUGGESTION_STOP_BOOKINGS_PAGE_GET) &&
    profile.value.authorities.has(RolePermissionsModel.CAN_SUGGESTION_STOP_BOOKINGS_STATS_PAGE_GET)
  ) {
    activeTab.value = "BOOKER STATS"
  }
})

const setActiveTab = (tab: string) => {
  activeTab.value = tab
}
</script>

<template>
  <Tabs v-model="activeTab">
    <Tab v-if="profile.authorities.has(RolePermissionsModel.CAN_SUGGESTION_STOP_BOOKINGS_PAGE_GET)" title="BOOKINGS">
      <Bookings />
    </Tab>
    <Tab
      v-if="profile.authorities.has(RolePermissionsModel.CAN_SUGGESTION_STOP_BOOKINGS_STATS_PAGE_GET)"
      title="BOOKER STATS"
    >
      <BookerStats @switch-tab="setActiveTab" />
    </Tab>
  </Tabs>
</template>

<script setup lang="ts">
import Select from "@vueform/multiselect"

import { useFuelService, useFuelStore } from "~/management/fuel"
import { DELETING_REASONS } from "~/management/fuel/fuel.constants"

const { removeSuggestionStop } = useFuelService()
const fuelStore = useFuelStore()
const { isOpenDeleteSidebar } = storeToRefs(fuelStore)
const { required } = useRule()

const emit = defineEmits(["reload"])

const rules = computed(() => ({
  reason: { required }
}))
const form = ref<any>({ reason: "", notes: "" })
const stop = ref<any>({
  id: null,
  store_number: "",
  address: ""
})
const loading = ref<boolean>(false)

const { vuelidate, hasError } = useValidate(form, rules)

const onSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (isValid && stop.value.id)
    removeSuggestionStop({ stopId: stop.value.id, reason: form.value.reason, note: form.value.notes }, loading, () =>
      emit("reload")
    )
}

const onBeforeShow = ({
  stopId,
  storeNumber,
  location,
  stopOrder
}: {
  stopId: number
  stopOrder: number
  storeNumber: string
  location: string
}) => {
  stop.value = {
    id: stopId,
    store_number: storeNumber,
    address: location,
    order: stopOrder
  }
}

const onHide = () => {
  form.value = { reason: "", notes: "" }
  stop.value = null
  vuelidate.value?.$reset()
  isOpenDeleteSidebar.value.clear()
  loading.value = false
}
</script>

<template>
  <Sidebar
    id="book-delete"
    color="danger"
    is-absolute-position
    :label="$t('actions.book_delete')"
    @before-show="onBeforeShow"
    @hide="onHide"
  >
    <form class="space-y-2.5" @submit.prevent="onSubmit">
      <div class="max-h-[250px] overflow-y-auto">
        <div class="flex w-full gap-6 p-2 text-xs">
          <p>{{ $t("labels.stop", { order: stop.order }) }}:</p>
          <span>#{{ stop.store_number }} {{ stop.address }}</span>
        </div>

        <div class="box relative">
          <FormGroup v-slot="{ id }" v-bind="hasError('reason')" required :label="$t('labels.reason')">
            <Select
              :id
              v-model="form.reason"
              append-to-body
              :options="DELETING_REASONS"
              :placeholder="$t('placeholders.select_reason')"
              class="!rounded-none"
            />
          </FormGroup>
        </div>
      </div>

      <Textarea v-model="form.notes" :placeholder="$t('placeholders.notes')" />

      <div class="flex justify-end pb-2.5">
        <Button
          type="submit"
          color="danger"
          :label="$t('actions.delete')"
          :loading="loading"
          :disabled="vuelidate.$error || loading"
        />
      </div>
    </form>
  </Sidebar>
</template>

<style scoped>
.box {
  @apply mt-2 flex flex-col gap-2 rounded-sm border border-gray-200 p-2 dark:border-gray-800;
}
</style>

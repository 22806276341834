<script setup lang="ts">
import { layouts } from "~/layout"

import AppLoading from "./AppLoading.vue"

const store = useMainStore()
const router = useRouter()
const layout = shallowRef()
router.afterEach((to) => (layout.value = layouts[to.meta.layout!] || layouts["MainLayout"]))
</script>

<template>
  <Transition appear name="fade" mode="out-in">
    <AppLoading v-if="store.loading" />
  </Transition>

  <Transition appear name="fade" mode="out-in">
    <div v-if="!store.loading">
      <component :is="layout">
        <RouterView v-slot="{ Component, route }">
          <Transition appear name="fade" mode="out-in">
            <component :is="Component" :key="route.path" />
          </Transition>
        </RouterView>
      </component>
    </div>
  </Transition>
</template>

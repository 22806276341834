<script setup lang="ts">
import { computed, TextareaHTMLAttributes } from "vue"

interface IProps extends /* @vue-ignore */ TextareaHTMLAttributes {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl"
  color?: "primary" | "secondary" | "success" | "warning" | "danger"
  resize?: boolean
  squared?: boolean
  disabled?: boolean
  modelValue?: string | number
}

const props = withDefaults(defineProps<IProps>(), { size: "xs", color: "primary", squared: true })

const model = defineModel<string>()

const textareaClass = computed(() => [
  {
    input: true,
    "form-textarea -mb-1": true,
    "resize-none": !props.resize,
    "input-squared": props.squared,
    "input-disabled": props.disabled,
    ["textarea-" + props.size]: true,
    ["input-" + props.color]: true
  }
])
</script>

<template>
  <textarea v-model="model" v-bind="$attrs" :class="textareaClass" :disabled="disabled" />
</template>

<script setup lang="ts">
import { ButtonIcon, DatePicker, Icon } from "$/ui"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"

import { useManagementService, useManagementStore } from "~/management"
import { TransactionModel, useTransactionsService, useTransactionsStore } from "~/management/transaction"
import { useUsersService, useUsersStore } from "~/management/users"

const { t } = useI18n()
const modal = useModal()

const managementStore = useManagementStore()
const transactionStore = useTransactionsStore()
const usersStore = useUsersStore()
const { selectUsers } = storeToRefs(usersStore)
const { driverList, truckList, companyList } = storeToRefs(managementStore)
const { list, companyIds, listLoading, transactionsCount, query, paginationTotal } = storeToRefs(transactionStore)

const { fetchDriverList, fetchTruckList, getCompanyLazySelect } = useManagementService()
const { fetchTransactionsList, getTransactionsCount } = useTransactionsService()
const { getUsersLazySelect } = useUsersService()

const cols = computed<ITableCol<TransactionModel>[]>(() => [
  {
    name: "idx",
    label: t("thead.no")
  },
  {
    name: "transaction_date",
    label: t("thead.date_time"),
    formatter: (value: string) => (value ? toZone(value, "MM/DD/YY HH:mm CT") : "")
  },
  {
    name: "unit_number",
    label: t("thead.truck")
  },
  {
    name: "driver_name",
    label: t("thead.driver")
  },
  {
    name: "card_number",
    label: t("thead.fuel_card"),
    width: "100px text-right"
  },
  {
    name: "quantity",
    label: t("thead.quantity"),
    labelClass: "border-l border-gray-400 pl-4"
  },
  {
    name: "total_amount",
    label: t("thead.total_price"),
    formatter: (value: number) => `$${value.toFixed(2)}`
  },
  {
    name: "disc_amount",
    label: t("thead.discount"),
    formatter: (value: number) => {
      return `$${value.toFixed(2)}`
    }
  },
  {
    name: "location_name",
    label: t("thead.location")
  },
  {
    name: "address",
    label: t("thead.address")
  },
  {
    name: "supplier_loc_code",
    label: t("thead.store")
  },
  {
    name: "ppu",
    label: t("thead.price")
  },
  {
    name: "actual_price",
    label: t("thead.price"),
    labelClass: "border-l border-gray-400 pl-4"
  },
  {
    name: "store_number",
    label: t("thead.store")
  },
  {
    name: "amount",
    label: t("thead.amount")
  },
  {
    name: "booker",
    label: t("thead.booker")
  },
  {
    name: "created",
    label: t("thead.date_time"),
    formatter: (value: string) => (value ? toZone(value, "MM/DD HH:mm CT") : "")
  },
  {
    name: "note",
    label: t("thead.notes"),
    width: "120px"
  }
])

const refreshFilter = (): void => {
  query.value.page = 0
  fetchTransactionsList()
}

onUnmounted(() => {
  query.value = {}
  companyIds.value = []
})
onBeforeMount(() => {
  fetchTransactionsList()
  getTransactionsCount()
})

const filteredCompany = () => {
  nextTick(() => {
    fetchTransactionsList()
  })
}

const handleTableClick = (val: any) => {
  val.id = !val.id
}

const refresh = () => {
  fetchTransactionsList()
  getTransactionsCount()
}
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="flex w-full flex-wrap items-center gap-4">
        <div class="w-1/6">
          <LazySelect
            v-model="query.driver_id"
            value-prop="id"
            content-refreshable
            :options="driverList"
            :placeholder="t('labels.driver')"
            :fetch="fetchDriverList"
            :get-option-label="(item: any) => fullName(item?.first_name, item?.last_name) || ''"
            @update:model-value="refreshFilter"
          />
        </div>
        <div class="w-1/6">
          <LazySelect
            v-model="query.booker_id"
            value-prop="id"
            content-refreshable
            :options="selectUsers"
            :placeholder="t('placeholders.select_booker')"
            :fetch="getUsersLazySelect"
            :get-option-label="
              (item: any) =>
                item?.nickname + ' ' + fullName(item?.firstname, item?.lastname) + ' ' + `(${item?.truck_count})`
            "
            @update:model-value="refreshFilter"
          >
            <template #option="{ option }">
              <span class="w-4/5">
                {{ option?.nickname + " " + fullName(option?.firstname, option?.lastname) }}
              </span>
              <span class="absolute right-2"> ({{ option?.truck_count }}) </span>
            </template>
          </LazySelect>
        </div>
        <div>
          <LazySelect
            v-model="query.truck_id"
            :options="truckList"
            content-refreshable
            :placeholder="t('placeholders.select_truck')"
            :fetch="fetchTruckList"
            :get-option-label="(item: any) => item.truck_id || ''"
            @update:model-value="refreshFilter"
          />
        </div>
        <div>
          <LazySelect
            v-model="companyIds"
            mode="multiple"
            class="tags-select min-w-[200px]"
            value-prop="id"
            tooltip
            characters="companies"
            content-refreshable
            :hide-selected="false"
            :close-on-select="false"
            :options="companyList"
            :placeholder="t('placeholders.all_companies')"
            :fetch="getCompanyLazySelect"
            :get-option-label="(item: any) => item.name || ''"
            @update:model-value="filteredCompany"
          />
        </div>
        <div>
          <DatePicker
            v-model="query.input_date"
            auto-apply
            teleport="body"
            format="MM/dd/yyyy"
            :dark="isDark"
            :enable-time-picker="false"
            :placeholder="$t('placeholders.date')"
            :max-date="new Date()"
            class="min-w-36"
            @update:model-value="refreshFilter"
          />
        </div>
      </div>
    </template>

    <div class="flex items-center justify-end gap-5">
      <HeaderAmountItem :amount="transactionsCount" :description="$t('labels.total_transactions')" />

      <Button :label="$t('actions.generate_report')" @click="modal.show('report')" />
      <ButtonIcon variant="ghost" color="success" :loading="listLoading" @click="refresh">
        <Icon icon="lucide:refresh-ccw" :width="20" />
      </ButtonIcon>
    </div>
  </TableHeader>

  <Table
    class="mt-4"
    no-wrap
    :skeleton="listLoading"
    :loading="listLoading"
    :cols="cols"
    :rows="list"
    expand-row
    head-class="bg-slate-200 dark:bg-gray-800 "
    body-class="cursor-pointer"
    @row-clicked="handleTableClick"
  >
    <template #idx="{ idx, sequence }">
      {{ sequence(idx, query.page || 0, query.size || 10) }}
    </template>

    <template #quantity="{ row }">
      <span class="border-l border-gray-400 pl-4">{{ row.quantity }}</span>
    </template>

    <template #supplier_loc_code="{ row }">
      <div
        v-if="row.store_number && row.supplier_loc_code"
        :class="[row.store_number !== row.supplier_loc_code ? 'font-bold text-danger-800' : '']"
      >
        {{ row.supplier_loc_code }}
      </div>
      <div v-else>{{ row.supplier_loc_code }}</div>
    </template>

    <template #store_number="{ row }">
      <div
        v-if="row.store_number && row.supplier_loc_code"
        :class="[row.store_number !== row.supplier_loc_code ? 'font-bold text-danger-800' : '']"
      >
        {{ row.store_number }}
      </div>
      <div v-else>{{ row.store_number }}</div>
    </template>
    <template #note="{ row }">
      <div v-if="row.is_likely_fraud" class="text-danger-800">Possibly Froud</div>
      <div v-if="row.note" class="mt-1">{{ row.note }}</div>
    </template>

    <template #actual_price="{ row }">
      <span class="border-l border-gray-400 pl-4">${{ row.actual_price.toFixed(2) }}</span>
    </template>

    <template #expandRow="{ row }">
      <tr v-if="!row.id" class="expanded-row border-4 border-[#CCC]">
        <td colspan="1" style="background: #ccc" class="px-4 py-2"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc">
          <div v-for="(item, index) in row?.line_items" :key="index" class="p-1">{{ item.limit_id }}</div>
        </td>
        <td colspan="1" style="background: #ccc">
          <div v-for="(item, index) in row?.line_items" :key="index" class="border-l border-gray-400 p-1 pl-4">
            {{ item.quantity }}
          </div>
        </td>
        <td colspan="1" style="background: #ccc">
          <div v-for="(item, index) in row?.line_items" :key="index" class="p-1 pl-0">
            {{ item.total_amount }}
          </div>
        </td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc">
          <div v-for="(item, index) in row?.line_items" :key="index" class="p-1 pl-0">
            {{ item.ppu }}
          </div>
        </td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
        <td colspan="1" style="background: #ccc"></td>
      </tr>
    </template>
  </Table>

  <TableFooter
    v-model:page="query.page"
    v-model:per-page="query.size"
    :total="paginationTotal"
    @change="fetchTransactionsList"
  />
</template>

<style>
.expanded-row {
  transition: all 0.3s ease-in-out;
}
</style>

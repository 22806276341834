<script lang="ts" setup>
import { useRouteProtection } from "$/composables/useRouteProtection"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"
import SystemSettingsList from "~/management/systemSettings/components/SystemSettingsList.vue"
import SystemSettingsModal from "~/management/systemSettings/components/SystemSettingsModal.vue"
import EfsSettingsForm from "~/management/systemSettings/components/EfsSettingsForm.vue"

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_SETTINGS_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container !ml-[180px]">
    <SystemSettingsList v-if="profile.authorities.has(RolePermissionsModel.CAN_TRUCK_PAGE_GET)" />
    <SystemSettingsModal />
    <EfsSettingsForm />
  </div>
</template>

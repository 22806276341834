<script setup lang="ts">
import { useRouteProtection } from "$/composables/useRouteProtection"
import UsersList from "~/management/users/components/UsersList.vue"
import UsersModal from "~/management/users/components/UsersModal.vue"
import UsersCompanyDetails from "~/management/users/components/UsersCompanyDetails.vue"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_USERS_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <UsersList v-if="profile.authorities.has(RolePermissionsModel.CAN_USER_PAGE_GET)" />
    <UsersModal />
    <UsersCompanyDetails />
  </div>
</template>

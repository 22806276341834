import { createNotivue } from "notivue"

import "notivue/notifications.css"
import "notivue/animations.css"

export const notivue = createNotivue({
  position: "bottom-right",
  limit: 3,
  pauseOnHover: true,
  pauseOnTabChange: true
})

import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { RoleModel, RolesAssignModel } from "~/management/roles/roles.model"

export const useRolesStore = defineStore("roles", () => {
  const list = ref<RoleModel[]>([])
  const listLoading = ref<boolean>(false)

  const form = ref<RolesAssignModel>(new RolesAssignModel())
  const formLoading = ref<boolean>(false)
  const selectList = ref<any>([])
  const query = ref<Omit<Query, "keyword">>({
    page: 0,
    size: 10
  })

  const pagination = ref<any>({
    totalPages: 0,
    totalElements: 0
  })

  const transformQuery = () => {
    return pickBy<unknown>(query.value, identity)
  }

  return { list, selectList, listLoading, query, pagination, form, formLoading, transformQuery }
})

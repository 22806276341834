import type { AxiosInstance } from "axios"
import { BookingParams, BookingTotals, IBooker, IBookerStats, BookingTotalsStats } from "~/management/bookers"

export const useBookersApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1/suggestion/bookings"
  const baseTotal: Readonly<string> = "/api/v1/suggestion/total"

  return {
    GET_BOOKINGS(params: BookingParams, companyIds: number[]): ResponseContainer<IBooker[]> {
      return axios.$get(base, {
        params: {
          ...params,
          company_ids: companyIds?.join(", ")
        }
      })
    },
    GET_BOOKINGS_COUNT(): ResponseContainer {
      return axios.$get(`${base}/count`)
    },
    GET_BOOKING_STATS(params: BookingParams, companyStatsIds: number[]): ResponseContainer<IBookerStats[]> {
      return axios.$get(`${base}/stats`, {
        params: {
          ...params,
          company_ids: companyStatsIds?.join(", ")
        }
      })
    },
    GET_BOOKING_TOTAL(inputDate: string, bookerId: number): ResponseContainer<BookingTotals> {
      const params = {
        bookerId,
        inputDate: inputDate === "" ? null : inputDate
      }
      return axios.$get(`${base}/stats/count_avg`, { params })
    },
    GET_BOOKING_TOTAL_COUNT(params: BookingParams, companyStatsIds: number[]): ResponseContainer<BookingTotalsStats> {
      return axios.$get(`${baseTotal}/bookings/stats`, {
        params: {
          ...params,
          company_ids: companyStatsIds?.join(", ")
        }
      })
    }
  }
}

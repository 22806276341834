import { RolesAssignModel, useRolesApi, useRolesStore } from "~/management/roles"

export const useRolesService = () => {
  const rolesApi = useRolesApi()
  const store = useRolesStore()
  const { confirmActivateDeactivate } = useAlert()
  const { list, selectList, form, listLoading, query, pagination } = storeToRefs(store)

  const getRolesListTable = async (): Promise<void> => {
    listLoading.value = true
    rolesApi
      .GET_ROLES(query.value)
      .then(({ content, totalPages, totalElements }) => {
        list.value = content
        pagination.value.totalPages = totalPages
        pagination.value.totalElements = totalElements
      })
      .finally(() => (listLoading.value = false))
  }
  const getRolesLazySelect = async (val: { page?: number; size?: number; keyword?: string }) => {
    await rolesApi
      .GET_ROLES({
        page: val.page,
        size: val.size,
        keyword: val.keyword
      })
      .then((res: any) => {
        selectList.value = res
      })
  }

  const getSingleRole = async (id: number): Promise<void> => {
    const result = await rolesApi.GET_SINGLE_ROLE(id)
    if (result) form.value = new RolesAssignModel(result.content)
  }

  const activateRole = async (id: number): Promise<void> => {
    confirmActivateDeactivate(false).then(() => {
      rolesApi.ACTIVATE_ROLE(id).then(() => getRolesListTable())
    })
  }

  const deactivateRole = async (id: number): Promise<void> => {
    await confirmActivateDeactivate(true).then(() => {
      rolesApi.DEACTIVATE_ROLE(id).then(() => getRolesListTable())
    })
  }

  const addRole = async (value: RolesAssignModel): Promise<void> => {
    delete value.id
    await rolesApi.POST_ROLE(value)
  }

  const editRole = async (value: Required<RolesAssignModel>): Promise<void> => {
    await rolesApi.PATCH_ROLE(value)
  }

  return {
    getRolesLazySelect,
    getRolesListTable,
    activateRole,
    deactivateRole,
    getSingleRole,
    addRole,
    editRole
  }
}

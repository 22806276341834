<script setup lang="ts">
import { Icon } from "@iconify/vue"

import { formatCardNumber } from "$/utils/string"
import { getGoogleMapsUrl } from "$/utils/location"

import { FUEL_TRUCK } from "~/management/fuel"

const emit = defineEmits(["reload"])

defineProps<{
  truck: FUEL_TRUCK
}>()

const refresh_data = () => emit("reload")
</script>

<template>
  <div class="flex items-end justify-between py-4 px-2.5 gap-10 border border-gray-200 dark:border-gray-600">
    <div class="flex items-center flex-wrap gap-5 text-xs font-semibold">
      <p class="fuel-details-header__line">
        {{ $t("labels.truck") }}: <span class="text-primary-700">{{ truck.truck_id || truck.unit_number }}</span>
      </p>
      <p class="fuel-details-header__line flex items-center gap-2">
        {{ $t("labels.status") }}:
        <Icon :icon="`lucide:circle-${truck?.speed > 5 ? 'play' : 'pause'}`" :width="24" color="green" />
        <span class="text-primary-700">{{ truck.speed || 0 }}mph</span>
      </p>
      <div class="fuel-details-header__line">
        {{ $t("labels.location") }}:
        <span v-if="truck?.location" class="text-primary-700">
          {{ truck.location }}
        </span>

        <template v-if="truck?.latitude && truck?.longitude">
          <ButtonCopy
            :text-to-copy="Object.values({ latitude: truck.latitude, longitude: truck.longitude }).join(', ')"
          />
          <ButtonIcon
            :href="getGoogleMapsUrl({ latitude: truck.latitude, longitude: truck.longitude })"
            target="_blank"
            icon="lucide:external-link"
            color="info"
            variant="ghost"
            width="18"
            size="xs"
          />
        </template>
      </div>
      <p class="fuel-details-header__line">
        {{ $t("labels.driver") }}:
        <span v-if="truck.driver_name" class="text-primary-700 capitalize">{{ truck.driver_name }}</span>
      </p>
      <p class="fuel-details-header__line">
        {{ $t("labels.driver_phone") }}:
        <span v-if="truck.driver_phone" class="text-primary-700">{{ truck.driver_phone }}</span>
      </p>
      <p class="fuel-details-header__line">
        {{ $t("labels.card") }}:
        <template v-if="truck.card_number">
          <span class="text-primary-700">{{ formatCardNumber(truck.card_number) }}</span>
          <ButtonCopy :text-to-copy="truck.card_number" />
        </template>
      </p>

      <p v-if="truck?.time" class="fuel-details-header__line">
        ({{ $t("labels.updated", { date: toZone(truck.time, "MM/DD/YYYY hh:mm CT") }) }})
      </p>
    </div>

    <div class="flex items-center gap-2">
      <Icon icon="lucide:bell-ring" :width="24" color="orange" />
      <Icon
        v-if="!truck.unit_number"
        icon="lucide:refresh-ccw"
        class="cursor-pointer"
        :width="24"
        color="green"
        @click="refresh_data"
      />
    </div>
  </div>
</template>

<style scoped>
.fuel-details-header__line {
  @apply text-gray-900 dark:text-gray-200 flex items-center flex-nowrap gap-1;
}
</style>

<script setup lang="ts">
import { useIdlingService } from "~/management/idling"

const { hide } = useModal()
const { required } = useRule()

const idlingId = ref<Nullable<number>>(null)
let params = ref<any>({
  truck_id: ""
})
const form = ref({
  reason: ""
})
const rules = ref({
  reason: { required }
})

const { vuelidate, hasError } = useValidate(form, rules)
const { postIdlingReason, fetchIdlingDetail } = useIdlingService()

const onSave = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  await postIdlingReason({ id: idlingId.value!, reason: form.value.reason }).then(() => hide("idling-reason"))
  fetchIdlingDetail(params.value!)
}

const onBeforeShow = ({ id, idlingIdData, reason }: { id: number; idlingIdData: number; reason: string }) => {
  idlingId.value = id
  params.value.truck_id = idlingIdData
  nextTick(() => {
    form.value.reason = reason
  })
}

const onHide = () => {
  vuelidate.value?.$reset()
}
</script>

<template>
  <Modal
    id="idling-reason"
    size="xs"
    :label="$t('labels.idling_reason')"
    :close-on-backdrop="false"
    :close-on-esc="false"
    @before-show="onBeforeShow"
    @hide="onHide"
  >
    <form class="grid gap-4 p-4">
      <FormGroup v-slot="{ id }" v-bind="hasError('reason')" required :label="$t('labels.reason_of_idling')">
        <Input :id v-model="form.reason" :placeholder="$t('placeholders.enter_reason')" size="xs" />
      </FormGroup>
    </form>

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button color="danger" :label="$t('actions.cancel')" @click="hide" />
        <Button :label="$t('actions.submit')" :disabled="vuelidate.$error" @click="onSave" />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ButtonHTMLAttributes, computed } from "vue"
import { Icon } from "@iconify/vue"

export interface IProps extends /* @vue-ignore */ ButtonHTMLAttributes {
  variant?: "solid" | "ghost" | "flat" | "white"
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  color?: "primary" | "secondary" | "success" | "warning" | "danger" | "info" | string
  label?: string
  loadingLabel?: string
  rounded?: boolean
  squared?: boolean
  loading?: boolean
  disabled?: boolean
  href?: string
  beforeIcon?: string
  to?: string | Record<string, any>
}

const props = withDefaults(defineProps<IProps>(), {
  size: "xs",
  color: "primary",
  variant: "solid",
  loadingLabel: "Loading...",
  squared: true
})

const buttonClass = computed(() => ({
  button: true,
  "button-rounded": props.rounded,
  "button-squared": props.squared,
  "button-disabled": props.disabled || props.loading,
  ["button-" + props.size]: true,
  ["button-" + props.color]: true,
  ["button-" + props.variant]: true
}))

const buttonAttrs = computed(() => {
  if (props.to) return { is: "router-link", to: props.to }
  else if (props.href) return { is: "a", href: props.href }
  else return { is: "button" }
})
</script>

<template>
  <component
    v-bind="{ ...$attrs, ...buttonAttrs }"
    :is="buttonAttrs.is"
    :class="buttonClass"
    :disabled="disabled || loading"
  >
    <div class="flex items-center gap-2 flex-nowrap">
      <Icon v-if="beforeIcon" :width="18" :height="18" :icon="beforeIcon" />
      <slot>
        <Icon v-if="loading" icon="lucide:loader" class="w-4 animate-spin" />

        {{ label }}
      </slot>
    </div>
  </component>
</template>

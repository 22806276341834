<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue"
import { useModalStore } from "../stores"
import { ButtonIcon, Icon } from "$/ui"

interface Props {
  id: string | number
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full"
  label?: string
  appendTo?: string
  loading?: boolean
  closable?: boolean
  overflow?: boolean
  closeOnEsc?: boolean
  closeOnBackdrop?: boolean
}

interface Emits {
  (e: "before-show", args?: any): void

  (e: "show", args?: any): void

  (e: "shown", args?: any): void

  (e: "before-hide"): void

  (e: "hide"): void

  (e: "hidden"): void

  (e: "close"): void
}

const props = withDefaults(defineProps<Props>(), {
  size: "sm",
  closable: true,
  overflow: true,
  closeOnEsc: true,
  closeOnBackdrop: true
})

const emits = defineEmits<Emits>()

const store = useModalStore()

const visible = ref<boolean>(false)
const backdrop = ref<HTMLElement>()
const args = ref<any>()

const modalClass = computed(() => ({
  modal: true,
  ["modal-" + props.size]: props.size
}))

const enableDocumentSettings = () => document.body.classList.add("!overflow-hidden")
const disableDocumentSettings = () => document.body.classList.remove("!overflow-hidden")

const onKeyDown = (event: KeyboardEvent) => {
  if (props.closable && props.closeOnEsc && event.code === "Escape") onClose()
}

const onBackdropClick = (event: Event) => {
  if (props.closable && props.closeOnBackdrop && backdrop.value === event.target) onClose()
}

const bindDocumentKeydownListener = () => window.document.addEventListener("keydown", onKeyDown)
const unbindDocumentKeydownListener = () => window.document.removeEventListener("keydown", onKeyDown)

const appendContainer = () => {
  if (props.appendTo && backdrop.value) {
    if (props.appendTo === "body") document.body.appendChild(backdrop.value)
    else document.getElementById(props.appendTo)?.appendChild(backdrop.value)
  }
}

const removeContainer = () => {
  if (props.appendTo && backdrop.value) {
    if (props.appendTo === "body") document.body.removeChild(backdrop.value)
    else document.getElementById(props.appendTo)?.removeChild(backdrop.value)
  }
}

const onBeforeEnter = () => {
  bindDocumentKeydownListener()
  appendContainer()
  emits("before-show", args.value)
}

const onEnter = () => emits("show", args.value)

const onAfterEnter = () => {
  enableDocumentSettings()
  emits("shown", args.value)
}

const onBeforeLeave = () => {
  disableDocumentSettings()
  emits("before-hide")
}

const onLeave = () => emits("hide")

const onAfterLeave = () => {
  unbindDocumentKeydownListener()
  removeContainer()
  emits("hidden")
  args.value = undefined
}

const show = (props?: unknown) => {
  args.value = props
  visible.value = true
}

const hide = () => (visible.value = false)

const onClose = () => {
  emits("close")
  hide()
}

onMounted(() => {
  if (props.id) store.setModal(props.id, { show, hide })
})

onBeforeUnmount(() => {
  disableDocumentSettings()
  if (props.id) store.removeModal(props.id)
})
</script>

<template>
  <transition name="modal-backdrop">
    <div v-show="visible" ref="backdrop" class="modal-backdrop" @click="onBackdropClick">
      <transition
        name="modal"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @after-enter="onAfterEnter"
        @before-leave="onBeforeLeave"
        @leave="onLeave"
        @after-leave="onAfterLeave"
      >
        <div v-if="visible" :class="modalClass">
          <div class="modal-content">
            <slot name="header" :hide="onClose">
              <div class="modal-header">
                <h3 class="modal-header-title">
                  {{ label }}
                </h3>

                <div class="flex items-center gap-1">
                  <slot name="refresh-icon" />
                  <slot name="header-action">
                    <ButtonIcon variant="ghost" color="danger" @click="onClose">
                      <Icon icon="lucide:x" :width="20" class="text-white" />
                    </ButtonIcon>
                  </slot>
                </div>

              </div>
            </slot>

            <div :class="loading ? 'overflow-hidden' : overflow ? 'relative overflow-y-auto h-full' : ''">
              <slot />

              <Spinner v-if="loading" />
            </div>

            <slot name="footer" :hide="onClose" />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { IdlingModel, IdlingParams } from "~/management/idling"

export const useIdlingStore = defineStore("idling", () => {
  const list = ref<IdlingModel[]>([])
  const idlingDetails = ref<IdlingModel>(new IdlingModel())
  const listLoading = ref<boolean>(false)
  const detailsLoading = ref<boolean>(false)
  const companyIds = ref<number[]>([])
  const queryDetails = ref<any>({
    page: 0,
    size: 20,
    total: 0,
    truck_id: null
  })
  const query = ref<any>({
    keyword: "",
    dateRange: [],
    truck_id: null,
    driver_id: null,
    page: 0,
    size: 20,
    total: 0
  })

  const transformQuery = (): IdlingParams => {
    const { dateRange, ...rest } = query.value
    const [from_date, to_date] = dateRange
      ? dateRange.map((date: any) => (date ? format(date, "YYYY-MM-DD") : undefined))
      : []

    return pickBy(
      {
        ...rest,
        from_date,
        to_date
      },
      identity
    )
  }

  return { companyIds, list, queryDetails, listLoading, query, idlingDetails, detailsLoading, transformQuery }
})

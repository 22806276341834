import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { TrucksModel } from "~/management/trucks"
import { IdlingParams } from "~/management/idling"

export const useTrucksStore = defineStore("trucks", () => {
  const list = ref<TrucksModel[]>([])
  const listLoading = ref<boolean>(false)
  const formLoading = ref<boolean>(false)
  const selectList = ref<any>([])
  const query = ref({
    keyword: "",
    page: 0,
    status: null,
    size: 10,
    total: 0,
    truck_id: null,
    dateRange: []
  })

  const transformQuery = (): IdlingParams => {
    const { dateRange, ...rest } = query.value
    const [from_date, to_date] = dateRange
      ? dateRange.map((date: any) => (date ? format(date, "YYYY-MM-DD") : undefined))
      : []

    return pickBy(
      {
        ...rest,
        from_date,
        to_date
      },
      identity
    )
  }

  return {
    selectList,
    list,
    listLoading,
    query,
    formLoading,
    transformQuery
  }
})

<template>
  <div class="w-full flex items-end mx-auto">
    <table class="w-full text-xs border-collapse">
      <thead>
        <tr class="text-green-700 font-semibold text-center">
          <th class="uppercase bg-transparent text-black text-left">
            {{ $t("thead.stop", { index: stop.stop_order + 1 }) }}
          </th>
          <th class="bg-transparent text-left">{{ $t("thead.location") }}</th>
          <th class="bg-transparent">{{ $t("thead.amount") }}</th>
          <th class="bg-transparent">{{ $t("thead.price") }}</th>
          <th class="bg-transparent">{{ $t("thead.date") }}</th>
        </tr>
      </thead>
      <tbody class="border-2 w-full text-center" :class="actualStyles.border">
        <tr>
          <td class="py-1">
            <div class="grid grid-cols-2 gap-6 p-1 w-24">
              <span class="font-semibold">#{{ stop.supplier_code }}</span>
              <Icon icon="lucide:fuel" width="20" />
            </div>
          </td>
          <td class="py-1">
            <div class="flex items-center">
              <span v-if="stop?.transaction_city">{{ `${stop?.transaction_city}, ${stop?.transaction_state}` }}</span>
              <ButtonIcon
                v-if="stop.transaction_latitude && stop.transaction_longitude"
                icon="lucide:external-link"
                :href="getGoogleMapsUrl({ latitude: stop.transaction_latitude, longitude: stop.transaction_longitude })"
                target="_blank"
                color="info"
                width="14"
                variant="ghost"
                size="xs"
              />
            </div>
          </td>
          <td class="py-1" :class="actualStyles.amount">{{ stop.quantity === 100 ? "FULL" : stop.quantity }}</td>
          <td class="py-1 font-semibold" :class="actualStyles.price">{{ stop.ppu?.toFixed(2) }}</td>
          <td v-if="stop.transaction_date" class="py-1">{{ toZone(stop.transaction_date, "MM/DD HH:mm CT") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"

import { getGoogleMapsUrl } from "$/utils/location"
import { useMatchColors } from "~/management/fuel/fuel.hooks"
import { toZone } from "$/utils/date"

import { SuggestionStop } from "~/management/fuel"

const props = defineProps<{
  stop: SuggestionStop
  index: number
}>()

const { actualStyles } = useMatchColors(props.stop)
</script>

// @ts-expect-error: TypeScript does not recognize this module
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js"

import { ShortCoordinate } from "$/models"
import { ref } from "vue"

export const useStationsStore = defineStore("stations", () => {
  const geocodedAddresses = ref<Array<ShortCoordinate>>([])
  const mapContainer = ref<HTMLElement | null>(null)
  const error = ref<string | null>(null)
  const globalPlatform = ref<H.service.Platform | null>(null)
  const globalMap = ref<null | H.Map>(null)
  const topStations = ref<boolean>(true)
  const isTopStation = ref<boolean>(true)
  const currentAddress = ref<string>("")

  return {
    currentAddress,
    isTopStation,
    geocodedAddresses,
    mapContainer,
    error,
    globalPlatform,
    globalMap,
    topStations
  }
})

<script setup lang="ts">
import { useRouteProtection } from "$/composables/useRouteProtection"
import TrucksList from "~/management/trucks/components/TrucksList.vue"

import { RolePermissionsModel } from "~/management/roles"

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_CARDS_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <TrucksList />
  </div>
</template>

<script setup lang="ts">
import { useFuelService, useFuelStore } from "~/management/fuel"
import { useManagementService, useManagementStore } from "~/management"
import { useUsersService, useUsersStore } from "~/management/users"

const { fetchTruckList } = useManagementService()
const { assignBookerToTruck } = useFuelService()
const { getUsersLazySelect } = useUsersService()
const storeFuel = useFuelStore()

const usersStore = useUsersStore()

const { successToast } = useAlert()
const { oldVal } = storeToRefs(storeFuel)
const { selectUsers } = storeToRefs(usersStore)
const management = useManagementStore()
const { truckList } = storeToRefs(management)
const { required } = useRule()
const { t } = useI18n()
const isLoading = ref<boolean>(false)
const form = ref<{ truck_id: any; booker: Nullable<number> }>({ truck_id: null, booker: null })

const rules = computed(() => ({
  truck_id: { required },
  booker: { required }
}))

const { vuelidate, hasError } = useValidate(form, rules)

const onSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  try {
    await assignBookerToTruck(form.value.truck_id!, form.value.booker!, isLoading)

    await nextTick(() => {
      onHide()
    })
    await successToast("Successfully assigned a booker to the trucks.")
  } catch (e) {
    console.log(e, "e")
  }
}

const onHide = () => {
  form.value = { truck_id: null, booker: null }
  vuelidate.value?.$reset()
  isLoading.value = false
  oldVal.value = ""
}
</script>

<template>
  <Sidebar id="assign" :label="$t('actions.assign')" @hide="onHide">
    <form class="space-y-2.5" @submit.prevent="onSubmit">
      <div class="box">
        {{ $t("labels.trucks") }}
        <FormGroup v-slot="{ id }" v-bind="hasError('truck_id')" required>
          <LazySelect
            :id="id"
            v-model="form.truck_id"
            mode="tags"
            class="tags-select min-h-[80px]"
            value-prop="id"
            multiple-content
            content-refreshable
            :hide-selected="false"
            :close-on-select="false"
            :options="truckList"
            :placeholder="t('placeholders.select_truck')"
            :fetch="fetchTruckList"
            :get-option-label="(item: any) => item.truck_id || ''"
          />
        </FormGroup>
        <!--        <div v-if="form.truck_id" class="flex text-xs items-center justify-start flex-nowrap gap-2">-->
        <!--          <p class="text-black dark:text-gray-300">{{ $t("labels.driver") }}:</p>-->
        <!--          <span class="text-primary-700 capitalize">{{ form.truck_id?.driver_dto?.name || "N/A" }}</span>-->
        <!--        </div>-->
      </div>

      <FormGroup v-slot="{ id }" v-bind="hasError('booker')" required>
        <LazySelect
          :id
          v-model="form.booker"
          value-prop="id"
          content-refreshable
          :options="selectUsers"
          :placeholder="t('placeholders.select_booker')"
          :fetch="getUsersLazySelect"
          :get-option-label="
            (item: any) =>
              item?.nickname + ' ' + fullName(item?.firstname, item?.lastname) + ' ' + `(${item?.truck_count})`
          "
        >
          <template #option="{ option }">
            <span class="w-4/5">
              {{ option?.nickname + " " + fullName(option?.firstname, option?.lastname) }}
            </span>
            <span class="absolute right-2"> ({{ option?.truck_count }}) </span>
          </template>
        </LazySelect>
      </FormGroup>

      <div class="flex justify-end">
        <Button
          type="submit"
          :label="$t('actions.submit')"
          :loading="isLoading"
          :disabled="vuelidate.$error || isLoading"
        />
      </div>
    </form>
  </Sidebar>
</template>

<style scoped>
.box {
  @apply mt-2 flex flex-col gap-2 rounded-sm border border-gray-200 p-2 dark:border-gray-800;
}
</style>

import * as L from "leaflet"
import * as d3 from "d3"
import * as topojson from "topojson-client"
import { Ref } from "vue"

import COUNTIES from "./assets/counties.json"
import { DashboardFuel } from "~/management/dashboard/dashboard.model"

export const loadMap = async (elId: string, options?: L.MapOptions) => {
  const USA_COORDINATES: L.LatLngExpression = [34.8259532, -101.4914187]
  const southWest = L.latLng(-85, -180)
  const northEast = L.latLng(85, 180)
  const map_bounds = L.latLngBounds(southWest, northEast)
  const openWeatherAppId = import.meta.env.APP_OPEN_WEATHER_APP_ID

  const weather_layer = ref<string>("pressure_new")

  const map = L.map(elId, {
    ...options,
    maxBounds: map_bounds,
    maxBoundsViscosity: 1.0,
    minZoom: 2
  }).setView(USA_COORDINATES, 3)
  L.tileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png"
  ).addTo(map)
  L.tileLayer(
    `https://tile.openweathermap.org/map/${weather_layer.value}/{z}/{x}/{y}.png?appid=${openWeatherAppId}`
  ).addTo(map)

  return map
}

interface Price {
  name: string
  price: Nullable<number>
}

export const loadGasPricesMap = async (
  map: HTMLDivElement,
  prices: DashboardFuel[],
  hoveredPrice: Ref<number | null>
): Promise<{ min: number; max: number }> => {
  const PRICES: Price[] = prices.map((price) => ({
    name: price.state,
    price: price.state_avg
  }))

  const minPrice: number = d3.min(PRICES, (d) => d.price) ?? 0
  const maxPrice: number = d3.max(PRICES, (d) => d.price) ?? 100

  const color = d3.scaleLinear<string>().domain([minPrice, maxPrice]).range(["#6AA84F", "#FF3B30"])

  const path = d3.geoPath()

  const namemap = new Map(
    COUNTIES.objects.states.geometries.map((geometry) => [geometry.properties.state, geometry.id])
  )

  const valuemap = new Map(PRICES.map((price) => [namemap.get(price.name), price.price]))

  const countires = topojson.feature(COUNTIES as any, COUNTIES.objects.states as any)
  const statemech = topojson.mesh(COUNTIES as any, COUNTIES.objects.states as any, (a, b) => a !== b)

  const svg = d3
    .create("svg")
    .attr("xmlns", "http://www.w3.org/2000/svg")
    .attr("viewBox", "0 0 975 610")
    .attr("class", "max-h-full w-[]")

  let tooltipPrice = ""
  let tooltipName = ""

  let tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any> = d3.select("#price-map-tooltip")
  if (tooltip.empty()) {
    tooltip = d3.select("body").append("div").attr("id", "price-map-tooltip").attr("class", "absolute hidden z-[2000]")
    tooltip.html(`
    <div class="bg-[#C0C0C0] border border-[#C0C0C0] rounded-sm min-w-[67px] relative font-medium dark:border-gray-800 dark:bg-gray-800">
      <div id="tooltip-price" class="text-center text-xl leading-6 py-px"></div>
      <div id="tooltip-name" class="bg-white text-center px-[13px] py-[5px] text-[13px] leading-[15px] dark:bg-gray-700"></div>
      <div class="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent border-t-[6px] border-t-[#C0C0C0] rounded-t-sm dark:border-t-gray-800"></div>
      <div class="absolute bottom-[-4px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[5px] border-l-transparent border-r-[5px] border-r-transparent border-t-[5px] border-t-white rounded-t-sm dark:border-t-gray-800"></div>
    </div>
  `)
  }

  const tooltipPriceElement = tooltip.select("#tooltip-price")
  const tooltipNameElement = tooltip.select("#tooltip-name")

  svg
    .append("g")
    .selectAll("path")
    .data((countires as unknown as GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>).features)
    .join("path")
    .attr("fill", (d) => {
      const id = d.id?.toString()
      const value = valuemap.get(id)
      return value ? color(value) : "#000000"
    })
    .attr("d", path)
    .on("mouseover touchstart", (event, d) => {
      const id = d.id?.toString()
      const value = valuemap.get(id)
      const name = d.properties?.state ?? "Unknown"

      hoveredPrice.value = value ?? 0
      tooltipPrice = value ? value.toString() : "N/A"
      tooltipName = name

      const fillColor = value ? (d3.color(color(value))?.brighter(1.03).toString() ?? color(value)) : "#000000"
      d3.select(event.currentTarget).attr("fill", fillColor)
      tooltipPriceElement.text(tooltipPrice)
      tooltipNameElement.text(tooltipName)
      tooltip.classed("hidden", false)
    })
    .on("mousemove touchmove", (event) => {
      const tooltipWidth = tooltip.node()!.offsetWidth
      tooltip
        .style("left", event.pageX - tooltipWidth / 2 + "px")
        .style("top", event.pageY - tooltip.node()!.offsetHeight - 10 + "px")
    })
    .on("mouseout touchend", (event, d) => {
      hoveredPrice.value = null
      d3.select(event.currentTarget).attr("fill", () => {
        const id = d.id?.toString()
        const value = id ? valuemap.get(id) : null
        return value ? color(value) : "#000000"
      })
      tooltip.classed("hidden", true)
    })

  svg
    .append("path")
    .datum(statemech)
    .attr("fill", "none")
    .attr("stroke", "white")
    .attr("stroke-linejoin", "round")
    .attr("d", path)

  while (map.firstChild) {
    map.removeChild(map.firstChild)
  }

  document.addEventListener("keyup", (evt) => {
    if (evt.code === "Escape") {
      tooltip.classed("hidden", true)
    }
  })
  map.appendChild(svg.node()!)

  return { min: minPrice, max: maxPrice }
}

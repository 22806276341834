import {
  PricesHistoryModelTable,
  PricesModelTable,
  StoresAssignModel,
  StoresListTable,
  StoresStatistics,
  PriceStoresStatistics
} from "~/management/priceStores"

export const usePriceStoresStore = defineStore("usePriceStoresStore", () => {
  const list = ref<StoresListTable[]>([])
  const listPriceTable = ref<PricesModelTable[]>([])
  const listPriceHistory = ref<PricesHistoryModelTable[]>([])
  const selectPrices = ref<any>([])
  const selectPriceHistory = ref<any>([])
  const storeStatistics = ref<StoresStatistics>(new StoresStatistics())
  const listLoading = ref<boolean>(false)
  const form = ref<StoresAssignModel>(new StoresAssignModel())
  const formLoading = ref<boolean>(false)
  const todayAveragePrice = ref<number>(0)

  const queryPriceHistory = ref<PriceStoresStatistics>(new PriceStoresStatistics())
  const queryPrice = ref<PriceStoresStatistics>(new PriceStoresStatistics())
  const queryStores = ref<PriceStoresStatistics>(new PriceStoresStatistics())

  return {
    todayAveragePrice,
    selectPriceHistory,
    selectPrices,
    list,
    listLoading,
    listPriceHistory,
    storeStatistics,
    queryStores,
    queryPrice,
    queryPriceHistory,
    form,
    listPriceTable,
    formLoading
  }
})

<script setup lang="ts">
import { Icon } from "@iconify/vue"

import { loadGasPricesMap } from "~/management/dashboard/dashboard.map"
import { DashboardFuel } from "~/management/dashboard"

const { mapPrices } = defineProps<{ mapPrices: DashboardFuel[] }>()

const map = ref<HTMLDivElement | null>(null)
const prices = ref({ min: 0, max: 0 })
const hoveredStatePrice = ref<number | null>(null)

const loadMap = () => {
  if (map.value) {
    loadGasPricesMap(map.value, mapPrices, hoveredStatePrice).then((res) => {
      prices.value = res
    })
  }
}

onMounted(() => loadMap())

watch(
  () => mapPrices,
  () => loadMap()
)

watch(hoveredStatePrice, (newPrice) => {
  if (newPrice !== null) {
    const minPrice = prices.value.min
    const maxPrice = prices.value.max
    const percentage = ((newPrice - minPrice) / (maxPrice - minPrice)) * 100
    const chevronIcons = Array.from(document.getElementsByClassName("price-chevron")) as SVGElement[]
    if (chevronIcons) {
      for (let icon of chevronIcons) {
        icon.style.left = `${percentage}%`
      }
    }
  }
})
</script>

<template>
  <div class="flex flex-col w-full items-center">
    <div ref="map" class="mt-5 w-full max-w-[800px]"></div>
    <div class="mt-6 flex items-center justify-between gap-2 w-full">
      <span>{{ prices.min }}</span>
      <div class="flex-1 bg-gradient-to-r from-[#6AA84F] to-[#FF3B30] h-3 relative">
        <Icon
          icon="lucide:chevron-down"
          :width="16"
          class="price-chevron absolute -top-2.5 left-0 transform -translate-x-1/2"
          :class="{ hidden: !hoveredStatePrice }"
        />
      </div>
      <span>{{ prices.max }}</span>
    </div>
  </div>
</template>

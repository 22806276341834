<script setup lang="ts">
import { PasswordChangeRequest, useSessionService, useSessionStore } from "~/session"
import { push } from "notivue"

const modal = useModal()
const { userId } = useSessionStore()
const { changeUserPassword } = useSessionService()
const { required, minLength, sameAs } = useRule()

const loading = ref(false)
const form = ref(new PasswordChangeRequest())

const rules = ref({
  oldPassword: { required, minLength: minLength(8) },
  newPassword: { required, minLength: minLength(8) },
  confirmPassword: {
    required,
    minLength: minLength(8),
    sameAs: sameAs(
      computed(() => form.value.newPassword),
      "new password"
    )
  }
})

const { vuelidate, hasError } = useValidate(form, rules)

const toggleModal = () => {
  modal.hide("profile-password")
  modal.show("profile")
}

const onHide = () => {
  loading.value = false
  form.value = new PasswordChangeRequest()
  vuelidate.value?.$reset()
}

const onSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  loading.value = true

  if (userId) {
    try {
      await changeUserPassword(Number(userId), form.value)
      push.success("Password has been changed successfully")
      toggleModal()
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }
}
</script>

<template>
  <Modal id="profile-password" :label="$t('actions.change_password')" size="xs" :closable="false" @hide="onHide">
    <div class="grid gap-4 p-4">
      <FormGroup v-slot="{ id }" v-bind="hasError('oldPassword')" required :label="$t('labels.old_password')">
        <InputPassword :id="id" v-model="form.oldPassword" :placeholder="$t('placeholders.enter_password')" />
      </FormGroup>

      <FormGroup v-slot="{ id }" v-bind="hasError('newPassword')" required :label="$t('labels.new_password')">
        <InputPassword :id="id" v-model="form.newPassword" :placeholder="$t('placeholders.enter_new_password')" />
      </FormGroup>

      <FormGroup v-slot="{ id }" v-bind="hasError('confirmPassword')" required :label="$t('labels.confirm_password')">
        <InputPassword :id="id" v-model="form.confirmPassword" :placeholder="$t('placeholders.confirm_new_password')" />
      </FormGroup>
    </div>

    <template #footer>
      <div class="modal-footer">
        <Button color="danger" :label="$t('actions.cancel')" @click="toggleModal" />
        <Button :label="$t('actions.save')" :loading @click="onSubmit" />
      </div>
    </template>
  </Modal>
</template>

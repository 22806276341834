<script setup lang="ts">
import { Icon } from "@iconify/vue"

import { ButtonIcon } from "$/ui"
import { RolePermissionsModel, useRolesService } from "~/management/roles"

import { useSessionStore } from "~/session"

const sessionStorage = useSessionStore()
const { profile } = storeToRefs(sessionStorage)

interface IProps {
  id: number
  isActive: boolean
}

const { id } = defineProps<IProps>()
const { show } = useModal()
const { activateRole, deactivateRole } = useRolesService()

const showModal = () => show("roles", id)
</script>

<template>
  <div class="-my-2 flex items-center justify-center gap-3" @click.stop>
    <Button
      v-if="isActive && profile.authorities.has(RolePermissionsModel.CAN_ROLE_DEACTIVATE)"
      variant="ghost"
      color="info"
      @click="deactivateRole(id)"
    >
      <span> {{ $t("actions.deactivate") }} </span>
    </Button>
    <Button
      v-else-if="profile.authorities.has(RolePermissionsModel.CAN_ROLE_ACTIVATE)"
      variant="ghost"
      color="info"
      @click="activateRole(id)"
    >
      <span> {{ $t("actions.activate") }} </span>
    </Button>

    <ButtonIcon pilled size="sm" variant="ghost" color="secondary" :label="$t('actions.edit')" @click="showModal">
      <Icon icon="lucide:pencil" :width="18" />
    </ButtonIcon>
  </div>
</template>

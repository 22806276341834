import get from "lodash/get"
import type { ValidationArgs } from "@vuelidate/core"
import { useVuelidate } from "@vuelidate/core"
import type { Ref } from "vue"

type Form<T> = { [key in keyof T]: any }

export function useValidate<T extends Form<V>, V extends ValidationArgs>(form: Ref<T>, rules: Ref<V>) {
  const vuelidate = useVuelidate(rules, form)

  const hasError = (key: Paths<T>) => {
    const value = get(vuelidate.value, key)
    const invalid = value?.$error
    const hint = value?.$errors[0]?.$message
    return { invalid, hint }
  }

  return { vuelidate, hasError }
}

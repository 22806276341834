<script lang="ts" setup>
import { EfsAssignModel, useSystemSettingsService, useSystemSettingsStore } from "~/management/systemSettings"

const modal = useModal()
const { getSystemSettingsList, createEfsSystemSetting, getEfsSingleSystemSetting, editEfsSystemSetting } =
  useSystemSettingsService()

const { t } = useI18n()
const { required } = useRule()

const systemSettingsStore = useSystemSettingsStore()

const { formEfs, formLoading } = storeToRefs(systemSettingsStore)

const rules = ref({
  account_name: { required },
  username: { required },
  password: { required }
})
const settingsEdit = ref<number | null>(null)

const { vuelidate, hasError } = useValidate(formEfs, rules)

const label = computed<string>(() =>
  settingsEdit.value ? t("actions.edit_efs_settings") : t("actions.add_efs_settings")
)

const handleSubmit = async () => {
  const isValid = await vuelidate.value?.$validate()
  if (!isValid) return
  formLoading.value = true
  try {
    if (settingsEdit.value) {
      const res = await editEfsSystemSetting(formEfs.value)
      if (res?.result?.code === "ok") {
        await getSystemSettingsList()
      }
    } else {
      const res = await createEfsSystemSetting(formEfs.value)
      if (res?.result?.code === "ok") {
        await getSystemSettingsList()
      }
    }
    await nextTick(() => {
      onHide()
    })
  } finally {
    formLoading.value = false
  }
}

const onBeforeShow = async (id: number) => {
  if (!id) return
  formLoading.value = true
  settingsEdit.value = id
  getEfsSingleSystemSetting(id).finally(() => {
    formLoading.value = false
  })
}

const onHide = () => {
  settingsEdit.value = null
  formEfs.value = new EfsAssignModel()
  formLoading.value = false
  modal.hide("efs-settings-form")
  vuelidate.value?.$reset()
}
</script>

<template>
  <Modal
    id="efs-settings-form"
    :close-on-backdrop="false"
    :label
    :loading="formLoading"
    @hide="onHide"
    @before-show="onBeforeShow"
  >
    <form class="space-y-4 p-4">
      <FormGroup v-slot="{ id }" :label="$t('labels.account_name')" required v-bind="hasError('account_name')">
        <Input :id v-model="formEfs.account_name" :placeholder="t('placeholders.account_name')" />
      </FormGroup>
      <FormGroup :label="$t('labels.username')" required v-bind="hasError('username')">
        <Input v-model="formEfs.username" :placeholder="t('placeholders.username')" />
      </FormGroup>
      <FormGroup :label="$t('labels.password')" required v-bind="hasError('password')">
        <Input v-model="formEfs.password" :placeholder="t('placeholders.password')" />
      </FormGroup>
    </form>
    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button :label="$t('actions.cancel')" color="danger" @click="hide" />
        <Button
          :disabled="vuelidate.$error || formLoading"
          :label="$t('actions.submit')"
          :loading="formLoading"
          @click="handleSubmit"
        />
      </div>
    </template>
  </Modal>
</template>

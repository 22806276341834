<script setup lang="ts">
import { CardEditModel, useCardService, useCardStore } from "~/management/card"
import { ButtonIcon, Icon } from "$/ui"
import { useSessionStore } from "~/session"
import { RolePermissionsModel } from "~/management/roles"

const { fetchCard, editAssignCard, fetchCardReload, fetchCardsList } = useCardService()
const cardStore = useCardStore()
const { formEdit, formLoading } = storeToRefs(cardStore)
const sessionStore = useSessionStore()

const { profile } = storeToRefs(sessionStore)
const refreshLoading = ref(false)
const modal = useModal()
const card = ref<{ number: string; id: Nullable<number> }>({
  number: "",
  id: null
})

const onSave = async () => {
  try {
    formLoading.value = true
    if (!profile.value.authorities.has(RolePermissionsModel.CAN_EFS_CARD_SET_AVAILABLE_LIMIT)) {
      formEdit.value.limits = formEdit.value.limits.map((item: any) => ({
        ...item,
        available: item.dailyMax
      }))
    }
    await editAssignCard(card.value.id!, formEdit.value)
    modal.hide("card-edit")
    onHide()
  } finally {
    formLoading.value = false
  }
}

const onBeforeShow = ({ id, number }: { id: number; number: string }) => {
  fetchCard(id)
  card.value = { id, number }
  if (card.value?.id) {
    handleRefresh()
  }
}

const onHide = () => {
  card.value = {
    number: "",
    id: null
  }
  formEdit.value = new CardEditModel()
  formLoading.value = false
  modal.hide("card-edit")
  fetchCardsList()
}

const handleRefresh = async () => {
  refreshLoading.value = true
  fetchCardReload(card.value.id!).finally(() => (refreshLoading.value = false))
}

const calcMinValue = (item: any) => {
  if (item?.usedAmount) {
    return item.usedAmount + 1
  } else {
    return 1
  }
}

const calcMinAvailableValue = (item: any) => {
  if (item?.usedAmount) {
    return item.usedAmount + 1
  } else {
    return 1
  }
}
</script>

<template>
  <Modal
    id="card-edit"
    :label="$t('actions.edit_card', { card: card.number })"
    :loading="formLoading"
    :close-on-backdrop="false"
    @before-show="onBeforeShow"
    @hide="onHide"
  >
    <template #refresh-icon>
      <span class="font-medium"> EFS reload: </span>
      <ButtonIcon variant="ghost" color="danger" :loading="refreshLoading" @click="handleRefresh">
        <Icon icon="lucide:refresh-ccw" :width="18" class="text-white" />
      </ButtonIcon>
    </template>
    <form v-if="formEdit?.limits?.length" class="grid gap-4 p-4" @submit.prevent="onSave">
      <div v-for="(item, index) in formEdit.limits" :key="index">
        <FormGroup v-slot="{ id }" :label="$t(`${item.limitId}`)">
          <div class="relative flex gap-3">
            <span class="absolute bottom-10 text-sm">Daily Max</span>
            <Input
              :id
              v-model="item.dailyMax"
              type="number"
              class="mt-4"
              :placeholder="$t('placeholders.set_limit')"
              :min="
                profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_SET_AVAILABLE_LIMIT) ? 1 : calcMinValue(item)
              "
              max="500"
              required
            />
            <span class="absolute bottom-10 right-[138px] text-sm">Available</span>
            <Input
              :id
              v-model="item.available"
              type="number"
              class="mt-4"
              :disabled="!profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_SET_AVAILABLE_LIMIT)"
              :placeholder="$t('placeholders.set_limit')"
              :min="
                profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_SET_AVAILABLE_LIMIT)
                  ? calcMinAvailableValue(item)
                  : ''
              "
              max="500"
              required
            />
          </div>
        </FormGroup>
      </div>
      <div class="modal-footer !pb-0">
        <Button color="danger" :label="$t('actions.cancel')" @click.prevent="onHide" />
        <!-- TODO: enable button on production -->
        <Button type="submit" :label="$t('actions.submit')" />
      </div>
    </form>
    <div v-else class="p-3 text-center">No Limits</div>
  </Modal>
</template>

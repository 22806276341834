<script setup lang="ts">
import { Icon } from "@iconify/vue"

import { ButtonIcon } from "$/ui"
import { useUsersService } from "~/management/users"
import { useSessionStore } from "~/session"
import { RolePermissionsModel } from "~/management/roles"

interface IProps {
  id: number
  isActive: boolean
}

const { id } = defineProps<IProps>()
const { show } = useModal()
const { activateUser, deactivateUser } = useUsersService()

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const showModal = () => show("users", id)
</script>

<template>
  <div class="flex items-center justify-center gap-3 -my-2" @click.stop>
    <Button
      v-if="isActive && profile.authorities.has(RolePermissionsModel.CAN_USER_DEACTIVATE)"
      variant="ghost"
      color="info"
      @click="deactivateUser(id)"
    >
      <span> {{ $t("actions.deactivate") }} </span>
    </Button>
    <Button
      v-else-if="profile.authorities.has(RolePermissionsModel.CAN_USER_ACTIVATE)"
      variant="ghost"
      color="info"
      @click="activateUser(id)"
    >
      <span> {{ $t("actions.activate") }} </span>
    </Button>

    <ButtonIcon
      v-if="profile.authorities.has(RolePermissionsModel.CAN_USER_EDIT)"
      pilled
      size="sm"
      variant="ghost"
      color="secondary"
      :label="$t('actions.edit')"
      @click="showModal"
    >
      <Icon icon="lucide:pencil" :width="18" />
    </ButtonIcon>
  </div>
</template>

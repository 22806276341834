<script setup lang="ts">
import { Icon } from "$/ui"

defineEmits(["enter", "clear"])
const uid = crypto.randomUUID()

defineProps<{ value: any }>()
</script>

<template>
  <div class="input-group relative">
    <!--    <label class="after" :for="uid">-->
    <!--      <Icon class="w-10 text-gray-400" icon="lucide:search" :width="20" />-->
    <!--    </label>-->

    <Input
      v-bind="$attrs"
      :id="uid"
      :placeholder="$t('actions.search')"
      class="text-xs !pr-8"
      @keydown.enter="$emit('enter')"
    />
    <Button v-if="value" variant="ghost" class="absolute right-0 top-2 !pr-2" color="red" @click="$emit('clear')">
      <Icon icon="lucide:x" :width="18" />
    </Button>
  </div>
</template>

<script setup lang="ts">
import { ButtonIcon, DatePicker, Icon } from "$/ui"

import { DashboardBooker, useDashboardBookerStats, useDashboardStore } from "~/management/dashboard"
import { useUsersService, useUsersStore } from "~/management/users"

const { t } = useI18n()

const intersectionTarget = ref<HTMLDivElement>()
const dashboardStore = useDashboardStore()
const { bookerStats, bookerDate, query, bookerId, bookerLoading } = storeToRefs(dashboardStore)

const { getUsersLazySelect } = useUsersService()
const { fetchBookerStats } = useDashboardBookerStats()

const usersStore = useUsersStore()
const { selectUsers } = storeToRefs(usersStore)

const cols = computed<ITableCol<DashboardBooker>[]>(() => [
  {
    name: "idx",
    label: t("thead.no")
  },
  {
    name: "nickname",
    label: t("thead.booker")
  },
  {
    name: "total_stops_count",
    label: t("thead.books"),
    sortField: "books",
    headClass: "w-[50px]",
    dataClass: "text-center"
  },
  {
    name: "matched",
    label: t("thead.matched"),
    sortField: "matched",
    headClass: "w-[50px] ",
    dataClass: "text-center"
  },
  {
    name: "unmatched",
    sortField: "unmatched",
    label: t("thead.unmatched"),
    headClass: "w-[50px] ",
    dataClass: "text-center"
  },
  {
    name: "canceled",
    sortField: "canceled",
    label: t("thead.canceled"),
    headClass: "w-[50px]",
    dataClass: "text-center"
  }
])

const { pause, resume } = useIntersectionObserver(intersectionTarget, async ([entry]) => {
  if (entry.isIntersecting) {
    if (!bookerStats.value.length || query.value.total - 1 === query.value.page) {
      pause()
      return
    }
    query.value.page = query.value.page + 1
    await fetchBookerStats()
  }
})

const gettingTarget = (val: any) => {
  intersectionTarget.value = val
}

watch(bookerLoading, (newLoading) => {
  if (newLoading) return pause()
  resume()
})
const refreshStats = () => {
  query.value.page = 0
  bookerStats.value = []
  fetchBookerStats()
}

const sortingBooking = (val: any, order: string) => {
  bookerStats.value = []
  if (order === "null") {
    query.value.sort_name = ""
    query.value.sort_dir = ""
    fetchBookerStats()
    return
  }
  if (val === "books") {
    query.value.sort_dir = order
    query.value.sort_name = val
    query.value.page = 0
    fetchBookerStats()
  } else if (val === "matched") {
    query.value.sort_dir = order
    query.value.sort_name = val
    query.value.page = 0
    fetchBookerStats()
  } else if (val === "unmatched") {
    query.value.sort_dir = order
    query.value.sort_name = val
    query.value.page = 0
    fetchBookerStats()
  } else if (val === "canceled") {
    query.value.sort_dir = order
    query.value.sort_name = val
    query.value.page = 0
    fetchBookerStats()
  }
}

onMounted(() => refreshStats())

onUnmounted(() => {
  bookerId.value = null
  bookerDate.value = new Date()
})
</script>

<template>
  <div class="border border-gray-200 bg-white px-4 py-2 dark:border-gray-700 dark:bg-gray-900">
    <div class="flex h-full w-full flex-col text-xs">
      <div
        class="flex flex-nowrap items-center justify-between gap-2 border-b border-gray-200 pb-1 dark:border-gray-700"
      >
        <h6 class="whitespace-nowrap text-xs">
          {{ $t("labels.booker_stats") }}
        </h6>

        <div class="flex flex-nowrap items-center gap-4">
          <LazySelect
            v-model="bookerId"
            value-prop="id"
            append-to-body
            content-refreshable
            :options="selectUsers"
            :placeholder="t('placeholders.select_booker')"
            :fetch="getUsersLazySelect"
            :get-option-label="
              (item: any) =>
                item?.nickname + ' ' + fullName(item?.firstname, item?.lastname) + ' ' + `(${item?.truck_count})`
            "
            @update:model-value="refreshStats"
          >
            <template #option="{ option }">
              <span class="w-4/5">
                {{ option?.nickname + " " + fullName(option?.firstname, option?.lastname) }}
              </span>
              <span class="absolute right-2"> ({{ option?.truck_count }}) </span>
            </template>
          </LazySelect>
          <DatePicker
            v-model="bookerDate"
            class="min-w-20"
            format="MM/dd/yyyy"
            :dark="isDark"
            :enable-time-picker="false"
            :clearable="false"
            :max-date="new Date()"
            @update:model-value="refreshStats"
          />
          <ButtonIcon
            variant="ghost"
            color="success"
            icon="lucide:refresh-ccw"
            width="20"
            :loading="bookerLoading"
            :disabled="bookerLoading"
            @click="refreshStats"
          >
            <Icon icon="lucide:refresh-ccw" :width="20" />
          </ButtonIcon>
        </div>
      </div>
      <Table
        no-wrap
        :loading="bookerLoading"
        :skeleton="bookerLoading"
        :cols="cols"
        :rows="bookerStats"
        class="booker-stats"
        head-class="bg-slate-200 dark:bg-gray-800"
        max-height="300px"
        @intersection-target="gettingTarget"
        @sorting="sortingBooking"
      >
        <template #idx="{ idx }">
          {{ idx !== bookerStats.length - 1 ? idx + 1 : "" }}
        </template>

        <template #total_stops_count="{ row }">
          {{ row.total_stops_count || row.books }}
        </template>
      </Table>
      <div ref="intersectionTarget" class="h-px"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ButtonIcon, Icon } from "$/ui"
import { RoleModel, RolePermissionsModel, useRolesService, useRolesStore } from "~/management/roles"
import RolesMenu from "~/management/roles/components/RolesMenu.vue"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"
import { useSessionStore } from "~/session"

const modal = useModal()
const { t } = useI18n()
const { getRolesListTable } = useRolesService()
const usersStore = useRolesStore()
const sessionStore = useSessionStore()

const { profile } = storeToRefs(sessionStore)
const { list, listLoading, query, pagination } = storeToRefs(usersStore)

const cols = computed<ITableCol<RoleModel>[]>(() => {
  const baseCols = [
    {
      name: "idx",
      label: t("thead.no")
    },
    {
      name: "name",
      label: t("thead.name")
    },
    {
      name: "description",
      label: t("thead.description")
    },
    {
      name: "created",
      label: t("thead.date_created"),
      formatter: (value: string) => (value ? toZone(value, "MM/DD/YYYY HH:mm CT") : "")
    },
    {
      name: "enabled",
      label: t("thead.status")
    }
  ]

  if (profile.value.authorities.has(RolePermissionsModel.CAN_ROLE_EDIT)) {
    baseCols.push({
      name: "actions",
      label: t("thead.actions"),
      labelClass: "justify-center"
    })
  }

  return baseCols
})

onUnmounted(() => (query.value = { page: 0, size: 10 }))
onMounted(() => getRolesListTable())
</script>

<template>
  <TableHeader>
    <template #left>
      <div />
    </template>
    <div class="flex items-center justify-end gap-5">
      <HeaderAmountItem :amount="pagination.totalElements" :description="$t('labels.total_roles')" />
      <Button v-if="profile.authorities.has(RolePermissionsModel.CAN_ROLE_CREATE)" @click="modal.show('roles')">
        {{ $t("actions.new_role") }}
      </Button>
      <ButtonIcon variant="ghost" color="success" :loading="false" @click="() => getRolesListTable()">
        <Icon icon="lucide:refresh-ccw" :width="20" />
      </ButtonIcon>
    </div>
  </TableHeader>

  <Table
    no-wrap
    :skeleton="listLoading"
    :loading="listLoading"
    :cols="cols"
    :rows="list"
    class="mt-4"
    head-class="bg-slate-200 dark:bg-gray-800"
  >
    <template #idx="{ idx, sequence }">
      {{ sequence(idx, query.page || 0, query.size || 10) }}
    </template>

    <template #enabled="{ row }">
      <span :class="row.enabled ? 'text-green-700' : 'text-red-500'">
        {{ row.enabled ? $t("labels.active") : $t("labels.inactive") }}
      </span>
    </template>

    <template #actions="{ row }">
      <RolesMenu :id="row.id" :is-active="row.enabled" />
    </template>
  </Table>

  <TableFooter
    v-model:page="query.page"
    v-model:per-page="query.size"
    :total="pagination.totalElements"
    @change="getRolesListTable"
  />
</template>

import { Profile, Role } from "~/session"

export const useSessionStore = defineStore("session", () => {
  const isLoading = ref<boolean>(false)
  const loggedIn = computed(() => !!appToken.value)

  const appToken = useLocalStorage("app_token", "", { writeDefaults: false })
  const userId = useLocalStorage("user_id", "", { writeDefaults: false })
  const userFullName = useLocalStorage("user_full_name", "", { writeDefaults: false })
  const profile = ref<Profile>(new Profile())
  const isAdmin = computed(() => profile.value.role_name === Role.SUPER_ADMIN)
  const username = computed(() => {
    return fullName(profile.value.firstname || "", profile.value.lastname || "")
  })

  return {
    isLoading,
    appToken,
    loggedIn,
    profile,
    userFullName,
    username,
    isAdmin,
    userId
  }
})

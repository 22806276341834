import type { AxiosError, AxiosInstance, AxiosResponse, CreateAxiosDefaults } from "axios"
import axios from "axios"
import { push } from "notivue"
import { i18n } from "./i18n"

import type { App } from "vue"

type Axios = {
  instance: AxiosInstance
  install: (app: App) => void
}

const extra = (axios: Record<string, any>) => {
  const axiosExtra: Record<string, any> = {}
  const methods = ["request", "delete", "get", "head", "options", "post", "patch", "put"]

  for (const method of methods) {
    axiosExtra["$" + method] = function (...args: any[]) {
      return this[method](...args).then((res: AxiosResponse) => res && res.data)
    }
  }
  for (const key in axiosExtra) axios[key] = axiosExtra[key].bind(axios)
}

export const createAxios = (options?: CreateAxiosDefaults) => {
  options = options || {
    baseURL: "/api",
    withCredentials: true,
    headers: {
      accept: "*/*",
      contentType: "application/json"
    }
  }

  const instance = axios.create(options)
  extra(instance)

  const plugin: Axios = {
    instance,
    install(app: App) {
      app.provide<AxiosInstance>("$axios", instance)
    }
  }

  return plugin
}

export const http = createAxios()

http.instance.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {}
    config.headers["Accept-Language"] = i18n.global.locale.value

    const token = localStorage.getItem("app_token")
    if (token) config.headers["Authorization"] = `Bearer ${token}`

    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

interface ErrorResponseData {
  result?: { code?: string }
  content?: string
}

http.instance.interceptors.response.use(
  (response) => {
    const code: string = response?.data?.result?.code

    if (!!code && code?.toLowerCase() !== "ok") {
      push.error(i18n.global.t(`messages.error.${code}`))
      return Promise.reject(response)
    }

    return Promise.resolve(response)
  },
  (error: AxiosError) => {
    const message: string = (<any>error?.response?.data)?.content || error.message
    const code: Record<number, string> = {
      401: i18n.global.t("messages.error.unauthorized"),
      403: i18n.global.t("messages.error.forbidden"),
      503: i18n.global.t("messages.error.deploying_backend")
    }
    if (message) push.error(i18n.global.t(`${message}`))
    else if (error.response?.status) push.error(code[error.response.status])

    return Promise.reject(error.response)
  }
)

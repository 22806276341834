import type { AxiosInstance } from "axios"

import { DashboardBooker, DashboardMPG, DashboardFuel } from "~/management/dashboard/dashboard.model"
import { BookingTotalsStats } from "~/management/bookers"

export const useDashboardApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()

  const suggestionBase = "/api/v1/suggestion"
  const baseFuel: Readonly<string> = "/api/v1/fuel"

  return {
    GET_MPG_STATS(
      params: { direction?: string; year?: number | null; month?: number | null } & Omit<PaginationParams, "keyword">
    ): ResponseContainer<DashboardMPG[]> {
      return axios.$get(`/api/v1/mpg/stats`, { params })
    },
    GET_BOOKER_STATS(
      params: { inputDate?: string; bookerId?: number } & Omit<PaginationParams, "keyword">
    ): ResponseContainer<DashboardBooker[]> {
      return axios.$get(`${suggestionBase}/bookings/stats`, { params })
    },
    GET_FUEL_DASHBOARD(from_date: string, to_date: string): ResponseContainer<DashboardFuel[]> {
      return axios.$get(`${baseFuel}/dashboard`, { params: { from_date, to_date } })
    },
    GET_BOOKING_TOTAL_COUNT(params: any): ResponseContainer<BookingTotalsStats> {
      return axios.$get(`${suggestionBase}/total/bookings/stats`, { params: params })
    }
  }
}

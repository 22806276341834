<script setup lang="ts">
import { FuelBookList, FuelDetailsModal } from "~/management/fuel"
import { RolePermissionsModel } from "~/management/roles"
import { useRouteProtection } from "$/composables/useRouteProtection"

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_FUEL_BOOK_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <FuelBookList />
    <FuelDetailsModal />
  </div>
</template>

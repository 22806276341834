<script lang="ts" setup>
import { ButtonIcon, Icon } from "$/ui"

import FuelListHeader from "./FuelBookListHeader.vue"
import FuelAssignSidebar from "./sidebars/FuelAssignSidebar.vue"
import FuelAssignFCSidebar from "~/management/fuel/components/sidebars/FuelAssignFCSidebar.vue"
import FuelDeactivationSidebar from "~/management/fuel/components/sidebars/FuelDeactivationSidebar.vue"
import FuelBookSidebar from "~/management/fuel/components/sidebars/FuelBookSidebar.vue"
import StationsMap from "~/map/stations/components/StationsMap.vue"
import FuelTankDetails from "~/management/fuel/components/sidebars/FuelTankDetail.vue"
import { useCardStore } from "~/management/card"
import { useTransactionsStore } from "~/management/transaction"

import { FuelModel, useFuelApi, useFuelService, useFuelStore } from "~/management/fuel"
import { ICoordinate } from "$/models"

import { useSessionStore } from "~/session"
import { RolePermissionsModel } from "~/management/roles"
import { CARD_STATUS } from "~/management/card/contants"
import { getGoogleMapsUrl } from "$/utils/location"

const { t } = useI18n()
const { GET_COUNT_SUGGESTIONS } = useFuelApi()
const { getFuelSuggestions } = useFuelService()

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)
const router = useRouter()

const modal = useModal()
const transactionStore = useTransactionsStore()
const fuelStore = useFuelStore()
const cardStore = useCardStore()
const { query: queryTransaction } = storeToRefs(transactionStore)
const { query: queryCard } = storeToRefs(cardStore)
const { sortMiles, list, listLoading, query, isVisibleMap, pagination } = storeToRefs(fuelStore)

const mouseDownColIndex = ref<any>(0)
const hoveredRowIndex = ref<any>(0)
const hoveredColIndex = ref<any>(0)
const rowClicked = ref<number>()
const currentLocation = ref<ICoordinate | null>(null)
const directionalAngle = ref<number>(0)

const cols = computed<ITableCol<FuelModel>[]>(() => {
  const baseCols = [
    {
      name: "idx",
      label: t("thead.no"),
      width: "50px",
      dataClass: mouseDownColIndex.value < 1 && hoveredColIndex.value >= 0 ? "select-text" : ""
    },
    {
      name: "truck",
      label: t("thead.truck"),
      dataClass: mouseDownColIndex.value < 2 && hoveredColIndex.value > 0 ? "select-text" : ""
    },
    {
      name: "driver",
      label: t("thead.driver"),
      dataClass: {
        "select-text": mouseDownColIndex.value < 3 && hoveredColIndex.value > 1,
        "w-[160px]   whitespace-normal": true
      }
    },
    {
      name: "driver_phone",
      label: t("thead.driver_phone"),
      dataClass: mouseDownColIndex.value < 4 && hoveredColIndex.value > 2 ? "select-text" : ""
    },
    {
      name: "card_number",
      label: t("thead.fuel_card"),
      formatter: (value: string) => formatCardNumber(value),
      dataClass: mouseDownColIndex.value < 5 && hoveredColIndex.value > 3 ? "select-text" : ""
    },
    {
      name: "tank",
      label: t("thead.tank"),
      dataClass: mouseDownColIndex.value < 6 && hoveredColIndex.value > 4 ? "select-text" : ""
    },
    {
      name: "miles",
      label: t("thead.miles"),
      sortField: "true",
      labelClass: "border-l border-gray-400 pl-4",
      dataClass: mouseDownColIndex.value < 7 && hoveredColIndex.value > 5 ? "select-text" : ""
    },
    {
      name: "mpg",
      label: t("thead.mpg1"),
      dataClass: mouseDownColIndex.value < 8 && hoveredColIndex.value > 6 ? "select-text" : ""
    },
    {
      name: "samsara_mpg",
      label: t("thead.mpg2"),
      dataClass: mouseDownColIndex.value < 9 && hoveredColIndex.value > 7 ? "select-text" : ""
    },
    {
      name: "fuel_level",
      label: t("thead.fuel_level"),
      dataClass: mouseDownColIndex.value < 10 && hoveredColIndex.value > 8 ? "select-text" : ""
    },
    {
      name: "distance",
      label: t("thead.distance"),
      labelClass: "border-l border-gray-400 pl-4",
      dataClass: mouseDownColIndex.value < 11 && hoveredColIndex.value > 9 ? "select-text" : ""
    },
    {
      name: "booker_name",
      label: t("thead.booker"),
      dataClass: mouseDownColIndex.value < 12 && hoveredColIndex.value > 10 ? "select-text" : "",
      formatter: (value: string) => capitalizeFirstLetter(value)
    },
    {
      name: "datetime",
      label: t("thead.date_time"),
      dataClass: mouseDownColIndex.value < 13 && hoveredColIndex.value > 11 ? "select-text" : "",

      formatter: (value: string) => (value ? toZone(value, "MM/DD HH:mm CT") : "")
    },
    {
      name: "city_st",
      label: t("thead.city_st"),
      dataClass: mouseDownColIndex.value < 14 && hoveredColIndex.value > 12 ? "select-text" : ""
    },
    {
      name: "stops",
      label: t("thead.stops"),
      dataClass: mouseDownColIndex.value < 15 && hoveredColIndex.value > 13 ? "select-text" : ""
    },
    {
      name: "amt",
      label: t("thead.amt"),
      dataClass: mouseDownColIndex.value < 16 && hoveredColIndex.value > 14 ? "select-text" : "",
      formatter: (value: number | string) => (value === "Full" ? "FULL" : value ? Number(value) : null)
    },
    {
      name: "price",
      label: t("thead.price"),
      dataClass: mouseDownColIndex.value < 17 && hoveredColIndex.value > 15 ? "select-text" : "",
      formatter: (value: number) => (value ? "$" + value.toFixed(2) : null)
    },
    {
      name: "store_number",
      label: t("thead.store"),
      dataClass: mouseDownColIndex.value < 18 && hoveredColIndex.value > 16 ? "select-text" : ""
    },
    {
      name: "transaction_store_number",
      label: t("thead.store"),
      labelClass: "border-l border-gray-400 pl-4",
      dataClass: [
        mouseDownColIndex.value < 19 && hoveredColIndex.value > 17 ? "select-text " : "",
        "bg-white dark:text-black"
      ]
    },
    {
      name: "transaction_price",
      label: t("thead.price"),
      formatter: (value: number) => (value ? value.toFixed(2) : null),
      dataClass: [
        mouseDownColIndex.value < 21 && hoveredColIndex.value > 19 ? "select-text " : "",
        "bg-white dark:text-black"
      ]
    },
    {
      name: "transaction_amt",
      label: t("thead.amt"),
      formatter: (value: number) => (value ? value.toFixed(2) : null),
      dataClass: [
        mouseDownColIndex.value < 22 && hoveredColIndex.value > 20 ? "select-text " : "",
        "bg-white dark:text-black"
      ]
    },
    {
      name: "transaction_datetime",
      label: t("thead.date_time"),
      formatter: (value: string) => (value ? toZone(value, "MM/DD HH:mm CT") : ""),
      dataClass: [
        mouseDownColIndex.value < 23 && hoveredColIndex.value > 21 ? "select-text " : "",
        "bg-white dark:text-black"
      ]
    }
  ]

  if (profile.value.authorities.has(RolePermissionsModel.CAN_SUGGESTION_GET_ONE)) {
    baseCols.push({
      name: "actions",
      label: t("actions.edit"),
      labelClass: "justify-center ",
      headClass: "sticky right-0 bg-slate-200 dark:bg-gray-800",
      dataClass: "bg-slate-200 dark:bg-gray-800 sticky right-0"
    })
  }

  return baseCols
})

const columnColor = (status: string) => {
  if (CARD_STATUS.INACTIVE === status) return "text-red-500"
  if (CARD_STATUS.FRAUD === status || CARD_STATUS.HOLD === status) return "text-yellow-500"
}

// NOTE: on_vacation may change on backend
const groupBy = (row: FuelModel) => {
  if (row?.on_vacation) return "Vacation"
  if (!row.nickname) return "Unassigned"
  return row.nickname
}

const groupDrivers = ["Casper", "Delroy", "Dominic", "Jackson", "Owen", "Simon"]
const groupOrder = ["Vacation", "Unassigned"]

const getGroupClass = (groupKey: string) => {
  const groupKeyTrim = groupKey.trim()
  switch (groupKeyTrim) {
    case "Unassigned":
      return "bg-orange-400"
    case "Vacation":
      return "bg-gray-600"
    default:
      return "bg-[#30B0C7]"
  }
}

const clickedRow = (val: any) => {
  if (!val?.truck?.id) return
  rowClicked.value = val.truck.id
}

const updateCurrentLocation = (coordinates: ICoordinate | null): void => {
  currentLocation.value = coordinates
}
const updateDirectionAngle = (directionAngle: number): void => {
  directionalAngle.value = directionAngle
}

const detailsBook = (val: any) => {
  if (val && val.id && val.suggestion_status === "NEW") {
    modal.show("book_details", { details: val.id })
  } else {
    modal.show("book_details", { vin_details: val?.truck?.vin })
  }
}

const cardPage = (val: string) => {
  if (val) {
    router.push({ name: "cards" })
    queryCard.value.keyword = val
    queryCard.value.status = ""
  }
}

const transactionPage = (val: any) => {
  if (val) {
    router.push({ name: "transactions" })
    queryTransaction.value.truck_id = val.id
    queryTransaction.value.name = val.truck_id
  }
}

let intervalId = ref<any>(null)

onBeforeMount(async () => {
  listLoading.value = true

  await GET_COUNT_SUGGESTIONS().then((res) => {
    if (res.content) {
      query.value.size = res.content
      getFuelSuggestions()
    }
  })
})

onMounted(() => {
  intervalId.value = setInterval(() => {
    getFuelSuggestions()
  }, 300000)
})

const sortingMiles = (val: any) => {
  if (val.name === "miles") {
    if (sortMiles.value === null) {
      sortMiles.value = true
    } else if (sortMiles.value === true) {
      sortMiles.value = false
    } else {
      sortMiles.value = null
    }
    getFuelSuggestions()
  }
}

const mouseDown = (event: any) => {
  const cell = event.target.closest("td, th")
  if (!cell) return
  const row = cell.parentNode
  mouseDownColIndex.value = [...row.children].indexOf(cell)
}

const dragSelection = (event: any) => {
  const cell = event.target.closest("td, th")
  if (!cell) return
  const row = cell.parentNode
  const table = row.parentNode
  const colIndex = [...row.children].indexOf(cell)
  const rowIndex = [...table.children].indexOf(row)
  hoveredColIndex.value = colIndex
  hoveredRowIndex.value = rowIndex
}

onUnmounted(() => {
  list.value = []
  clearInterval(intervalId.value)
})
</script>

<template>
  <template v-if="isVisibleMap">
    <StationsMap :current-location="currentLocation" :direction-angle="directionalAngle" />
  </template>
  <template v-else>
    <FuelListHeader class="sticky top-[1px]" />
    <FuelTable
      :cols="cols"
      :group-by="groupBy"
      :group-drivers="groupDrivers"
      :group-order="groupOrder"
      :loading="listLoading"
      :rows="list"
      :skeleton="listLoading"
      :table-row="rowClicked"
      class="fuel-table mt-4 select-none"
      collapsible
      head-class="bg-slate-200 dark:bg-gray-800"
      inifinite-scroll
      no-wrap
      @mousedown="mouseDown"
      @mousemove="dragSelection"
      @header-clicked="sortingMiles"
      @row-clicked="clickedRow"
    >
      <template #card_number="{ row }">
        <div class="flex items-center gap-2">
          <ButtonCopy v-if="row.card_number" :text-to-copy="row.card_number" @click="cardPage(row.card_number)" />
          <span v-if="row.truck?.card_status === CARD_STATUS.ACTIVE">{{ formatCardNumber(row.card_number) }}</span>
          <del v-else :class="columnColor(row.truck?.card_status!)">
            {{ formatCardNumber(row.card_number) }}
          </del>
        </div>
      </template>

      <template #idx="{ idx }">
        {{ idx + 1 }}
      </template>

      <template #tank="{ row }">
        <div class="flex items-center justify-between">
          <span :class="[row?.transaction_amt && row.transaction_amt > row.tank ? 'font-bold text-danger-800' : '']">{{
            row.tank
          }}</span>
          <ButtonIcon
            v-if="profile.authorities.has(RolePermissionsModel.CAN_TRUCK_SET_TANK)"
            :label="$t('actions.edit')"
            class="justify-end"
            color="info"
            size="sm"
            variant="ghost"
            @click.stop="
              modal.show('tank-details', {
                id: row.truck?.id,
                tank: row.tank,
                truck_id: row.truck?.truck_id,
                name: row.driver
              })
            "
          >
            <Icon :width="14" icon="lucide:pencil" />
          </ButtonIcon>
        </div>
      </template>
      <template #miles="{ row }">
        <span class="border-l border-gray-400 pl-4">{{ row.miles }}</span>
      </template>
      <template #mpg="{ row }">
        <span v-if="row.mpg" :class="[row.mpg > 8.5 || row.mpg < 5.5 ? 'font-bold text-danger-800' : '']">{{
          row.mpg
        }}</span>
      </template>

      <template #store_number="{ row }">
        <span
          v-if="row.suggestion_status !== 'NEW'"
          :class="[
            row.store_number === row.transaction_store_number ? 'font-bold text-green-500' : 'font-bold text-danger-800'
          ]"
          >{{ row.store_number }}</span
        >
        <span v-else>{{ row.store_number }}</span>
      </template>

      <template #transaction_store_number="{ row }">
        <span
          v-if="row.suggestion_status !== 'NEW'"
          :class="[
            row.store_number === row.transaction_store_number ? 'font-bold text-green-500' : 'font-bold text-danger-800'
          ]"
          class="border-l border-gray-400 pl-4"
          >{{ row.transaction_store_number }}</span
        >
        <span v-else class="border-l border-gray-400 pl-4">{{ row.transaction_store_number }}</span>
      </template>

      <template #group-header="{ groupKey, count }">
        <div
          :class="[
            'flex items-center border-b border-gray-100 p-2 font-semibold uppercase text-white dark:border-gray-800',
            getGroupClass(groupKey)
          ]"
        >
          <span class="ml-4 font-bold">{{ groupKey }} ({{ count }})</span>
        </div>
      </template>

      <template #fuel_level="{ row }">
        <div v-if="!!row.fuel_level" class="flex w-[100px] flex-nowrap items-center gap-2">
          <span class="whitespace-nowrap text-xs font-semibold">{{ row.fuel_level }}%</span>
          <div class="relative h-5 flex-grow rounded-sm bg-gray-200">
            <div
              :style="{
                width: `${Math.min(row.fuel_level, 100)}%`,
                backgroundColor: row.fuel_level < 20 ? 'red' : 'green'
              }"
              class="absolute inset-y-0 left-0 transition-all duration-300 ease-in-out"
            />
          </div>
        </div>
      </template>

      <template #distance="{ row }">
        <span
          v-if="(row.truck && row.truck.distance) || row.truck?.distance === 0"
          :class="[row.truck?.movement_status === 'APPROACHING' ? '' : 'font-bold text-red-500']"
          class="border-l border-gray-400 pl-4"
        >
          {{ row.truck.movement_status === "APPROACHING" ? `${row.truck.distance} mi` : `-${row.truck.distance} mi` }}
        </span>
      </template>

      <template #truck="{ row }">
        <div v-if="row?.truck?.truck_id" class="flex items-center gap-2">
          <ButtonIcon
            v-if="row.truck.speed"
            color="success"
            icon="lucide:circle-play"
            size="xs"
            variant="ghost"
            width="18"
          />

          <ButtonIcon
            v-if="!row.truck.speed"
            color="danger"
            icon="lucide:circle-pause"
            size="xs"
            variant="ghost"
            width="18"
          />
          <ButtonIcon
            v-if="row.truck?.latitude && row.truck?.longitude"
            :href="getGoogleMapsUrl({ latitude: row.truck.latitude, longitude: row.truck.longitude })"
            :color="row.truck?.has_not_moved_for_three_days ? 'danger' : 'info'"
            icon="lucide:map-pin"
            size="xs"
            target="_blank"
            variant="ghost"
            width="18"
          />
          <div v-else class="mr-6" />

          <div>
            {{ row.truck.truck_id }}
          </div>
        </div>
      </template>

      <template #actions="{ row }">
        <div class="flex items-center">
          <ButtonIcon
            v-if="row.truck"
            color="secondary"
            size="sm"
            variant="ghost"
            @click.stop="transactionPage(row.truck)"
          >
            <Icon :width="18" icon="lucide:arrow-right-left" />
          </ButtonIcon>
          <ButtonIcon
            :label="$t('actions.edit')"
            color="secondary"
            size="sm"
            variant="ghost"
            @click.stop="detailsBook(row)"
          >
            <Icon :width="18" icon="lucide:pencil" />
          </ButtonIcon>
        </div>
      </template>
    </FuelTable>

    <TableFooter
      v-model:page="query.page"
      v-model:per-page="query.size"
      :total="pagination.total"
      @change="getFuelSuggestions"
    />
  </template>

  <FuelBookSidebar
    :current-location="currentLocation"
    @update:current-location="updateCurrentLocation"
    @update:direction-angle="updateDirectionAngle"
  />
  <FuelTankDetails />
  <FuelAssignSidebar />
  <FuelAssignFCSidebar />
  <FuelDeactivationSidebar />
</template>

<style>
.fuel-table .table .bg-yellow-200 {
  cursor: pointer;
  position: relative;
}

/* Tooltip styles */
.fuel-table .table .bg-yellow-200:hover::after {
  content: "Fuel level increased. Transaction occurred and transaction is pending.";
  position: absolute;
  left: 45%;
  bottom: 100%;
  transform: translateX(-50%);
  background: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
}

.dark .fuel-table .table .bg-yellow-200:hover::after {
  background: white;
  color: black;
}

/* Show tooltip on hover */
.fuel-table .table .bg-yellow-200:hover::after {
  opacity: 1;
  visibility: visible;
}
</style>

import type { AxiosInstance } from "axios"

import { RoleModel, RoleModelWithAuthorities, RolePermissionsModel, RolesAssignModel } from "~/management/roles"

type RoleAuthorityEditParams = {
  roleId: number
  authority: RolePermissionsModel
}

export const useRolesApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1/roles"

  return {
    GET_ROLES(params: PaginationParams): ResponseContainer<RoleModel[]> {
      return axios.$get(base, { params })
    },
    PATCH_ROLE(body: Required<RolesAssignModel>): Promise<ResponseType> {
      return axios.$put(base, body)
    },
    POST_ROLE(body: Omit<RolesAssignModel, "id">): Promise<ResponseType> {
      return axios.$post(base, body)
    },
    REMOVE_AUTHORITY_TO_ROLE(params: RoleAuthorityEditParams): Promise<ResponseType> {
      return axios.$put(`${base}/remove-authority`, params)
    },
    ADD_AUTHORITY_TO_ROLE(params: RoleAuthorityEditParams): Promise<ResponseType> {
      return axios.$put(`${base}/add-authority`, params)
    },
    ACTIVATE_ROLE(id: number): Promise<ResponseType> {
      return axios.$patch(`${base}/activate/${id}`)
    },
    DEACTIVATE_ROLE(id: number): Promise<ResponseType> {
      return axios.$patch(`${base}/deactivate/${id}`)
    },
    GET_SINGLE_ROLE(id: number): ResponseContainer<RoleModelWithAuthorities> {
      return axios.$get(`${base}/${id}`)
    }
  }
}

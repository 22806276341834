import axios from "axios"
import { IGeocodeResponse } from "~/map/stations/stations.model"

export const useStationsApi = () => {
  const routeBase = "https://router.hereapi.com/v8"
  const discoverBase = "https://discover.search.hereapi.com/v1"
  const API_KEY = import.meta.env.APP_HERE_API_KEY

  return {
    FETCH_ROUTES_CALCULATION(params: any) {
      params.append("apiKey", API_KEY)
      params.append("transportMode", "truck")
      params.append("return", "polyline,summary")
      params.append("alternatives", 2)

      return axios.get(`${routeBase}/routes`, { params })
    },
    FETCH_ADDRESS_GEOCODE(q: string): Promise<IGeocodeResponse> {
      return axios.get(`${discoverBase}/geocode`, {
        params: { q, apiKey: API_KEY, countryCode: "USA" }
      })
    }
  }
}

import { ICoordinate, ILocation } from "$/models"
import { StoresListTable } from "~/management/priceStores"
import { FUEL_PRICE } from "~/map/stations/stations.model"

export interface CALCULATED_ROUTE {
  distance: number
  duration: number
  eta?: string
}

export interface SuggestionStop {
  id: number
  is_send: boolean
  duration: number
  distance: number
  is_pending: boolean
  note: string
  amount: number
  created_at: string
  is_full: boolean
  stop_order: number
  is_actual: boolean
  actual_price: number
  effective_date: string
  station_address: string
  station_city: string
  station_country: string
  station_state: string
  store_number: string
  station_longitude: number
  station_latitude: number
  booker: string
  nickname: string
  transaction_address1: Nullable<string>
  transaction_address2: Nullable<string>
  transaction_city: Nullable<string>
  transaction_country: Nullable<string>
  transaction_latitude: Nullable<number>
  transaction_longitude: Nullable<number>
  transaction_state: Nullable<string>
  card_number: Nullable<string>
  funded_total: Nullable<number>
  ppu: Nullable<number>
  supplier_code: Nullable<string>
  quantity: Nullable<number>
  transaction_date: Nullable<string>
  eta?: string
  is_insert_point?: boolean
  fuel_station_id?: number
  route?: CALCULATED_ROUTE
}

export interface FUEL_TRUCK {
  note: string
  truck_id: string
  vin_number: string
  card_number: Nullable<string>
  miles: Nullable<number>
  time: Nullable<string>
  fuel_level: number
  tank: number
  driver_name: Nullable<string>
  driver_phone: Nullable<string>
  speed: number
  longitude: Nullable<number>
  latitude: Nullable<number>
  location: Nullable<string>
  state: Nullable<string>
  unit_number: string
}

export interface FuelSuggestionDetail extends FUEL_TRUCK {
  suggestion_stops: SuggestionStop[]
}

export interface TRUCK {
  truckId: string
  id?: number
  value?: number
  vin_number: string
  driver_dto?: {
    id: Nullable<number>
    name: Nullable<string>
    sections_address_dto_list: Nullable<
      {
        section_id: number
        section_type: "PICKUP" | "DELIVERY"
        order_index: number
        address: string
      }[]
    >
  }
}

export interface FuelStationDto {
  store_number: string
  avg_price: number
  city: string
  state: string
  address: string
  country: string
  longitude: number
  latitude: number
  fuel_price_dto: FUEL_PRICE
}

export interface SuggestionStopDto {
  amount: number
  avg: number
  distance: number
  is_actual: boolean
  is_full: boolean
  miles: number
  mpg: number
  stop_order: number
  samsara_mpg: number
  is_pending: boolean
}

export interface TruckDto {
  id: number
  truck_id: string
  tank: number
  fuel_level: number
  longitude: number | null
  latitude: number | null
  vin: string
  distance: number | null
  booker: string | null
  booker_id: string | null
  card_number: string | null
  is_assigned: boolean
  card_status: string | null
  speed: number | null
  movement_status: string
}

export interface TransactionDto {
  card_number: Nullable<string>
  id: Nullable<number>
  is_matched: Nullable<boolean>
  ppu: Nullable<number>
  quantity: Nullable<number>
  supplier_code: Nullable<string>
  transaction_date: Nullable<string>
}

export interface FuelSuggestion {
  truck_dto: TruckDto
  driver_dto: {
    is_vacation: boolean
    name: string
    phone: string
  }
  booker_dto: {
    booker: string
    created: string
    nickname: string
  }
  suggestion_dto: {
    id: number
    is_done: boolean
    total_stops_count: number
    status: string
  }
  suggestion_stop_dto: SuggestionStopDto
  fuel_station_dto: FuelStationDto
  transaction_dto: TransactionDto
}

export class TankDetail {
  truck_id: number | null = null
  tank: number | null = null

  constructor(data?: Partial<TankDetail>) {
    if (data) {
      this.truck_id = data.truck_id || null
      this.tank = data.tank || null
    }
  }
}

export class FuelCompaniesList {
  id: number | null = null
  name: string = ""

  constructor(data?: Partial<FuelCompaniesList>) {
    if (data) {
      this.id = data.id || null
      this.name = data.name || ""
    }
  }
}

export interface TruckBookInfo {
  suggestion_id: number
  message: string
}

export interface TruckCurrentLocation {
  coordinates: ICoordinate
  distance: number
  state: string
  location: string
  direction: string
  status: string
  trailer: string
  document: string
  directionAngle: number
  speed: number
  unitNumber: string
  driver: string
  company: string
}

export class FuelModel {
  id: number = 0
  truck: TruckDto | null = null
  driver: string = ""
  is_pending: boolean = false
  driver_phone: string = ""
  card_number: string = ""
  tank: number = 0
  miles: number = 0
  mpg: number = 0
  samsara_mpg: number = 0
  avg_price: number = 0
  fuel_level: number = 0
  distance: number = 0
  booker: string = ""
  nickname: string = ""
  booker_name: string = ""
  suggestion_status: string = ""
  movement_status: string = ""
  datetime: string = ""
  city_st: string = ""
  stops: number | null = null
  amt: number | string = 0
  price: number = 0
  store_number: Nullable<string> = null
  transaction_store_number: Nullable<string> = null
  transaction_price: Nullable<number> = null
  transaction_amt: Nullable<number> = null
  transaction_datetime: Nullable<string> = null
  on_vacation: boolean = false
  fuel_station?: FuelStationDto | null = null

  constructor(data?: Partial<FuelSuggestion>) {
    if (data) {
      this.id = data.suggestion_dto!.id!
      this.booker_name = data?.booker_dto?.nickname ?? ""
      this.suggestion_status = data?.suggestion_dto?.status ?? ""
      this.truck = data.truck_dto ?? null
      this.driver = data.driver_dto?.name ?? ""
      this.driver_phone = data.driver_dto?.phone ?? ""
      this.card_number = data.truck_dto?.card_number ?? ""
      this.tank = data.truck_dto?.tank ?? 0
      this.miles = data.suggestion_stop_dto?.miles ?? 0
      this.mpg = data.suggestion_stop_dto?.mpg ?? 0
      this.samsara_mpg = data.suggestion_stop_dto?.samsara_mpg ?? 0
      this.avg_price = data.suggestion_stop_dto?.avg ?? 0
      this.fuel_level = data.truck_dto?.fuel_level ?? 0
      this.distance = data.truck_dto?.distance ?? 0
      this.booker = data.truck_dto?.booker ?? ""
      this.nickname = data.truck_dto?.nickname ?? ""
      this.movement_status = data.truck_dto?.movement_status ?? ""
      this.is_pending = data.suggestion_stop_dto?.is_pending ?? false
      this.datetime = data.booker_dto?.created ?? ""
      this.city_st = data.fuel_station_dto?.city ? `${data.fuel_station_dto.city}, ${data.fuel_station_dto.state}` : ""
      this.stops = data.suggestion_dto?.total_stops_count ?? null
      this.amt = data?.suggestion_stop_dto?.is_full ? "Full" : (data?.suggestion_stop_dto?.amount ?? 0)
      this.price = data?.fuel_station_dto?.fuel_price_dto?.actual_price ?? 0
      this.store_number = data?.fuel_station_dto?.store_number ?? ""
      this.fuel_station = data?.fuel_station_dto ?? null
      this.transaction_store_number = data?.transaction_dto?.supplier_code ?? ""
      this.transaction_price = data?.transaction_dto?.ppu ?? null
      this.transaction_amt = data?.transaction_dto?.quantity ?? null
      this.transaction_datetime = data?.transaction_dto?.transaction_date ?? null
      this.on_vacation = data?.driver_dto?.is_vacation ?? false
    }
  }
}

export class BookStopModel {
  id?: Nullable<number> = null
  note?: string = ""
  stop_order?: number = 0
  address?: string = ""
  price: Nullable<number>
  store: StoresListTable = new StoresListTable()
  amount: Nullable<number> = null
  distance?: Nullable<number> = null
  is_full?: boolean = false
  location?: Nullable<ILocation> = null
  date?: Nullable<string> = null
  booker?: Nullable<string> = null
  is_insert_point?: boolean = false
  fuel_station_id?: Nullable<number> = null

  constructor(data?: BookStopModel | SuggestionStop) {
    if (data) {
      this.id = data.id || null
      this.note = data.note || ""
      this.stop_order = data.stop_order || 0
      this.address = (data as BookStopModel).address || (data as SuggestionStop).station_address || ""
      this.distance = (data as BookStopModel).distance ?? (data as SuggestionStop).route?.distance
      this.price = (data as BookStopModel).price ?? (data as SuggestionStop).actual_price
      this.amount = data.amount
      this.is_full = data.is_full || false
      this.location = (data as BookStopModel).location || null
      this.date = (data as BookStopModel).date || null
      this.booker = data.booker || null
      this.is_insert_point = data.is_insert_point || false

      if ("store" in data) {
        this.store = data.store
      } else {
        this.store = {
          id: (data as SuggestionStop).id,
          fuel_station_id: (data as SuggestionStop).fuel_station_id,
          store_number: (data as SuggestionStop).store_number,
          address: (data as SuggestionStop).station_address,
          state: (data as SuggestionStop).station_state,
          city: (data as SuggestionStop).station_city,
          active: true,
          latitude: (data as SuggestionStop).station_latitude,
          longitude: (data as SuggestionStop).station_longitude,
          actual_price: (data as SuggestionStop).actual_price
        }
      }
    }
  }
}

export class BookForm {
  truck: Nullable<TRUCK> = null
  note: string = ""
  stops: BookStopModel[] = [
    new BookStopModel({
      stop_order: 0,
      address: "",
      store: new StoresListTable(),
      price: null,
      amount: null,
      is_full: false
    })
  ]
}

export class SuggestionStopPayload {
  fuel_station_id: Nullable<number> = null
  amount: number = 0
  note: string = ""
  stop_order: number = 0
  is_full: boolean = false
  id?: Nullable<number> = null

  constructor(data?: BookStopModel) {
    if (data) {
      this.note = data.note ?? ""
      this.fuel_station_id = data.store.fuel_station_id ?? data.store.id
      this.amount = data.amount ?? 0
      this.stop_order = data.stop_order ?? 0
      this.is_full = data.is_full ?? false
      this.id = data.id ?? null
    }
  }
}

export class BookPayload {
  truck_id: Nullable<number> = null
  note: string = ""
  suggestion_stop_create_dtos: SuggestionStopPayload[] = []

  constructor(data?: BookForm) {
    if (data) {
      this.truck_id = data?.truck?.value ?? data?.truck?.id ?? null
      this.note = data?.note || ""
      this.suggestion_stop_create_dtos = data.stops.map((stop: BookStopModel) => new SuggestionStopPayload(stop))
    }
  }
}

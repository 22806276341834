import type { AxiosInstance } from "axios"
import {
  CardAssignModel,
  CardEditModel,
  CardModel,
  CardStatisticsModel,
  CardStatus,
  EditCardUsedPayload,
  HistoryEntry,
  HistoryPayload
} from "~/management/card/card.model"

export const useCardApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1/cards"

  return {
    GET_CARDS(params: PaginationParams, status?: CardStatus, companyIds?: number[]): ResponseContainer<CardModel[]> {
      return axios.$get(base, {
        params: { ...params, status, company_ids: companyIds?.join(", ") }
      })
    },
    GET_CARD(id: number): ResponseContainer<CardEditModel> {
      return axios.$get(`${base}/${id}`)
    },
    GET_ONE_CARD_NAME(id: number): ResponseContainer<string> {
      return axios.$get(`${base}/get-driver/${id}`)
    },
    GET_CARD_STATISTICS(): ResponseContainer<CardStatisticsModel> {
      return axios.$get(`${base}/statistics`)
    },
    EDIT_ASSIGN_CARD(id: number, payload: CardEditModel): Promise<ResponseType> {
      return axios.$put(`${base}/${id}`, payload)
    },
    ASSIGN_CARD(id: number, payload: CardAssignModel): Promise<ResponseType> {
      return axios.$put(`${base}/assign/${id}`, payload)
    },
    CARD_ACTIVATE(id: number, number: string): Promise<ResponseType> {
      return axios.$patch(`${base}/activate/${id}`, null, { params: { number } })
    },
    CARD_DEACTIVATE(id: number, number: string): Promise<ResponseType> {
      return axios.$patch(`${base}/deactivate/${id}`, null, { params: { number } })
    },
    GET_CARD_HISTORY(card_id: number, params: PaginationParams): ResponseContainer<HistoryEntry[]> {
      return axios.$get(`${base}/history/${card_id}`, { params })
    },
    POST_CARD_HISTORY(card_id: number, payload: HistoryPayload[]): ResponseContainer<HistoryEntry[]> {
      return axios.$post(`${base}/history/${card_id}`, payload)
    },
    GET_CARD_RELOAD(card_id: number): ResponseContainer<CardEditModel> {
      return axios.$get(`${base}/reload/${card_id}`)
    },
    EDIT_CARD_USED(card_id: number, payload: EditCardUsedPayload) {
      return axios.$patch(`${base}/used/${card_id}`, null, { params: payload })
    }
  }
}

import { push } from "notivue"

import {
  ForgotPassword,
  PasswordChangeRequest,
  PasswordResetResponse,
  PatchProfile,
  Profile,
  SignIn
} from "./session.model"
import { useSessionStore } from "./session.store"
import { useSessionApi } from "~/session/session.api"

export const useSessionService = () => {
  const router = useRouter()
  const sessionApi = useSessionApi()
  const { confirmSignOut } = useAlert()

  const sessionStore = useSessionStore()
  const { appToken, userFullName, userId, isLoading, loggedIn, profile } = storeToRefs(sessionStore)

  const signIn = async (payload: SignIn) => {
    try {
      isLoading.value = true
      const {
        content: { token, id, firstname, lastname }
      } = await sessionApi.SIGN_IN(payload)
      if (!token) return

      appToken.value = token
      localStorage.setItem("user_id", String(id))
      localStorage.setItem("user_full_name", `${firstname || ""} ${lastname || ""}`)
      userId.value = String(id)
      userFullName.value = `${firstname || ""} ${lastname || ""}`
      if (id) await getProfile(id)

      await router.replace("/")
    } finally {
      isLoading.value = false
    }
  }

  const logOut = async () => {
    profile.value = new Profile()
    appToken.value = ""
    await router.replace({ name: "sign-in" })
  }

  const setProfile = async (payload: PatchProfile) => {
    try {
      isLoading.value = true
      await sessionApi.PATCH_PROFILE(payload)
      push.success("Successfully changed")
    } finally {
      isLoading.value = false
    }
  }

  const resetPassInit = async (payload: ForgotPassword) => {
    try {
      isLoading.value = true
      await sessionApi.POST_RESET_PASSWORD_INIT(payload)
      push.success("Link for reset has been sent to your email")
    } finally {
      isLoading.value = false
    }
  }

  const resetPassFinish = async (payload: PasswordResetResponse) => {
    try {
      isLoading.value = true
      await sessionApi.POST_RESET_PASSWORD_FINISH(payload)
      push.success("Your password has been reset successfully")
      await router.replace({ name: "sign-in" })
    } finally {
      isLoading.value = false
    }
  }

  const changeUserPassword = async (user_id: number, payload: PasswordChangeRequest) => {
    return await sessionApi.CHANGE_USER_PASSWORD(user_id, payload)
  }

  const getProfile = async (userId: number) => {
    if (!loggedIn.value) return
    return new Promise<Profile>((resolve, reject) => {
      isLoading.value = true
      sessionApi
        .FETCH_ONE_USER(userId)
        .then(({ content }) => {
          profile.value = new Profile(content)
          resolve(profile.value)
        })
        .catch((error) => {
          reject(error)
          logOut()
        })
        .finally(() => (isLoading.value = false))
    })
  }

  const confirmLogoutOut = () => confirmSignOut().then(logOut)

  return {
    signIn,
    logOut,
    setProfile,
    resetPassInit,
    resetPassFinish,
    confirmLogoutOut,
    changeUserPassword,
    getProfile
  }
}

import { ILocation } from "$/models"

export class IdlingModel {
  id!: number
  truck_id: string = ""
  driver_name: string = ""
  booker: string = ""
  reason: string = ""
  address: string = ""
  latitude: Nullable<number> = null
  longitude: Nullable<number> = null
  location: string = ""
  total_durations?: string = ""
  total_duration_sec: Nullable<number> = null
  total_gallons: Nullable<number> = null
  duration_sec: Nullable<number> = null

  constructor(data?: Partial<IdlingModel>) {
    if (data) {
      this.id = data.id!
      this.reason = data.reason || ""
      this.duration_sec = data.duration_sec || 0
      this.truck_id = data.truck_id || ""
      this.driver_name = data.driver_name || ""
      this.total_duration_sec = data.total_duration_sec || null
      this.total_gallons = data.total_gallons || 0
      this.total_durations = data.total_durations || ""
      this.booker = data.booker || ""
      this.address = data.address || ""
      this.location = data.location || ""
    }
  }
}

export interface IdlingDetail {
  id: number
  reason: string
  total_duration_sec: string
  total_gallons: number
  address: string
  booker: string
  start?: string
  end?: string
  truck?: string
  driver?: string
  location?: ILocation
  duration_sec?: number
}

export interface IdlingPayload {
  id: number
  reason: string
}

export interface IdlingParams extends PaginationParams {
  from_date?: string
  to_date?: string
}

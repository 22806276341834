import { ICoordinate } from "$/models"
import { states } from "$/constants/states"

const METERS_PER_MILE = 1609.344
const MILES_PER_METER = 0.000621371192

export const getGoogleMapsUrl = (coordinate: ICoordinate) => {
  const url = new URL("https://www.google.com/maps/search/")
  url.searchParams.append("api", "1")
  url.searchParams.append("query", `${coordinate.latitude},${coordinate.longitude}`)
  return url.toString()
}

export const getMiles = (meters: number, suffix: boolean = true) => {
  const miles = meters * MILES_PER_METER
  return suffix ? `${miles} mi` : miles
}

export const getMeters = (miles: number, suffix: boolean = true) => {
  const meters = miles * METERS_PER_MILE
  return suffix ? `${meters} m` : meters
}

export const getUsaStateAbbreviation = (state: string): string => {
  const lowercaseState = state.toLowerCase()

  if (state.length === 2) return state.toUpperCase()

  const stateObject = states.find(
    (s) =>
      s.label.toLowerCase() === lowercaseState ||
      s.id === lowercaseState ||
      s.abbreviation.toLowerCase() === lowercaseState
  )

  if (stateObject) return stateObject.abbreviation

  return state
}

export const getUsaStateName = (abbreviation: string): string => {
  const uppercaseAbbreviation = abbreviation?.toUpperCase()

  if (abbreviation.length > 2) return abbreviation

  const stateObject = states.find((s) => s.abbreviation === uppercaseAbbreviation)

  if (stateObject) return stateObject.label

  return abbreviation
}

export const formatLocation = ({ address, city }: { address: string; city?: string }) => {
  return `${address}, ${city ? getUsaStateAbbreviation(city) : ""}`
}

<script lang="ts" setup>
import { useSystemSettingsService, useSystemSettingsStore } from "~/management/systemSettings"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"
import { Icon } from "@iconify/vue"
import SystemSettingsMenu from "~/management/systemSettings/components/SystemSettingsMenu.vue"

const modal = useModal()
const { t } = useI18n()

const systemSettingsStore = useSystemSettingsStore()
const { getSystemSettingsList } = useSystemSettingsService()

const { list, query, listLoading, settingTypeComputed, pagination } = storeToRefs(systemSettingsStore)

const cols = computed<ITableCol<any>[]>(() => {
  const baseCols = [
    {
      name: "idx",
      label: t("thead.no")
    }
  ]

  if (settingTypeComputed.value === "MPG") {
    baseCols.push(
      {
        name: "correction_mpg",
        label: t("thead.correction_mpg")
      },
      {
        name: "active",
        label: t("thead.status")
      },
      {
        name: "updated",
        label: t("thead.date_updated"),
        formatter: (value: string) => (value ? toZone(value, "MM/DD/YYYY HH:mm CT") : "")
      },
      {
        name: "actions",
        label: t("thead.actions"),
        labelClass: "justify-center"
      }
    )
  } else if (settingTypeComputed.value === "MOBILE") {
    baseCols.push(
      {
        name: "radius",
        label: t("thead.radius_form")
      },
      {
        name: "fuel_percent",
        label: t("thead.fuel_percent_form")
      },

      {
        name: "speed",
        label: t("thead.speed_form")
      },
      {
        name: "minutes",
        label: t("thead.minutes_form")
      },
      {
        name: "active",
        label: t("thead.status")
      },
      {
        name: "updated",
        label: t("thead.date_updated"),
        formatter: (value: string) => (value ? toZone(value, "MM/DD/YYYY HH:mm CT") : "")
      },
      {
        name: "actions",
        label: t("thead.actions"),
        labelClass: "justify-center"
      }
    )
  } else {
    baseCols.push(
      {
        name: "account_name",
        label: t("thead.account_name")
      },
      {
        name: "username",
        label: t("thead.username")
      },

      {
        name: "created_at",
        label: t("thead.created_at"),
        formatter: (value: string) => (value ? toZone(value, "MM/DD/YYYY HH:mm CT") : "")
      },

      {
        name: "actions",
        label: t("thead.actions"),
        labelClass: "justify-center"
      }
    )
  }
  return baseCols
})

onUnmounted(() => (query.value = { page: 0, size: 20 }))
onMounted(() => getSystemSettingsList())
</script>

<template>
  <div>
    <TableHeader>
      <template #left>
        <div />
      </template>
      <div class="flex items-center justify-end gap-5">
        <HeaderAmountItem
          v-if="settingTypeComputed === 'EFS'"
          :amount="pagination.totalElements"
          :description="$t('labels.total_settings')"
        />
        <Button v-if="settingTypeComputed === 'EFS'" @click="modal.show('efs-settings-form')">
          {{ $t("actions.new_efs_account") }}
        </Button>
        <ButtonIcon :loading="false" color="success" variant="ghost" @click="getSystemSettingsList()">
          <Icon :width="20" icon="lucide:refresh-ccw" />
        </ButtonIcon>
      </div>
    </TableHeader>

    <Table :cols="cols" :loading="listLoading" :rows="list" :skeleton="listLoading" class="mt-4" no-wrap>
      <template #idx="{ idx, sequence }">
        {{ sequence(idx, query.page || 0, query.size || 10) }}
      </template>

      <template #active="{ row }">
        <span :class="row.active ? 'text-green-700' : 'text-red-500'">
          {{ row.active ? $t("labels.active") : $t("labels.inactive") }}
        </span>
      </template>

      <template #actions="{ row }">
        <SystemSettingsMenu :id="row.id" :is-active="row.active" :setting-type-computed="settingTypeComputed" />
      </template>
    </Table>
  </div>
</template>

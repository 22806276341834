<script lang="ts" setup>
import { useSystemSettingsStore } from "~/management/systemSettings"

const { hasError } = defineProps<{ hasError: any }>()

const systemSettingsStore = useSystemSettingsStore()
const { form, settingTypeComputed } = storeToRefs(systemSettingsStore)
</script>

<template>
  <form class="space-y-4 p-4" novalidate @submit.prevent="$emit('submit')">
    <template v-if="settingTypeComputed === 'MPG'">
      <FormGroup v-slot="{ id }" :label="$t('thead.correction_mpg')" v-bind="hasError('correction_mpg')">
        <Input
          :id
          v-model="form.correction_mpg"
          :placeholder="$t('placeholders.correction_mpg')"
          required
          type="number"
        />
      </FormGroup>
    </template>
    <template v-else>
      <FormGroup v-slot="{ id }" :label="$t('thead.radius_form')" v-bind="hasError('radius')">
        <Input :id v-model="form.radius" :placeholder="$t('placeholders.enter_radius')" required type="number" />
      </FormGroup>
      <FormGroup :label="$t('thead.minutes_form')" v-bind="hasError('minutes')">
        <Input v-model="form.minutes" :placeholder="$t('placeholders.enter_minutes')" type="number" />
      </FormGroup>
      <FormGroup :label="$t('thead.fuel_percent_form')" v-bind="hasError('fuel_percent')">
        <Input v-model="form.fuel_percent" :placeholder="$t('placeholders.enter_fuel_percent')" type="number" />
      </FormGroup>
      <FormGroup :label="$t('thead.speed_form')" v-bind="hasError('speed')">
        <Input v-model="form.speed" :placeholder="$t('placeholders.enter_speed')" type="number" />
      </FormGroup>
    </template>
  </form>
</template>

<script lang="ts" setup>
import Select from "@vueform/multiselect"

import { ButtonIcon, Icon } from "$/ui"
import { useManagementService, useManagementStore } from "~/management"
import CardMenu from "~/management/card/components/CardMenu.vue"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"
import { useSessionStore } from "~/session"
import { CardModel, useCardService, useCardStore } from "~/management/card"

import { CARD_STATUS } from "~/management/card/contants"
import { RolePermissionsModel } from "~/management/roles"
import { useUsersService, useUsersStore } from "~/management/users"

const companyStore = useManagementStore()

const modal = useModal()
const { t } = useI18n()
const intersectionTarget = ref<HTMLDivElement>()

const { getCompanyLazySelect, fetchTruckList } = useManagementService()
const { fetchCardsList, fetchCardStatistics, debouncedSearch, switchToNotInUse, switchToInUse } = useCardService()
const { getUsersLazySelect } = useUsersService()

const sessionStore = useSessionStore()
const cardStore = useCardStore()
const usersStore = useUsersStore()

const { selectUsers } = storeToRefs(usersStore)
const { companyList, truckList } = storeToRefs(companyStore)
const { profile } = storeToRefs(sessionStore)
const { list, listLoading, cardStatistics, query, companyIds } = storeToRefs(cardStore)

const cols = computed<ITableCol<CardModel>[]>(() => {
  const baseCols: ITableCol<CardModel>[] = [
    {
      name: "idx",
      label: t("thead.no"),
      width: "40px",
      dataClass: "text-center"
    },
    {
      name: "unit_number",
      label: t("thead.truck")
    },
    {
      name: "tank",
      label: t("thead.tank")
    },
    {
      name: "driver_name",
      label: t("thead.driver")
    },
    {
      name: "number",
      label: t("thead.fuel_card")
    },
    {
      name: "status",
      label: t("thead.card_status")
    },
    {
      name: "limits",
      label: t("thead.limit")
    },
    {
      name: "assigned_date",
      label: t("thead.assigned"),
      formatter: (value: string) => (value ? toZone(value, "MM/DD HH:mm CT") : "")
    },
    {
      name: "status_is_used",
      label: t("thead.using_status")
    },
    {
      name: "updated",
      label: t("thead.last_updated"),
      formatter: (value: string) => (value ? toZone(value, "MM/DD/YYYY HH:mm CT") : "")
    },
    {
      name: "is_used",
      label: t("Using")
    }
  ]

  if (
    profile.value.authorities.has(RolePermissionsModel.CAN_EFS_CARD_SET_LIMIT) ||
    profile.value.authorities.has(RolePermissionsModel.CAN_EFS_CARD_DEACTIVATE) ||
    profile.value.authorities.has(RolePermissionsModel.CAN_EFS_CARD_ACTIVATE)
  ) {
    baseCols.push({
      name: "actions",
      label: t("thead.actions"),
      withoutLink: true,
      labelClass: "justify-center",
      visible: !sessionStore.isAdmin
    })
  }

  return baseCols
})

const clearSearch = () => {
  query.value.keyword = ""
  fetchCardsList()
}

const parser = (val: any) => {
  return JSON.parse(val)
}

onBeforeMount(() => {
  fetchCardsList()
  fetchCardStatistics()
})

onUnmounted(() => {
  query.value = {
    keyword: "",
    page: 0,
    size: 100,
    total: 0,
    truck_id: null,
    status: CARD_STATUS.ACTIVE,
    booker_id: null,
    is_used: null
  }
  companyIds.value = []
})

const refreshFilter = (): void => {
  query.value.page = 0
  fetchCardsList()
}

const refresh = () => {
  list.value = []
  query.value.page = 0
  fetchCardsList()
  fetchCardStatistics()
}

const { pause, resume } = useIntersectionObserver(intersectionTarget, async ([entry]) => {
  if (entry.isIntersecting) {
    if (!list.value.length || query.value.total - 1 === query.value.page) {
      pause()
      return
    }
    query.value.page = query.value.page + 1
    fetchCardsList()
  }
})

const gettingTarget = (val: any) => {
  intersectionTarget.value = val
}

watch(listLoading, (newLoading) => {
  if (newLoading) return pause()
  resume()
})
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="flex w-full flex-wrap items-center gap-4">
        <div>
          <InputSearch
            v-model="query.keyword"
            :value="query.keyword"
            class="!h-9"
            @clear="clearSearch"
            @input="debouncedSearch"
          />
        </div>

        <div>
          <Select
            v-model="query.status"
            :options="Object.values(CARD_STATUS)"
            :placeholder="$t('labels.status')"
            append-to-body
            class="min-w-36 !rounded-none"
            @clear="fetchCardsList"
            @select="refreshFilter"
          />
        </div>
        <div>
          <LazySelect
            v-model="companyIds"
            :close-on-select="false"
            :fetch="getCompanyLazySelect"
            :get-option-label="(item: any) => item.name || ''"
            :hide-selected="false"
            :options="companyList"
            :placeholder="t('placeholders.all_companies')"
            content-refreshable
            characters="companies"
            class="tags-select min-w-[200px]"
            mode="multiple"
            tooltip
            value-prop="id"
            @update:model-value="refreshFilter"
          />
        </div>
        <div>
          <LazySelect
            v-model="query.truck_id"
            :fetch="fetchTruckList"
            :get-option-label="(item: any) => item.truck_id || ''"
            :options="truckList"
            :placeholder="t('placeholders.select_truck')"
            content-refreshable
            class="w-1/8 min-w-[100px]"
            value-prop="id"
            @update:model-value="refreshFilter"
          />
        </div>
        <div>
          <LazySelect
            v-model="query.booker_id"
            :fetch="getUsersLazySelect"
            :get-option-label="
              (item: any) =>
                item?.nickname + ' ' + fullName(item?.firstname, item?.lastname) + ' ' + `(${item?.truck_count})`
            "
            :hide-selected="false"
            :options="selectUsers"
            :placeholder="t('placeholders.select_booker')"
            content-refreshable
            characters="bookers"
            class="tags-select"
            tooltip
            value-prop="id"
            @clear="fetchCardsList"
            @update:model-value="refreshFilter"
          >
            <template #option="{ option }">
              <span class="w-4/5">
                {{ option?.nickname + " " + fullName(option?.firstname, option?.lastname) }}
              </span>
              <span class="absolute right-2"> ({{ option?.truck_count }}) </span>
            </template>
          </LazySelect>
        </div>

        <div>
          <Select
            v-model="query.is_used"
            :options="[
              { label: $t('labels.in_used'), val: 'true' },
              { label: $t('labels.not_in_used'), val: 'false' }
            ]"
            append-to-body
            class="min-w-36 !rounded-none"
            placeholder="Using"
            value-prop="val"
            @clear="fetchCardsList"
            @select="refreshFilter"
          />
        </div>
      </div>
    </template>
    <div class="flex items-center justify-end gap-5">
      <HeaderAmountItem :amount="cardStatistics.total" :description="$t('labels.total_cards')" />
      <HeaderAmountItem :amount="cardStatistics.active" :description="$t('labels.active_cards')" />
      <HeaderAmountItem :amount="cardStatistics.inactive" :description="$t('labels.inactive_cards')" />

      <div class="flex items-center gap-5">
        <Button
          v-if="profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_ASSIGN)"
          @click="modal.show('card-assign')"
        >
          {{ $t("actions.assign_card") }}
        </Button>

        <ButtonIcon :loading="listLoading" color="success" variant="ghost" @click="refresh">
          <Icon :width="20" icon="lucide:refresh-ccw" />
        </ButtonIcon>
      </div>
    </div>
  </TableHeader>

  <Table
    :cols="cols"
    :loading="listLoading"
    :rows="list"
    :skeleton="listLoading"
    class="mt-4"
    head-class="bg-slate-200 dark:bg-gray-800"
    no-wrap
    inifinite-scroll
    @intersection-target="gettingTarget"
  >
    <template #idx="{ idx }">
      {{ idx + 1 }}
    </template>

    <template #is_used="{ row }">
      <Button v-if="row.is_used" class="!p-0" color="info" variant="ghost" @click="switchToInUse(row.id)">
        {{ $t("labels.not_in_used") }}
      </Button>
      <Button v-else class="!p-0" color="info" variant="ghost" @click="switchToNotInUse(row.id)">
        {{ $t("labels.in_used") }}
      </Button>
    </template>
    <template #status_is_used="{ row }">
      <span>{{ row.is_used ? "In Use" : "Not in Use" }}</span>
    </template>

    <template #limits="{ row }">
      <div v-if="parser(row.limits).length">
        <span v-for="(item, index) in parser(row.limits)" :key="index" class="mr-2">
          {{ item.limit ? `${item.limit}:  ${item.available}/${item.daily_max}` : "" }}
        </span>
      </div>
    </template>

    <template #actions="{ row }">
      <CardMenu :id="row.id!" :is-active="row.status === CARD_STATUS.ACTIVE" :number="row.number" />
    </template>
  </Table>
</template>

import { IdlingPayload, useIdlingApi, useIdlingStore } from "~/management/idling"

export const useIdlingService = () => {
  const { GET_IDLING, GET_IDLING_DETAIL, POST_IDLING } = useIdlingApi()
  const idlingStore = useIdlingStore()

  const { companyIds, list, queryDetails, listLoading, query, idlingDetails, detailsLoading } = storeToRefs(idlingStore)

  const fetchIdlingList = (): void => {
    listLoading.value = true
    GET_IDLING(idlingStore.transformQuery(), companyIds.value)
      .then(({ content, totalElements }) => {
        list.value = content
        query.value.total = totalElements ?? 0
      })
      .finally(() => (listLoading.value = false))
  }

  const fetchIdlingDetail = (params: any): void => {
    detailsLoading.value = true
    GET_IDLING_DETAIL(params)
      .then(({ content, totalElements }: any) => {
        idlingDetails.value = content
        queryDetails.value.total = totalElements ?? 0
      })
      .finally(() => (detailsLoading.value = false))
  }

  const postIdlingReason = async (payload: IdlingPayload): Promise<void> => {
    await POST_IDLING(payload)
  }

  return {
    fetchIdlingList,
    fetchIdlingDetail,
    postIdlingReason
  }
}

<script setup lang="ts">
import { ButtonIcon, DatePicker, Icon } from "$/ui"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"

import { useManagementService, useManagementStore } from "~/management"
import { BookersModel, useBookersService, useBookersStore } from "~/management/bookers"
import { useUsersService, useUsersStore } from "~/management/users"
import { DELETING_REASONS } from "~/management/fuel/fuel.constants"

const { t } = useI18n()

const { fetchDriverList, fetchTruckList, getCompanyLazySelect } = useManagementService()
const { getUsersLazySelect } = useUsersService()
const { fetchBookings, fetchBookingsCount } = useBookersService()

const bookerStore = useBookersStore()
const managementStore = useManagementStore()
const usersStore = useUsersStore()
const { selectUsers } = storeToRefs(usersStore)
const { driverList, truckList, companyList } = storeToRefs(managementStore)
const { filterStats, list, companyIds, listLoading, query, bookingsCount, paginationTotal } = storeToRefs(bookerStore)

const cols = computed<ITableCol<BookersModel>[]>(() => [
  {
    name: "idx",
    label: t("thead.no")
  },
  {
    name: "truckNumber",
    label: t("thead.truck")
  },
  {
    name: "driver",
    label: t("thead.driver")
  },
  {
    name: "dateTime",
    label: t("thead.date"),
    formatter: (value) => toZone(value, "MM/DD/YYYY HH:mm CT")
  },

  {
    name: "fuelStop",
    label: t("thead.fuel_stop")
  },
  {
    name: "location",
    label: t("thead.location")
  },
  {
    name: "amount",
    label: t("thead.amount")
  },

  {
    name: "price",
    label: t("thead.price"),
    formatter: (value: number) => `${value ? "$" + value.toFixed(2) : ""}`
  },
  {
    name: "stateAvgPrice",
    label: t("thead.state_avg_price"),
    formatter: (value: number) => {
      return `${value ? "$" + value.toFixed(2) : ""}`
    }
  },

  {
    name: "status",
    label: t("thead.status")
  },

  {
    name: "notes",
    label: t("thead.notes"),
    width: "120px"
  },

  {
    name: "booker",
    label: t("thead.booker")
  }
])

onUnmounted(() => {
  query.value = {}
  companyIds.value = []
})

onBeforeMount(() => {
  fetchBookings()
  fetchBookingsCount()
})

const filteredCompany = () => {
  nextTick(() => {
    fetchBookings()
  })
}

const refresh = () => {
  query.value.page = 0
  fetchBookings()
  fetchBookingsCount()
}
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="flex w-full flex-wrap items-center gap-4">
        <div class="w-1/6">
          <LazySelect
            v-model="query.driverId"
            value-prop="id"
            content-refreshable
            :options="driverList"
            :placeholder="t('labels.driver')"
            :fetch="fetchDriverList"
            :get-option-label="(item: any) => fullName(item?.first_name, item?.last_name) || ''"
            @update:model-value="refresh"
          />
        </div>
        <div class="w-1/6">
          <LazySelect
            v-model="query.bookerId"
            value-prop="id"
            content-refreshable
            :options="selectUsers"
            :content="{ id: filterStats?.id, name: filterStats?.name }"
            :contenteditable="filterStats && !!filterStats.id"
            :placeholder="t('placeholders.select_booker')"
            :fetch="getUsersLazySelect"
            :get-option-label="
              (item: any) =>
                item?.nickname + ' ' + fullName(item?.firstname, item?.lastname) + ' ' + `(${item?.truck_count})`
            "
            @update:model-value="refresh"
          >
            <template #option="{ option }">
              <span class="w-4/5">
                {{ option?.nickname + " " + fullName(option?.firstname, option?.lastname) }}
              </span>
              <span class="absolute right-2"> ({{ option?.truck_count }}) </span>
            </template>
          </LazySelect>
        </div>
        <div>
          <LazySelect
            v-model="query.truckId"
            value-prop="id"
            content-refreshable
            :options="truckList"
            :placeholder="t('placeholders.select_truck')"
            :fetch="fetchTruckList"
            :get-option-label="(item: any) => item.truck_id || ''"
            @update:model-value="refresh"
          />
        </div>
        <div>
          <LazySelect
            v-model="companyIds"
            mode="multiple"
            class="tags-select min-w-[200px]"
            value-prop="id"
            tooltip
            characters="companies"
            content-refreshable
            :hide-selected="false"
            :close-on-select="false"
            :options="companyList"
            :placeholder="t('placeholders.all_companies')"
            :fetch="getCompanyLazySelect"
            :get-option-label="(item: any) => item.name || ''"
            @update:model-value="filteredCompany"
          />
        </div>
        <div>
          <DatePicker
            v-model="query.inputDate"
            auto-apply
            teleport="body"
            class="min-w-36"
            model-type="format"
            :enable-time-picker="false"
            :dark="isDark"
            :placeholder="$t('placeholders.date')"
            :max-date="new Date()"
            @update:model-value="refresh"
          />
        </div>
      </div>
    </template>
    <div class="flex items-center justify-end gap-5">
      <HeaderAmountItem :amount="bookingsCount.content" :description="$t('labels.total_books')" />

      <ButtonIcon variant="ghost" color="success" :loading="listLoading" @click="refresh">
        <Icon icon="lucide:refresh-ccw" :width="20" />
      </ButtonIcon>
    </div>
  </TableHeader>

  <Table
    class="mt-4"
    no-wrap
    :skeleton="listLoading"
    :loading="listLoading"
    :cols="cols"
    :rows="list"
    head-class="bg-slate-200 dark:bg-gray-800"
  >
    <template #idx="{ idx, sequence }">
      {{ sequence(idx, query.page || 0, query.size || 10) }}
    </template>

    <template #amount="{ row }">
      {{ row.isFull ? "Full" : row.amount }}
    </template>

    <template #status="{ row }">
      {{ row.status ? DELETING_REASONS[row.status] : "" }}
    </template>
  </Table>
  <TableFooter
    v-model:page="query.page!"
    v-model:per-page="query.size!"
    :total="paginationTotal"
    @change="fetchBookings"
  />
</template>

import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { CardAssignModel, CardEditModel, CardStatisticsModel, CardModel } from "~/management/card"
import { CARD_STATUS } from "~/management/card/contants"

export const useCardStore = defineStore("card", () => {
  const list = ref<CardModel[]>([])
  const listLoading = ref<boolean>(false)
  const form = ref<CardAssignModel>(new CardAssignModel())
  const formEdit = ref<CardEditModel>(new CardEditModel())
  const formLoading = ref<boolean>(false)
  const cardStatistics = ref<CardStatisticsModel>(new CardStatisticsModel())
  const companyIds = ref<number[]>([])
  const selectList = ref<any>([])
  const query = ref({
    keyword: "",
    page: 0,
    size: 100,
    total: 0,
    truck_id: null,
    status: CARD_STATUS.ACTIVE,
    booker_id: null,
    is_used: null
  })

  const transformQuery = () => {
    return pickBy<unknown>(query.value, identity)
  }

  return {
    selectList,
    companyIds,
    list,
    cardStatistics,
    listLoading,
    query,
    form,
    formEdit,
    formLoading,
    transformQuery
  }
})

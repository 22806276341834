import { useTransactionsApi, useTransactionsStore } from "~/management/transaction"
import { TransactionModel } from "~/management/transaction/transaction.model"

export const useTransactionsService = () => {
  const { GET_TRANSACTIONS, GET_TRANSACTIONS_COUNT } = useTransactionsApi()
  const transactionsStore = useTransactionsStore()

  const { list, companyIds, transactionsCount, listLoading, paginationTotal, query } = storeToRefs(transactionsStore)
  const getTransactionsCount = async () => {
    await GET_TRANSACTIONS_COUNT().then(({ content }) => {
      transactionsCount.value = content
    })
  }
  const fetchTransactionsList = () => {
    listLoading.value = true
    if (query.value.input_date) query.value.input_date = format(new Date(query.value.input_date), "MM/DD/YYYY")

    GET_TRANSACTIONS(transactionsStore.transformQuery(), companyIds.value)
      .then(({ content, totalElements }) => {
        list.value = content.map((item) => new TransactionModel(item))
        paginationTotal.value = totalElements ?? 0
      })
      .finally(() => (listLoading.value = false))
  }

  return {
    getTransactionsCount,
    fetchTransactionsList
  }
}

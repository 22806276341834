import { createRouter, createWebHistory } from "vue-router"
import { routes } from "@/pages"
import { useSessionMiddleware } from "~/session"

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => useSessionMiddleware(to, from, next))

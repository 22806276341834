<template>
  <div
    ref="mapContainer"
    :class="[
      'border border-gray-200 bg-white px-4 py-2 transition-all duration-300 dark:border-gray-700 dark:bg-gray-900',
      isFullscreen ? 'fixed inset-0 z-50' : 'relative h-[500px]'
    ]"
  >
    <div class="flex h-full w-full flex-col text-xs">
      <div class="flex flex-nowrap items-center justify-between border-b border-gray-200 pb-1 dark:border-gray-700">
        <h6>
          {{ $t("labels.weather_map") }}
        </h6>

        <ButtonIcon
          v-if="isFullscreen"
          variant="ghost"
          color="secondary"
          icon="lucide-minimize-2"
          width="20"
          @click="exitFullScreen"
        />
        <ButtonIcon
          v-else
          variant="ghost"
          color="secondary"
          icon="lucide-maximize-2"
          width="20"
          @click="handleFullscreenToggle"
        />
      </div>
      <div id="map" class="!h-[100%]"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import L, { Map } from "leaflet"

import { useFullscreen } from "@vueuse/core"

onMounted(() => {
  initMap()
})

const isFullscreen = ref<boolean>(false)

const exitFullScreen = () => {
  isFullscreen.value = false
  handleFullscreenChange()
}

const handleFullscreenChange = () => {
  if (document.fullscreenElement && isFullscreen.value) {
    console.log("Entered fullscreen mode")
  } else {
    handleFullscreenToggle()
  }
}

document.addEventListener("fullscreenchange", handleFullscreenChange)

// Clean up the event listeners when the component is unloaded
window.addEventListener("beforeunload", () => {
  document.removeEventListener("fullscreenchange", handleFullscreenChange)
})

const apiKey = import.meta.env.APP_OPEN_WEATHER_APP_ID

const initMap = () => {
  map.value = L.map("map").setView([37.8, -96], 4) // Center of the USA

  // Base layer from OpenStreetMap
  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    maxZoom: 19,
    attribution: "© OpenStreetMap contributors"
  }).addTo(map.value)

  // Add temperature layer
  addTemperatureLayer()

  // Add markers and temperature labels for each city
  addCityMarkers()
}

const addTemperatureLayer = () => {
  const temperatureLayer = L.tileLayer(`https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=${apiKey}`, {
    maxZoom: 19,
    attribution: '&copy; <a href="https://openweathermap.org">OpenWeatherMap</a>'
  })

  // Add to the map
  temperatureLayer.addTo(map.value)
}
const mapContainer = ref<HTMLElement | null>(null)
const maps = ref<Map | null>(null)

const { toggle: toggleFullscreen } = useFullscreen(mapContainer)

const handleFullscreenToggle = async () => {
  isFullscreen.value = !isFullscreen.value
  await toggleFullscreen()

  setTimeout(() => {
    maps.value?.invalidateSize()
  }, 100)
}

const cities = ref([
  // Add your cities here...
  // { name: "New York", coords: [40.7128, -74.006] },
  // { name: "Los Angeles", coords: [34.0522, -118.2437] },
  // { name: "Chicago", coords: [41.8781, -87.6298] }
  // ... other cities
])
const fetchTemperature = async (location: any) => {
  try {
    const response = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${location.coords[0]}&lon=${location.coords[1]}&appid=${apiKey}&units=metric`
    )

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()
    return data.main.temp
  } catch (error) {
    console.error("Error fetching temperature:", error)
    return null
  }
}

const addCityMarkers = async () => {
  for (const city of cities.value) {
    const temp = await fetchTemperature(city)
    if (temp === null) continue

    const label = L.divIcon({
      className: "temperature-label",
      html: `<div style="background: blue; color: white; font-weight: bold; font-size: 12px; padding: 2px 5px; border-radius: 5px;">${temp}°C</div>`,
      iconSize: [60, 30],
      iconAnchor: [30, 15]
    })

    L.marker(city.coords, { icon: label }).addTo(map.value)
  }
}
</script>

<style>
#map {
  width: 100%;
}

.temperature-label {
  border-radius: 5px; /* Rounded corners */
  padding: 5px; /* Padding */
}
</style>

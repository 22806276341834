<script setup lang="ts">
import { computed } from "vue"

interface IProps {
  href?: string
  label?: string
  disabled?: boolean
  to?: string | Record<string, any>
  color?: "primary" | "secondary" | "success" | "warning" | "danger" | "default" | "info"
}

const props = withDefaults(defineProps<IProps>(), { color: "primary" })

const linkClass = computed(() => ({
  link: true,
  "link-disabled": props.disabled,
  ["link-" + props.color]: true
}))

const linkAttrs = computed(() => {
  if (props.to) return { is: "router-link", to: props.to }
  else if (props.href) return { is: "a", href: props.href }
  else return { is: "a", href: "#" }
})
</script>

<template>
  <component v-bind="{ ...linkAttrs, ...$attrs }" :is="linkAttrs.is" :class="linkClass" :disabled="disabled">
    <slot>{{ label }}</slot>
  </component>
</template>

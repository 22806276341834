// @ts-expect-error: TypeScript does not recognize this module
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js"

import { useStationsApi } from "~/map/stations/stations.api"
import { useStationsStore } from "~/map/stations/stations.store"
import { BookStopModel, useFuelStore } from "~/management/fuel"

import { CalcRouteQueryParams, DriverLocation, RouterRoute, StationWithDistance } from "~/map/stations/stations.model"
import { ICoordinate, ShortCoordinate } from "$/models"

import { mapRouteData } from "$/mockData/mapRouteData"
import { formatLocation } from "$/utils/location"
import { storeToRefs } from "pinia"
import { useManagementService, useManagementStore } from "~/management"

import { booleanPointInPolygon, point as turfPoint } from "@turf/turf"
import { StoresListTable } from "~/management/priceStores"
import { STATES } from "~/map/stations/assets/states"

const { driverLocation, waypoints } = mapRouteData

export const useStationsService = () => {
  const { FETCH_ROUTES_CALCULATION, FETCH_ADDRESS_GEOCODE } = useStationsApi()

  const { fetchStoresList } = useManagementService()

  const managementStore = useManagementStore()
  const mapStore = useStationsStore()

  const { storeList } = storeToRefs(managementStore)
  const { geocodedAddresses, mapContainer, error, globalPlatform, globalMap, currentAddress } = storeToRefs(mapStore)

  let map: H.Map | null = null
  let routePolyline: H.map.Polyline | null = null
  let ui: H.ui.UI | null = null
  let platform: H.service.Platform | null = null
  let fuelStationGroup: H.map.Group | null = null
  let defaultLayers: any = null

  const polylineStates = new Set<string>()

  const cleanupMap = () => {
    let map = globalMap.value
    if (fuelStationGroup) {
      fuelStationGroup.removeAll()
      fuelStationGroup = null
    }
    if (map) {
      map.removeObjects(map.getObjects())
      map.dispose()
      map = null
    }
    if (ui) {
      ui.dispose()
      ui = null
    }
    platform = null
    defaultLayers = null
    routePolyline = null
    polylineStates.clear()
    window.removeEventListener("resize", () => map?.getViewPort()?.resize())
  }

  function initMapv2() {
    if (!mapContainer.value) return

    platform = new H.service.Platform({
      apikey: import.meta.env.APP_HERE_API_KEY
    })

    globalPlatform.value = platform

    const engineType = H.Map.EngineType["HARP"]
    defaultLayers = platform.createDefaultLayers({ engineType })

    defaultLayers.raster.normal.map.setMin(3)
    defaultLayers.raster.normal.mapnight.setMin(3)
    defaultLayers.raster.satellite.map.setMin(3)

    map = new H.Map(
      mapContainer.value,
      isDark.value ? defaultLayers.vector.normal.mapnight : defaultLayers.vector.normal.map,
      {
        engineType,
        pixelRatio: window.devicePixelRatio || 1,
        center: { lat: 47.7511, lng: -120.7401 },
        zoom: 4,
        padding: { top: 50, right: 50, bottom: 50, left: 50 }
      }
    )

    globalMap.value = map

    ui = H.ui.UI.createDefault(map, defaultLayers)

    new H.mapevents.Behavior(new H.mapevents.MapEvents(map))
    const zoomRectangle = new H.ui.ZoomRectangle({
      alignment: H.ui.LayoutAlignment.BOTTOM_RIGHT
    })
    ui.addControl("rectangle", zoomRectangle)

    window.addEventListener("resize", () => map?.getViewPort().resize())
  }

  const initMap = () => {
    if (!mapContainer.value || geocodedAddresses.value.length === 0) return

    cleanupMap()

    platform = new H.service.Platform({
      apikey: import.meta.env.APP_HERE_API_KEY
    })

    globalPlatform.value = platform

    const engineType = H.Map.EngineType["HARP"]
    defaultLayers = platform.createDefaultLayers({ engineType })

    defaultLayers.raster.normal.map.setMin(3)
    defaultLayers.raster.normal.mapnight.setMin(3)
    defaultLayers.raster.satellite.map.setMin(3)

    map = new H.Map(
      mapContainer.value,
      isDark.value ? defaultLayers.vector.normal.mapnight : defaultLayers.vector.normal.map,
      {
        engineType,
        pixelRatio: window.devicePixelRatio || 1,
        center: new H.geo.Point(geocodedAddresses.value[0].lat, geocodedAddresses.value[0].lng),
        zoom: 4,
        padding: { top: 50, right: 50, bottom: 50, left: 50 }
      }
    )

    globalMap.value = map

    ui = H.ui.UI.createDefault(map, defaultLayers)

    new H.mapevents.Behavior(new H.mapevents.MapEvents(map))
    const zoomRectangle = new H.ui.ZoomRectangle({
      alignment: H.ui.LayoutAlignment.BOTTOM_RIGHT
    })
    ui.addControl("rectangle", zoomRectangle)

    fuelStationGroup = new H.map.Group()
    map.addObject(fuelStationGroup)

    window.addEventListener("resize", () => map?.getViewPort().resize())
  }

  const updateMapTheme = (isDark: boolean) => {
    if (!map || !defaultLayers) return

    map.setBaseLayer(isDark ? defaultLayers.vector.normal.mapnight : defaultLayers.vector.normal.map)
    globalMap.value?.setBaseLayer(isDark ? defaultLayers.vector.normal.mapnight : defaultLayers.vector.normal.map)
  }

  const processWaypointsWithDriver = (
    driverLocation: DriverLocation,
    originalWaypoints: typeof waypoints
  ): ShortCoordinate[] => {
    const { latitude, longitude } = driverLocation.coordinates

    const driverPoint: ShortCoordinate = {
      lat: latitude,
      lng: longitude
    }

    const waypointCoordinates = originalWaypoints.map((wp) => ({
      lat: wp.latitude,
      lng: wp.longitude,
      type: wp.type
    }))

    // Find the next pickup/delivery point based on distance
    let nearestPointIndex = 0
    let shortestDistance = Infinity

    waypointCoordinates.forEach((point, index) => {
      const distance = calculateHaversineDistance(driverPoint, point)
      if (distance < shortestDistance) {
        shortestDistance = distance
        nearestPointIndex = index
      }
    })

    const remainingWaypoints = waypointCoordinates.slice(nearestPointIndex)

    return [driverPoint, ...remainingWaypoints]
  }

  const getWaypoints = async () => {
    geocodedAddresses.value = processWaypointsWithDriver(driverLocation, waypoints)
  }

  // Helper function to calculate distance between two points (Haversine formula)
  const calculateHaversineDistance = (point1: ShortCoordinate, point2: ShortCoordinate) => {
    const R = 6371 // Earth's radius in km
    const dLat = ((point2.lat - point1.lat) * Math.PI) / 180
    const dLon = ((point2.lng - point1.lng) * Math.PI) / 180
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((point1.lat * Math.PI) / 180) *
        Math.cos((point2.lat * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return R * c
  }

  const getDistanceToLineSegment = (
    point: ShortCoordinate,
    lineStart: ShortCoordinate,
    lineEnd: ShortCoordinate
  ): number => {
    const A = point.lat - lineStart.lat
    const B = point.lng - lineStart.lng
    const C = lineEnd.lat - lineStart.lat
    const D = lineEnd.lng - lineStart.lng

    const dot = A * C + B * D
    const lenSq = C * C + D * D
    let param = -1

    if (lenSq !== 0) {
      param = dot / lenSq
    }

    let xx, yy
    if (param < 0) {
      xx = lineStart.lat
      yy = lineStart.lng
    } else if (param > 1) {
      xx = lineEnd.lat
      yy = lineEnd.lng
    } else {
      xx = lineStart.lat + param * C
      yy = lineStart.lng + param * D
    }

    return calculateHaversineDistance(point, { lat: xx, lng: yy })
  }

  const addCurrentLocationMarker = (coordinates: ICoordinate, directionAngle: number): void => {
    const rotateDegree = directionAngle ?? 0
    const domIconElement = document.createElement("div")
    domIconElement.style.margin = "-12px 0 0 -12px" // Center the 24x24 icon
    domIconElement.id = "domMarker"
    domIconElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" style="transform: rotate(${rotateDegree}deg)">
  <path
    d="m5 21l-1-1l8-18l8 18l-1 1l-7-3z"
    fill="#30B0C7"
    fill-opacity="1"
    stroke-width="2"
    stroke="#4D4D4D"
    stroke-linejoin="round"
    stroke-linecap="round"
  />
</svg>`

    const domIcon = new H.map.DomIcon(domIconElement, { icon: domIconElement })

    const marker = new H.map.DomMarker(
      { lat: coordinates.latitude, lng: coordinates.longitude },
      {
        icon: domIcon,
        zIndex: 50,
        data: "truck"
      }
    )
    globalMap.value.addObject(marker)
  }

  const addFuelStationMarkers = async (route: any, routeBuffer: number, coordinates: ICoordinate, topTen: boolean) => {
    const map = globalMap.value

    if (!fuelStationGroup) {
      fuelStationGroup = new H.map.Group()
      map.addObject(fuelStationGroup)
    }

    if (!map || !fuelStationGroup) return 0

    fuelStationGroup.removeAll()

    const driverPosition: ShortCoordinate = {
      lat: coordinates?.latitude,
      lng: coordinates?.longitude
    }

    const routePoints: Array<ShortCoordinate> = []
    route.sections.forEach((section: any) => {
      if (!section.polyline) return
      const sectionLineString = H.geo.LineString.fromFlexiblePolyline(section.polyline)
      const pointsArray = sectionLineString.getLatLngAltArray()

      for (let i = 0; i < pointsArray.length; i += 3) {
        routePoints.push({
          lat: pointsArray[i],
          lng: pointsArray[i + 1]
        })
      }
    })

    await fetchStoresList({ page: 0, size: 1000, active: true }, [...polylineStates])
    const fuelStations = storeList.value?.content ?? []

    const stationsWithDistances: StationWithDistance[] = await Promise.all(
      fuelStations
        .filter((station) => station.latitude !== null && station.longitude !== null)
        .filter((station) => {
          const stationPoint = {
            lat: station.latitude as number,
            lng: station.longitude as number
          }

          for (let i = 0; i < routePoints.length - 1; i++) {
            const distance = getDistanceToLineSegment(stationPoint, routePoints[i], routePoints[i + 1])
            if (distance <= routeBuffer / 1000) return true
          }
          return false
        })
        .sort((a: any, b: any) => a.actual_price - b.actual_price)
        .slice(0, topTen ? 10 : Infinity)
        .map(async (station) => {
          const stationPoint = {
            lat: station.latitude as number,
            lng: station.longitude as number
          }

          const routeDistance = await calculateRouteDistance(driverPosition, stationPoint)

          return {
            ...station,
            distanceToDriver: routeDistance || 0
          }
        })
    )

    // Sorting stations distance
    stationsWithDistances.sort((a, b) => a.distanceToDriver - b.distanceToDriver)

    stationsWithDistances.forEach((station) => {
      const actualPrice = station.actual_price ?? 0
      const gasStationMarker = new H.map.DomIcon(`
      <div class="min-w-[100px] rounded-lg bg-[#4B7132] text-white font-sans shadow-md relative cursor-pointer" style="z-index: -1">
        <div class="text-xl font-bold text-center px-3 py-0.5">
          ${actualPrice.toFixed(3)}
        </div>
    
        <div class="bg-white text-black rounded-b-lg flex items-center justify-between p-1.5 gap-3 text-sm">
          <div class="flex items-center gap-1">
            <svg class="w-3.5 h-3.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 20V7C4 5.89543 4.89543 5 6 5H13C14.1046 5 15 5.89543 15 7V20M4 20H15M4 20H3M15 20H16M14 9H19.4C19.7314 9 20 9.26863 20 9.6V16.4C20 16.7314 19.7314 17 19.4 17H17.5M7 9H12M8 12H11" 
                    stroke="orange" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <span class="text-green-700">${station.store_number}</span>
          </div>
    
          <div>${station.distanceToDriver.toLocaleString()} miles</div>
        </div>
      </div>
    `)

      const marker = new H.map.DomMarker(
        { lat: station.latitude, lng: station.longitude },
        {
          data: station
        }
      )

      marker.setIcon(gasStationMarker)

      fuelStationGroup?.addEventListener("tap", (evt: H.util.Event) => {
        const marker = evt.target
        if (marker instanceof H.map.DomMarker) {
          const station = marker.getData() as StationWithDistance
          const { handleAddStopToSidebar, bookForm } = useFuelStore()
          handleAddStopToSidebar(
            new BookStopModel({
              id: station.id,
              stop_order: bookForm.stops.length,
              store: new StoresListTable({
                id: station.id,
                store_number: station.store_number,
                name: station.name,
                address: station.address,
                city: station.city ?? "",
                state: station.state ?? "",
                active: station.active ?? false,
                latitude: station.latitude,
                longitude: station.longitude,
                interstate: station.interstate ?? "",
                actual_price: station.actual_price
              }),
              distance: station.distanceToDriver,
              address: formatLocation({ address: station.address ?? "", city: station.city ?? "" }),
              price: station.actual_price ?? 0,
              amount: 0,
              is_full: false
            })
          )
        }
      })

      fuelStationGroup?.addObject(marker)
    })

    return stationsWithDistances.length
  }

  const onDrawRoute = (route: any) => {
    if (!map) return

    const routeLineString = new H.geo.LineString()

    route.sections.forEach((section: any) => {
      if (!section.polyline) return
      const sectionLineString = H.geo.LineString.fromFlexiblePolyline(section.polyline)
      const pointsArray = sectionLineString.getLatLngAltArray()

      for (let i = 0; i < pointsArray.length; i += 3000) {
        const lat = pointsArray[i]
        const lng = pointsArray[i + 1]
        const point = turfPoint([lng, lat])

        for (const state of STATES) {
          if (booleanPointInPolygon(point, state)) {
            polylineStates.add(state.properties.state)
            break
          }
        }
      }
      sectionLineString.getLatLngAltArray().forEach((point: number, idx: number) => {
        if (idx % 3 === 0) {
          routeLineString.pushLatLngAlt(
            point,
            sectionLineString.getLatLngAltArray()[idx + 1],
            sectionLineString.getLatLngAltArray()[idx + 2]
          )
        }
      })
    })

    if (routePolyline) map.removeObject(routePolyline)

    routePolyline = new H.map.Polyline(routeLineString, {
      data: null,
      style: {
        lineWidth: 5,
        strokeColor: "rgba(0, 128, 255, 0.7)",
        lineCap: "round",
        lineJoin: "round"
      }
    })

    map.addObject(routePolyline)

    // Add waypoint markers
    // addStopMarkersToMap()

    map.getViewModel().setLookAtData(
      {
        bounds: routePolyline.getBoundingBox()!
      },
      true
    )
  }
  const geocodeAddress = (address: any) => {
    return new Promise((resolve, reject) => {
      globalPlatform.value.getSearchService().reverseGeocode(
        {
          at: `${address.lat},${address.lng}`
        },
        (result) => {
          const title = result.items[0].title
          resolve(title)
        },
        (error) => {
          console.error(`Error fetching geocoding data for ${address}:`, error)
          reject(error)
        }
      )
    })
  }
  const showingCurrentLocation = async (currentLocation: ICoordinate | null, directionAngle: number) => {
    if (!currentLocation) {
      cleanupMap()
      return
    }

    if (!map || geocodedAddresses.value.length < 2) {
      console.error("Map not initialized or insufficient addresses")
      return
    }

    addCurrentLocationMarker(currentLocation, directionAngle)

    await geocodeAddress({ lat: currentLocation?.latitude, lng: currentLocation?.longitude }).then((res) => {
      if (res) {
        // console.log(res, "res")
        console.log(res, "RESPONSE")
        currentAddress.value = res
      }
    })
  }

  function showDriverLocation(driverLocation: ICoordinate | null, directionAngle: number = 0) {
    if (!driverLocation) return
    addCurrentLocationMarker(driverLocation, directionAngle)
  }

  const calculateRouteAndUpdateMap = async (
    currentLocation: ICoordinate | null,
    addresses: string[],
    directionAngle: number,
    topTen: boolean
  ) => {
    if (!currentLocation) {
      cleanupMap()
      return
    }

    const map = globalMap.value

    if (!map || geocodedAddresses.value.length < 2) {
      console.error("Map not initialized or insufficient addresses")
      return
    }
    await geocodeAddresses(addresses)
    addCurrentLocationMarker(currentLocation, directionAngle)
    addStopMarkersToMap()

    const origin = geocodedAddresses.value[0]
    const destination = geocodedAddresses.value[geocodedAddresses.value.length - 1]
    const waypoints = geocodedAddresses.value.slice(0, -1)

    const params = new URLSearchParams()

    params.append("origin", `${origin.lat},${origin.lng}`)
    waypoints.forEach((waypoint) => {
      params.append("via", `${waypoint.lat},${waypoint.lng}`)
    })
    params.append("destination", `${destination.lat},${destination.lng}`)

    // Add remaining waypoints as via points
    if (geocodedAddresses.value.length > 2) {
      const viaPoints = geocodedAddresses.value
        .slice(1, -1) // Exclude origin (driver) and destination
        .map((addr) => `${addr.lat},${addr.lng}`)

      viaPoints.forEach((point) => params.append("via", point))
    }

    try {
      const result = await FETCH_ROUTES_CALCULATION(params)
      // console.log(result, "result")
      const routes = result?.data?.routes

      for (let i = 0; i < routes.length; i++) {
        onDrawRoute(routes[i])

        await addFuelStationMarkers(routes[i], 2000, currentLocation, topTen)
      }
    } catch (error: any) {
      handleMapError(error)
    }
  }

  const markerColors = computed(() => {
    return geocodedAddresses.value.map((_, index) => {
      if (index === 0) {
        return "#469946"
      } else if (index === geocodedAddresses.value.length - 1) {
        return "#FF4646"
      } else {
        return "#FFC107"
      }
    })
  })

  // TODO: uncomment in case stop markers are in need
  const addStopMarkersToMap = () => {
    if (!map) return

    map.getObjects().forEach((obj: any) => {
      if (obj instanceof H.map.Marker) {
        map?.removeObject(obj)
      }
    })

    geocodedAddresses.value.forEach((address, index) => {
      const fillColor = markerColors.value[index]
      const icon = new H.map.DomIcon(
        `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 384 512" style="margin-left: -15px; margin-top: -40px">
      <path fill="${fillColor}"
            d="M192 0C86.4 0 0 86.4 0 192c0 76.8 25.6 99.2 172.8 310.4a24 24 0 0 0 38.4 0C358.4 291.2 384 268.8 384 192 384 86.4 297.6 0 192 0zm84.85 242.91a16 16 0 1 1-16-16 16 16 0 0 1 16 16z"/>
      <text x="192" y="280" font-family="Arial" font-size="250" text-anchor="middle" fill="#FFF">${String.fromCharCode(65 + index)}</text>
    </svg>`
      )

      const marker = new H.map.DomMarker({ lat: address.lat, lng: address.lng }, { icon })
      map?.addObject(marker)
    })
  }

  const handleMapError = (err: any) => {
    console.error("Error in StationsMap:", err)
    error.value = err.message || "An error occurred while processing the map data."
  }

  const calculateETAAndDistance = async (origin: ShortCoordinate, destination: ShortCoordinate) => {
    const params = new URLSearchParams()
    params.append("origin", `${origin.lat},${origin.lng}`)
    params.append("destination", `${destination.lat},${destination.lng}`)

    try {
      const result = await FETCH_ROUTES_CALCULATION(params)
      return result?.data?.routes[0]?.sections[0]
    } catch (error) {
      console.error("Error calculating ETA and distance:", error)
      handleMapError(error)
      return null
    }
  }

  const calculateRouteDistance = async (
    origin: ShortCoordinate,
    destination: ShortCoordinate
  ): Promise<number | null> => {
    try {
      const params = new URLSearchParams()
      params.append("origin", `${origin.lat},${origin.lng}`)
      params.append("destination", `${destination.lat},${destination.lng}`)

      const result = await FETCH_ROUTES_CALCULATION(params)
      const route = result?.data?.routes[0]

      const distanceInMeters = route?.sections[0]?.summary.length
      return Math.round(distanceInMeters * 0.000621371)
    } catch (error) {
      console.error("Error calculating route distance:", error)
      return null
    }
  }

  const geocodeAddresses = async (addresses: string[]) => {
    if (addresses.length === 0) {
      console.log("No addresses to geocode")
      return
    }

    geocodedAddresses.value = await Promise.all(
      addresses.map(async (address) => {
        try {
          const response = await FETCH_ADDRESS_GEOCODE(address)
          if (!response.data.items.length) {
            console.info(`No results found for address: ${address}`)
          }
          const location = response.data.items[0]?.position
          return {
            lat: location.lat,
            lng: location.lng,
            label: address
          }
        } catch (err) {
          console.error(`Error geocoding address "${address}":`, err)
          throw err
        }
      })
    )
  }

  async function calculateRoute(queryParams: CalcRouteQueryParams): Promise<RouterRoute[] | undefined> {
    const router = globalPlatform.value.getRoutingService(null, 8)
    const {
      routingMode = "fast",
      origin,
      destination,
      returnInfo = "polyline",
      transportMode = "truck",
      lang = "en-US",
      intermediate,
      alternatives = 2
    } = queryParams

    const routingParameters = {
      transportMode,
      origin,
      destination,
      routingMode,
      return: returnInfo,
      alternatives,
      lang
    }

    if (intermediate && intermediate.length > 0) {
      routingParameters.via = new H.service.Url.MultiValueQueryParameter(
        intermediate.map((wp) => `${wp.position.lat},${wp.position.lng}`)
      )
    }

    try {
      const route = await router.calculateRoute(routingParameters)
      return route.routes
    } catch (error) {
      console.log(error, "ERROR CALC ROUTE")
    }
  }

  return {
    geocodeAddress,
    getWaypoints,
    initMap,
    handleMapError,
    calculateRouteAndUpdateMap,
    cleanupMap,
    showingCurrentLocation,
    calculateETAAndDistance,
    calculateHaversineDistance,
    calculateRouteDistance,
    updateMapTheme,
    map,
    initMapv2,
    calculateRoute,
    showDriverLocation,
    addFuelStationMarkers
  }
}

import { SuggestionStop } from "~/management/fuel/fuel.model"

interface MatchResult {
  matched: boolean
  failedSide: string | null
  actualStyles: {
    border: string
    amount: string
    price: string
  }
  suggestedStyles: {
    border: string
    amount: string
    price: string
  }
}

export const useMatchColors = (stop: SuggestionStop): MatchResult => {
  const baseStyle = "text-gray-500"
  // const redStyle = "text-red-500"
  // const greenStyle = "text-green-500"
  const redBorder = "border-red-500"
  const greenBorder = "border-green-500"
  const defaultResult = {
    matched: true,
    failedSide: "red",
    actualStyles: {
      border: "",
      amount: baseStyle,
      price: baseStyle
    },
    suggestedStyles: {
      border: "",
      amount: baseStyle,
      price: baseStyle
    }
  }

  if (!stop?.ppu || !stop?.quantity) return defaultResult

  // const actualPriceInCents = Math.round(stop.ppu * 100)
  // const suggestedPriceInCents = Math.round(stop.actual_price * 100)
  // const actualAmount = stop.quantity
  // const suggestedAmount = stop.amount

  const result = { ...defaultResult }

  // // Check if amounts match
  // if (actualAmount !== suggestedAmount) {
  //   result.matched = false
  //   if (actualAmount > suggestedAmount) {
  //     // @ts-expect-error failedSide can be string
  //     result.failedSide = "actual"
  //     result.actualStyles.amount = redStyle
  //     result.actualStyles.border = redBorder
  //     result.suggestedStyles.border = greenBorder
  //     result.suggestedStyles.amount = greenStyle
  //   } else {
  //     // @ts-expect-error failedSide can be string
  //     result.failedSide = "suggested"
  //     result.suggestedStyles.amount = redStyle
  //     result.suggestedStyles.border = redBorder
  //     result.actualStyles.border = greenBorder
  //     result.actualStyles.amount = greenStyle
  //   }
  // }
  //
  // // Check if prices match
  // if (actualPriceInCents !== suggestedPriceInCents) {
  //   result.matched = false
  //   if (actualPriceInCents > suggestedPriceInCents) {
  //     // @ts-expect-error failedSide can be string
  //     result.failedSide = "actual"
  //     result.actualStyles.price = redStyle
  //     result.suggestedStyles.price = greenStyle
  //     result.actualStyles.border = redBorder
  //     result.suggestedStyles.border = greenBorder
  //   } else {
  //     // @ts-expect-error failedSide can be string
  //     result.failedSide = "suggested"
  //     result.suggestedStyles.price = redStyle
  //     result.actualStyles.price = greenStyle
  //     result.suggestedStyles.border = redBorder
  //     result.actualStyles.border = greenBorder
  //   }
  // }
  //
  // if (stop.store_number !== stop.supplier_code) {
  //   result.matched = false
  // }
  //
  if (stop.store_number === stop.supplier_code) {
    result.matched = true
    result.failedSide = "green"
    result.actualStyles.border = greenBorder
    result.suggestedStyles.border = greenBorder
  } else {
    result.matched = false
    result.failedSide = "red"
    result.actualStyles.border = redBorder
    result.suggestedStyles.border = redBorder
  }

  return result
}

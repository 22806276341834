export interface FuelPrice {
  retail_price: number
  disc_retail: number
  savings_total: number
  actual_price: number
  effective_date: string
}

export class PricesModelTable {
  id!: number
  store_number: string = ""
  address: string = ""
  fuel_prices: FuelPrice[] = []

  constructor(data?: Partial<PricesModelTable>) {
    if (data) {
      this.id = data.id!
      this.store_number = data.store_number || ""
      this.address = data.address || ""
      this.fuel_prices = data.fuel_prices || []
    }
  }
}

export class PricesHistoryModelTable {
  id!: number
  store_number: string = ""
  city: string = ""
  state: string = ""
  fuel_prices: FuelPrice[] = []

  constructor(data?: Partial<PricesHistoryModelTable>) {
    if (data) {
      this.id = data.id!
      this.state = data.state || ""
      this.store_number = data.store_number || ""
      this.city = data.city || ""
      this.fuel_prices = data.fuel_prices || []
    }
  }
}

export class StoresAssignModel {
  id!: number
  name: string = ""
  store: string = ""
  state: string = ""
  address: string = ""
  city: string = ""
  country: string = ""
  interstate: string = ""
  latitude: Nullable<number> = null
  longitude: Nullable<number> = null
  zip_code: string = ""
  logo_url: string = ""
  filename: string = ""

  constructor(data?: Partial<StoresAssignModel>) {
    if (data) {
      this.id = data.id!
      this.name = data.name || ""
      this.store = data.store_number || ""
      this.state = data.state || ""
      this.address = data.adress || ""
      this.city = data.city || ""
      this.country = data.country || ""
      this.interstate = data.interstate || ""
      this.latitude = data.latitude || null
      this.longitude = data.longitude || null
      this.zip_code = data.zip_code || ""
      this.logo_url = data.logo_url || ""
      this.filename = data.filename || ""
    }
  }
}

export class StoresStatistics {
  activeStores: number = 0
  inactiveStores: number = 0
  totalStores: number = 0

  constructor(data?: Partial<StoresStatistics>) {
    if (data) {
      this.activeStores = data.activeStores || 0
      this.inactiveStores = data.inactiveStores || 0
      this.totalStores = data.totalStores || 0
    }
  }
}

export class PriceStoresStatistics {
  page: number = 0
  size: number = 20
  total: number = 0
  totalPages: number = 0
  fromDate: string = ""
  toDate: string = ""
  keyword: Nullable<string> = null
  fuel_station_id: Nullable<number> = null
  state: Nullable<string> = null

  constructor(data?: PriceStoresStatistics) {
    if (data) {
      this.page = data.page
      this.size = data.size
      this.total = data.total
      this.totalPages = data.totalPages
      this.fromDate = data.fromDate
      this.toDate = data.toDate
      this.keyword = data.keyword
      this.fuel_station_id = data.fuel_station_id
    }
  }
}

export class StoresListTable {
  id!: number
  store_number: string = ""
  address: string = ""
  state: string = ""
  city: string = ""
  name: string = ""
  active: boolean = false
  latitude: Nullable<number> = null
  longitude: Nullable<number> = null
  interstate: Nullable<string> = null
  actual_price: Nullable<number> = null
  fuel_station_id: Nullable<number> = null
  zip_code: string = ""

  constructor(data?: Partial<StoresListTable>) {
    if (data) {
      this.id = data.id!
      this.store_number = data.store_number ?? ""
      this.address = data.address ?? ""
      this.city = data.city ?? ""
      this.active = data.active ?? false
      this.state = data.state ?? ""
      this.latitude = data.latitude ?? null
      this.longitude = data.longitude ?? null
      this.interstate = data.interstate ?? null
      this.actual_price = data.actual_price ?? null
      this.fuel_station_id = data.fuel_station_id ?? null
      this.name = data.name ?? ""
      this.zip_code = data.zip_code ?? ""
    }
  }
}

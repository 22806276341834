<script setup lang="ts">
import { useRouteProtection } from "$/composables/useRouteProtection"
import CardList from "~/management/card/components/CardList.vue"
import CardAssignModal from "~/management/card/components/CardAssignModal.vue"
import CardEditModal from "~/management/card/components/CardEditModal.vue"
import CardHistoryModal from "~/management/card/components/CardHistoryModal.vue"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const { isChecking } = useRouteProtection(RolePermissionsModel.CAN_ACCESS_CARDS_PAGE)
</script>

<template>
  <div v-if="!isChecking" class="primary-container">
    <CardList v-if="profile.authorities.has(RolePermissionsModel.CAN_CARD_PAGE_GET)" />
    <CardAssignModal />
    <CardEditModal />
    <CardHistoryModal />
  </div>
</template>

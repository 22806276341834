<script setup lang="ts">
import { computed, ref } from "vue"
import { Icon } from "@iconify/vue"

defineOptions({ inheritAttrs: false })
const shown = ref(false)
const type = computed(() => (shown.value ? "text" : "password"))
</script>

<template>
  <div class="relative">
    <Input v-bind="$attrs" class="!pr-10 w-full" :type="type" />

    <div
      class="absolute top-0 bottom-0 right-0 grid w-10 text-gray-400 cursor-pointer place-items-center"
      @click="shown = !shown"
    >
      <Icon v-if="shown" icon="lucide:eye-off" :width="20" />
      <Icon v-else icon="lucide:eye" :width="20" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import TrucksMenu from "~/management/trucks/components/TrucksMenu.vue"
import { TrucksModel, useTrucksService, useTrucksStore } from "~/management/trucks"
import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"
import { Icon } from "@iconify/vue"
import { ButtonIcon, DatePicker } from "$/ui"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"
import { push } from "notivue"
import { i18n } from "@/app/plugins"
import Select from "@vueform/multiselect"

const modal = useModal()
const { t } = useI18n()

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)
const { fetchTrucksList, debouncedSearch } = useTrucksService()

const truckStore = useTrucksStore()

const refresh = () => {
  query.value.page = 0
  fetchTrucksList()
}

const clearSearch = () => {
  query.value.keyword = ""
  fetchTrucksList()
}
const onDateChange = (value: string[] | null): void => {
  if (value && value[0] && value[1]) {
    refresh()
  } else if (value && value[0]) {
    push.warning(i18n.global.t(`messages.range_did_not_selected`))
    query.value.dateRange = []
  } else {
    refresh()
  }
}

const { list, listLoading, query } = storeToRefs(truckStore)

const statusList = ref<any>([
  {
    label: "Active",
    value: "true"
  },
  {
    label: "Inactive",
    value: "false"
  }
])

const cols = computed<ITableCol<TrucksModel>[]>(() => [
  {
    name: "idx",
    label: t("thead.no"),
    width: "40px",
    dataClass: "text-center"
  },
  {
    name: "truck_id",
    label: t("thead.truckId")
  },
  {
    name: "driver_name",
    label: t("thead.driverName")
  },
  {
    name: "vin_number",
    label: t("thead.vinNumber")
  },
  {
    name: "samsara_vin",
    label: t("thead.samsaraVin")
  },

  {
    name: "company_name",
    label: t("thead.companyName")
  },
  {
    name: "created_at",
    label: t("thead.created_at"),
    formatter: (value: string) => (value ? toZone(value, "MM/DD/YY HH:mm CT") : "")
  },

  {
    name: "active",
    label: t("thead.status")
  }
])

onMounted(() => {
  fetchTrucksList()
})
onUnmounted(() => {
  query.value.dateRange = []
  query.value.page = 0
  query.value.size = 10
  query.value.keyword = ""
  query.value.truck_id = null
})
</script>

<template>
  <TableHeader class="mb-4">
    <template #left>
      <div class="flex w-full flex-wrap items-center gap-4">
        <div>
          <InputSearch
            v-model="query.keyword"
            :value="query.keyword"
            class="!h-9"
            @clear="clearSearch"
            @input="debouncedSearch"
          />
        </div>
        <div>
          <DatePicker
            v-model="query.dateRange"
            teleport="body"
            range
            hide-input-icon
            :dark="isDark"
            :enable-time-picker="false"
            :placeholder="$t('placeholders.period')"
            :max-date="new Date()"
            class="min-w-44"
            @update:model-value="onDateChange"
          />
        </div>
        <div>
          <Select
            v-model="query.status"
            searchable
            label="label"
            :options="statusList"
            append-to-body
            :placeholder="t('placeholders.select_status')"
            class="select-user min-w-36 !rounded-none"
            @update:model-value="refresh"
          />
        </div>
      </div>
    </template>

    <div class="flex items-center justify-end gap-5">
      <HeaderAmountItem :amount="query.total" :description="$t('labels.total_trucks')" />

      <ButtonIcon :loading="listLoading" color="success" variant="ghost" @click="fetchTrucksList">
        <Icon :width="20" icon="lucide:refresh-ccw" />
      </ButtonIcon>
    </div>
  </TableHeader>
  <Table
    :cols="cols"
    :loading="listLoading"
    :rows="list"
    :skeleton="listLoading"
    class="mt-4"
    head-class="bg-slate-200 dark:bg-gray-800"
    no-wrap
  >
    <template #idx="{ idx, sequence }">
      {{ sequence(idx, query.page, query.size) }}
    </template>
    <template #samsara_vin="{ row }">
      <div class="flex items-center gap-2">
        <span class="w-[140px]">{{ row.samsara_vin }}</span>
        <ButtonIcon
          v-if="profile.authorities.has(RolePermissionsModel.CAN_TRUCK_SET_SAMSARA_VIN)"
          :label="$t('actions.edit')"
          color="info"
          size="sm"
          variant="ghost"
          @click.stop="
            modal.show('truck_vin_sam', {
              id: row?.id,
              samsara_vin: row?.samsara_vin
            })
          "
        >
          <Icon :width="14" icon="lucide:pencil" />
        </ButtonIcon>
      </div>
    </template>

    <template #active="{ row }">
      <badge :color="[row.active ? 'success' : 'danger']"> {{ row.active ? "Active" : "Inactive" }}</badge>
    </template>
  </Table>
  <TableFooter v-model:page="query.page" v-model:per-page="query.size" :total="query.total" @change="fetchTrucksList" />
  <TrucksMenu />
</template>

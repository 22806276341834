<script setup lang="ts">
import { ButtonIcon, DatePicker, Icon } from "$/ui"
import { useManagementStore, useManagementService } from "~/management"
import { IdlingModel, useIdlingService, useIdlingStore } from "~/management/idling"
import { toDuration } from "$/utils/date"
import { push } from "notivue"
import { i18n } from "@/app/plugins"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const { fetchTruckList, fetchDriverList, getCompanyLazySelect } = useManagementService()
const { t } = useI18n()
const modal = useModal()

const idlingStore = useIdlingStore()
const managementStore = useManagementStore()
const sessionStore = useSessionStore()

const { profile } = storeToRefs(sessionStore)
const { driverList, truckList, companyList } = storeToRefs(managementStore)
const { list, listLoading, companyIds, query } = storeToRefs(idlingStore)

const { fetchIdlingList } = useIdlingService()

const cols = computed<ITableCol<IdlingModel>[]>(() => {
  const baseCols = [
    {
      name: "idx",
      label: t("thead.no")
    },
    {
      name: "truck_id",
      label: t("thead.truck")
    },
    {
      name: "driver_name",
      label: t("thead.driver")
    },

    {
      name: "total_duration_sec",
      label: t("thead.duration"),
      formatter: (value: number) => (value ? toDuration(value) : "")
    },
    {
      name: "total_gallons",
      label: t("thead.gallons")
    },
    {
      name: "booker",
      label: t("thead.booker")
    }
  ]

  if (profile.value.authorities.has(RolePermissionsModel.CAN_IDLING_PAGE_GET)) {
    baseCols.push({
      name: "actions",
      label: t("thead.actions"),
      headClass: "flex items-center justify-center",
      dataClass: "flex items-center justify-center"
    })
  }

  return baseCols
})

const handleShowDetailsModal = (data: IdlingModel) => {
  modal.show("idling-details", data)
}

const fetchFilteredIdlingList = (): void => {
  query.value.page = 0
  fetchIdlingList()
}

const onDateChange = (value: string[] | null): void => {
  if (value && value[0] && value[1]) {
    fetchFilteredIdlingList()
  } else if (value && value[0]) {
    push.warning(i18n.global.t(`messages.range_did_not_selected`))
    query.value.dateRange = []
  } else {
    fetchFilteredIdlingList()
  }
}

onBeforeMount(() => {
  fetchIdlingList()
})
onUnmounted(() => {
  query.value = {}
  query.value.dateRange = []
  companyIds.value = []
})

const filteredCompany = () => {
  nextTick(() => {
    fetchFilteredIdlingList()
  })
}
</script>

<template>
  <TableHeader class="mb-4">
    <template #left>
      <div class="flex w-full flex-wrap items-center gap-4">
        <div class="w-1/6">
          <LazySelect
            v-model="query.driver_id"
            value-prop="id"
            content-refreshable
            :options="driverList"
            :placeholder="t('labels.driver')"
            :fetch="fetchDriverList"
            :get-option-label="(item: any) => fullName(item?.first_name, item?.last_name) || ''"
            @update:model-value="fetchFilteredIdlingList"
          />
        </div>
        <div>
          <LazySelect
            v-model="query.truck_id"
            value-prop="id"
            content-refreshable
            :options="truckList"
            :placeholder="t('placeholders.select_truck')"
            :fetch="fetchTruckList"
            :get-option-label="(item: any) => item.truck_id || ''"
            @update:model-value="fetchFilteredIdlingList"
          />
        </div>
        <div>
          <LazySelect
            v-model="companyIds"
            mode="multiple"
            class="tags-select min-w-[200px]"
            value-prop="id"
            tooltip
            characters="companies"
            content-refreshable
            :hide-selected="false"
            :close-on-select="false"
            :options="companyList"
            :placeholder="t('placeholders.all_companies')"
            :fetch="getCompanyLazySelect"
            :get-option-label="(item: any) => item.name || ''"
            @update:model-value="filteredCompany"
          />
        </div>

        <div>
          <DatePicker
            v-model="query.dateRange"
            teleport="body"
            range
            hide-input-icon
            :dark="isDark"
            :enable-time-picker="false"
            :placeholder="$t('placeholders.period')"
            :max-date="new Date()"
            class="min-w-44"
            @update:model-value="onDateChange"
          />
        </div>
      </div>
    </template>

    <div class="flex items-center justify-end gap-5">
      <ButtonIcon variant="ghost" color="success" :loading="listLoading" @click="fetchIdlingList">
        <Icon icon="lucide:refresh-ccw" :width="20" />
      </ButtonIcon>
    </div>
  </TableHeader>

  <Table
    no-wrap
    :skeleton="listLoading"
    :loading="listLoading"
    :cols="cols"
    :rows="list"
    head-class="bg-slate-200 dark:bg-gray-800"
  >
    <template #idx="{ idx, sequence }">
      {{ sequence(idx, query.page || 0, query.size || 10) }}
    </template>
    <template #actions="{ row }">
      <Button variant="ghost" color="info" before-icon="lucide:eye" @click="handleShowDetailsModal(row)">
        <span> {{ $t("actions.see_details") }} </span>
      </Button>
    </template>
  </Table>

  <TableFooter v-model:page="query.page" v-model:per-page="query.size" :total="query.total" @change="fetchIdlingList" />
</template>

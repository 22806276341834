export const isDark = useDark({ storageKey: "theme" })

export const cleanQuery = (query: Record<string, any>) => {
  const cleanedObject = Object.assign({}, query)

  for (const key in cleanedObject) {
    const value = cleanedObject[key]
    if (typeof value === "string") {
      const trimmedValue = value.trim()
      cleanedObject[key] = trimmedValue.length > 0 ? trimmedValue : null
    }
  }

  return cleanedObject
}

export const disableKeys = (event: KeyboardEvent, keys: string[]) => {
  if (keys.includes(event.key)) event.preventDefault()
}

import { Ref } from "vue"

import { useBookersStore } from "~/management/bookers/bookers.store"
import { useBookersApi } from "~/management/bookers/bookers.api"
import { BookersModel, BookersStatsModel, BookingParams, BookingTotals } from "~/management/bookers/bookers.model"

export const useBookersService = () => {
  const { GET_BOOKINGS, GET_BOOKING_TOTAL_COUNT, GET_BOOKINGS_COUNT, GET_BOOKING_TOTAL, GET_BOOKING_STATS } =
    useBookersApi()

  const bookerStore = useBookersStore()
  const { list, companyStatsIds, companyIds, bookingsCount, listLoading, paginationTotal } = storeToRefs(bookerStore)
  const fetchBookingsCount = () => {
    GET_BOOKINGS_COUNT().then(({ content }) => {
      bookingsCount.value.content = content || 0
    })
  }
  const fetchBookings = () => {
    listLoading.value = true
    // @ts-expect-error transformQuery returns BookingParams indeed
    GET_BOOKINGS(bookerStore.transformQuery(), companyIds.value)
      .then(({ content, totalElements }) => {
        list.value = content.map((booking) => new BookersModel(booking))
        paginationTotal.value = totalElements ?? 0
      })
      .finally(() => (listLoading.value = false))
  }

  const fetchBookingStatistics = async (
    statsList: Ref<BookersStatsModel[]>,
    total: Ref<number>,
    params: BookingParams,
    loading: Ref<boolean>
  ) => {
    loading.value = true

    // filter out empty inputDate because backend requires date
    const filteredParams: BookingParams = {
      ...params,
      ...(params.inputDate === "" && { inputDate: null })
    }

    const res = await GET_BOOKING_TOTAL_COUNT(filteredParams, companyStatsIds.value)
    await GET_BOOKING_STATS(filteredParams, companyStatsIds.value)
      .then(({ content, totalElements }) => {
        const stats = content.map((booking) => new BookersStatsModel(booking))
        statsList.value = [...stats, res]
        total.value = totalElements ?? 0
      })
      .finally(() => (loading.value = false))
  }

  const fetchBookingsTotal = async (total: Ref<BookingTotals>, statsQuery: BookingParams) => {
    GET_BOOKING_TOTAL(statsQuery.inputDate!, statsQuery.bookerId)
      .then(({ content }) => {
        total.value = content
      })
      .finally(() => (listLoading.value = false))
  }

  return { fetchBookingsCount, fetchBookings, fetchBookingsTotal, fetchBookingStatistics }
}

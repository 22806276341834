import type { AxiosInstance } from "axios"
import { StoresStatistics, StoresListTable, PricesModelTable, PricesHistoryModelTable } from "~/management/priceStores"

export const usePriceStoresApi = () => {
  const axios: Readonly<AxiosInstance> = useAxios()
  const base: Readonly<string> = "/api/v1"

  return {
    GET_PRICE_TABLE(params: any): ResponseContainer<PricesModelTable> {
      return axios.$get(`${base}/fuel`, { params })
    },
    GET_PRICE_HISTORY_LIST_TABLE(params: any): ResponseContainer<PricesHistoryModelTable> {
      return axios.$get(`${base}/fuel`, { params })
    },
    GET_PRICE_HISTORY_AVG_PRICE(params: any): ResponseContainer<any> {
      return axios.$get(`${base}/fuel/stations/daily-avg`, { params })
    },
    GET_STORE_STATISTICS(): ResponseContainer<StoresStatistics> {
      return axios.$get(`${base}/fuel/statistics`)
    },
    GET_ONE_STORE(id: number): ResponseContainer<StoresListTable> {
      return axios.$get(`${base}/fuel/${id}`)
    },
    GET_STORE_LIST_TABLE(params: any): ResponseContainer<StoresListTable> {
      return axios.$get(`${base}/fuel/stores`, { params })
    },
    POST_NEW_STORE(stores: any): ResponseContainer<PricesModelTable> {
      return axios.$post(`${base}/fuel`, stores)
    },
    PUT_NEW_STORE(stores: any): ResponseContainer<PricesModelTable> {
      return axios.$put(`${base}/fuel`, stores)
    },
    PATCH_ACTIVE_STORE(id: number) {
      return axios.$patch(`${base}/fuel/activate/${id}`)
    },
    PATCH_DEACTIVATE_STORE(id: number) {
      return axios.$patch(`${base}/fuel/deactivate/${id}`)
    }
  }
}

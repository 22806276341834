<template>
  <Modal
    id="book_details"
    size="full"
    :label="$t('labels.book_details')"
    :loading="loading"
    :close-on-backdrop="false"
    :close-on-esc="false"
    @before-show="onBeforeShow"
    @hide="onHide"
  >
    <div class="h-full p-2.5">
      <FuelDetailsHeader
        v-if="truck_data?.truck_id || truck_data?.unit_number"
        :truck="truck_data"
        @reload="loadDetails(suggestion_id!)"
      />
      <div v-for="(stop, index) in sortedStopsByOrder" :key="stop.store_number" class="relative -m-2.5 flex flex-col">
        <FuelDetailsModalSuggestion :stop :index @reload="loadDetails(suggestion_id!)" />
        <div
          v-if="stop.transaction_date && checkTransaction === stop.transaction_date && truck_data?.truck_id"
          class="mt-4 grid grid-cols-1 p-2.5 lg:grid-cols-11"
        >
          <div class="col-span-5">
            <FuelDetailsTruck :index :truck="truck_data" :route="stop.route" />
          </div>
        </div>
        <div
          v-if="stop.is_insert_point && !checkTransaction && truck_data?.truck_id"
          class="mt-4 grid grid-cols-1 p-2.5 lg:grid-cols-11"
        >
          <div class="col-span-5">
            <FuelDetailsTruck :index :truck="truck_data" :route="stop.route" />
          </div>
        </div>

        <div class="hidden">{{ stop.route }}</div>
      </div>
    </div>

    <FuelBookEditSidebar @reload="loadDetails(suggestion_id!)" />
    <FuelBookDeleteSidebar @reload="loadDetails(suggestion_id!)" />
  </Modal>
</template>

<script setup lang="ts">
import FuelDetailsHeader from "~/management/fuel/components/details/FuelDetailsHeader.vue"
import FuelBookEditSidebar from "~/management/fuel/components/sidebars/FuelBookEditSidebar.vue"
import FuelBookDeleteSidebar from "~/management/fuel/components/sidebars/FuelBookDeleteSidebar.vue"
import FuelDetailsModalSuggestion from "~/management/fuel/components/details/FuelDetailsModalSuggestion.vue"
import FuelDetailsTruck from "~/management/fuel/components/details/FuelDetailsTruck.vue"

import { SuggestionStop, useFuelService, useFuelStore } from "~/management/fuel"

const { getFuelSuggestionDetails, getFuelSuggestionDetailsVinNumber } = useFuelService()

const fuelStore = useFuelStore()
const { isOpenEditSidebar, isOpenDeleteSidebar, suggestion_stops, truck_data } = storeToRefs(fuelStore)

const loading = ref<boolean>(false)
const suggestion_id = ref<number | null>(null)

const checkTransaction = computed(() => {
  for (let i = sortedStops.value.length - 1; i >= 0; i--) {
    const stop = sortedStops.value[i]
    if (stop.transaction_date) {
      return stop.transaction_date
    }
  }
})

const sortedStopsByOrder = computed(() => {
  return sortedStops.value.sort((a, b) => a?.stop_order - b?.stop_order)
})

const sortedStops = computed(() => {
  const stops = [...suggestion_stops.value]
  const supplierCodeIndex = stops.findIndex((stop) => !!stop?.supplier_code)

  const insertPoint = {
    station_latitude: truck_data.value?.latitude,
    station_longitude: truck_data.value?.longitude,
    store_number: "",
    supplier_code: null,
    is_insert_point: true
  }

  const alreadyExists = stops.some(
    (stop) =>
      stop.is_insert_point &&
      stop.station_latitude === insertPoint.station_latitude &&
      stop.station_longitude === insertPoint.station_longitude
  )

  if (!alreadyExists) {
    if (supplierCodeIndex !== -1) {
      stops.splice(supplierCodeIndex + 1, 0, insertPoint as SuggestionStop)
    } else {
      stops.unshift(insertPoint as SuggestionStop)
    }
  }

  return stops
})

const loadDetails = async (id: number) => {
  await getFuelSuggestionDetails(id, loading)
}

const onBeforeShow = async (val: any) => {
  if (!val.details && !val.vin_details) return
  if (val.details) {
    suggestion_id.value = val.details
    await loadDetails(val.details)
  } else {
    await getFuelSuggestionDetailsVinNumber(val.vin_details, loading)
  }
}

const onHide = () => {
  truck_data.value = null
  suggestion_stops.value = []
  loading.value = false
  suggestion_id.value = null
  isOpenEditSidebar.value.clear()
  isOpenDeleteSidebar.value.clear()
}
</script>

<script setup lang="ts">
import { ButtonIcon, Icon } from "$/ui"

const toggle = useToggle(isDark)

const icon = computed(() => (isDark.value ? "lucide:sun" : "lucide:moon"))
</script>

<template>
  <ButtonIcon variant="ghost" color="secondary" @click="toggle()">
    <Icon :key="icon" :icon="icon" :width="20" />
  </ButtonIcon>
</template>

<script setup lang="ts">
import { DashboardBookerStats, DashboardMpgStats, DashboardPriceMap, DashboardWeatherMap } from "~/management/dashboard"
import { RolePermissionsModel } from "~/management/roles"
import { useSessionStore } from "~/session"

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)
</script>

<template>
  <div class="primary-container relative grid grid-cols-1 gap-1 lg:grid-cols-2 2xl:grid-cols-3">
    <DashboardMpgStats />
    <DashboardBookerStats
      v-if="profile.authorities.has(RolePermissionsModel.CAN_SUGGESTION_STOP_BOOKINGS_STATS_PAGE_GET)"
    />
    <DashboardPriceMap />
    <DashboardWeatherMap />
  </div>
</template>

import Swal from "sweetalert2/dist/sweetalert2.js"
import type { SweetAlertOptions, SweetAlertResult } from "sweetalert2"

const alertOptions: SweetAlertOptions = {
  customClass: {
    cancelButton: "button button-md button-white button-secondary w-full",
    confirmButton: "button button-md button-solid button-primary w-full"
  }
}

const alert = Swal.mixin(alertOptions)
const toast = alert?.mixin({
  toast: true,
  backdrop: false,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true
})

const successDialog = (options: SweetAlertOptions): Promise<SweetAlertResult> => {
  return alert?.fire({
    icon: "success",
    showConfirmButton: false,
    timer: 1500,
    ...options
  })
}

const infoDialog = (options: SweetAlertOptions): Promise<SweetAlertResult> => {
  return alert?.fire({
    icon: "info",
    showConfirmButton: false,
    timer: 1500,
    ...options
  })
}

const warningDialog = (options: SweetAlertOptions): Promise<SweetAlertResult> => {
  return alert?.fire({
    icon: "warning",
    showConfirmButton: false,
    timer: 1500,
    ...options
  })
}

const errorDialog = (options: SweetAlertOptions): Promise<SweetAlertResult> => {
  return alert?.fire({
    icon: "error",
    showConfirmButton: false,
    timer: 1500,
    ...options
  })
}

const successToast = (message: string | any, options?: SweetAlertOptions): Promise<SweetAlertResult> => {
  return toast?.fire({
    title: message,
    icon: "success",
    ...options
  })
}

const errorToast = (message: string | any, options?: SweetAlertOptions): Promise<SweetAlertResult> => {
  return toast?.fire({
    title: message,
    icon: "error",
    ...options
  })
}

const showToast = (options: SweetAlertOptions): Promise<SweetAlertResult> => {
  return toast?.fire(options)
}

export const useAlert = () => {
  const { t } = useI18n()

  const confirmDialog = (
    options: SweetAlertOptions,
    color: string = "secondary",
    opacity: string = "500"
  ): Promise<SweetAlertResult> => {
    // @ts-expect-error color is defined as string which was not Color type
    const c = colors[color][opacity]

    return alert?.fire({
      icon: "question",
      iconColor: c,
      showCancelButton: true,
      customClass: {
        cancelButton: "button button-md button-white button-secondary w-full",
        confirmButton: `button button-md button-solid button-${color} w-full`
      },
      confirmButtonText: t("actions.yes"),
      cancelButtonText: t("actions.no"),
      ...options
    })
  }

  const confirmActivateDeactivate = (val: boolean) => {
    const options = {
      title: val ? t("messages.confirm.activate") : t("messages.confirm.deactivate"),
      confirmButtonText: val ? t("actions.activate") : t("actions.deactivate"),
      cancelButtonText: t("actions.cancel")
    }

    return new Promise((resolve) =>
      confirmDialog(options, "danger").then(({ isConfirmed }) => {
        if (isConfirmed) resolve(isConfirmed)
      })
    )
  }
  const confirmInUsedNotInUsed = (val: boolean) => {
    const options = {
      title: val ? t("messages.confirm.switch_in_use") : t("messages.confirm.switch_not_in_use"),
      confirmButtonText: val ? t("actions.confirm") : t("actions.confirm"),
      cancelButtonText: t("actions.cancel")
    }

    return new Promise((resolve) =>
      confirmDialog(options, "success").then(({ isConfirmed }) => {
        if (isConfirmed) resolve(isConfirmed)
      })
    )
  }
  const confirmSignOut = () => {
    const options = {
      title: t("messages.confirm.logout"),
      confirmButtonText: t("actions.logout"),
      cancelButtonText: t("actions.cancel")
    }

    return new Promise((resolve) =>
      confirmDialog(options, "danger").then(({ isConfirmed }) => {
        if (isConfirmed) resolve(isConfirmed)
      })
    )
  }

  const confirmDeactivate = () => {
    const options = {
      title: t("messages.confirm.deactivate"),
      confirmButtonText: t("actions.deactivate"),
      cancelButtonText: t("actions.cancel")
    }

    return new Promise((resolve) =>
      confirmDialog(options, "danger").then(({ isConfirmed }) => {
        if (isConfirmed) resolve(isConfirmed)
      })
    )
  }

  const confirmDelete = () => {
    const options = {
      title: t("messages.confirm.are_you_sure_to_delete_efs_account"),
      confirmButtonText: t("actions.yes"),
      cancelButtonText: t("actions.cancel")
    }

    return new Promise((resolve) =>
      confirmDialog(options, "danger").then(({ isConfirmed }) => {
        if (isConfirmed) resolve(isConfirmed)
      })
    )
  }

  return {
    confirmDelete,
    confirmDialog,
    successDialog,
    infoDialog,
    warningDialog,
    errorDialog,
    successToast,
    errorToast,
    showToast,
    confirmSignOut,
    confirmActivateDeactivate,
    confirmDeactivate,
    confirmInUsedNotInUsed
  }
}

<template>
  <Sidebar id="book" :label="$t('actions.book')" @before-hide="onHide" @before-show="getTruckInfo">
    <template v-if="form.truck?.driver_dto?.id" #header-icon>
      <Spinner v-if="isCurrentLocationLoading" />
      <Icon icon="lucide:map" @click="isVisibleMap = true" />
    </template>
    <form class="space-y-2.5" @submit.prevent="onSubmit">
      <div class="box">
        <FormGroup v-slot="{ id }" :label="$t('labels.truck')" required v-bind="hasError('truck')">
          <LazySelect
            :id
            v-model="form.truck"
            :fetch="fetchTruckList"
            :get-option-label="(item: any) => item.truck_id || ''"
            :options="truckList"
            :placeholder="t('placeholders.select_truck')"
            content-refreshable
            object
            value-prop="id"
            @select="clearStops"
            @update:model-value="getTruckInfo"
          />
        </FormGroup>

        <div v-if="form.truck?.driver_dto?.id" class="flex flex-nowrap items-center justify-start gap-2 text-xs">
          <p class="text-black dark:text-gray-300">{{ $t("labels.driver") }}:</p>
          <span class="capitalize text-green-700">{{ form.truck.driver_dto.name }}</span>
        </div>
        <div v-if="form.truck?.miles" class="flex flex-nowrap items-center justify-start gap-2 text-xs">
          <p class="text-black dark:text-gray-300">{{ $t("labels.miles") }}:</p>
          <span class="capitalize text-green-700">{{ form.truck.miles }}</span>
        </div>
      </div>
      <div class="max-h-[250px] overflow-y-auto">
        <div v-for="(stop, index) in form.stops" :key="index" class="box relative">
          <FormGroup
            v-slot="{ id }"
            :label="$t('labels.stop', { order: index! + 1 })"
            required
            v-bind="hasError(`stops.${index}.store`)"
          >
            <LazySelect
              :id
              v-model="stop.store"
              :content="{ id: stop.store?.id, name: stop.store?.store_number }"
              :contenteditable="!!stop.store?.id"
              :fetch="fetchStoresList"
              :get-option-label="(item: any) => item.store_number || ''"
              :options="storeList"
              :placeholder="t('placeholders.store')"
              append-to-body
              content-refreshable0
              object
              value-prop="id"
              @select="getValue(stop)"
            />
          </FormGroup>

          <div v-if="stop?.store" class="flex w-full items-center justify-between text-xs font-semibold text-green-700">
            <p>{{ `${stop.store?.city ? stop.store?.city + "," : ""} ${stop.store?.state || ""}` }}</p>
            <p>${{ stop.store.actual_price || 0 }}</p>
          </div>
          <div class="grid w-full grid-cols-12">
            <InputCheckbox
              v-model="stop.is_full"
              :label="$t('labels.full')"
              class="col-span-4 self-center"
              @click="stopAdding(stop.is_full, index)"
            />

            <FormGroup v-slot="{ id }" class="col-span-8" required v-bind="hasError(`stops.${index}.amount`)">
              <Input
                :id
                v-model="stop.amount"
                :disabled="stop.is_full"
                :placeholder="$t('placeholders.enter_amount')"
                min="0"
                size="xs"
                type="number"
              />
            </FormGroup>
          </div>

          <Button
            v-if="form.stops.length > 1 && index !== 0"
            class="self-end"
            color="danger"
            variant="ghost"
            @click.prevent="removeStop(index)"
          >
            <Icon :width="20" icon="lucide:trash" />
          </Button>
        </div>
        <span id="contentScrollable" />
      </div>
      <div v-if="infoMessage" class="text-sm text-danger-500">{{ infoMessage }}</div>
      <div v-if="warningMessage" class="text-sm text-warning-500">{{ warningMessage }}</div>
      <Button
        v-if="!isStop"
        :disabled="form.stops?.length > 1"
        :label="$t('actions.add_stop')"
        color="secondary"
        variant="flat"
        @click.prevent="addStop"
      />

      <div v-if="errors.duplicate_stores" class="text-sm text-red-500">
        {{ errors.duplicate_stores }}
      </div>

      <Textarea v-model="form.note" :placeholder="$t('placeholders.notes')" />

      <div class="flex justify-end pb-2.5">
        <Button
          :disabled="Object.keys(errors).length > 0 || isLoading"
          :label="$t('actions.submit')"
          :loading="isLoading"
          type="submit"
        />
      </div>
    </form>
    <FormGroup label="Copy All Stops">
      <ButtonCopy :text-to-copy="copyContent" @click="copyAllContentDetail" />
    </FormGroup>

    <div class="max-h-[330px] overflow-y-auto">
      <div v-for="(stop, index) in form.stops" :key="index">
        <FuelBookStop
          :book-index="index"
          :book-stop="stop"
          :current-location="currentLocation"
          :form="form.stops"
          class="box"
        />
      </div>
      <span id="contentScrollableDetail" />
    </div>
  </Sidebar>
</template>

<script lang="ts" setup>
import { Icon } from "@iconify/vue"
import { storeToRefs } from "pinia"

import FuelBookStop from "~/management/fuel/components/FuelBookStop.vue"

import { useManagementService, useManagementStore } from "~/management"
import { BookForm, BookStopModel, useFuelApi, useFuelService, useFuelStore } from "~/management/fuel"
import { StoresListTable } from "~/management/priceStores"
import { ICoordinate } from "$/models"
import { getGoogleMapsUrl } from "$/utils/location"
import { watch } from "vue"

const { currentLocation } = defineProps<{ currentLocation: ICoordinate | null }>()
const emit = defineEmits<{
  (e: "update:current-location", coordinates: ICoordinate | null): void
  (e: "update:direction-angle", directionAngle: number): void
}>()

const { GET_CURRENT_LOCATION } = useFuelApi()
const management = useManagementStore()
const { truckList, storeList } = storeToRefs(management)
const store = useFuelStore()
const { bookForm: form, isVisibleMap, oldVal, isStop } = storeToRefs(store)
const { addFuelSuggestion, getTruckBookInfo } = useFuelService()
const { fetchTruckList, fetchStoresList } = useManagementService()
const { t } = useI18n()

const isLoading = ref<boolean>(false)
const errors = ref<Record<string, string>>({})
const isCurrentLocationLoading = ref<boolean>(false)

const copyContent = ref<string>("")
const infoMessage = ref<string>("")
const warningMessage = ref<string>("")

const scrollToBottom = () => {
  let element: any = document.getElementById("contentScrollable")
  let elementDetail: any = document.getElementById("contentScrollableDetail")

  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "end" })
    elementDetail.scrollIntoView({ behavior: "smooth", block: "end" })
  }
}

const copyAllContentDetail = async () => {
  copyContent.value = ""
  form.value.stops.forEach((item: any, index: number) => {
    const itemContent = `Fuel stop #${index + 1}

${item.store?.name || ""} #${item.store?.store_number || ""}

Amount: ${item.is_full ? "For a Full Tank" : item.amount ? "For a " + " " + item.amount + " gallons" : 0}

Address:  ${item.store?.address || ""} ${item.store.city || ""} ${item.store.state ? ", " + item.store.state || "" : ""} ${item.store.zip_code || ""}

Interstate: ${item.store?.interstate || ""}

Distance: ${item.distance || 0} miles

Map link: ${
      item.store.latitude && item.store.longitude
        ? getGoogleMapsUrl({
            latitude: item.store.latitude,
            longitude: item.store.longitude
          })
        : ""
    }


`
    copyContent.value += itemContent
  })
}

const validateForm = (): boolean => {
  errors.value = {}
  let isValid = true

  if (!form.value.truck) {
    errors.value["truck"] = t("validations.required")
    isValid = false
  }

  form.value.stops.forEach((stop, index) => {
    if (!stop.store?.id) {
      errors.value[`stops.${index}.store`] = t("validations.required")
      isValid = false
    }
    if (!stop?.amount) {
      errors.value[`stops.${index}.amount`] = t("validations.required")
      isValid = false
    }
  })

  const storeIds = form.value.stops.map((stop) => stop.store?.id).filter((id): id is number => id !== undefined)

  if (storeIds.length !== new Set(storeIds).size) {
    errors.value["duplicate_stores"] = t("validations.duplicateStop")
    isValid = false
  }

  return isValid
}

const clearStops = () => {
  infoMessage.value = ""
  warningMessage.value = ""
  isStop.value = false
  form.value.stops = []
  nextTick(() => {
    store.handleAddStopToSidebar(
      new BookStopModel({
        stop_order: form.value.stops.length,
        store: new StoresListTable(),
        address: "",
        price: null,
        amount: null,
        is_full: false
      })
    )
  })
}

const stopAdding = (val: any, index: number) => {
  isStop.value = !val
  form.value.stops = form.value.stops.splice(0, index + 1)
  if (!val) {
    form.value.stops[index].amount = " "
  } else {
    form.value.stops[index].amount = ""
  }
}

const hasError = (field: string) => {
  const error = errors.value[field]
  return {
    invalid: !!error,
    hint: error
  }
}

const onSubmit = () => {
  const isValid = validateForm()
  if (!isValid) return

  form.value.stops = form.value.stops.map((item: any, index: number) => {
    item.stop_order = index
    return item
  })
  nextTick(() => {
    addFuelSuggestion(isLoading)
    onHide()
  })
}

const onHide = () => {
  form.value = new BookForm()
  errors.value = {}
  infoMessage.value = ""
  warningMessage.value = ""
  isVisibleMap.value = false
  oldVal.value = ""
  isStop.value = false
}

const addStop = () => {
  store.handleAddStopToSidebar(
    new BookStopModel({
      stop_order: form.value.stops.length,
      store: new StoresListTable(),
      address: "",
      price: null,
      amount: null,
      is_full: false
    })
  )
}

const removeStop = (index: number) => {
  if (form.value.stops.length > 1) {
    form.value.stops.splice(index, 1)

    form.value.stops.forEach((stop, idx) => {
      stop.stop_order = idx + 1
    })
    errors.value = {}
  }
}

const getTruckInfo = (): void => {
  if (form.value?.truck?.id) {
    getTruckBookInfo(form.value.truck.id)
  }
  if (form.value.truck) {
    if (!form.value.truck.driver_dto?.id) {
      setTimeout(() => {
        warningMessage.value = "Driver is not being assigned"
        infoMessage.value = ""
      }, 0)
    }

    isCurrentLocationLoading.value = true
    GET_CURRENT_LOCATION(form.value.truck.vin_number)
      .then(({ content }: any) => {
        emit("update:direction-angle", content.heading_degrees ?? 0)
        emit("update:current-location", { latitude: content.latitude || null, longitude: content.longitude || null })
        if (!content.latitude || !content.longitude) {
          warningMessage.value = "Truck Location is missing"
          infoMessage.value = ""
        }
      })
      .catch(() => {
        isVisibleMap.value = false
        emit("update:direction-angle", 0)
        emit("update:current-location", null)
      })
      .finally(() => (isCurrentLocationLoading.value = false))
  } else {
    emit("update:current-location", null)
  }
}

const getValue = (val: any) => {
  if (form.value && form.value.stops?.length === 1) {
    setTimeout(() => {
      if (!val.store.latitude || !val.store.longitude) {
        infoMessage.value = ""
        warningMessage.value = "Stop Location is missing"
        return
      }

      if (form.value.truck && form.value.truck.miles && val && val.distance) {
        const estimatedMiles = form.value.truck.miles
        if (estimatedMiles < val.distance) {
          warningMessage.value = ""
          infoMessage.value = "Unreachable pilot"
        } else if (estimatedMiles > val.distance) {
          infoMessage.value = ""
        }
      } else {
        infoMessage.value = ""
        warningMessage.value = "The truck details are missing."
      }
    }, 1500)
  } else {
    infoMessage.value = ""
  }
}

// Watch stops for store changes

let previousLength = form.value.stops.length

watch(
  () => isVisibleMap.value,
  (val) => {
    if (!val) {
      onHide()
    }
  }
)

watch(
  () => form.value.truck,
  (val) => {
    if (val) {
      errors.value["truck"] = ""
      errors.value = {}
    }
  }
)

watch(
  () => form.value.stops,
  (newStops) => {
    if (newStops.length > previousLength) {
      nextTick(() => {
        scrollToBottom()
      })
    }
    if (newStops.length === 1 && newStops[0]?.store?.store_number) {
      getValue(newStops[0])
    }
    infoMessage.value = ""
    warningMessage.value = ""
    previousLength = newStops.length

    newStops.forEach((_stop, index) => {
      if (errors.value[`stops.${index}.store`]) validateForm()
      if (errors.value[`stops.${index}.amount`]) validateForm()
    })
    if (errors.value["duplicate_stores"]) validateForm()
  },
  { deep: true }
)
</script>

<style scoped>
.box {
  @apply mt-2 flex flex-col gap-2 rounded-sm border border-gray-200 p-2 dark:border-gray-800;
}
</style>

import { cloneDeep } from "lodash"
import { createPinia } from "pinia"
import type { PiniaPluginContext } from "pinia"

const resetStorePlugin = ({ store }: PiniaPluginContext) => {
  const initialState = cloneDeep(store.$state)
  store.$reset = () => store.$patch(cloneDeep(initialState))
}

export const store = createPinia()
store.use(resetStorePlugin)

<template>
  <div class="mx-auto flex w-full items-end">
    <table class="w-full border-collapse text-xs">
      <thead>
        <tr class="text-center font-semibold text-green-700">
          <th class="bg-transparent text-left uppercase text-black">
            {{ $t("thead.stop", { index: stop.stop_order + 1 }) }}
          </th>
          <th class="bg-transparent text-left">{{ $t("thead.location") }}</th>
          <th class="bg-transparent">{{ $t("thead.amount") }}</th>
          <th class="bg-transparent">{{ $t("thead.price") }}</th>
          <th class="bg-transparent">{{ $t("thead.date") }}</th>
          <th class="bg-transparent">{{ $t("thead.booker") }}</th>
        </tr>
      </thead>
      <tbody class="w-full border-2 text-center" :class="[suggestedStyles.border, dynamicColor]">
        <tr>
          <td class="py-1">
            <div class="grid w-24 grid-cols-2 gap-6 p-1">
              <span class="font-semibold">#{{ stop.store_number }}</span>
              <div class="relative">
                <Icon icon="lucide:fuel" width="20" />
                <FuelTruckStopDirection
                  v-if="index !== 0"
                  :distance="stop?.distance"
                  :duration="formatDuration(stop?.duration || 0)"
                  class-name="absolute bottom-8 -right-12"
                />
              </div>
            </div>
          </td>
          <td class="py-1">
            <div class="flex items-center">
              <span v-if="stop?.station_city">{{ `${stop?.station_city}, ${stop?.station_state}` }}</span>
              <ButtonIcon
                v-if="stop.station_latitude && stop.station_longitude"
                icon="lucide:external-link"
                :href="getGoogleMapsUrl({ latitude: stop.station_latitude, longitude: stop.station_longitude })"
                target="_blank"
                color="info"
                width="14"
                variant="ghost"
                size="xs"
              />
            </div>
          </td>
          <td class="py-1" :class="suggestedStyles.amount">{{ stop.is_full ? "FULL" : stop.amount }}</td>
          <td class="py-1 font-semibold" :class="suggestedStyles.price">${{ stop.actual_price?.toFixed(2) }}</td>
          <td class="py-1">{{ toZone(stop.created_at, "MM/DD HH:mm CT") }}</td>
          <td class="py-1 capitalize">{{ stop.nickname }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="!stop?.supplier_code" class="m-1 flex items-center py-1">
      <ButtonIcon
        v-if="stop.is_pending"
        icon="lucide:rotate-cw"
        color="secondary"
        :loading="isLoading"
        width="18"
        size="xs"
        variant="ghost"
        @click="requestTransaction(stop.id)"
      />
      <ButtonIcon v-else icon="lucide:rotate-cw" color="secondary" disabled width="18" size="xs" variant="ghost" />
      <div class="flex items-center">
        <ButtonIcon
          v-if="profile.authorities.has(RolePermissionsModel.CAN_SUGGESTION_STOP_EDIT)"
          icon="lucide:pencil"
          color="secondary"
          width="18"
          size="xs"
          variant="ghost"
          @click="handleOpenEditSidebar"
        />
        <ButtonIcon
          v-if="profile.authorities.has(RolePermissionsModel.CAN_SUGGESTION_STOP_DELETE)"
          icon="lucide:trash"
          color="danger"
          width="18"
          size="xs"
          variant="ghost"
          @click="handleOpenDeleteSidebar"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"

import FuelTruckStopDirection from "~/management/fuel/components/details/FuelTruckStopDirection.vue"
import { SuggestionStop, useFuelStore, useFuelService } from "~/management/fuel"

import { getGoogleMapsUrl } from "$/utils/location"
import { useMatchColors } from "~/management/fuel/fuel.hooks"

import { formatDuration } from "$/utils/string"
import { useSessionStore } from "~/session"
import { RolePermissionsModel } from "~/management/roles"

const { requestPendingTransaction } = useFuelService()
const sidebar = useSidebar()
const fuelStore = useFuelStore()
const { isOpenDeleteSidebar, isOpenEditSidebar, isLoading } = storeToRefs(fuelStore)

const emit = defineEmits(["reload"])

const props = defineProps<{
  stop: SuggestionStop
  index: number
}>()

const sessionStore = useSessionStore()
const { profile } = storeToRefs(sessionStore)

const { suggestedStyles } = useMatchColors(props.stop)

const dynamicColor = computed(() => {
  if (isOpenEditSidebar.value.get(props.index)) {
    return "!border-orange-300 border-dashed bg-orange-100 text-black"
  }

  if (isOpenDeleteSidebar.value.get(props.index)) {
    return "!border-red-300 border-dashed bg-red-100 text-black"
  }

  return "border-gray-200 dark:border-gray-800"
})

const handleOpenEditSidebar = () => {
  isOpenEditSidebar.value.clear()
  sidebar.show("book-edit", props.stop)
  isOpenEditSidebar.value.set(props.index, true)

  nextTick(() => {
    sidebar.hide("book-delete")
  })
}

const requestTransaction = async (id: number) => {
  if (!id) return
  await requestPendingTransaction(id, () => emit("reload"))
}

const handleOpenDeleteSidebar = () => {
  isOpenDeleteSidebar.value.clear()
  sidebar.show("book-delete", {
    stopId: props.stop.id,
    storeNumber: props.stop.store_number,
    location: `${props.stop.station_city}, ${props.stop.station_state}`,
    stopOrder: props.stop.stop_order
  })
  isOpenDeleteSidebar.value.set(props.index, true)

  nextTick(() => {
    sidebar.hide("book-edit")
  })
}
</script>

import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

import { TransactionModel } from "~/management/transaction/transaction.model"

export const useTransactionsStore = defineStore("transaction", () => {
  const list = ref<TransactionModel[]>([])
  const transactionsCount = ref<number>(0)
  const listLoading = ref<boolean>(false)
  const companyIds = ref<number[]>([])
  const query = ref<any>({
    driver_id: null,
    truck_id: null,
    booker_id: null,
    name: null,
    input_date: "",
    page: 0,
    size: 20
  })

  const paginationTotal = ref<number>(0)

  const transformQuery = () => {
    return pickBy<unknown>(query.value, identity)
  }

  return { companyIds, list, transactionsCount, listLoading, query, paginationTotal, transformQuery }
})

<script lang="ts" setup>
import { breakpointsTailwind } from "@vueuse/core"
import { Icon } from "@iconify/vue"
import { SidebarComponentItem, SidebarHeaderItem, SidebarItem, SidebarMenu } from "vue-sidebar-menu"

import { useLayoutStore } from "~/layout"
import { useSessionService, useSessionStore } from "~/session"
import { useModal } from "$/ui"
import { useSidebarMenus } from "~/layout/layout.hooks"

const { confirmLogoutOut } = useSessionService()

type Menu = Array<Partial<SidebarItem> | SidebarComponentItem | SidebarHeaderItem>

const { t } = useI18n()
const store = useLayoutStore()
const route = useRoute()
const modal = useModal()
const breakpoints = useBreakpoints(breakpointsTailwind)
const sidebarMenus = useSidebarMenus()
const isDev = ref<string>("development")

const isDesktop = breakpoints.greaterOrEqual("lg")

const isHoverExpanded = ref<boolean>(false)
const isPinned = ref<boolean>(!store.sidebarIsCollapsed)
const shouldShowSecondNavbar = ref(false)

const sessionStorage = useSessionStore()
const { profile } = storeToRefs(sessionStorage)

const menu = computed<Menu>(() => {
  return sidebarMenus.reduce((acc, item) => {
    if (!item.key || profile.value.authorities.has(item.key)) {
      acc.push(item)
    }
    return acc
  }, [] as Menu)
})

const onOverlayClick = () => (store.sidebarIsShown = false)

const togglePin = () => {
  isPinned.value = !isPinned.value
  store.sidebarIsCollapsed = !isPinned.value
}

watch(
  () => store.sidebarIsCollapsed,
  (val) => {
    if (val) {
      isPinned.value = false
      isHoverExpanded.value = false
    }
  }
)

const onItemClick = (_event: Event, item: any) => {
  if (item?.direction === "profile") modal.show("profile")

  if (item.href.name === "system-settings") {
    shouldShowSecondNavbar.value = true
    isHoverExpanded.value = false
    return
  }
  shouldShowSecondNavbar.value = false
}

const onMouseEnter = () => {
  if (!isPinned.value) {
    isHoverExpanded.value = true
    store.isHover = true
  }
}

const onMouseLeave = () => {
  if (!isPinned.value) {
    isHoverExpanded.value = false
    store.isHover = false
  }
}

const isEffectivelyCollapsed = computed(() => {
  if (isDesktop) {
    return store.sidebarIsCollapsed && !isHoverExpanded.value
  }
  return store.sidebarIsCollapsed
})

const systemSettingRoutes = ["mobile-settings", "mpg-settings"]

// Check if the current route is a system setting route
const isSystemSettingPage = computed(() => {
  return systemSettingRoutes.includes(route.name)
})

onBeforeMount(() => {
  isDev.value = import.meta.env.APP_ENV
})
</script>

<template>
  <Transition mode="out-in" name="app-sidebar-slide">
    <SidebarMenu
      v-show="store.sidebarIsShown"
      :collapsed="isEffectivelyCollapsed"
      :menu="menu"
      :class="[isDev == 'development' ? '!bg-red-500' : '']"
      class="app-sidebar lg:!flex"
      disable-hover
      hide-toggle
      width="280px"
      width-collapsed="72px"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @item-click="onItemClick"
    >
      <template #header>
        <div class="app-sidebar-header">
          <RouterLink v-if="!isEffectivelyCollapsed" class="w-full" to="/" @click="shouldShowSecondNavbar = false">
            <div class="flex items-center gap-4">
              <Transition name="fade">
                <h3 v-if="!isEffectivelyCollapsed" class="grow truncate text-xl font-bold uppercase text-white">
                  {{ isDev === "development" ? t("labels.dev_app_name") : t("labels.app_name") }}
                </h3>
              </Transition>
            </div>
          </RouterLink>
          <div class="cursor-pointer select-none bg-transparent !p-3" @click="togglePin">
            <span v-if="isEffectivelyCollapsed" class="text--2xl font-bold uppercase text-white">gl fb</span>
            <Icon
              v-else
              :icon="isPinned ? 'lucide:pin' : 'lucide:pin-off'"
              :width="24"
              class="text-white transition-transform duration-300 ease-in-out"
            />
          </div>
        </div>
      </template>

      <template v-if="isDesktop" #footer>
        <Button color="secondary" variant="solid" @click="confirmLogoutOut">
          <Icon :width="20" icon="lucide:log-out" />
          {{ $t("actions.logout") }}
        </Button>
      </template>
    </SidebarMenu>
  </Transition>

  <Transition mode="out-in" name="app-sidebar-slide">
    <div
      v-if="shouldShowSecondNavbar || isSystemSettingPage"
      id="secondary-sidebar"
      :class="[isDesktop ? { 'translate-x-[-208px]': isEffectivelyCollapsed } : '!z-[1001] mt-5 translate-x-[0px]']"
      :style="!isDesktop ? { left: '0' } : {}"
      class="fixed bottom-0 left-[280px] top-0 z-[1002] w-[180px] transform border-r border-gray-200 bg-white p-4 transition-transform duration-300 ease-in-out dark:bg-gray-900 dark:text-white"
    >
      <h2 class="mb-4 text-lg font-semibold">Settings</h2>
      <ul class="sub-nav">
        <li class="mb-2 text-sm text-gray-700 hover:text-blue-500">
          <router-link :to="{ name: 'mobile-settings' }">
            {{ $t("menu.mobile_settings") }}
          </router-link>
        </li>
        <li class="mb-2 text-sm text-gray-700 hover:text-blue-500">
          <router-link :to="{ name: 'mpg-settings' }">
            {{ $t("menu.mpg_settings") }}
          </router-link>
        </li>
        <li class="mb-2 text-sm text-gray-700 hover:text-blue-500">
          <router-link :to="{ name: 'efs-settings' }">
            {{ $t("menu.efs_settings") }}
          </router-link>
        </li>
      </ul>
    </div>
  </Transition>
  <!-- Overlay -->

  <Transition mode="out-in" name="app-sidebar-overlay">
    <div v-if="store.sidebarIsShown && !isDesktop" class="app-sidebar-overlay" @click="onOverlayClick" />
  </Transition>
</template>

<style>
.sub-nav li a.router-link-active {
  @apply text-blue-500;
}
</style>

import { useDashboardApi } from "~/management/dashboard/dashboard.api"
import { useDashboardStore } from "~/management/dashboard/dashboard.store"
import { DashboardFuel } from "~/management/dashboard/dashboard.model"

export const useDashboardBookerStats = () => {
  const { GET_BOOKER_STATS, GET_BOOKING_TOTAL_COUNT, GET_MPG_STATS } = useDashboardApi()

  const dashboardStore = useDashboardStore()
  const { bookerStats, mpgDate, queryMpg, query, mpgStats, mpgLoading, bookerDate, bookerId, bookerLoading } =
    storeToRefs(dashboardStore)

  const fetchMpgList = async () => {
    mpgLoading.value = true
    const formattedDate = mpgDate.value
    GET_MPG_STATS({
      year: formattedDate.year || formattedDate.getUTCFullYear() || null,
      month: formattedDate.month + 1 || formattedDate.getUTCMonth() + 1 || null,
      ...queryMpg.value
    })
      .then(({ content, totalPages }) => {
        queryMpg.value.total = totalPages as number
        if (mpgStats.value && mpgStats.value.length) {
          mpgStats.value = [...mpgStats.value, ...content]
          return
        }
        mpgStats.value = content
      })
      .catch((err) => console.error(err))
      .finally(() => (mpgLoading.value = false))
  }

  const fetchBookerStats = async () => {
    bookerLoading.value = true
    const formattedDate = format(new Date(bookerDate.value), "MM/DD/YYYY")
    const res = await GET_BOOKING_TOTAL_COUNT({ inputDate: formattedDate, bookerId: bookerId.value })
    await GET_BOOKER_STATS({ inputDate: formattedDate, bookerId: bookerId.value, ...query.value })
      .then(({ content, totalPages }) => {
        if (!res.books) return
        const data = [...content, res]
        query.value.total = totalPages as number
        if (bookerStats.value && bookerStats.value.length) {
          bookerStats.value = [...bookerStats.value, ...data]
          return
        }
        bookerStats.value = data
      })
      .catch((err) => console.error(err))
      .finally(() => (bookerLoading.value = false))
  }

  return { query, fetchBookerStats, fetchMpgList }
}

export const useDashboardMap = () => {
  const { GET_FUEL_DASHBOARD } = useDashboardApi()

  const mapLoading = ref<boolean>(false)
  const mapPrices = ref<DashboardFuel[]>([])
  const mapDates = ref<string[]>([format(new Date(), "MM/DD/YYYY"), format(new Date(), "MM/DD/YYYY")])

  const fetchFuelPrices = async (): Promise<void> => {
    mapLoading.value = true

    const fromDate = format(new Date(mapDates.value[0]), "MM/DD/YYYY")
    const toDate = format(new Date(mapDates.value[1]), "MM/DD/YYYY")

    await GET_FUEL_DASHBOARD(fromDate, toDate)
      .then((res) => (mapPrices.value = res.content))
      .catch((err) => console.error(err))
      .finally(() => (mapLoading.value = false))
  }

  return { mapPrices, mapLoading, mapDates, fetchFuelPrices }
}

import { useManagementApi, useManagementStore } from "~/management"

export const useManagementService = () => {
  const managementStore = useManagementStore()
  const {
    GET_DRIVERS,
    GET_COMPANIES_SELECT_LIST,
    GET_TRUCKS,
    GET_STORES,
    GET_CARDS,
    GET_COMPANIES,
    CREATE_STORE_LOGO,
    UPLOAD_LOGO
  } = useManagementApi()

  const { companyList, driverList, truckList, storeList, companiesList, cardList } = storeToRefs(managementStore)

  const fetchDriverList = async (params: PaginationParams) => {
    await GET_DRIVERS({
      page: params.page,
      size: params.size,
      keyword: params.keyword
    }).then((res: any) => {
      driverList.value = res
    })
  }
  const getCompanyLazySelect = async (val: { page?: number; size?: number; keyword?: string }) => {
    await GET_COMPANIES_SELECT_LIST({
      page: val.page,
      size: val.size,
      keyword: val.keyword
    }).then((res: any) => {
      companyList.value = res
    })
  }

  const fetchCardList = async (params: PaginationParams) => {
    await GET_CARDS({
      page: params.page,
      size: params.size,
      keyword: params.keyword
    }).then((res: any) => {
      cardList.value = res
    })
  }
  const fetchStoresList = async (
    val: {
      page?: number
      size?: number
      keyword?: string
      active?: boolean
    },
    states?: string[]
  ) => {
    await GET_STORES(
      {
        page: val.page,
        size: val.size,
        active: val.active,
        keyword: val.keyword
      },
      states
    ).then((res) => (storeList.value = res))
  }
  const fetchTruckList = async (params: PaginationParams) => {
    await GET_TRUCKS({
      page: params.page,
      size: params.size,
      keyword: params.keyword
    }).then((res: any) => {
      truckList.value = res
    })
  }
  const fetchCompaniesList = async (params: PaginationParams) => {
    await GET_COMPANIES({
      page: params.page,
      size: params.size,
      keyword: params.keyword
    }).then((res: any) => {
      companiesList.value = res
    })
  }

  const createStoreLogo = async (stations: number[], sending_type: string, formData: FormData) => {
    try {
      return await CREATE_STORE_LOGO(stations, sending_type, formData)
    } catch (e) {
      console.log(e, "e")
    }
  }

  const uploadStationLogo = async (formData: FormData) => {
    try {
      return await UPLOAD_LOGO(formData)
    } catch (e) {
      console.log(e, "e")
    }
  }

  return {
    getCompanyLazySelect,
    fetchStoresList,
    fetchCardList,
    fetchTruckList,
    fetchDriverList,
    fetchCompaniesList,
    createStoreLogo,
    uploadStationLogo
  }
}

import type { RouteRecordRaw } from "vue-router"
import UsersPage from "./management/UsersPage.vue"
import IdlingPage from "./management/IdlingPage.vue"
import PriceStoresPage from "./management/PriceStores.vue"
import TransactionsPage from "./management/TransactionsPage.vue"
import BookersPage from "./management/BookersPage.vue"
import CardsPage from "./management/CardPage.vue"
import FuelBookPage from "./management/FuelBookPage.vue"
import DashboardPage from "./management/DashboardPage.vue"
import RolesPage from "./management/RolesPage.vue"
import SystemSettingsPage from "@/pages/management/SystemSettingsPage.vue"
import TrucksPage from "@/pages/management/TrucksPage.vue"

export const routes: RouteRecordRaw[] = [
  {
    path: "/session/sign-in",
    name: "sign-in",
    component: () => import("@/pages/session/SessionSignInPage.vue"),
    meta: { public: true, layout: "EmptyLayout" }
  },
  {
    path: "/session/forgot-password",
    name: "forgot-password",
    component: () => import("@/pages/session/SessionForgotPasswordPage.vue"),
    meta: { public: true, layout: "EmptyLayout" }
  },
  {
    path: "/session/reset-password",
    name: "reset-pass",
    component: () => import("@/pages/session/SessionResetPassPage.vue"),
    meta: {
      layout: "EmptyLayout",
      public: true
    }
  },
  {
    path: "/",
    redirect: "/management/dashboard"
  },
  {
    path: "/management/dashboard",
    name: "dashboard",
    component: DashboardPage
  },
  {
    path: "/management/fuel-book",
    name: "fuel-book",
    component: FuelBookPage
  },
  {
    path: "/management/cards",
    name: "cards",
    component: CardsPage
  },
  {
    path: "/management/trucks",
    name: "trucks",
    component: TrucksPage
  },
  {
    path: "/management/transactions",
    name: "transactions",
    component: TransactionsPage
  },
  {
    path: "/management/idling",
    name: "idling",
    component: IdlingPage
  },
  {
    path: "/management/users",
    name: "users",
    component: UsersPage
  },
  {
    path: "/management/roles",
    name: "roles",
    component: RolesPage
  },
  {
    path: "/management/system-settings",
    name: "system-settings",
    // component: SystemSettingsPage,
    redirect: () => {
      return { name: "mobile-settings" }
    }
  },
  {
    path: "/management/system-settings/mobile",
    name: "mobile-settings",
    component: SystemSettingsPage
  },
  {
    path: "/management/system-settings/mpg",
    name: "mpg-settings",
    component: SystemSettingsPage
  },
  {
    path: "/management/system-settings/efs",
    name: "efs-settings",
    component: SystemSettingsPage
  },
  {
    path: "/management/price-stores",
    name: "price-stores",
    component: PriceStoresPage
  },
  {
    path: "/management/report",
    name: "report",
    component: () => import("@/pages/management/ReportPage.vue")
  },
  {
    path: "/management/bookers",
    name: "bookers",
    component: BookersPage
  }
]

<script setup lang="ts">
import { useTrucksService } from "~/management/trucks"

const { editTruck, fetchTrucksList } = useTrucksService()

const modal = useModal()
const formLoading = ref<boolean>(false)
const samsaraVinNumber = ref<string>("")
const truckIdObject = ref<number | null>(null)

const onSave = async () => {
  try {
    formLoading.value = true
    await editTruck(truckIdObject.value!, { samsara_vin: samsaraVinNumber.value })
    fetchTrucksList()
  } finally {
    formLoading.value = false
    modal.hide("truck_vin_sam")
    onHide()
  }
}

const onHide = () => {
  samsaraVinNumber.value = ""
  truckIdObject.value = null
  formLoading.value = false
}

const onBeforeShow = async ({ id, samsara_vin }: { id: number | null; samsara_vin: string }) => {
  if (!id) return
  await nextTick(() => {
    truckIdObject.value = id
    samsaraVinNumber.value = samsara_vin
  })
}
</script>

<template>
  <Modal
    id="truck_vin_sam"
    :label="$t('actions.samsara_vin_number')"
    :loading="formLoading"
    :close-on-backdrop="false"
    @hide="onHide"
    @before-show="onBeforeShow"
  >
    <form class="grid gap-4 p-4">
      <FormGroup v-slot="{ id }" :label="$t('labels.vin_number')">
        <Input :id v-model="samsaraVinNumber" :placeholder="$t('placeholders.write_vin_number')" />
      </FormGroup>
    </form>

    <template #footer="{ hide }">
      <div class="modal-footer">
        <Button color="danger" :label="$t('actions.cancel')" @click="modal.hide('truck_vin_sam')" />
        <Button :label="$t('actions.submit')" @click="onSave" />
      </div>
    </template>
  </Modal>
</template>

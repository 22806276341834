<script setup lang="ts">
import { ButtonIcon, Icon } from "$/ui"
import { useLayoutStore } from "~/layout"

import HeaderAmountItem from "~/management/components/HeaderAmountItem.vue"
import { useSessionStore } from "~/session"

import { useFuelService, useFuelStore } from "~/management/fuel"
import { useManagementService, useManagementStore } from "~/management"
import { useUsersService, useUsersStore } from "~/management/users"
import { RolePermissionsModel } from "~/management/roles"
import Select from "@vueform/multiselect"

const { t } = useI18n()
const sidebar = useSidebar()

const todaysCount = ref<number>(0)

const store = useLayoutStore()
const management = useManagementStore()
const fuelStore = useFuelStore()
const usersStore = useUsersStore()
const sessionsStore = useSessionStore()

const { selectUsers } = storeToRefs(usersStore)
const { cardList, driverList, truckList, companyList } = storeToRefs(management)
const { query, listLoading, bookerIds, oldVal, companyIds } = storeToRefs(fuelStore)
const { profile } = storeToRefs(sessionsStore)

const { fetchDriverList, fetchTruckList, fetchCardList, getCompanyLazySelect } = useManagementService()
const { getFuelSuggestions, getTodaySuggestionCount } = useFuelService()
const { getUsersLazySelect } = useUsersService()

const refreshFilter = (): void => {
  query.value.page = 0
  getFuelSuggestions()
}

const handleCardKeydown = (event: KeyboardEvent) => {
  const key = event.key
  if (!/^\d$/.test(key) && key !== "Backspace" && key !== "Delete" && key !== "ArrowLeft" && key !== "ArrowRight") {
    event.preventDefault()
  }
}

const openRightSidebar = (val: string) => {
  const currentOldVal = oldVal.value
  oldVal.value = val
  store.sidebarIsCollapsed = true
  sidebar.show(val)
  nextTick(() => {
    sidebar.hide(currentOldVal)
  })
}

const statusList = ref<any>([
  {
    label: "Active",
    value: "ACTIVE"
  },
  {
    label: "Unassigned",
    value: "UNASSIGNED"
  },
  {
    label: "Vacation",
    value: "VACATION"
  }
])

onMounted(() => {
  getTodaySuggestionCount(todaysCount)
})
onUnmounted(() => {
  query.value = {
    truckId: null,
    driverId: null,
    companyIds: null,
    cardId: "",
    page: 0,
    size: 100,
    status: null
  }
  companyIds.value = []
})

const filteredCompany = () => {
  nextTick(() => {
    query.value.page = 0
    getFuelSuggestions()
  })
}

const refresh = () => {
  query.value.page = 0
  getFuelSuggestions()
  getTodaySuggestionCount(todaysCount)
}
</script>

<template>
  <TableHeader>
    <template #left>
      <div class="flex w-full flex-wrap items-center gap-4">
        <div>
          <LazySelect
            v-model="query.driverId"
            value-prop="id"
            content-refreshable
            :options="driverList"
            :placeholder="t('labels.driver')"
            :fetch="fetchDriverList"
            :get-option-label="(item: any) => fullName(item?.first_name, item?.last_name) || ''"
            @update:model-value="refreshFilter"
          />
        </div>
        <div>
          <LazySelect
            v-model="bookerIds"
            mode="multiple"
            class="tags-select min-w-[200px]"
            value-prop="id"
            tooltip
            characters="bookers"
            content-refreshable
            :hide-selected="false"
            :close-on-select="false"
            :options="selectUsers"
            :placeholder="t('placeholders.select_booker')"
            :fetch="getUsersLazySelect"
            :get-option-label="
              (item: any) =>
                item?.nickname + ' ' + fullName(item?.firstname, item?.lastname) + ' ' + `(${item?.truck_count})`
            "
            @update:model-value="refreshFilter"
          >
            <template #option="{ option }">
              <span class="w-4/5">
                {{ option?.nickname + " " + fullName(option?.firstname, option?.lastname) }}
              </span>
              <span class="absolute right-2"> ({{ option?.truck_count }}) </span>
            </template>
          </LazySelect>
        </div>
        <div>
          <LazySelect
            v-model="query.truckId"
            value-prop="id"
            content-refreshable
            :options="truckList"
            :placeholder="t('placeholders.select_truck')"
            :fetch="fetchTruckList"
            :get-option-label="(item: any) => item.truck_id || ''"
            @update:model-value="refreshFilter"
          />
        </div>
        <div>
          <LazySelect
            v-model="query.cardId"
            value-prop="id"
            content-refreshable
            :options="cardList"
            :placeholder="t('placeholders.select_card')"
            :fetch="fetchCardList"
            :get-option-label="(item: any) => item.number || ''"
            @update:model-value="refreshFilter"
            @keydown="handleCardKeydown"
          />
        </div>
        <div>
          <LazySelect
            v-model="companyIds"
            mode="multiple"
            class="tags-select min-w-[200px]"
            value-prop="id"
            tooltip
            characters="companies"
            content-refreshable
            :hide-selected="false"
            :close-on-select="false"
            :options="companyList"
            :placeholder="t('placeholders.all_companies')"
            :fetch="getCompanyLazySelect"
            :get-option-label="(item: any) => item.name || ''"
            @clear="getFuelSuggestions"
            @update:model-value="filteredCompany"
          />
        </div>

        <div>
          <Select
            v-model="query.status"
            searchable
            label="label"
            :options="statusList"
            append-to-body
            :placeholder="t('placeholders.select_status')"
            class="select-user min-w-36 !rounded-none"
            @update:model-value="refreshFilter"
          />
        </div>
      </div>
    </template>

    <div
      class="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 lg:justify-end lg:gap-8"
    >
      <HeaderAmountItem :amount="todaysCount" :description="$t('labels.today_suggests')" class="w-full sm:w-auto" />

      <div class="flex flex-col space-y-2 sm:flex-row sm:items-center sm:gap-5 sm:space-y-0">
        <div class="grid grid-cols-2 gap-2 sm:flex sm:flex-row">
          <Button
            v-if="profile.authorities.has(RolePermissionsModel.CAN_SUGGESTION_CREATE)"
            @click="openRightSidebar('book')"
          >
            {{ $t("actions.book") }}
          </Button>
          <Button
            v-if="profile.authorities.has(RolePermissionsModel.CAN_TRUCK_ASSIGN_BOOKER)"
            color="success"
            @click="openRightSidebar('assign')"
          >
            {{ $t("actions.assign") }}
          </Button>
          <Button
            v-if="
              profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_DEACTIVATE) &&
              profile.authorities.has(RolePermissionsModel.CAN_EFS_CARD_ACTIVATE)
            "
            color="warning"
            @click="openRightSidebar('deactivation')"
          >
            {{ $t("actions.deactivate") }}
          </Button>
        </div>

        <ButtonIcon variant="ghost" color="success" :loading="listLoading" class="w-full" @click="refresh">
          <Icon icon="lucide:refresh-ccw" :width="20" />
        </ButtonIcon>
      </div>
    </div>
  </TableHeader>
</template>

export interface RoleModel {
  id: number
  name: string
  description: string
  enabled: boolean
  created: string
  createdBy: number
  lastModifiedBy: number
}

export enum RolePermissionsModel {
  // PAGE ACCESS
  CAN_ACCESS_TRUCK_PAGE = "CAN_ACCESS_TRUCK_PAGE",
  CAN_ACCESS_SETTINGS_PAGE = "CAN_ACCESS_SETTINGS_PAGE",
  CAN_ACCESS_FUEL_BOOK_PAGE = "CAN_ACCESS_FUEL_BOOK_PAGE",
  CAN_ACCESS_CARDS_PAGE = "CAN_ACCESS_CARDS_PAGE",
  CAN_ACCESS_BOOKERS_PAGE = "CAN_ACCESS_BOOKERS_PAGE",
  CAN_ACCESS_TRANSACTIONS_PAGE = "CAN_ACCESS_TRANSACTIONS_PAGE",
  CAN_ACCESS_PRICES__STORES_PAGE = "CAN_ACCESS_PRICES__STORES_PAGE",
  CAN_ACCESS_IDLING_PAGE = "CAN_ACCESS_IDLING_PAGE",
  CAN_ACCESS_USERS_PAGE = "CAN_ACCESS_USERS_PAGE",
  CAN_ACCESS_ROLES_PAGE = "CAN_ACCESS_ROLES_PAGE",

  // TRUCK
  CAN_TRUCK_ASSIGN_BOOKER = "CAN_TRUCK_ASSIGN_BOOKER",
  CAN_TRUCK_ASSIGN_CARD = "CAN_TRUCK_ASSIGN_CARD",

  // FUEL STATION
  CAN_FUEL_STATION_PAGE_GET = "CAN_FUEL_STATION_PAGE_GET",
  CAN_FUEL_STATION_STORES_PAGE_GET = "CAN_FUEL_STATION_STORES_PAGE_GET",
  CAN_FUEL_STATION_CREATE = "CAN_FUEL_STATION_CREATE",
  CAN_FUEL_STATION_DEACTIVATE = "CAN_FUEL_STATION_DEACTIVATE",
  CAN_FUEL_STATION_ACTIVATE = "CAN_FUEL_STATION_ACTIVATE",
  CAN_TRUCK_SET_TANK = "CAN_TRUCK_SET_TANK",
  CAN_FUEL_STATION_EDIT = "CAN_FUEL_STATION_EDIT",

  // IDLING
  CAN_IDLING_PAGE_GET = "CAN_IDLING_PAGE_GET",
  CAN_IDLING_WITH_TRUCKS_PAGE_GET = "CAN_IDLING_WITH_TRUCKS_PAGE_GET",
  CAN_IDLING_SET_REASON = "CAN_IDLING_SET_REASON",

  // ROLE
  CAN_ROLE_PAGE_GET = "CAN_ROLE_PAGE_GET",
  CAN_ROLE_CREATE = "CAN_ROLE_CREATE",
  CAN_ROLE_EDIT = "CAN_ROLE_EDIT",
  CAN_ROLE_DEACTIVATE = "CAN_ROLE_DEACTIVATE",
  CAN_ROLE_ACTIVATE = "CAN_ROLE_ACTIVATE",

  // SUGGESTION
  CAN_SUGGESTION_GET_ONE = "CAN_SUGGESTION_GET_ONE",
  CAN_SUGGESTION_CREATE = "CAN_SUGGESTION_CREATE",

  // SUGGESTION STOP
  CAN_SUGGESTION_STOP_EDIT = "CAN_SUGGESTION_STOP_EDIT",
  CAN_SUGGESTION_STOP_DELETE = "CAN_SUGGESTION_STOP_DELETE",

  // BOOKERS PAGE TABS
  CAN_SUGGESTION_STOP_BOOKINGS_PAGE_GET = "CAN_SUGGESTION_STOP_BOOKINGS_PAGE_GET",
  CAN_SUGGESTION_STOP_BOOKINGS_STATS_PAGE_GET = "CAN_SUGGESTION_STOP_BOOKINGS_STATS_PAGE_GET",

  // USER
  CAN_USER_PAGE_GET = "CAN_USER_PAGE_GET",
  CAN_USER_CREATE = "CAN_USER_CREATE",
  CAN_USER_EDIT = "CAN_USER_EDIT",
  CAN_USER_DEACTIVATE = "CAN_USER_DEACTIVATE",
  CAN_USER_ACTIVATE = "CAN_USER_ACTIVATE",

  // CARD
  CAN_CARD_PAGE_GET = "CAN_CARD_PAGE_GET",
  CAN_EFS_CARD_SET_LIMIT = "CAN_EFS_CARD_SET_LIMIT",
  CAN_EFS_CARD_ASSIGN = "CAN_EFS_CARD_ASSIGN",
  CAN_EFS_CARD_DEACTIVATE = "CAN_EFS_CARD_DEACTIVATE",
  CAN_EFS_CARD_ACTIVATE = "CAN_EFS_CARD_ACTIVATE",
  CAN_CARD_HISTORY_PAGE_GET = "CAN_CARD_HISTORY_PAGE_GET",
  CAN_EFS_CARD_SET_AVAILABLE_LIMIT = "CAN_EFS_CARD_SET_AVAILABLE_LIMIT",

  // TRANSACTION
  CAN_TRANSACTION_PAGE_GET = "CAN_TRANSACTION_PAGE_GET",

  // SYSTEM SETTING
  CAN_SETTINGS_CHANGE_STATUS = "CAN_SETTINGS_CHANGE_STATUS",
  CAN_SETTINGS_EDIT = "CAN_SETTINGS_EDIT",

  //TRUCK
  CAN_TRUCK_PAGE_GET = "CAN_TRUCK_PAGE_GET",
  CAN_TRUCK_SET_SAMSARA_VIN = "CAN_TRUCK_SET_SAMSARA_VIN"
}

export interface RoleModelWithAuthorities extends Omit<RoleModel, "createdBy" | "lastModifiedBy"> {
  authorities: RolePermissionsModel[]
}

export class RolesAssignModel {
  id?: Nullable<number> = null
  name: string = ""
  description: string = ""
  authorities: RolePermissionsModel[] = []

  constructor(data?: RolesAssignModel | RoleModelWithAuthorities) {
    if (data) {
      this.id = data.id || null
      this.name = data.name || ""
      this.description = data.description || ""
      this.authorities = data.authorities || []
    }
  }
}
